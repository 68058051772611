import React from 'react'
import * as Styled from '../style'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { constants } from '../../../utils/constant'
const BetIDInput = () => {
  return (
    <Styled.BetIDInput className="display-flex">
      <TextField
        id="outlined-basic"
        label={constants.Bet_ID}
        variant="outlined"
      />
      <Button variant="contained" className="submitBtn">
        {constants.Submit}
      </Button>
    </Styled.BetIDInput>
  )
}

export default BetIDInput
