import { useEffect, useReducer, useState } from 'react'
import { ActionTypes } from './ActionTypes'
import { toast } from 'react-toastify'
import { constants } from '../../constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import { sendOTP, verifyOTP } from '../../redux/auth/action'
import { useNavigate } from 'react-router-dom'

export default function useSignup() {
  const navigate = useNavigate()
  // Initial state for the login form
  const initialState = {
    phone: '',
    country: '',
    showOTP: false,
    otpResent: false,
    timer: 60,
    otpSent: false,
    otp: '',
    password: '',
  }

  // Reducer function to handle state changes based on actions
  const loginReducer = (state, action) => {
    switch (action.type) {
      case ActionTypes.SET_PHONE:
        return { ...state, phone: action.payload }
      case ActionTypes.SET_COUNTRY:
        return { ...state, country: action.payload }
      case ActionTypes.SET_PASSWORD:
        return { ...state, password: action.payload }
      case ActionTypes.SHOW_OTP:
        return { ...state, showOTP: true }
      case ActionTypes.OTP_RESENT:
        return { ...state, otpResent: true, timer: 60 }
      case ActionTypes.DECREMENT_TIMER:
        return { ...state, timer: state.timer - 1 }
      case ActionTypes.OTP_SENT:
        return { ...state, otpSent: true }
      case ActionTypes.RESET_OTP_SENT:
        return { ...state, otpSent: false }
      case ActionTypes.SET_OTP:
        return { ...state, otp: action.payload }
      default:
        return state
    }
  }

  // State management using useReducer
  const [state, dispatch] = useReducer(loginReducer, initialState)
  // State to manage timer running status
  const [timerRunning, setTimerRunning] = useState(false)

  const dispatchRedux = useDispatch()
  const loader = useSelector((state) => state.loading)

  // Validation function for form inputs
  const validateForm = () => {
    const { phone, password, otp, showOTP } = state

    const trimPhone = phone.trim()
    const trimPassword = password.trim()

    // Named constants
    const {
      MOBILE_REQUIRED,
      INVALID_MOBILE,
      PASSWORD_REQUIRED,
      INVALID_PASSWORD,
      OTP_REQUIRED,
    } = constants

    const showToastError = (message) => {
      toast.error(message)
      return false
    }

    // Validation functions
    const isMobileValid = () => {
      if (trimPhone === '') {
        return showToastError(MOBILE_REQUIRED)
      }
      const phoneRegex = /^\+?\d{1,3}\s?\d{6,14}$/ // Validates international phone numbers
      if (!phoneRegex.test(trimPhone)) {
        return showToastError(INVALID_MOBILE)
      }
      return true
    }

    const isPasswordValid = () => {
      if (trimPassword === '') {
        return showToastError(PASSWORD_REQUIRED)
      }
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/ // Requires at least one letter and one digit
      if (!passwordRegex.test(trimPassword) || trimPassword.length < 6) {
        return showToastError(INVALID_PASSWORD)
      }
      return true
    }

    const isOTPValid = () => {
      if (showOTP && otp.trim() === '') {
        return showToastError(OTP_REQUIRED)
      }
      return true
    }

    // Perform validations
    if (!isMobileValid() || !isPasswordValid() || !isOTPValid()) {
      return false
    }

    return true
  }

  // Effect to handle countdown timer for OTP resend
  useEffect(() => {
    let interval
    if (state.otpSent && state.timer > 0) {
      setTimerRunning(true)
      interval = setInterval(() => {
        dispatch({ type: ActionTypes.DECREMENT_TIMER })
      }, 1000)
    } else {
      setTimerRunning(false)
      clearInterval(interval)
      dispatch({ type: ActionTypes.RESET_OTP_SENT })
    }

    // Cleanup function to clear interval
    return () => clearInterval(interval)
  }, [state.otpSent, state.timer])

  // Method to handle form submission
  const handleFormSubmit = (event) => {
    event.preventDefault()
    if (validateForm()) {
      if (state.showOTP) {
        if (loader.isLoading) return
        dispatchRedux(verifyOTP(state, navigate))
      } else {
        if (loader.isLoading) return
        dispatchRedux(sendOTP({ phone: state.phone }))
        dispatch({ type: ActionTypes.SHOW_OTP })
        dispatch({ type: ActionTypes.OTP_SENT })
      }
    }
  }

  // Method to resend OTP
  const handleResendOTP = () => {
    // Prevent multiple OTP requests before the timer expires
    if (!state.otpSent) {
      dispatch({ type: ActionTypes.OTP_RESENT })
      if (loader.isLoading) return
      dispatchRedux(sendOTP({ phone: state.phone }))
    } else {
      toast.info(constants.OTP_ALREADY_SENT)
    }
  }

  // Return state and methods for login form
  return {
    state,
    timerRunning,
    handleFormSubmit,
    handleResendOTP,
    dispatch,
  }
}
