import React, { createContext, useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'

// Create a context
const SocketContext = createContext()

// Create a provider component
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    const connectSocket = () => {
      // Create a new socket connection
      const newSocket = io('wss://odds.100panel.org', {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: 2, // Retry limit
        reconnectionDelay: 1000, // 1 second delay between retries
        reconnectionDelayMax: 5000, // Maximum delay of 5 seconds
      })
      newSocket.on('connect', () => {
        console.log('Socket connected:', newSocket.id)
      })

      newSocket.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason)
        if (reason === 'transport error') {
          console.error('Transport error occurred, attempting reconnection')
        }
      })

      newSocket.on('connect_error', (error) => {
        console.error('Socket connection error:', error)
      })

      newSocket.on('connect_timeout', () => {
        console.error('Socket connection timeout')
      })

      setSocket(newSocket)
    }

    connectSocket()

    // Cleanup function to disconnect the socket when the component unmounts
    return () => {
      if (socket) {
        socket.disconnect()
        socket.off('connect_error')
        socket.off('connect_timeout')
        socket.off('disconnect')

        // Add more events to be turned off as needed
      }
    }
  }, [])

  const turnOffEmit = (event) => {
    if (socket) {
      socket.off(event)
    }
  }

  const disconnectAll = () => {
    if (socket) {
      socket.disconnect()
    }
  }

  const reconnectSocket = () => {
    if (socket) {
      socket.connect()
    }
  }

  return (
    <SocketContext.Provider
      value={{ socket, turnOffEmit, disconnectAll, reconnectSocket }}
    >
      {children}
    </SocketContext.Provider>
  )
}

// Custom hook to use socket
export const useSocket = () => {
  return useContext(SocketContext)
}
