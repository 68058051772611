import styled from 'styled-components'
import { color } from '../../color/color'

export const Container = styled.div`
  background-color: ${color._343435};
  padding: 5px 8px;
  color: ${color._FFFFFF};
  margin-right: 2px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 2px;
  width: 48px;
`
