export const cardData = {
  headers: ['Odds', 'Pair Plus', 'Total'],
  rows: [
    {
      label: 'Player 1',
      values: [
        { value: '1.86', isSuspended: false },
        { value: 'Pair Plus 1', isSuspended: true },
        { value: '3.67', isSuspended: false },
      ],
    },
    {
      label: 'Player 2',
      values: [
        { value: '2.34', isSuspended: false },
        { value: 'Pair Plus 2', isSuspended: false },
        { value: '4.46', isSuspended: false },
      ],
    },
    {
      label: 'Player 3',
      values: [
        { value: '2.34', isSuspended: false },
        { value: 'Pair Plus 3', isSuspended: false },
        { value: '4.46', isSuspended: true },
      ],
    },
    {
      label: 'Player 4',
      values: [
        { value: '2.34', isSuspended: false },
        { value: 'Pair Plus 4', isSuspended: false },
        { value: '4.46', isSuspended: true },
      ],
    },
    {
      label: 'Player 5',
      values: [
        { value: '2.34', isSuspended: true },
        { value: 'Pair Plus 5', isSuspended: false },
        { value: '4.46', isSuspended: false },
      ],
    },
    {
      label: 'Player 6',
      values: [
        { value: '2.34', isSuspended: true },
        { value: 'Pair Plus 7', isSuspended: true },
        { value: '4.46', isSuspended: false },
      ],
    },
  ],
}
