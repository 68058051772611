export const ActionTypes = {
  SET_PHONE: 'SET_PHONE',
  SET_COUNTRY: 'SET_COUNTRY',
  SHOW_OTP: 'SHOW_OTP',
  OTP_RESENT: 'OTP_RESENT',
  DECREMENT_TIMER: 'DECREMENT_TIMER',
  OTP_SENT: 'OTP_SENT',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_OTP: 'SET_OTP',
}
