import axios from 'axios'
import { toast } from 'react-toastify'

const getToastPosition = () => {
  return {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  }
}

const interceptor = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_RDY,
  headers: {
    'Content-Type': 'application/json',
  },
})

interceptor.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['authorizationToken'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    console.error('Request error:', error)
    return Promise.reject(error)
  },
)

interceptor.interceptors.response.use(
  (response) => {
    const method = response.config.method.toUpperCase()
    if (
      response?.data?.status >= 200 &&
      response?.data?.status < 300 &&
      method !== 'GET'
    ) {
      toast.success(response?.data?.msg, getToastPosition())
    } else if (
      response?.data?.status >= 300 &&
      response?.data?.status < 400 &&
      method !== 'GET'
    ) {
      toast.warning(response?.data?.msg, getToastPosition())
    } else if (method !== 'GET') {
      toast.error(response?.data?.msg, getToastPosition())
    }
    return response.data
  },
  (error) => {
    console.error('Response error:', error)
    if (error.response) {
      return error.response
    } else {
      return Promise.reject(error)
    }
  },
)

export default interceptor
