import React from 'react'
import * as Styled from '../style'
import SelectVal from './SelectVal'
import { SingleCompArr } from '../constant'
const SingleComp = () => {
  return (
    <Styled.SingleComp columnCheck={7}>
      {SingleCompArr.map((item, index) => {
        return (
          <SelectVal val={item.val} key={item + index} subVal={item.subVal} />
        )
      })}
    </Styled.SingleComp>
  )
}

export default SingleComp
