import React from 'react'
import { OverviewRow } from '../styles'

const OverviewRows = ({ keyName, value }) => (
  <OverviewRow>
    <side>{keyName} :</side>
    <aside>{value} </aside>
  </OverviewRow>
)

export default OverviewRows
