import React, { useState } from 'react'
import { StyledInput, StyledInputGroupText } from '../styles'
import { toast } from 'react-toastify'
import { getToastPosition } from '../../../utils/constant'

const InputAddon = ({
  addon = 20,
  index,
  setValues,
  values,
  setInputDisabled,
  setStoredValues,
  storedValues,
}) => {
  const [inputValue, setInputValue] = useState(addon)

  const handleChange = (e) => {
    // For showing Validation
    const newValues = [...values]
    const newLocalValues = [...storedValues]

    newValues[index] = e.target.value
    newLocalValues[index] = e.target.value

    // Check if the value exceeds the max value
    if (newValues.filter((v) => v === e.target.value).length > 1) {
      toast.warn('Each stack value must be unique', getToastPosition())
      setInputDisabled(true)
    } else if (e.target.value > 5000000) {
      toast.warn('value cannot exceed 5000000', getToastPosition())
      setInputDisabled(true)
      return
    } else {
      setInputDisabled(false)
    }
    setStoredValues(newLocalValues)
    setValues(newValues)
    // for storing values
    const value = parseFloat(e.target.value)
    setInputValue(isNaN(value) ? '' : value)
  }

  return (
    <div className="input-group mb-3">
      <div>
        <StyledInputGroupText className="input-box">
          {inputValue}
        </StyledInputGroupText>
      </div>
      <StyledInput
        type="number"
        className="form-control"
        aria-label="Username"
        aria-describedby="basic-addon1"
        onChange={(e) => handleChange(e)}
        value={inputValue}
      />
    </div>
  )
}

export default InputAddon
