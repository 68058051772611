import {
  getAuthData,
  getSportsDetails,
  getUserDetails,
  removeAuthData,
  setAuthData,
} from '../../utils/utils'
import ACTIONTYPES from './actionType'

const { token, userId, phone, isWithdrawPassword, username, phoneCountryCode } =
  getAuthData()

const { userDetails } = getUserDetails()
const { sportsData } = getSportsDetails()
const initialState = {
  isAuthenticated: !!token,
  token: token,
  errorMessage: null,
  userId: userId,
  otp: null,
  otpverified: false,
  limit: {},
  phone: phone,
  isWithdrawPassword: isWithdrawPassword,
  isModalClose: false,
  isDemoUserName: null,
  isOtpSent: false,
  username: username,
  userData: userDetails,
  phoneCountryCode: phoneCountryCode,
  profileData: {},
  unSettledBets: [],
  accountStatementData: [],
  sportsData: sportsData,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONTYPES.LOGIN_SUCCESS:
      setAuthData({
        token: action.payload.token,
        userId: action.payload.userId,
        phone: action.payload.phone,
        isWithdrawPassword: action.payload.isWithdrawPassword,
        username: action.payload.username,
        phoneCountryCode: action.payload.phoneCountryCode,
      })
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
        errorMessage: null,
        limit: action.payload.limit,
        phone: action.payload.phone,
        isWithdrawPassword: action.payload.isWithdrawPassword,
        isModalClose: true,
        username: action.payload.username,
        phoneCountryCode: action.payload.phoneCountryCode,
      }

    case ACTIONTYPES.LOGOUT_SUCCESS:
      removeAuthData()
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        userId: null,
        errorMessage: null,
        limit: {},
        phone: null,
        isWithdrawPassword: 0,
        isModalClose: false,
        isDemoUserName: null,
        username: '',
        userData: {},
        phoneCountryCode: null,
      }
    case ACTIONTYPES.LOGIN_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case ACTIONTYPES.LOGOUT_FAIL:
      return {
        ...state,
        isDemoUserName: null,
        errorMessage: action.payload,
      }
    case ACTIONTYPES.OTP_SENT_SUCCESS:
      return {
        ...state,
        otp: action.payload,
      }
    case ACTIONTYPES.OTP_SENT_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case ACTIONTYPES.OTP_VERIFY_SUCCESS:
      return {
        ...state,
        otpverified: true,
        isOtpSent: true,
      }
    case ACTIONTYPES.OTP_VERIFY_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case ACTIONTYPES.CHANGE_PASSOWRD_SUCCESS:
      return {
        ...state,
      }
    case ACTIONTYPES.CHANGE_PASSOWRD_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case ACTIONTYPES.LOGIN_DEMO_SUCCESS:
      return {
        isDemoUserName: action.payload.username,
      }
    case ACTIONTYPES.LOGOUT_DEMO_SUCCESS:
      return {
        isDemoUserName: null,
      }
    case ACTIONTYPES.USER_DETAILS_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      }
    case ACTIONTYPES.PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        profileData: action.payload,
      }
    case ACTIONTYPES.PROFILE_DETAILS_FAIL:
      return {
        ...state,
        profileData: {},
      }
    case ACTIONTYPES.USER_DETAILS_FAIL:
      return {
        ...state,
        userData: {},
      }
    case ACTIONTYPES.ACCOUNTSTATEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        accountStatementData: action.payload,
      }
    case ACTIONTYPES.ACCOUNTSTATEMENT_DETAILS_FAIL:
      return {
        ...state,
        accountStatementData: [],
      }
    case ACTIONTYPES.UNSETELLED_BETS_DETAILS_SUCCESS:
      return {
        ...state,
        unSettledBets: action.payload,
      }
    case ACTIONTYPES.UNSETELLED_BETS_DETAILS_FAIL:
      return {
        ...state,
        unSettledBets: [],
      }
    case ACTIONTYPES.SPORTS_DETAILS_SUCCESS:
      return {
        ...state,
        sportsData: action.payload,
      }
    case ACTIONTYPES.SPORTS_DETAILS_FAIL:
      return {
        ...state,
        sportsData: [],
      }
    default:
      return state
  }
}

export default authReducer
