import { color } from '../../color/color'
import deposit from '../../assets/images/deposit.png'
import withdrawal from '../../assets/images/withdrawal.png'

export const _constants = {}

export const item = [
  {
    id: 1,
    name: 'Deposit',
    color: color._006400,
    image: deposit,
    link: '/deposit',
  },
  {
    id: 2,
    name: 'Withdrawal',
    color: color._8B0000,
    image: withdrawal,
    link: '/withdraw',
  },
]
