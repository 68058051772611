import React from 'react'
import * as Styled from '../style'
import { NumberArr } from '../constant'
import SelectVal from './SelectVal'

const NumberSep = ({ seperateDiv }) => {
  return (
    <Styled.NumberSep>
      <Styled.NumberSepLeft>
        <Styled.SingleComp columnCheck={5}>
          {NumberArr.map((item, index) => {
            return (
              <SelectVal val={item} key={item + index} subVal={item.subVal} />
            )
          })}
        </Styled.SingleComp>
      </Styled.NumberSepLeft>
      <Styled.NumberSepRight>
        <SelectVal val={seperateDiv} height="206px" />
      </Styled.NumberSepRight>
    </Styled.NumberSep>
  )
}

export default NumberSep
