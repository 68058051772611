import React, { useState } from 'react'
import * as Styled from '../style'
import { FiMinus } from 'react-icons/fi'
import { FaPlus } from 'react-icons/fa6'
import { ImCross } from 'react-icons/im'
import { constants } from '../../../utils/constant'
import { color } from '../../../color/color'
const BetTab = ({
  matchTitle = 'Sri Lanka Vs Uganda',
  betTitle = 'Winner (incl. super over) @ Uganda',
  setAddedBet,
  oddsValue,
  setActiveBetId,
}) => {
  const [stakeValue, setStakeValue] = useState(0)
  const betOptions = [100, 500, 1000, 5000, 10000, 50000]

  const handleBetValues = (value) => {
    setStakeValue(stakeValue + value)
  }
  const handleRemoveBet = () => {
    setActiveBetId('')
    setAddedBet(false)
  }

  return (
    <Styled.BetTab>
      <Styled.CrossDiv onClick={handleRemoveBet}>
        <ImCross />
      </Styled.CrossDiv>
      <Styled.MatchTitle>{matchTitle}</Styled.MatchTitle>
      <Styled.BetTitle>{betTitle}</Styled.BetTitle>
      <Styled.OddsStake className="display-flex">
        <Styled.Odds className="display-flex">
          <Styled.OddsStakeTitle>{constants.Odds}</Styled.OddsStakeTitle>
          <Styled.OddsInput className="display-flex">
            <Styled.OddsIcon className="odds-input-child">
              <FiMinus color={color._FFFFFF} />
            </Styled.OddsIcon>
            <Styled.OddsValue className="odds-input-child">
              {oddsValue}
            </Styled.OddsValue>
            <Styled.OddsIcon className="odds-input-child">
              <FaPlus color={color._FFFFFF} />
            </Styled.OddsIcon>
          </Styled.OddsInput>
        </Styled.Odds>

        <Styled.Stake className="display-flex">
          <Styled.OddsStakeTitle>{constants.Stake}</Styled.OddsStakeTitle>
          <Styled.StakeInput
            type="number"
            value={stakeValue === 0 ? '' : stakeValue}
            onChange={(e) => setStakeValue(e.target.value)}
          />
        </Styled.Stake>
      </Styled.OddsStake>
      <Styled.Clear onClick={() => setStakeValue(0)}>
        {constants.Clear}
      </Styled.Clear>

      <Styled.BetValues>
        {betOptions.map((elem, indx) => (
          <Styled.BetOption
            key={elem + indx}
            onClick={() => handleBetValues(elem)}
          >{`+${elem}`}</Styled.BetOption>
        ))}
      </Styled.BetValues>
      <Styled.BetTabFooter className="display-flex">
        <Styled.BFootLeft>
          <Styled.Profit>{constants.Profit}</Styled.Profit>
          <Styled.Pts>
            {constants.Pts} {0.0}
          </Styled.Pts>
        </Styled.BFootLeft>
        <Styled.PlaceBet>{constants.Place_Bet}</Styled.PlaceBet>
      </Styled.BetTabFooter>
      <Styled.BetMessage>{constants.Market_Not_Active}</Styled.BetMessage>
    </Styled.BetTab>
  )
}

export default BetTab
