import styled from 'styled-components'
import { color } from '../../color/color'

export const ImageOption = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Img = styled.img`
  cursor: pointer;
  height: auto;
  width: 100%;
  @media (max-width: 991px) {
    height: 100%;
  }
`

export const Title = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  background: ${color._222};
  color: ${color._fff};
  padding: 2px 0;
  white-space: nowrap;
`

export const ImageDiv = styled.div`
  width: 16.66%;
  padding: 1px;
  @media (max-width: 991px) {
    width: 25%;
  }
`
