import React, { useState } from 'react'
import { Box, Container, ProfileSpan, Row, TopContainer } from './styles'
import profileImage from '../../assets/images/profile_image.png'
import ProfileImage from './ProfileUtils/ProfileImage'
import TabComponent from './ProfileUtils/TabComponent'
import { constants } from '../../utils/constant'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'

const ProfileContainer = () => {
  const location = useLocation()
  const username = useSelector((state) => state.auth.username)
  const phone = useSelector((state) => state.auth.phone)
  const isDemoUserName = useSelector((state) => state.auth.isDemoUserName)

  const [activeTab, setActiveTab] = useState(
    location?.state?.profileTab || 'Overview',
  )

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  const getPhoneDetails = (phone) => {
    if (phone?.length === 0) {
      return username?.toUpperCase()
    } else if (/^0+$/.test(phone)) {
      return username?.toUpperCase()
    } else {
      return phone
    }
  }

  const phoneNumber = getPhoneDetails(phone)

  return (
    <Container>
      <Row>
        <TopContainer>
          <ProfileSpan>{constants.Profile}</ProfileSpan>
        </TopContainer>
      </Row>
      <Row>
        <ProfileImage
          userImage={profileImage}
          username={phoneNumber || isDemoUserName || 'DEMO123'}
        />
        <Box flex={3}>
          <TabComponent
            activeTab={activeTab}
            handleTabChange={handleTabChange}
          />
        </Box>
      </Row>
    </Container>
  )
}

export default ProfileContainer
