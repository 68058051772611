import styled from 'styled-components'
import { color } from '../../../color/color'

export const OddView = styled.div`
  margin-top: 3px;
`
export const CasinoTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #f7f7f7;
  color: #333333;
  margin-top: -2px;
`
export const CasinoTableFullBox = styled.div`
  border: 0;
  width: 100%;
  background-color: #f7f7f7;
`
export const CasinoTableBox = styled.div`
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
export const CasinoVideoCardBox = styled.div`
  background-color: #2c3e50d9;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
`
export const CmeterLow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 45px;
  width: 100%;
  & :first-child {
    width: 10%;
  }
`

export const CmeterHigh = styled.div`
  margin-top: 5px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 45px;
  width: 100%;
  & :first-child {
    width: 10%;
  }
`
export const TextFancy = styled.div`
  color: #fdcf13;
`
export const TextSuccess = styled.div`
  color: #17ec17 !important;
`
export const CmeterLowCards = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`
export const CardImg = styled.img`
  height: 40px;
  width: 32px;
  margin-bottom: 5px;
  margin-right: 5px;
`

export const CmeterHighCards = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  & :first-child {
    width: 32px !important;
  }
`
export const CasinoTableRightBox = styled.div`
  border-bottom: 1px solid #c7c8ca;
  cursor: pointer;
  padding: 5px;
  width: 49%;
  background-color: #f2f2f2;
  border-left: 1px solid #c7c8ca;
  border-right: 1px solid #c7c8ca;
  border-top: 1px solid #c7c8ca;
  img {
    height: 50px;
  }
`
export const TextInfo = styled.b`
  color: #097c93 !important;
`
export const CardOddBox = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-right: 10px;
`

export const CasinoNationBox = styled.div`
  min-height: 18px;
  position: relative;
  z-index: 1;
`
export const CmeterCardBox = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
