import styled from 'styled-components'
import { color } from '../../color/color'
import { Link } from 'react-router-dom'

export const SidebarLink = styled(Link)`
  display: flex;
  color: ${color._E9EFF8};
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color._F1F1F1};
  list-style: none;
  height: 39px;
  text-decoration: none;
  background: ${color._E9EFF8};
`
export const SidebarLabel = styled.span`
  margin-left: 10px;
  color: ${color._FFFFFF};
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
`
export const SvgSection = styled.div`
  svg {
    color: ${color._808080};
    width: 9px;
    margin-right: 10px;
  }
`
export const SidebarSubLabel = styled.span`
  color: ${({ _color }) => (_color ? _color : color._DC3545)};
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
`
export const DropdownLink = styled.div`
  background: ${color._FFFFFF};
  min-height: 39px;
  font-size: 12px;
  text-decoration: none;
  color: ${color._DC3545};
  border: 1px solid #f2edf3;
  &:hover {
    cursor: pointer;
  }
`

export const Box = styled.div`
  box-sizing: border-box;
`
export const MatchContainer = styled.div`
  background: ${color._E9EFF8};
  color: ${color._333333};
  font-weight: 600;
  font-size: 15px;
`
export const Col6 = styled.div`
  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: 50%;
  }
`
export const Img = styled.img`
  max-width: 100%;
  height: auto;
  width: 20px;
  height: 20px;
  max-width: 20px;
  margin-right: 3px;
  height: auto;
  vertical-align: middle;
`
export const SportsTitle = styled.span`
  &:before {
    content: '';
    background: linear-gradient(180deg, ${color._C10930} 0, ${color._C10930});
    clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
    width: 18px;
    height: calc(100% + 0px);
    position: absolute;
    right: -17px;
    top: 0;
  }
  background: ${color._C10930};
  text-transform: uppercase;
  position: relative;
  padding: 10px 12px;
  color: ${color._FFFFFF};
  display: inline-flex;
  width: max-content;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  font-family: 'Titillium Web', sans-serif;
  @media (max-width: 991px) {
    font-size: 11px;
  }
`

export const MinMax = styled.span`
  align-items: center;
  height: 40px;
  display: flex;
  color: ${color._000000};
  float: right;
  font-size: 10px;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  @media (max-width: 991px) {
    font-size: 9px;
  }
`
export const BetTableRow = styled.div`
  background: ${color._FFFFFF};
  color: ${color._1E1E1E};
  transition: 0.9s;
  &:hover {
    box-shadow: inset 0 0 10px 0px ${color._F9F9F9};
  }
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-shrink: 0;
  height: 42px;
  width: 100%;
  max-width: 100%;
  align-content: center;
  margin: 0px auto;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: end;
  }
`

export const ColMd6 = styled.div`
  width: 50%;
  position: relative;
`

export const GameBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
`
export const GameLeftColumn = styled.div`
  display: flex;
  align-items: center;
  width: 82%;
`
export const GameName = styled.div``
export const GameNameAnchor = styled.div`
  text-decoration: none;
`
export const TeamName = styled.p`
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 280px;
  width: 280px;
  color: rgb(51, 51, 51);
  font-size: 13px;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  text-decoration: none;
  line-height: normal;
  @media (max-width: 991px) {
    max-width: 150px;
  }
`
export const TeamEvent = styled.div`
  font-size: 10px;
  color: ${color._838383};
  font-weight: 500;
  text-decoration: none;
`
export const GameDate = styled.div`
  width: 78px;
  display: block;
  font-size: 9px;
  font-weight: 500;
  color: ${color._444444};
  background: ${color._E9EFF8};
  padding: 4px;
  margin: 0 4px;
  @media (max-width: 991px) {
    width: 50px;
    padding: 0;
    font-size: 8px;
  }
`
export const GameDateInplay = styled.div`
  width: 78px;
  display: block;
  font-size: 9px;
  font-weight: 500;
  color: ${color._444444};
  background: ${color._E9EFF8};
  padding: 4px;
  margin: 0 4px;
  @media (max-width: 991px) {
    width: 50px;
    padding: 0;
    font-size: 8px;
  }
  text-align: center;
  position: relative;
  background: none;
  border: none;
`

export const GameDateSpan = styled.span`
  @keyframes run {
    0% {
      left: 0;
      width: 5%;
    }
    50% {
      width: 100%;
    }
    100% {
      right: 0;
      width: 5%;
    }
  }

  color: ${color._03B37F};
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  &:after {
    content: '';
    background-color: ${color._03B37F};
    height: 2px;
    width: 50px;
    position: absolute;
    bottom: -1px;
    animation: linear infinite alternate;
    animation-name: run;
    animation-duration: 1.2s;
    -webkit-animation: linear infinite alternate;
    -webkit-animation-name: run;
    -webkit-animation-duration: 1.2s;
  }
`
export const GameDatePara = styled.p`
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  display: block;
  margin-bottom: 0;
`

export const GameIcons = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${color._7F7F7F};
`
export const GameIconAnchor = styled.a`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${color._7F7F7F};
`
export const GameIconSpan = styled.span`
  background: ${color._C10930};
  width: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  height: 20px;
  vertical-align: super;
  color: ${color._FFFFFF};
  line-height: 20px;
  font-size: 9px;
  font-weight: 700;
  margin-left: 3px;
`
export const GameIconImage = styled.img`
  width: 15px;
  vertical-align: baseline;
  cursor: pointer;
  @media (max-width: 991px) {
    width: 17px;
  }
`
export const ImageBox = styled.div`
  display: flex;
`

export const Col4 = styled.div`
  flex: 0 0 auto;
  width: 33.3%;
  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: 33.3%;
  }
  @media (max-width: 768px) {
    flex: 0 0 auto;
    width: 70%;
    &:nth-child(1) {
      display: none;
    }
    &:nth-child(3) {
      display: none;
    }
  }
`
export const BackLay = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: 'pointer';
`
export const BlBox = styled.div`
  background: ${({ _color }) => (_color ? _color : color._66D1FF)};
  width: 100%;
  padding: 0;
  text-align: center;
  margin: 0;
  cursor: 'pointer';
  position: relative;
  transition: all 0.5s ease 0s;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 42px;
  border-left: 1px solid ${color._F8F8F8};
`
export const BlBoxLay = styled.div`
  background: ${({ _color }) => (_color ? _color : color._66D1FF)};
  width: 100%;
  padding: 0;
  text-align: center;
  margin: 0;
  cursor: 'pointer';
  position: relative;
  transition: all 0.5s ease 0s;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 42px;
  border-left: 1px solid ${color._F8F8F8};
`
export const Span = styled.span`
  display: block;
  line-height: 1.1;
  font-family: 'Titillium Web', sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: rgb(51, 51, 51);
  cursor: 'pointer';
`

export const Em = styled.em`
  display: block;
  font-style: normal;
  font-size: 10px;
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  cursor: 'pointer';
`
export const Button = styled.button`
  color: #fff !important;
  background: linear-gradient(
    180deg,
    ${color._FA7E29} 0%,
    ${color._F6682F} 80%,
    ${color._F6682F} 100%
  );
  box-shadow: inset 0px -10px 20px 0px ${color._9F0101};
  width: 100%;
  font-weight: 700;
  border-radius: 5px;
  text-transform: uppercase;
  margin-right: 3px !important;
  font-size: 12px;
  padding: 5px 45px;
  position: relative;
  border: 1px solid ${color._C10930} !important;
`
export const Col2 = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`
export const Suspended = styled.div`
  display: flex;
  font-weight: 700;
  color: red;
  font-size: 13px;
  box-shadow: inset 6px 0 0px -5px #f00, inset -6px 0 0px -5px red;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #fff;
  opacity: 0.9;
  z-index: 1;
  font-family: 'Titillium Web', sans-serif;
  @media (max-width: 768px) {
    width: 70%;
    right: 0;
    left: auto;
  }
`
export const BackLayContainer = styled.div`
  display: flex;
  background-color: ${color._FFFFFF};
  border-bottom: 1px solid ${color._F2EDF4};
  height: 1.5rem;
`
export const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
`

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10%;
  @media (max-width: 991px) {
    flex: 1;
    gap: 11%;
  }
  @media (max-width: 768px) {
    flex: 0.5;
    gap: 35%;
  }
  @media (max-width: 600px) {
    flex: 0.5;
    gap: 25%;
  }
`

export const Heading = styled.h6`
  font-size: 11px;
  text-transform: uppercase;
  border: none;
  line-height: normal;
  padding: 4px 0;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  color: ${color._333333};
  @media (max-width: 768px) {
    margin-right: 15px;
  }
  @media (max-width: 600px) {
    margin-right: 10px;
  }
`
