import React from 'react'
import * as Styled from './style'
import { componentRoutes } from '../../utils/componentRoutes'
import { Link } from 'react-router-dom'
import { constants } from '../../utils/constant'

const ImageOption = ({ options, showTitle = false }) => {
  return (
    <Styled.ImageOption>
      {options.map((elem, indx) => (
        <Styled.ImageDiv key={elem.id + indx}>
          {console.log(elem.eventId, 'eventIdHere')}
          <Link
            to={`/casino-detail/${elem.eventId}`}
            state={{ id: elem.id, name: elem.alt }}
          >
            <Styled.Img
              data-src={elem.dataSrc}
              src={elem.src}
              alt={elem.alt}
              ref={elem.ref}
            />
            {showTitle && (
              <Styled.Title>{elem?.title || constants.userName}</Styled.Title>
            )}
          </Link>
        </Styled.ImageDiv>
      ))}
    </Styled.ImageOption>
  )
}

export default ImageOption
