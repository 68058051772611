import styled from 'styled-components'
import { color } from '../../../color/color'

export const CasinoTableBox = styled.div`
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
export const CasinoTableBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 10px;
  width: calc(24% - 7.5px);
`
export const CasinoNationName = styled.div`
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
`

export const CasinoOddsBoxLay = styled.div`
  position: relative;
  width: 49%;
  align-items: center;
  border-left: 1px solid ${color._C7C8CA};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: 700;
  justify-content: center;
  min-height: 46px;
  padding: 5px 0;
  background: ${color._FFB4C5};
`

export const CasinoOddsBoxBack = styled.div`
  position: relative;
  width: 49%;
  align-items: center;
  border-left: 1px solid ${color._C7C8CA};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: 700;
  justify-content: center;
  min-height: 46px;
  padding: 5px 0;
  background: ${color._66D1FF};
`

export const CasinoOdds = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  position: relative;
`

export const Suspended = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${color._FFFFFF};
  opacity: 0.9;
  z-index: 1;
  font-family: 'Titillium Web', sans-serif;
  background-color: ${color._808080};
  color: ${color._FFFFFF};
  font-size: 16px;
  padding: 5px;
  width: 100% !important;
  height: 100%;
  text-align: center;
  svg {
    filter: invert(1);
  }
  @media (max-width: 768px) {
    width: 70%;
    right: 0;
    left: auto;
  }
`
