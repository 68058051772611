import React, { memo } from 'react'
import * as Styled from './style'
import Select from '../../components/Select/Select'
import { SportsOption, constants } from '../../utils/constant'

const TableRowContent = ({ item, index, isProfitLoss, unsettledBets }) => {
  if (unsettledBets) {
    return (
      <>
        <Styled.TableData>{index + 1}</Styled.TableData>
        <Styled.TableData>{'-'}</Styled.TableData>
        <Styled.TableData>{'-'}</Styled.TableData>
        <Styled.TableData>{'-'}</Styled.TableData>
        <Styled.TableData>{item.team}</Styled.TableData>
        <Styled.TableData>{'-'}</Styled.TableData>
        <Styled.TableData>{item.rate}</Styled.TableData>
        <Styled.TableData>{item.amount}</Styled.TableData>
        <Styled.TableData>{'-'}</Styled.TableData>
        <Styled.TableData>{'-'}</Styled.TableData>
      </>
    )
  }

  if (isProfitLoss) {
    return (
      <>
        <Styled.TableData>{index + 1}</Styled.TableData>
        <Styled.TableData>{item.created}</Styled.TableData>
        <Styled.TableData>{item.eventType || '-'}</Styled.TableData>
        <Styled.TableData>{item.amount || ''}</Styled.TableData>
        <Styled.TableData>{item.amount || '-'}</Styled.TableData>
        <Styled.TableData>{item.amount}</Styled.TableData>
        <Styled.TableData>{'TOTAL'}</Styled.TableData>
        <Styled.TableData>
          <Styled.Button>Show Bets</Styled.Button>
        </Styled.TableData>
      </>
    )
  }

  return (
    <>
      <Styled.TableData>{index + 1}</Styled.TableData>
      <Styled.TableData>{item.created}</Styled.TableData>
      <Styled.TableData>{item.amount}</Styled.TableData>
      <Styled.TableData></Styled.TableData>
      <Styled.TableData>{item.debitCredit}</Styled.TableData>
      <Styled.TableData>{item.desc}</Styled.TableData>
      <Styled.TableData>{'-'}</Styled.TableData>
    </>
  )
}

const AccountInfoData = ({
  heading,
  btnName,
  tableHeader,
  hideFilters = false,
  tableBody = [],
  isProfitLoss = false,
  unsettledBets = false,
}) => {
  return (
    <Styled.AccountInfoData>
      <Styled.Heading>{heading}</Styled.Heading>
      {!hideFilters && (
        <Styled.Filters className="filters">
          <Select name={constants.All_Sports} options={SportsOption} />
          <Styled.DateInput type={constants.date} name={constants.Start_Date} />
          <Styled.DateInput type={constants.date} name={constants.End_Date} />
          <Styled.Button>{btnName}</Styled.Button>
        </Styled.Filters>
      )}
      <Styled.Table>
        <Styled.TableHead>
          <Styled.TableRow>
            {tableHeader.map((elem, indx) => (
              <Styled.TableHeadData key={elem.title + indx} width={elem.width}>
                {elem.title}
              </Styled.TableHeadData>
            ))}
          </Styled.TableRow>
        </Styled.TableHead>
        <Styled.TableBody>
          {tableBody.length ? (
            tableBody.map((item, index) => (
              <Styled.TableRow key={item.id}>
                <TableRowContent
                  item={item}
                  index={index}
                  isProfitLoss={isProfitLoss}
                  unsettledBets={unsettledBets}
                />
              </Styled.TableRow>
            ))
          ) : (
            <Styled.TableData colSpan={10}>
              {constants.NoDataFound}
            </Styled.TableData>
          )}
        </Styled.TableBody>
      </Styled.Table>
      <Styled.Buttons>
        <Styled.TableButtons>{constants.PreviousPage}</Styled.TableButtons>
        <Styled.TableButtons>{constants.NextPage}</Styled.TableButtons>
      </Styled.Buttons>
    </Styled.AccountInfoData>
  )
}

export default memo(AccountInfoData)
