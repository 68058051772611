import styled from 'styled-components'
import { color } from '../../color/color'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${color._F8F9FA};
  color: ${color._343A40};
  text-align: center;
`

export const Title = styled.h1`
  font-size: 6rem;
  margin: 0;
`

export const Message = styled.p`
  font-size: 1.5rem;
  margin: 0;
`

export const HomeLink = styled.div`
  margin-top: 1rem;
  font-size: 1.25rem;
  color: ${color._007BFF};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
