import React, { useEffect, useRef, useState } from 'react'
import * as Styled from './style'
import AccountInfoData from '../../container/AccountInfoData/AccountInfoData'
import { AccountInfoTableHeader, constants } from '../../utils/constant'
import Layout from '../../Layout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAccountStatement } from '../../redux/auth/action'
import Loader from '../../components/Loader/Loader'

const AccountsStatement = (props) => {
  const dispatch = useDispatch()
  const avoidRerender = useRef(false)
  const token = useSelector((state) => state.auth.token)
  const accountStatement = useSelector(
    (state) => state.auth.accountStatementData,
  )
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!avoidRerender.current) {
      dispatch(fetchAccountStatement(token)).finally(() => {
        setLoading(false)
      })
    }
    return () => (avoidRerender.current = true)
  }, [dispatch, token])

  return (
    <>
      <Layout>
        {loading ? (
          <Loader />
        ) : (
          <Styled.AccountsStatement>
            <AccountInfoData
              heading={constants.ACCOUNT_STATEMENT}
              btnName={constants.GET_STATEMENT}
              tableHeader={AccountInfoTableHeader}
              tableBody={accountStatement}
            />
          </Styled.AccountsStatement>
        )}
      </Layout>
    </>
  )
}

export default AccountsStatement
