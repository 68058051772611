import styled from 'styled-components'
import { color } from '../../color/color'
export const Span = styled.span`
  background-color: ${color._CCCCCC};
  display: table;
  width: 100%;
  padding: 10px 15px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
`

export const HeaderWrapper = styled.div`
  margin: 2px 0px 0px 0px !important;
`
