import React from 'react'
import * as Styled from '../style'
const BatterTable = ({
  heading,
  batter,
  runs,
  balls,
  four,
  six,
  strikeRate,
  viewStrikeRate = false,
  batterStatus,
  viewBatterStatus = false,
  notOut,
  batterColor = '#fff',
  comments,
  fontSize
}) => {
  return (
    <Styled.BatterTable heading={heading} fontSize={fontSize}>
      {comments ? (
        <Styled.Comments>{comments}</Styled.Comments>
      ) : (
        <Styled.BattingDiv className="display-flex">
          <Styled.Batter batterColor={batterColor}>{batter}</Styled.Batter>
          <Styled.BatTable>{runs}</Styled.BatTable>
          <Styled.BatTable>{balls}</Styled.BatTable>
          <Styled.BatTable>{four}</Styled.BatTable>
          <Styled.BatTable>{six}</Styled.BatTable>
          {viewStrikeRate && <Styled.BatTable>{strikeRate}</Styled.BatTable>}
        </Styled.BattingDiv>
      )}

      {viewBatterStatus && (
        <Styled.BatterStatus notOut={notOut}>
          {batterStatus}
        </Styled.BatterStatus>
      )}
    </Styled.BatterTable>
  )
}

export default BatterTable
