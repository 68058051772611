import React from 'react'
import * as Styled from './styles'
import { colorData, playerTablesData } from './constant'
import { color } from '../../../color/color'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'

const PlayerTable = ({ player, style, labels, rows }) => {
  return (
    <div className="col-6" style={style}>
      <Styled.Table>
        <tr>
          <Styled.RowHeadingDataContainer>
            {player}
          </Styled.RowHeadingDataContainer>
          <Styled.BoxDataBlue isSuspended={labels.back.isSuspended}>
            <div className="position-relative">
              {labels.back.isSuspended && (
                <Styled.SuspendedContainer>
                  <Lock fill={color._fff} />
                </Styled.SuspendedContainer>
              )}
              {labels.back.value}
            </div>
          </Styled.BoxDataBlue>
          <Styled.BoxDataPink isSuspended={labels.lay.isSuspended}>
            <div className="position-relative">
              {labels.lay.isSuspended && (
                <Styled.SuspendedContainer>
                  <Lock fill={color._fff} />
                </Styled.SuspendedContainer>
              )}
              {labels.lay.value}
            </div>
          </Styled.BoxDataPink>
        </tr>
        {rows.length &&
          rows.map((row, index) => (
            <tr key={index + row.type}>
              <Styled.RowHeadingDataContainer>
                {row.type}
              </Styled.RowHeadingDataContainer>
              {row.colspan ? (
                <Styled.BoxDataBlue
                  colSpan={row.colspan}
                  isSuspended={row.isSuspended}
                >
                  <div className="position-relative">
                    {row.isSuspended && (
                      <Styled.SuspendedContainer>
                        <Lock fill={color._fff} />
                      </Styled.SuspendedContainer>
                    )}
                    {row.back}
                  </div>
                </Styled.BoxDataBlue>
              ) : (
                <>
                  <Styled.BoxDataBlue isSuspended={row.isSuspendedBack}>
                    <div className="position-relative">
                      {row.isSuspendedBack && (
                        <Styled.SuspendedContainer>
                          <Lock fill={color._fff} />
                        </Styled.SuspendedContainer>
                      )}
                      <Styled.GridContainer>
                        <Styled.Span>{row.back}</Styled.Span>
                        <Styled.TotalValueContainer>
                          {row.backTotal}
                        </Styled.TotalValueContainer>
                      </Styled.GridContainer>
                    </div>
                  </Styled.BoxDataBlue>
                  <Styled.BoxDataPink isSuspended={row.isSuspendedLay}>
                    <div className="position-relative">
                      {row.isSuspendedLay && (
                        <Styled.SuspendedContainer>
                          <Lock fill={color._fff} />
                        </Styled.SuspendedContainer>
                      )}
                      <Styled.GridContainer>
                        <Styled.Span>{row.lay}</Styled.Span>
                        <Styled.TotalValueContainer>
                          {row.layTotal}
                        </Styled.TotalValueContainer>
                      </Styled.GridContainer>
                    </div>
                  </Styled.BoxDataPink>
                </>
              )}
            </tr>
          ))}
      </Styled.Table>
    </div>
  )
}

const _2CardTeenPatti = () => {
  return (
    <Styled.Container className="container">
      <div className="row">
        {playerTablesData.length &&
          playerTablesData.map((playerData, index) => (
            <PlayerTable
              key={
                index +
                playerData.labels.back.value +
                playerData.labels.lay.value
              }
              player={playerData.player}
              style={playerData.style}
              labels={playerData.labels}
              rows={playerData.rows}
            />
          ))}
      </div>

      <div className="row mt-2">
        <Styled.ColContainer className="col-12">
          {colorData.length &&
            colorData.map((item, index) => {
              return (
                <Styled.ColorContainer
                  key={index + item.label}
                  isSuspended={item.isSuspended}
                >
                  <div className="position-relative">
                    {item.isSuspended && (
                      <Styled.SuspendedContainer>
                        <Lock fill={color._fff} />
                      </Styled.SuspendedContainer>
                    )}
                    {item.label}
                  </div>
                </Styled.ColorContainer>
              )
            })}
        </Styled.ColContainer>
      </div>
    </Styled.Container>
  )
}

export default _2CardTeenPatti
