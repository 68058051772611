export const constants = {
  player: 'Player',
}

export const tableHeaders = ['', 'Back', 'Lay']

export const playersData = [
  {
    player: '8',
    stats: {
      Back: { value: 1.98, isSuspended: false },
      Lay: { value: 3.23, isSuspended: false },
    },
  },
  {
    player: '9',
    stats: {
      Back: { value: 1.98, isSuspended: false },
      Lay: { value: 3.23, isSuspended: false },
    },
  },
  {
    player: '10',
    stats: {
      Back: { value: 1.98, isSuspended: false },
      Lay: { value: 3.23, isSuspended: false },
    },
  },
  {
    player: '11',
    stats: {
      Back: { value: 1.98, isSuspended: false },
      Lay: { value: 3.23, isSuspended: false },
    },
  },
]

export const numberdata = [
  {
    id: 1,
    count: 1,
    isSuspended: false,
  },
  {
    id: 2,
    count: 2,
    isSuspended: false,
  },
  {
    id: 3,
    count: 3,
    isSuspended: false,
  },
  {
    id: 4,
    count: 4,
    isSuspended: false,
  },
  {
    id: 5,
    count: 5,
    isSuspended: false,
  },
  {
    id: 6,
    count: 6,
    isSuspended: false,
  },
  {
    id: 7,
    count: 7,
    isSuspended: false,
  },
  {
    id: 8,
    count: 8,
    isSuspended: false,
  },
  {
    id: 9,
    count: 9,
    isSuspended: false,
  },
  {
    id: 10,
    count: 10,
    isSuspended: false,
  },
]
