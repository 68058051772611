import styled from 'styled-components'
import { color } from '../../color/color'

const inputDefaultCss = `
display: grid;
@media (min-width: 768px) and (max-width: 991px) {
  width: 190px;
  margin-left: 10%;
}
`

export const InputDiv = styled.div`
  ${({ customStyle }) => (customStyle ? customStyle : inputDefaultCss)}
`

export const Input = styled.input`
  border: none;
  border-radius: 5px;
  color: ${color._FFFFFF};
  text-align: center;
  padding: 10px;
  font-size: 13px;
  background-color: ${({ inputBgColor }) =>
    inputBgColor ? inputBgColor : color._FFFFFF};
  &:focus {
    background-color: ${({ inputBgColor }) =>
      inputBgColor ? color._FF0000 : color._FFFFFF};
    border: 1px solid white;
  }
`

export const Label = styled.label`
  color: ${color._FFFFFF};
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  font-family: 'Titillium Web', sans-serif;
  margin-left: 5px;
  margin-left: ${({ customStyle }) => (customStyle ? '5px' : null)};
`
