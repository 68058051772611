import ACTIONTYPES from './actionTypes'

const initialState = {
  data: [],
  errorMessage: null,
}

const bookMakerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONTYPES.BOOKMAKER_DATA_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        errorMessage: null,
      }
    case ACTIONTYPES.BOOKMAKER_DATA_FETCH_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default bookMakerReducer
