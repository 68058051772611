export const _constants = {
  title: 'Fancy',
  name: 'LOK SABHA 2024',
  date: '(10/06/2024 12:00:00 PM)',
  placeBet: 'Place Bet',
  Bookmarked: 'Successfully Added',
}
export const FancyButtons = [
  { name: 'All', filter: '1' },
  { name: 'Session 4', filter: '4' },
  { name: 'W/P Market 10', filter: '10' },
  { name: 'Odd/even 12', filter: '12' },
  { name: 'xtra market 14', filter: '14' },
]

export const fancyData = [
  {
    id: 1,
    data: [
      {
        name: ' BJP Will Win Total Seats In LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 500k',
      },
    ],
  },
  {
    id: 2,
    data: [
      {
        name: ' BJP Will Win Total Seats In LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 50k',
      },
      {
        name: ' BJP Will Win Total Seats In LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 500k',
      },
    ],
  },
  {
    id: 2,
    data: [
      {
        name: 'BJP Will Win Total Seats In Maharashtra LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 500k',
      },
      {
        name: 'BJP Will Win Total Seats In Maharashtra LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 5k',
      },
      {
        name: 'BJP Will Win Total Seats In Maharashtra LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 5000k',
      },
      {
        name: 'BJP Will Win Total Seats In Maharashtra LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 5000k',
      },
    ],
  },
  {
    id: 3,
    data: [
      {
        name: 'BJP Will Win Total Seats In Maharashtra LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 500k',
      },
      {
        name: 'BJP Will Win Total Seats In Maharashtra LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 5k',
      },
      {
        name: 'BJP Will Win Total Seats In Maharashtra LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 5000k',
      },
      {
        name: 'BJP Will Win Total Seats In Maharashtra LS 2024',
        maxBet: 'Max Bet: 100',
        maxMarket: 'Max Market: 5000k',
      },
    ],
  },
]

export const getToastPosition = () => {
  return {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  }
}
