import styled from 'styled-components'
import { color } from '../../color/color'

export const ModalHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid ${color._DEE2E6};
  border-top-left-radius: calc(0.5rem - (1px));
  border-top-right-radius: calc(0.5rem - (1px));
  justify-content: space-between;
  background-color: ${color._343435};
  border-color: ${color._343435};
  color: ${color._FFFFFF};
  height: 27px;
  margin-bottom: 5px;
`
export const ModalTitle = styled.h5`
  margin-bottom: 0;
  font-size: 17px;
  line-height: 1.5;
`

export const Modal = styled.div`
  .modal-content {
    gap: 5px;
  }
  &.modal-container {
    gap: 5px;
  }
`
