import styled from 'styled-components'
import { color } from '../../../color/color'

export const Container = styled.div`
  background-color: ${color._F2F2F2} !important;
`

export const Span = styled.span`
  font-size: 16px;
  font-weight: 700;
  font-family: Roboto Condensed, sans-serif;
  text-wrap: nowrap;
`

export const BoxData = styled.div`
  border: 1px solid ${color._C7C8CA} !important;
  padding: 10px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._72BBEF} !important;
`
export const LockContainer = styled.div`
  position: absolute;
  z-index: 1;
`
export const SuspendedContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
