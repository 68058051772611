import _3DD from './cardImages/3DD.webp'
import _3CC from './cardImages/3CC.webp'
import _9DD from './cardImages/9DD.webp'
import _4DD from './cardImages/4DD.webp'
import _2CC from './cardImages/2CC.webp'
import _2HH from './cardImages/2HH.webp'
import _3SS from './cardImages/3SS.webp'
import _JHH from './cardImages/JHH.webp'
import _KSS from './cardImages/KSS.webp'
import _QCC from './cardImages/QCC.webp'
import _QDD from './cardImages/QDD.webp'
import _1 from './cardImages/A.png'
import _2 from './cardImages/2.png'
import _3 from './cardImages/3.png'
import _4 from './cardImages/4.png'
import _5 from './cardImages/5.png'
import _6 from './cardImages/6.png'
import _7 from './cardImages/7.png'
import _8 from './cardImages/8.png'
import _9 from './cardImages/9.png'
import _10 from './cardImages/10.png'
import _J from './cardImages/J.png'
import _K from './cardImages/K.png'
import _Q from './cardImages/Q.png'

export const _constants = {
  low: 'Low',
  high: 'High',
  lowNumber: 26,
  highNumber: 48,
}

export const lowCardsData = [
  {
    image: _3DD,
    alt: '3DD',
  },
  {
    image: _3CC,
    alt: '3CC',
  },
  {
    image: _9DD,
    alt: '9DD',
  },
  {
    image: _4DD,
    alt: '4DD',
  },
  {
    image: _2CC,
    alt: '2CC',
  },
  {
    image: _2HH,
    alt: '2HH',
  },
  {
    image: _3SS,
    alt: '3SS',
  },
]

export const highCardsData = [
  {
    image: _JHH,
    alt: 'JHH',
  },
  {
    image: _KSS,
    alt: 'KSS',
  },
  {
    image: _QCC,
    alt: 'QCC',
  },
  {
    image: _QDD,
    alt: 'QDD',
  },
]

export const lowcards = [
  {
    image: _1,
    alt: '1',
  },
  {
    image: _2,
    alt: '2',
  },
  {
    image: _3,
    alt: '3',
  },
  {
    image: _4,
    alt: '4',
  },
  {
    image: _5,
    alt: '5',
  },
  {
    image: _6,
    alt: '6',
  },
  {
    image: _7,
    alt: '7',
  },
  {
    image: _8,
    alt: '8',
  },
  {
    image: _9,
    alt: '9',
  },
]

export const highcards = [
  {
    image: _10,
    alt: '10',
  },
  {
    image: _J,
    alt: 'J',
  },
  {
    image: _Q,
    alt: 'Q',
  },
  {
    image: _K,
    alt: 'K',
  },
]
