import React from 'react'
import * as Styled from './styles'

const Marquee = ({ label, bgColor, fontColor,time="20s" }) => {
  return (
    <Styled.Container bgColor={bgColor}>
      <Styled.Span fontColor={fontColor} time={time}>{label}</Styled.Span>
    </Styled.Container>
  )
}

export default Marquee
