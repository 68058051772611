import React, { useEffect, useState, useRef } from 'react'
import {
  ICasinoFilterMenuOptions,
  TabMenuOptions,
  constants,
} from '../../utils/constant'
import PagesHeader from '../../components/PagesHeader/PagesHeader'
import ICasinoImg from '../../assets/sidebar/menu-99998.png'
import GamesFilter from '../../components/GamesFilterSubtabs/GamesFilter'
import TabMenu from './utils/TabMenu'
import ImageOption from '../../components/ImageOptionGallery/ImageOption'

import { useDispatch, useSelector } from 'react-redux'
import { getCasinoGames } from '../../redux/casino/action'
import Loader from '../../components/Loader/Loader'
import Layout from '../../Layout'

const useIntersectionObserver = (callback, options) => {
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)
    if (ref.current) observer.observe(ref.current)

    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [callback, options])

  return ref
}
const ICasino = () => {
  const [activeTab, setActiveTab] = useState({ title: 'ALL' })
  const [activeMenu, setActiveMenu] = useState({ alt: 'Roulette' })
  const handleSubTab = (e) => {
    setActiveTab(e)
  }
  const handleMenu = (e) => {
    setActiveMenu(e)
  }
  const allCasinoGames = useSelector((state) => state.casino.data)
  const isLoading = useSelector((state) => state.casino.isLoading)
  const [casGames, setCasGames] = useState([])
  const dispatch = useDispatch()
  const avoidRerender = useRef(false)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!avoidRerender.current) {
      dispatch(getCasinoGames(token))
    }
    return () => (avoidRerender.current = true)
  }, [dispatch])

  useEffect(() => {
    const formattedGames =
      allCasinoGames?.length &&
      allCasinoGames?.map((item) => {
        return {
          src: item.imageUrl,
          alt: item.name,
          id: item.name,
        }
      })
    setCasGames(formattedGames)
  }, [allCasinoGames])

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target
        img.src = img.dataset.src
        img.onload = () => img.classList.add('loaded')
      }
    })
  }

  const options = { threshold: 0.1 }
  const imgRef = useIntersectionObserver(handleIntersection, options)

  return (
    <Layout>
      <>
        <PagesHeader src={ICasinoImg} heading={constants.INT_CASINO} />
        <GamesFilter
          subtabsOption={ICasinoFilterMenuOptions}
          activeTab={activeTab}
          handleSubTab={handleSubTab}
        />
        <TabMenu
          options={TabMenuOptions}
          handleMenu={handleMenu}
          activeMenu={activeMenu}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <ImageOption
            options={
              casGames?.length
                ? casGames?.map((game) => ({
                    ...game,
                    src: game.src,
                    dataSrc: game.src,
                    ref: imgRef,
                    title: game.alt,
                  }))
                : []
            }
            showTitle={true}
          />
        )}
      </>
    </Layout>
  )
}

export default ICasino
