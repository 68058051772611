import styled from 'styled-components'
import { color } from '../../../color/color'

export const MainContainer = styled.div`
  background-color: ${color._F7F7F7};
  color: ${color._000};
`

export const InnerContainer = styled.div`
  padding: 0;
  width: 32%;
`
export const TitleContainer = styled.div`
  text-align: center;
  font-weight: bold;
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`

export const TableInnerContainer = styled.div`
  width: 100% !important;
`

export const Span = styled.span`
  width: 100% !important;
`
export const MidSpan = styled.span`
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 1;
`
export const MidContainer = styled.div`
  background-image: linear-gradient(to right, #08c, #2c3e50);
  font-weight: 700 !important;
  line-height: 1;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  color: ${color._fff};
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
`

export const ColOuterContainer = styled.div`
  padding: 15px;
  border: 1px solid ${color._C7C8CA};
`

export const TableData = styled.div`
  border: 1px solid ${color._C7C8CA} !important;
  padding: 10px !important;
  text-align: center !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  width: 50% !important;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._72BBEF} !important;
`
export const RowContainer = styled.div`
  background-color: ${color._F2F2F2} !important;
  margin: auto !important;
`

export const LockContainer = styled.div`
  position: absolute;
  z-index: 1;
`
export const SuspendedContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
