import React from 'react'
import * as Styled from './style'
import { FaArrowUp } from 'react-icons/fa'
import { FaArrowDown } from 'react-icons/fa'
import { color } from '../../../../color/color'
import { CiLock } from 'react-icons/ci'
const BetDetail = ({
  betTitle = 'Cricket Club Dibrugarh',
  icon,
  value = 2.8,
  id,
  activeBetId,
  setActiveBetId,
  setBetDetails,
  setBetsSlider,
  setAddedBet,
  matchTitle = 'Demo',
  availableBet = false,
  betName,
  key,
}) => {
  const handleBet = () => {
    setActiveBetId(id)
    setBetDetails({
      betTitle,
      value,
      matchTitle,
    })
    setAddedBet(true)
    setBetsSlider(true)
  }
  return (
    <Styled.BetDetail
      className="display-flex"
      onClick={availableBet ? handleBet : () => {}}
      active={activeBetId === id}
      availableBet={availableBet}
      key={key}
    >
      {availableBet ? (
        <>
          <Styled.BetTitle>{betName}</Styled.BetTitle>
          <Styled.IconValue className="display-flex">
            {
              <Styled.Icon>
                {icon === 'up'
                  ? icon && <FaArrowUp color={color._008000} />
                  : icon && <FaArrowDown color={color._FF0000} />}
              </Styled.Icon>
            }

            <Styled.Value>{value}</Styled.Value>
          </Styled.IconValue>
        </>
      ) : (
        <CiLock />
      )}
    </Styled.BetDetail>
  )
}

export default BetDetail
