import React, { useState } from 'react'
import {
  Btn,
  ButtonContainer,
  Container,
  ErrorMessage,
  FormGroup,
  GlobalStyle,
  Header,
  Image,
  PasswordInput,
  OtpInput,
  PhoneInputWrapper,
  RegistrationForm,
  ResendButton,
  SignUpInfo,
  SignUpInfoSpan,
  TermsAndCondition,
  WrapperContainer,
  LoginSpan,
  Icon,
} from './styles'
import { PhoneInput } from 'react-international-phone'
import { useNavigate } from 'react-router-dom'
import { constants } from '../../constants/constants'
import { ActionTypes } from './ActionTypes'
import logoImage from '../../assets/images/logo.png'
import useSignup from './Signup.hook'
import Toast from '../../components/Toast/Toast'
import { FaEye } from 'react-icons/fa'
import { color } from '../../color/color'
import { useSelector } from 'react-redux'

export default function LoginContainer() {
  const { state, timerRunning, handleFormSubmit, handleResendOTP, dispatch } =
    useSignup()
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/', { state: { loginModal: true } })
  }
  const isOtpSent = useSelector((state) => state.auth.isOtpSent)
  const [show, setShow] = useState(null)
  const [otpLength, setOtpLength] = useState()
  const handleChange = () => {
    setShow(!show)
  }
  const onChangeOtpInput = (e) => {
    setOtpLength(
      e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'),
    )
    dispatch({
      type: ActionTypes.SET_OTP,
      payload: e.target.value
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*?)\..*/g, '$1'),
    })
  }
  return (
    <WrapperContainer>
      <GlobalStyle />
      <Container>
        <div className="text-center pt-3">
          <Image
            className="img-fluid main-logo"
            src={logoImage}
            alt="logo-signup"
          />
          <Header>{constants.REGISTER}</Header>
        </div>
        <RegistrationForm id="signup_form" onSubmit={handleFormSubmit}>
          <FormGroup>
            <PhoneInputWrapper>
              <PhoneInput
                defaultCountry="in"
                value={state.phone}
                placeholder={constants.Mobile_no}
                onChange={(phone) =>
                  dispatch({
                    type: ActionTypes.SET_PHONE,
                    payload: phone,
                  })
                }
              />
            </PhoneInputWrapper>
          </FormGroup>
          <FormGroup>
            <PasswordInput
              type={show ? constants.text : constants.PASSWORD}
              className="form-control"
              id={constants.PASSWORD}
              name={constants.PASSWORD}
              value={state.password.replace(/\s/g, '')}
              placeholder={constants.PASSWORD_PLACEHOLDER}
              onChange={(password) =>
                dispatch({
                  type: ActionTypes.SET_PASSWORD,
                  payload: password?.target?.value.replace(/\s/g, ''),
                })
              }
            />
            <Icon>
              <FaEye onClick={handleChange} color={color._FFFFFF} />
            </Icon>
            <ErrorMessage>{constants.PASSWORD_RULES}</ErrorMessage>
          </FormGroup>
          <TermsAndCondition>
            <small>{constants.TERMS_CONDITION}</small>
          </TermsAndCondition>
          {state.showOTP && isOtpSent && (
            <FormGroup>
              <OtpInput
                type="text"
                className="form-control"
                id={constants.OTP}
                name={constants.OTP}
                placeholder={constants.OTP.toUpperCase()}
                onChange={onChangeOtpInput}
                value={otpLength}
                maxLength="6"
              />
              {timerRunning ? (
                <ErrorMessage>
                  ({constants.RESEND_TIMER} {state.timer})
                </ErrorMessage>
              ) : (
                <ErrorMessage>
                  <ResendButton type="button" onClick={handleResendOTP}>
                    {constants.RESEND}
                  </ResendButton>
                </ErrorMessage>
              )}
            </FormGroup>
          )}
          <ButtonContainer>
            <Btn
              type="submit"
              className="btn btn-primary mt-1"
              id="signup_submit"
            >
              {state.showOTP ? constants.SIGNUP : constants.GET_OTP}
            </Btn>
          </ButtonContainer>
        </RegistrationForm>
        <SignUpInfo>
          <SignUpInfoSpan>{constants.ALREADY_ACCOUNT}</SignUpInfoSpan>
          <LoginSpan className="anchor-white" onClick={handleNavigate}>
            {constants.LOGIN}
          </LoginSpan>
        </SignUpInfo>
      </Container>
      <Toast />
    </WrapperContainer>
  )
}
