import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import * as Styled from './style'
const CarouselSection = ({ indicators = true, width, data = [] }) => {
  // indicator props is use to show/hide the indicator as per the need by default its true
  // width we can pass width as per the need if we don't pass it , it will take the
  // by default width added in css
  // Data the images we show on the carousel
  return (
    <Carousel indicators={indicators}>
      {data &&
        data?.map((elem, indx) => {
          return (
            // it will map the images and show it on UI
            <Carousel.Item key={elem + indx}>
              <Styled.Img src={elem.src} alt={elem.src} width={width} />
            </Carousel.Item>
          )
        })}
    </Carousel>
  )
}

export default CarouselSection
