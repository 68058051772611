import styled from 'styled-components'
import { color } from '../../color/color'

export const Select = styled.select`
  width: 160px;
  appearance: none;
  background-color: ${color._fff};
  color: ${color._333};
  padding: 5px 12px 5px 5px;
  height: 30px !important;
  border: ${color._959595} 1px solid;
  font-size: 12px;
  border-radius: 4px;
`
