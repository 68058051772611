import styled from 'styled-components'
import { color } from '../../color/color'
export const Container = styled.div`
  width: 100%;
  display: none;
  text-decoration: none;
  background: linear-gradient(
    180deg,
    ${color._FA7E29} 0%,
    ${color._F6682F} 80%,
    ${color._F6682F} 100%
  );
  box-shadow: inset 0px -10px 20px 0px ${color._9F0101};
  @media (max-width: 991px) {
    display: flex;
  }
`

export const Anchor = styled.a`
  width: 50%;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
  color: ${color._FFFFFF};
  margin: 0 1px;
  box-shadow: 0px 0px 2px 1px ${color._FFFFFF};
  text-decoration: none;
`

export const Span = styled.span`
  /*blinking Animation on UI  */
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  text-decoration: none;
  animation: blinker 1s linear infinite;
`
