import React, { useState } from 'react'
import { Modal, Row, Col, Form } from 'react-bootstrap'
import { IoMdClose } from 'react-icons/io'

import {
  Container,
  Btn,
  ButtonContainer,
  SaveBtn,
  Label,
  ModalHeaderSpan,
  HeaderBox,
} from '../style'
import { _constants } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { color } from '../../../color/color'
import { addWithdrawAccount } from '../../../redux/widthdraw/action'

const AddAccountModal = ({ show, handleClose }) => {
  const initialState = {
    accountType: '',
    name: '',
    accountNumber: '',
    ifscCode: '',
    withdrawPassword: '',
  }

  const [formData, setFormData] = useState(initialState)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [errors, setErrors] = useState({
    accountType: '',
    name: '',
    accountNumber: '',
    ifscCode: '',
    withdrawPassword: '',
  })

  const dispatch = useDispatch()
  const loader = useSelector((state) => state.loading)

  const handleInputChange = (e) => {
    const { name, value } = e.target

    const filteredValue = e.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
    const fileredIFSC = e.target.value.replace(/./g, (char) =>
      char.toUpperCase(),
    )
    let error = ''

    switch (name) {
      case 'accountType':
        error = !value.trim() ? _constants.setAccountType : ''
        break
      case 'name':
        error = !value.trim() ? _constants.selectName : ''
        break
      case 'accountNumber':
        error = !value.trim() ? _constants.selectAccountNumber : ''
        error = !/^\d{11,19}$/.test(filteredValue)
          ? 'Account number must be between 11-19 digits.'
          : error
        break
      case 'ifscCode':
        error = !value.trim() ? _constants.selectIfsc : ''
        error = !/^[A-Za-z0-9]{11}$/.test(value)
          ? 'Invalid IFSC code format.'
          : error
        break
      case 'withdrawPassword':
        error = !value.trim() ? _constants.selectWithdraw : ''
        error =
          !/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/.test(value) ||
          value.length < 8
            ? 'Password must be Alphanumeric'
            : error
        break
      default:
        break
    }

    setErrors({ ...errors, [name]: error })
    setFormData({
      ...formData,
      [name]:
        name === 'accountNumber'
          ? filteredValue
          : name === 'ifscCode'
          ? fileredIFSC
          : value,
    })
  }

  const handleSubmit = (e) => {
    const token = localStorage.getItem('token')
    e.preventDefault()
    setFormSubmitted(true)

    const errorsCopy = {}

    if (!formData.accountType.trim()) {
      errorsCopy.accountType = _constants.setAccountType
    }

    if (!formData.name.trim()) {
      errorsCopy.name = _constants.selectName
    }

    if (!formData.accountNumber.trim()) {
      errorsCopy.accountNumber = _constants.selectAccountNumber
    } else if (!/^\d{11,19}$/.test(formData.accountNumber)) {
      errorsCopy.accountNumber = 'Account number must be between 11-19 digits.'
    }

    if (!formData.ifscCode.trim()) {
      errorsCopy.ifscCode = _constants.selectIfsc
    } else if (!/^[A-Za-z0-9]{11}$/.test(formData.ifscCode)) {
      errorsCopy.ifscCode = 'Invalid IFSC code format.'
    }
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/
    if (!formData.withdrawPassword.trim()) {
      errorsCopy.withdrawPassword = _constants.selectWithdraw
    } else if (
      !passwordRegex.test(formData.withdrawPassword) ||
      formData.withdrawPassword.length < 8
    ) {
      errorsCopy.withdrawPassword = 'Password must be AlphaNumeric'
    }

    setErrors(errorsCopy)

    const apiRequest = { ...formData, ifsc: formData.ifscCode }

    if (Object.keys(errorsCopy).length === 0) {
      if (loader.isLoading) return
      dispatch(addWithdrawAccount(apiRequest, token))
      handleClose()
      setFormData(initialState)
    }
  }

  const handleCancel = () => {
    handleClose()
    setFormData(initialState)
    setErrors(initialState)
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Container>
        <HeaderBox>
          <IoMdClose
            color="#ffffff"
            className="close-icon"
            onClick={handleClose}
          />
          <ModalHeaderSpan className="text-center pt-3">
            {_constants.AddAccount}
          </ModalHeaderSpan>
        </HeaderBox>
        <div
          style={{
            background: color._C10930,
            paddingLeft: '1px',
            paddingRight: '1px',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Row className="mb-4">
              <Col>
                <Label>{_constants.ACCOUNTTYPE}</Label>
                <Form.Select
                  name="accountType"
                  onChange={handleInputChange}
                  value={formData.accountType}
                >
                  <option>---{_constants.Select}---</option>
                  <option value="bank-account">{_constants.BankAccount}</option>
                </Form.Select>
                {formSubmitted && errors.accountType && (
                  <div style={{ color: 'red' }}>{errors.accountType}</div>
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Label>{_constants.Name}</Label>
                <Form.Control
                  name="name"
                  placeholder={_constants.EnterName}
                  onChange={handleInputChange}
                  value={formData.name}
                />
                {formSubmitted && errors.name && (
                  <div style={{ color: 'red' }}>{errors.name}</div>
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Label>{_constants.ACNUMBER}</Label>
                <Form.Control
                  name="accountNumber"
                  placeholder={_constants.EnterACNumber}
                  type="text"
                  pattern="[0-9]+"
                  onChange={handleInputChange}
                  value={formData.accountNumber}
                  maxLength={19}
                  minLength={11}
                />
                {formSubmitted && errors.accountNumber && (
                  <div style={{ color: 'red' }}>{errors.accountNumber}</div>
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Label>{_constants.IFSCCODE}</Label>
                <Form.Control
                  name="ifscCode"
                  placeholder={_constants.EnterIFSCCode}
                  onChange={handleInputChange}
                  value={formData.ifscCode}
                  type="text"
                  pattern="[A-Za-z0-9]*"
                  maxLength={11}
                />
                {formSubmitted && errors.ifscCode && (
                  <div style={{ color: 'red' }}>{errors.ifscCode}</div>
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Label>{_constants.WITHDRAWPASSWORD}</Label>
                <Form.Control
                  name="withdrawPassword"
                  placeholder={_constants.ENTERWITHDRAWPASSWORD}
                  onChange={handleInputChange}
                  value={formData.withdrawPassword}
                />
                {formSubmitted && errors.withdrawPassword && (
                  <div style={{ color: 'red' }}>{errors.withdrawPassword}</div>
                )}
              </Col>
            </Row>
          </form>
        </div>
        <Modal.Footer className="modal-footer-section">
          <div>
            <ButtonContainer>
              <Btn className="btn btn-secondary mt-1" onClick={handleCancel}>
                {_constants.Close}
              </Btn>
            </ButtonContainer>
          </div>
          <div>
            <ButtonContainer>
              <SaveBtn
                type="submit"
                className="btn btn-primary mt-1"
                onClick={handleSubmit}
              >
                {_constants.Save}
              </SaveBtn>
            </ButtonContainer>
          </div>
        </Modal.Footer>
      </Container>
    </Modal>
  )
}

export default AddAccountModal
