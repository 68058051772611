import React, { useEffect, useRef } from 'react'
import { OverviewTitle } from '../styles'
import OverviewRows from './OverviewRows'
import { OverviewData } from '../../../utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfileDetails } from '../../../redux/auth/action'

const OverviewContent = () => {
  const username = useSelector((state) => state.auth.username)
  const phone = useSelector((state) => state.auth.phone)
  const isDemoUserName = useSelector((state) => state.auth.isDemoUserName)
  const token = useSelector((state) => state.auth.token)
  const profileData = useSelector((state) => state.auth.profileData)
  const dispatch = useDispatch()
  const avoidRerender = useRef(false)

  const getPhoneDetails = (phone) => {
    if (phone?.length === 0) {
      return username?.toUpperCase()
    } else if (/^0+$/.test(phone)) {
      return username?.toUpperCase()
    } else {
      return phone
    }
  }

  useEffect(() => {
    if (!avoidRerender.current) {
      dispatch(fetchProfileDetails(token))
    }
    return () => (avoidRerender.current = true)
  }, [])

  const phoneNumber = getPhoneDetails(phone)

  const getDetails = (value) => {
    switch (value) {
      case '1':
        return phoneNumber || isDemoUserName || 'DEMO123'
      case '2':
        return (profileData?.availableChips || '0') + '/-'
      case '3':
        return (profileData?.expose || '0.00') + '/-'
      case '4':
        return (profileData?.totalChips || '0') + '/-'
      case '5':
        return (profileData?.pl || '0.00') + '/-'
    }
  }

  return (
    <div>
      <OverviewTitle>
        Welcome to ReddyBook,{' '}
        <b>{phoneNumber || isDemoUserName || 'DEMO123'}</b>
      </OverviewTitle>
      {OverviewData?.map((item) => (
        <OverviewRows keyName={item.keyName} value={getDetails(item.value)} />
      ))}
    </div>
  )
}

export default OverviewContent
