import styled from 'styled-components'
import { color } from '../../color/color'

export const Container = styled.div`
  padding: 3px;
  background-color: ${({ bgColor }) =>
    bgColor ? color._F9C8D3 : color._94DFFF};
  height: 220px;
`
export const Content = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1px;
  display: flex;
`
export const InputGroup = styled.div`
  flex-wrap: nowrap;
`
export const InputGroupText = styled.span`
  height: 35px;
  padding: 0 4px;
  border: none;
  border-radius: 0;
  background: ${color._024F99};
  color: ${color._FFFFFF};
  display: flex;
  justify-content: space-around;
  align-items: center;
  i {
    margin-top: -6px;
  }
`
export const Input = styled.input`
  width: 77%;
  border: none;
  background: ${color._FFFFFF};
  height: 35px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  @media (max-width: 992px) {
    width: 90%;
  }
`
export const BetStacks = styled.div`
  position: relative;
  padding: 1px 0;
  font-weight: 600;
  vertical-align: middle;
  font-size: 12px;
  border: none;
  width: 100%;
`
export const BetStacksInput = styled.input`
  width: 100%;
  height: 35px;
  text-align: center;
  border-radius: 0;
  border: none;
  background: ${color._FFFFFF};
`
export const Button = styled.button`
  max-height: 38px;
  background: ${color._FFFFFF} !important;
  width: calc(25% - 1px);
  margin-top: 2px;
  float: left;
  font-weight: normal;
  color: ${color._000000};
  font-size: 12px;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 2px;
  border: none;
  font-weight: 600;
  margin-right: 1px;
  border-radius: 0 !important;
  line-height: 1.6;
  border: none !important;
  padding: 0.375rem 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    border: none;
    background: ${color._EEEEEE};
  }
  & i:before {
    line-height: 1.4 !important;
  }
`
export const StackButton = styled.button`
  background-color: ${({ _color }) =>
    _color ? _color : color._008000} !important;
  width: calc(25% - 1px);
  margin-top: 2px;
  float: left;
  font-weight: normal;
  color: ${color._000000};
  font-size: 12px;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 2px;
  border: none;
  font-weight: 600;
  margin-right: 1px;
  height: 35px;
`
export const StackButtonSpan = styled.span`
  color: ${color._FFFFFF};
`
export const ButtonIcons = styled.i`
  color: ${color._008000};
  font-weight: 900;
  font-size: 15px;
  vertical-align: middle;
`
export const ButtonSpan = styled.span`
  color: ${color._000000};
`
export const Description = styled.span`
  color: ${color._C10930};
  padding: 0;
  margin: 0;
  font-size: 12px;
  width: 100%;
  font-weight: 600;
  background: none;
  font-family: 'Titillium Web', sans-serif;
`
export const ActionDangerButton = styled.button`
  width: 49%;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 2px;
  height: 35px;
  border: none;
  background: ${color._F96F72};
  color: ${color._FFFFFF};
  margin-right: 2px;
  font-family: 'Titillium Web', sans-serif;
`
export const ActionSuccessButton = styled.button`
  background: ${({ isDisabled }) =>
    isDisabled ? color._808080 : color._008000};
  width: 49%;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 2px;
  height: 35px;
  border: none;
  color: ${color._FFFFFF};
  font-family: 'Titillium Web', sans-serif;
`
