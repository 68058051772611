export const _constants = {
  noRecord: 'No real-time records found',
}
export const HorseRacingTab = [
  {
    title: 'ALL',
    id: 1,
    disable: false,
    img: 'https://www.reddybook.club/assets/images/menu-home.png',
    path: '/',
  },
  {
    title: 'GB',
    id: 2,
    disable: false,
    img: 'https://www.reddybook.club/assets/images/menu-home.png',
    path: '/',
  },
]
