import styled from 'styled-components'
import { color } from '../../color/color'
import redBg from '../../assets/images/red.png'

export const HeaderComponent = styled.header`
  box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
  background-color: ${color._FFFFFF};
  position: sticky;
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  top: 0px;
  padding-left: 20px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    background: ${color._000000};
    padding-left: 0px;
  }
`

export const Header = styled.div`
  position: sticky;
  z-index: 2;
  top: 0;
`
export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 5rem;
  display: flex;
  align-items: center;
  @media (max-width: 700px) {
    padding: 0px;
  }
`
export const Dflex = styled.div`
  display: flex;
`
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Anchor = styled.button`
  line-height: 1;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
`
export const DesktopLogo = styled.img`
  width: 117px;
  height: 36px;
  @media (max-width: 991px) {
    display: none;
  }
`
export const MobileLogo = styled.img`
  width: 117px;
  height: 36px;
  display: none;
  @media (max-width: 991px) {
    display: block;
    max-height: 30px;
    width: 100px;
    padding-left: 0.3rem;
  }
`
export const SearchContainer = styled.div`
  text-decoration: none;
  min-width: 360px;
  padding: 0 20px;
  @media (max-width: 1197px) {
    display: none;
  }
`
export const SearchBox = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  svg {
    color: ${color._000000};
  }
`
export const SearchInput = styled.input`
  border: 0;
  font-size: 14px;
  color: ${color._012970};
  box-shadow: 0 0 10px 0 rgb(1 41 112 / 15%);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${color._212529};
  background-color: ${color._FFFFFF};
  background-clip: padding-box;
  border: 1px solid ${color._CED4DA};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 40px;
`
export const SearchButton = styled.div`
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
  margin-top: -5px;
`
export const Rules = styled.button`
  background: ${color._C10930};
  border-bottom: 4px solid ${color._6D081D};
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 11px;
  padding: 7px 15px;
  border-radius: 5px;
  text-decoration: none;
  color: ${color._FFFFFF};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  border-top: none;
  border-right: none;
  border-left: none;
  &:hover {
    background: ${color._6D081D};
    border-bottom: 4px solid ${color._C10930};
  }
  @media (max-width: 991px) {
    display: none;
  }
`
export const NavContainer = styled.nav`
  margin-left: auto;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 3rem;
  }
`
export const NavOption = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: end;

  @media (max-width: 450px) {
    width: 100%;
  }
`
export const AccountList = styled.div`
  &:before {
    background: url(${redBg});
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -10px;
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: center;
    display: flex;
    width: 90%;
    z-index: 99;
  }
  margin: 0 0px;
  width: 100px;
  background: none;
  color: ${color._000000};
  position: relative;
  cursor: pointer;
  text-align: center;

  &:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
  }
  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
  }
  @media (max-width: 450px) {
    width: 100%;
    top: -2px;
    left: 5px;
    &:before {
      width: 100%;
      min-width: 60px;
    }
  }
`
export const AccountListAnchor = styled.div`
  display: block;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
`

export const AccountListIcon = styled.i`
  display: none;
  font-size: 15px;
  position: absolute;
  right: 10px;
  top: 0px;
  opacity: 0.3;
`
export const AccountListTitle = styled.span`
  top: 2px;
  margin-left: -5px;
  font-size: 12px;
  text-transform: uppercase;
  line-height: normal;
  color: ${color._FFFFFF};
  font-weight: 700;
  position: relative;
  z-index: 999;
  text-align: center;
  b {
    display: block;
    color: ${color._000000};
  }
  @media (max-width: 991px) {
    b {
      display: block;
      color: ${color._FFFFFF};
      margin-left: -6px;
    }
  }
  @media (max-width: 450px) {
    top: 2px;
    margin-left: 1px;
    font-size: 10px;
    b {
      display: block;
      color: ${color._FFFFFF};
      margin-top: 5px;
    }
  }
  @media (max-width: 400px) {
    top: -2px;
    margin-left: 1px;
    font-size: 10px;
    b {
      display: block;
      color: ${color._FFFFFF};
      margin-left: 2px;
    }
  }
`
export const ProfileSection = styled.div`
  position: relative;
  display: flex;
  // padding-right: 1rem;
  @media (max-width: 991px) {
    padding-right: 0.2rem;
  }
`
export const ProfileAnchor = styled.div`
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  color: ${color._000000} !important;
  text-transform: uppercase;
  margin-left: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-right: 0;
  @media (max-width: 991px) {
    color: ${color._FFFFFF} !important;
  }
`
export const ProfileIcon = styled.i`
  @media (max-width: 991px) {
    display: none;
  }
`
export const ProfileTitle = styled.div`
  white-space: normal;
  padding-left: 0.5rem;
  width: 100%;
  min-width: 62px;
`
export const UserSection = styled.div`
  position: relative;
  display: flex;
  padding-right: 1rem;
  gap: 10px;
  @media (max-width: 991px) {
    padding-right: 0.3rem;
  }
`
export const Button = styled.button`
  background: ${color._343435};
  border: 1px solid ${color._C10930};
  color: ${color._FFFFFF};
  border-radius: 10px;
  font-size: 13px;
  text-transform: uppercase;
  padding: 7px 15px;
  font-weight: 600;
  box-shadow: inset 0 0 0 1px ${color._FFFFFF};
  transition: 0.9s;
  letter-spacing: 1px;
  @media (max-width: 991px) {
    padding: 7px 18px;
    margin-left: 10px;
    box-shadow: inset 0 0 0 1px ${color._FFFFFF};
    border-radius: 5px;
    background: ${color._343435};
    border: 1px solid ${color._C10930};
  }
`

export const Deposit = styled.button`
  background: ${color._006400};
  border-bottom: 4px solid ${color._8B0000};
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 11px;
  padding: 7px 15px;
  border-radius: 5px;
  text-decoration: none;
  color: ${color._FFFFFF};
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  border-top: none;
  border-right: none;
  border-left: none;

  cursor: pointer;

  &:hover {
    background: ${color._6D081D};
    border-bottom: 4px solid ${color._C10930};
  }
  @media (max-width: 991px) {
    display: none;
  }
`
export const Withdrawal = styled.button`
  background: ${color._8B0000};
  border-bottom: 4px solid ${color._006400};
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 11px;
  padding: 7px 15px;
  border-radius: 5px;
  font-weight: 400;
  text-decoration: none;
  color: ${color._FFFFFF};
  display: flex;
  align-items: center;
  font-family: 'Titillium Web', sans-serif;
  cursor: pointer;
  border-top: none;
  border-right: none;
  border-left: none;

  &:hover {
    background: ${color._6D081D};
    border-bottom: 4px solid ${color._C10930};
  }
  @media (max-width: 991px) {
    display: none;
  }
`
export const Img = styled.img`
  width: 18px;
  filter: invert(1);
  margin-right: 5px;
  height: auto;
`
