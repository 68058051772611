import React from 'react'
import TabPanelItem from './TabPanelItem'
import { tabs } from '../../../utils/constant'

const TabContents = ({ activeTab }) => {
    
    return (
      <div className="tab-content" id="nav-tabContent">
        {tabs.map((tab) => (
          <TabPanelItem key={tab} tabId={tab} activeTab={activeTab} />
        ))}
      </div>
    )
  }

export default TabContents
