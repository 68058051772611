import React from 'react'
import LogOne from '../assets/images/log_one.png'
import LogTwo from '../assets/images/log_two.png'
import LogThree from '../assets/images/log_three.png'
import LogFour from '../assets/images/log_four.png'
const IRoulette = React.lazy(() =>
  import('../assets/images/icasino/roulette.webp'),
)
const IBlackJack = React.lazy(() =>
  import('../assets/images/icasino/blackjack.webp'),
)
const IBaccarat = React.lazy(() =>
  import('../assets/images/icasino/baccarat.webp'),
)
const IPoker = React.lazy(() => import('../assets/images/icasino/poker.webp'))
const IDragonTiger = React.lazy(() =>
  import('../assets/images/icasino/dragon-tiger.webp'),
)
const ITeenPatti = React.lazy(() =>
  import('../assets/images/icasino/teenpatti.webp'),
)
const ILucky7 = React.lazy(() => import('../assets/images/icasino/lucky7.webp'))
const I32Cards = React.lazy(() =>
  import('../assets/images/icasino/32cards.webp'),
)
const IOthers = React.lazy(() => import('../assets/images/icasino/others.webp'))
const ICricketWar = React.lazy(() =>
  import('../assets/images/icasino/cricketwar.webp'),
)
const ILottery = React.lazy(() =>
  import('../assets/images/icasino/lottery.webp'),
)
const IHiLow = React.lazy(() => import('../assets/images/icasino/hi-low.webp'))
const IAndarBhar = React.lazy(() =>
  import('../assets/images/icasino/andarbahar.webp'),
)
const ISicbo = React.lazy(() => import('../assets/images/icasino/sicbo.webp'))
const IScratchCards = React.lazy(() =>
  import('../assets/images/icasino/scratch-cards.webp'),
)
const IRummy = React.lazy(() => import('../assets/images/icasino/rummy.webp'))
const ISlots = React.lazy(() => import('../assets/images/icasino/slots.webp'))
const IBingo = React.lazy(() => import('../assets/images/icasino/bingo.webp'))
const ICrash = React.lazy(() => import('../assets/images/icasino/crash.webp'))
const IMonopoly = React.lazy(() =>
  import('../assets/images/icasino/monopoly.webp'),
)
const IDealOrNoDeal = React.lazy(() =>
  import('../assets/images/icasino/deal-or-no-deal.webp'),
)
const Events = React.lazy(() => import('../assets/svg/sports/Events.svg'))
const Favourite = React.lazy(() =>
  import('../assets/svg/sports/Favourites.svg'),
)
const Live = React.lazy(() => import('../assets/svg/sports/Live.svg'))
const MyBets = React.lazy(() => import('../assets/svg/sports/MyBets.svg'))
const Results = React.lazy(() => import('../assets/svg/sports/Results.svg'))
const Search = React.lazy(() => import('../assets/svg/sports/Search.svg'))

const Home = React.lazy(() => import('../assets/svg/ResponsiveDrawer/Home.svg'))
const SBLive = React.lazy(() =>
  import('../assets/svg/ResponsiveDrawer/Live.svg'),
)
const Trophy = React.lazy(() =>
  import('../assets/svg/ResponsiveDrawer/Trophy.svg'),
)
const Report = React.lazy(() =>
  import('../assets/svg/ResponsiveDrawer/Report.svg'),
)
const SBFavourite = React.lazy(() =>
  import('../assets/svg/ResponsiveDrawer/Favourite.svg'),
)

// Casino Provider
const CasinoP1 = React.lazy(() =>
  import('../assets/images/casinoProvider/mac-casino.webp'),
)
const CasinoP2 = React.lazy(() =>
  import('../assets/images/casinoProvider/aesexy-casino.png'),
)
const CasinoP3 = React.lazy(() =>
  import('../assets/images/casinoProvider/bombaylive-casuino.png'),
)
const CasinoP4 = React.lazy(() =>
  import('../assets/images/casinoProvider/ezugigaming-casino.png'),
)
const CasinoP5 = React.lazy(() =>
  import('../assets/images/casinoProvider/jiligaming-casino.png'),
)
const CasinoP6 = React.lazy(() =>
  import('../assets/images/casinoProvider/spribegaming-casino.png'),
)
const CasinoP7 = React.lazy(() =>
  import('../assets/images/casinoProvider/vivogaming-casino.png'),
)
const CasinoP8 = React.lazy(() =>
  import('../assets/images/casinoProvider/betgames_casino_1.png'),
)
const CasinoP9 = React.lazy(() =>
  import('../assets/images/casinoProvider/evolutiongaming_casino_1.png'),
)
const CasinoP10 = React.lazy(() =>
  import('../assets/images/casinoProvider/gamzixgaming_casino_1.png'),
)
const CasinoP11 = React.lazy(() =>
  import('../assets/images/casinoProvider/kingmaker_Casino_1.png'),
)
const CasinoP12 = React.lazy(() =>
  import('../assets/images/casinoProvider/royalgaming_casino_1.png'),
)
const CasinoP13 = React.lazy(() =>
  import('../assets/images/casinoProvider/smartsoft_casino_1.png'),
)
const CasinoP14 = React.lazy(() =>
  import('../assets/images/casinoProvider/turbogames_casino_1.png'),
)

export const ResponsiveDrawerData = [
  {
    icon: Home,
    title: 'Home',
  },
  {
    icon: SBLive,
    title: 'Inplay',
  },
  {
    icon: Trophy,
    title: 'All Sports',
  },
  {
    icon: Report,
    title: 'My Bets',
  },
  {
    icon: Report,
    title: 'Results',
  },
  {
    icon: SBFavourite,
    title: 'Favourites',
  },
]
export const constants = {
  LOGIN_NOW: 'LOGIN NOW',
  Forgot_Password: 'Forgot Password',
  LOG_IN: 'LOG IN',
  LOGIN_WITH_DEMO_ID: 'LOGIN WITH DEMO ID',
  Powered_by: 'Powered by',
  reddybook: 'reddybook',
  reddybook_email: 'reddybook.club@gmail.com',
  Username_MobileNumber: 'USERNAME / MOBILE NUMBER',
  PASSWORD: 'PASSWORD',
  Remember_Me: 'Remember Me ?',
  PhoneWidth: 573,
  Sign_Out: 'Sign Out',
  HI: 'HI,',
  Home: 'Home',
  MyProfile: 'My Profile',
  AccountStatement: 'Account Statement',
  StakeSettings: 'Stake Settings',
  ProfitAndLoss: 'Profit & Loss',
  UnsettledBets: 'Unsettled Bets',
  ChangePassword: 'Change Password',
  No: 'No',
  Date: 'Date',
  Total: 'Total',
  Balance: 'Balance',
  DC: 'D/C',
  Description: 'Description',
  Details: 'Details',
  AllSports: 'All Sports',
  Cricket: 'Cricket',
  Football: 'Football',
  Tennis: 'Tennis',
  Politics: 'Politics',
  HorseRacing: 'Horse Racing',
  GreyhoundRacing: 'Greyhound Racing',
  Kabbadi: 'Kabbadi',
  Casino: 'Casino',
  SportsBook: 'Sports Book',
  IntCasino: 'Int Casino',
  Binary: 'Binary',
  NoDataFound: 'No Data Found',
  PreviousPage: 'Previous Page',
  NextPage: 'Next Page',
  ACCOUNT_STATEMENT: 'ACCOUNT STATEMENT',
  GET_STATEMENT: 'GET STATEMENT',
  Profile: 'Profile',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  RE_ENTER_PASSWORD: 'Re-enter New Password',
  NOTE: 'Note',
  CHANGE_PASSWORD: 'Change Password',
  PASSWORD_RULE_FIRST: 'The New Password field must be at least 6 characters',
  PASSWORD_RULE_SECOND:
    'The New Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number',
  OVERVIEW: 'Overview',
  STACK_SETTING: 'Stack settings',
  CHANGE_PASSWORD_KEY: 'Change Password',
  All_Sports: 'All Sports',
  date: 'date',
  Start_Date: 'Start Date',
  End_Date: 'End Date',
  Event: 'Event',
  Comm_In: 'Comm In',
  Comm_Out: 'Comm Out',
  Amount: 'Amount',
  Info: 'Info',
  Profit_Loss_Heading: 'PROFIT LOSS TOTAL P/L : IR 0',
  Submit: 'Submit',
  Event_Name: 'Event Name',
  Nation: 'Nation',
  Event_Type: 'Event Type',
  Market_Name: 'Market Name',
  Side: 'Side',
  Rate: 'Rate',
  Place_Date: 'Place Date',
  Match_Date: 'Match Date',
  UNSETTLED_BETS: 'UNSETTLED BETS',
  INT_CASINO: 'INT CASINO',
  Back: 'Back',
  Sports_Book: 'Sports Book',
  Events: 'Events',
  Virtual_Sports: 'Virtual Sports',
  SRL: 'SRL',
  Favourite: 'Favourite',
  userName: 'Demo Name',
  Successfully_logged_in: 'Successfully logged in',
  Successfully_logged_out: 'Successfully logged out',
  Invalid_Username_Or_Password: 'Enter Username/Password',
  Market: 'Market',
  Odds: 'Odds',
  Stake: 'Stake',
  No_Matched_Bets_Found: 'No Matched Bets Found.',
  Place_your_bets: 'Place your bets',
  Your_section_will_appear_here: 'Your section will appear here',
  BET_SLIP: 'BET SLIP',
  BET_DETAILS: 'BET DETAILS',
  MY_BETS: 'MY BETS',
  RESULTS: 'RESULTS',
  QuickSearchTagLine: 'Finding for something special?',
  Quickly_Search: 'Quickly Search',
  Search: 'Search',
  QUICK_SEARCH: 'QUICK SEARCH',
  My_Bets: 'My Bets',
  MyBets: 'MyBets',
  Results: 'Results',
  Inplay: 'Inplay',
  Live: 'Live',
  Favourites: 'Favourites',
  LIVE_EVENTS: 'LIVE EVENTS',
  UPCOMING_EVENTS: 'UPCOMING EVENTS',
  Bet_ID: 'Bet ID',
  IPL: 'IPL',
  Clear: 'Clear',
  Profit: 'Profit',
  Pts: 'Pts',
  Place_Bet: 'Place Bet',
  Market_Not_Active: 'Market is not Active!',
  PLACE_BETS: 'PLACE BETS',
  OPEN_BETS: 'OPEN BETS',
  BET_SLIP_OPEN_BETS: 'BET SLIP / OPEN BETS',
  FANCY: 'FANCY',
  FOOTBALL: 'FOOTBALL',
  CRICKET: 'CRICKET',
  TENNIS: 'TENNIS',
  ALL: 'ALL',
  Back_price: '0',
  Stack_Success_Message: 'Stack Updated Sucessfully',
}

export const addonValues = [20, 30, 40, 50, 60, 70, 80, 90]
export const tabs = ['Overview', 'Stack settings', 'Change Password']
export const LoginInputArr = [
  {
    label: constants.Username_MobileNumber,
    type: 'text',
    inputBgColor: '#00427c',
    value: 'username',
  },
  {
    label: constants.PASSWORD,
    type: 'password',
    inputBgColor: '#00427c',
    value: 'password',
  },
]
export const PasswordObj = {
  label: constants.Remember_Me,
  type: 'checkbox',
  customStyle: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '10px',
  },
}

export const CarouselImages = [
  {
    src: LogOne,
  },
  {
    src: LogTwo,
  },
  {
    src: LogThree,
  },
  {
    src: LogFour,
  },
]

export const ProfileDropdownOptions = [
  {
    icon: <i class="bi bi-house"></i>,
    title: constants.Home,
    navigate: '/',
  },
  {
    icon: <i class="bi bi-person"></i>,
    title: constants.MyProfile,
    navigate: '/profile',
    profileTab: 'Overview',
  },
  {
    icon: <i class="bi bi-bar-chart-steps"></i>,
    title: constants.AccountStatement,
    navigate: '/reports/account-statement',
  },
  {
    icon: <i class="bi bi-bullseye"></i>,
    title: constants.StakeSettings,
    navigate: '/profile',
    profileTab: 'Stack settings',
  },
  {
    icon: <i class="bi bi-bar-chart-line"></i>,
    title: constants.ProfitAndLoss,
    navigate: '/reports/profit-loss',
  },
  {
    icon: <i class="bi bi-bar-chart"></i>,
    title: constants.UnsettledBets,
    navigate: '/reports/unsettled-bets',
  },
  {
    icon: <i class="bi bi-lock"></i>,
    title: constants.ChangePassword,
    navigate: '/profile',
    profileTab: 'Change Password',
  },
]

export const AccountInfoTableHeader = [
  {
    title: constants.No,
    width: '93px',
  },
  {
    title: constants.Date,
    width: '122px',
  },
  {
    title: constants.Total,
    width: '124px',
  },
  {
    title: constants.Balance,
    width: '170px',
  },
  {
    title: constants.DC,
    width: '107px',
  },
  {
    title: constants.Description,
    width: '222px',
  },
  {
    title: constants.Details,
    width: '155px',
  },
]

export const ProfitAndLossTableHeader = [
  {
    title: constants.No,
    width: '80px',
  },
  {
    title: constants.Date,
    width: '105px',
  },
  {
    title: constants.Event,
    width: '118px',
  },
  {
    title: constants.Comm_In,
    width: '158px',
  },
  {
    title: constants.Comm_Out,
    width: '181px',
  },
  {
    title: constants.Amount,
    width: '147px',
  },
  {
    title: constants.Total,
    width: '107px',
  },
  {
    title: constants.Info,
    width: '95px',
  },
]

export const UnsettledBetTableHeader = [
  {
    title: constants.No,
    width: '51px',
  },
  {
    title: constants.Event_Name,
    width: '129px',
  },
  {
    title: constants.Nation,
    width: '84px',
  },
  {
    title: constants.Event_Type,
    width: '119px',
  },
  {
    title: constants.Market_Name,
    width: '141px',
  },
  {
    title: constants.Side,
    width: '63px',
  },
  {
    title: constants.Rate,
    width: '66px',
  },
  {
    title: constants.Amount,
    width: '94px',
  },
  {
    title: constants.Place_Date,
    width: '116px',
  },
  {
    title: constants.Match_Date,
    width: '124px',
  },
]

export const SportsOption = [
  constants.AllSports,
  constants.Cricket,
  constants.Football,
  constants.Tennis,
  constants.Politics,
  constants.HorseRacing,
  constants.GreyhoundRacing,
  constants.Kabbadi,
  constants.Casino,
  constants.SportsBook,
  constants.IntCasino,
  constants.Binary,
]

export const OverviewData = [
  {
    keyName: 'User Id',
    value: '1',
  },
  {
    keyName: 'Available Chips',
    value: '2',
  },
  {
    keyName: 'Exposure',
    value: '3',
  },
  {
    keyName: 'Total Chips',
    value: '4',
  },
  {
    keyName: 'Profit/Loss',
    value: '5',
  },
]
export const CasinoProviderOptions = [
  {
    src: CasinoP1,
    alt: '7 Up And Down',
    id: '7_Up_And_Down',
  },
  {
    src: CasinoP2,
    alt: 'Roulette',
    id: 'Roulette',
  },
  {
    src: CasinoP3,
    alt: 'Teen Patti',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP4,
    alt: 'Dragon Tiger',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP5,
    alt: 'Cricket War',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP6,
    alt: 'Baccarat',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP7,
    alt: 'Amar Akbar Anthony',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP8,
    alt: '7 Up And Down',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP9,
    alt: 'Roulette',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP10,
    alt: 'Teen Patti',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP11,
    alt: 'Dragon Tiger',
    id: 'Teen_Patti',
  },
  {
    src: CasinoP12,
    alt: 'Cricket War',
  },
  {
    src: CasinoP13,
    alt: 'Baccarat',
  },
  {
    src: CasinoP14,
    alt: 'Amar Akbar Anthony',
  },
]

export const ICasinoFilterMenuOptions = [
  {
    title: 'ALL',
  },
  {
    title: 'VIVO',
  },
  {
    title: 'MAC88',
  },
  {
    title: 'ROYAL GAMING',
  },
  {
    title: 'AE SEXY',
  },
  {
    title: 'JILI',
  },
  {
    title: 'KINGMAKER',
  },
  {
    title: 'BOMBAY LIVE',
  },
  {
    title: 'SPRIBE',
  },
  {
    title: 'GAMZIX',
  },
  {
    title: 'EZUGI',
  },
  {
    title: 'TURBO GAMES',
  },
  {
    title: 'EVOLUTION GAMING',
  },
  {
    title: 'BETGAMES.TV',
  },
]

export const TabMenuOptions = [
  {
    src: IRoulette,
    alt: 'Roulette',
  },
  {
    src: IBaccarat,
    alt: 'Baccarat',
  },
  {
    src: IBlackJack,
    alt: 'Blackjack',
  },
  {
    src: IPoker,
    alt: 'Poker',
  },
  {
    src: IDragonTiger,
    alt: 'Dragon Tiger',
  },
  {
    src: ITeenPatti,
    alt: 'TeenPatti',
  },
  {
    src: ILucky7,
    alt: 'Lucky7',
  },
  {
    src: I32Cards,
    alt: '32Cards',
  },
  {
    src: IOthers,
    alt: 'Others',
  },
  {
    src: ICricketWar,
    alt: 'Cricketwar',
  },
  {
    src: ILottery,
    alt: 'Lottery',
  },
  {
    src: IHiLow,
    alt: 'Hi Low',
  },
  {
    src: IAndarBhar,
    alt: 'Andarbhar',
  },
  {
    src: ISicbo,
    alt: 'Sicbo',
  },
  {
    src: IScratchCards,
    alt: 'Scratch Cards',
  },
  {
    src: IRummy,
    alt: 'Rummy',
  },
  {
    src: ISlots,
    alt: 'Slots',
  },
  {
    src: IBingo,
    alt: 'Bingo',
  },
  {
    src: ICrash,
    alt: 'Crash',
  },
  {
    src: IMonopoly,
    alt: 'Monopoly',
  },
  {
    src: IDealOrNoDeal,
    alt: 'Deal Or No Deal',
  },
]

export const UpperContent = [
  {
    title: 'Events',
    svg: Events,
  },
  {
    title: 'Live',
    svg: Live,
  },
  {
    title: 'Favourite',
    svg: Favourite,
  },
  {
    title: 'MyBets',
    svg: MyBets,
  },
  {
    title: 'Results',
    svg: Results,
  },
  {
    title: 'Search',
    svg: Search,
  },
]

export const CloseDrawerArr = [
  {
    title: 'Live',
    svg: Live,
  },
  {
    title: 'Events',
    svg: Events,
  },
  {
    title: 'MyBets',
    svg: MyBets,
  },
  {
    title: 'Search',
    svg: Search,
  },
]

export const LowerContent = [
  {
    title: 'Cricket',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_cricket.de56ce3f.svg',
  },
  {
    title: 'Soccer',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_football_w.50cabbda.svg',
  },
  {
    title: 'Tennis',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_tennis_w.f31f6089.svg',
  },
  {
    title: 'Virtual Sports',
    icon: 'SRL',
  },
  {
    title: 'American Football',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/americanFootball.802c1d2f.svg',
  },
  {
    title: 'Badminton',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_badminton.2681cdeb.svg',
  },
  {
    title: 'Baseball',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_baseball.34810a61.svg',
  },
  {
    title: 'Basketball',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_basketball_w.d6316da7.svg',
  },
  {
    title: 'Darts',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/dart.679818a9.svg',
  },
  {
    title: 'Futsal',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/futsal.8b77883f.svg',
  },
  {
    title: 'Ice Hockey',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_ice_hockey_w.f355ce9e.svg',
  },
  {
    title: 'Kabaddi',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_kabaddi_w.0aa18d95.svg',
  },
  {
    title: 'MMA',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_MMA.834af83f.svg',
  },
  {
    title: 'Rugby',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/rugby.fb43c1ec.svg',
  },
  {
    title: 'Snooker',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_snooker_w.d205c954.svg',
  },
  {
    title: 'Table Tennis',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_table_tennis_w.a4abf836.svg',
  },
  {
    title: 'Volleyball',
    icon: 'https://sports-v3.mysportsfeed.io/static/media/ic_volleyball_w.8a3df575.svg',
  },
]

export const getToastPosition = () => {
  return {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  }
}

export const MyBetsOptions = [
  'Cricket',
  'Soccer',
  'Tennis',
  'SRL',
  'Americal Football',
  'Badminton',
  'Baseball',
  'Basketball',
  'Darts',
  'Futsal',
  'Ice Hockey',
  'Kabaddi',
  'MMA',
  'Rugby',
  'Snooker',
  'Table Tennis',
  'Volleyball',
]

export const MyBetsTableHeader = (width) => {
  return [
    {
      name: 'PLACED DATE & TIME',
      view: width > 929,
    },
    {
      name: 'MATCH',
      view: true,
    },
    {
      name: 'MARKET/RUNNER',
      view: width > 929,
    },
    {
      name: 'ODDS',
      view: true,
    },
    {
      name: 'STAKE',
      view: true,
    },
    {
      name: 'BET STATUS',
      view: true,
    },
    {
      name: 'RESULTS',
      view: width > 929,
    },
    {
      name: 'RETURNS',
      view: true,
    },
  ]
}

export const getWeekDay = (day) => {
  switch (day) {
    case 0:
      return 'SUN'
    case 1:
      return 'MON'
    case 2:
      return 'TUE'
    case 3:
      return 'WED'
    case 4:
      return 'THUS'
    case 5:
      return 'FRI'
    default:
      return 'SAT'
  }
}

export const getResultOptions = (date) => {
  let arr = []
  let currDate = date.getDate()
  let dayNumber = date.getDay()
  for (let i = 0; i < 7; i++, currDate--) {
    let weekDay = getWeekDay(dayNumber)
    arr.push({
      currDate,
      weekDay,
    })
    if (dayNumber < 0) {
      dayNumber = 6
    } else {
      dayNumber--
    }
  }
  return arr
}
