import React from 'react'
import * as Styled from './styles'
import { constants, playersData, tableHeaders } from './constant'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { color } from '../../../color/color'

function MuflisTeenPatti() {
  return (
    <Styled.TableContainer>
      <div className="row">
        {playersData.length &&
          playersData.map(({ player, stats }) => (
            <div className="col-6" key={player + stats.winner}>
              <Styled.Table>
                <tr>
                  <Styled.PrimaryTableHeadRow>{`${constants.player} ${player}`}</Styled.PrimaryTableHeadRow>
                  <Styled.TableRowEmpty />
                  <Styled.TableRowRightEmpty />
                </tr>
                <tr>
                  {tableHeaders.length &&
                    tableHeaders.map((header, index) => (
                      <Styled.SecondaryTableHeadRow key={header + index}>
                        {header}
                      </Styled.SecondaryTableHeadRow>
                    ))}
                </tr>
                <tr>
                  {Object.keys(stats).map((statKey, index) => {
                    const stat = stats[statKey]
                    return (
                      <Styled.TableData
                        key={index + stats[statKey]}
                        isSuspended={stat.isSuspended}
                      >
                        <div className="align-items-center justify-content-center position-relative">
                          {stat.isSuspended && (
                            <Styled.SuspendedContainer>
                              <Lock fill={color._fff} />
                            </Styled.SuspendedContainer>
                          )}
                          <span>{stat.value}</span>
                        </div>
                      </Styled.TableData>
                    )
                  })}
                </tr>
              </Styled.Table>
            </div>
          ))}
      </div>
    </Styled.TableContainer>
  )
}

export default MuflisTeenPatti
