import { useEffect, useMemo, useRef, useState } from 'react'
import Layout from '../../Layout'
import PlaceBetBox from '../../components/PlaceBetBox/PlaceBetBox'
import MatchDetails from '../../container/MatchDetails/MatchDetails'
import { _constants } from './constants'
import * as Styled from './style'
import {
  getBookmakerData,
  getMatchOddsData,
  getspecificEvent,
  transformInput,
} from '../../utils/utils'
import Loader from '../../components/Loader/Loader'
import { constants } from '../../utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import Fancy from '../../components/Fancy/Fancy'
import { getFancyData } from '../../redux/fancy/action'
import { getBookmakerData as getBookmakerDataState } from '../../redux/bookmaker/action'
import { TabComponent } from './utils'
import { io } from 'socket.io-client'
import { getPlaceBetStatus } from '../../redux/match/action'
import { getMatchOddData } from '../../redux/matchOdds/action'

const FootballDetails = ({ sportsEventId }) => {
  const [showBetSection, setShowBetSection] = useState(false)
  const [backlayAmount, setBackLayAmount] = useState(0)
  const [currentSportsId, setCurrentSportsId] = useState(0)
  const [minBet, setMinBet] = useState(0)
  const [maxBet, setMaxBet] = useState(0)
  const [betDelay, setBetDelay] = useState(0)
  const [teamName, setTeamName] = useState('')
  const [oddType, setOddType] = useState('')
  const [oddStr, setOddstr] = useState('')
  const [oddVolume, setOddVolume] = useState(0)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const placebetStatus = useSelector((state) => state.match.betStatus)
  const openMatchedBets =
    useSelector((state) => state.match.openMatchedBets) || []
  const openFancyBets = useSelector((state) => state.match.openFancyBets) || []

  const [activeTab, setActiveTab] = useState('Matched')
  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  const [fancy, setFancy] = useState(false)
  const [fancyList, setFancyList] = useState([])
  const [loading, setLoading] = useState(true)
  const [bgColor, setBgColor] = useState()
  const dispatch = useDispatch()
  const handleBetSection = (
    data,
    sportsId,
    min_bet,
    max_bet,
    isFancy = false,
    color = false,
    bet_delay,
    odd_type,
    teamName,
    strValue,
    inputVolume,
  ) => {
    setShowBetSection(true)
    setBackLayAmount(data)
    setCurrentSportsId(sportsId)
    setFancy(false)
    setBgColor(color)
    setMinBet(min_bet)
    setMaxBet(max_bet)
    setBetDelay(bet_delay)
    setTeamName(teamName)
    setOddType(odd_type)
    setOddstr(strValue)
    setOddVolume(inputVolume)

    if (isFancy) {
      setFancy(true)
      setMinBet(min_bet)
      setMaxBet(max_bet)
      setBgColor(color)
      setBackLayAmount(data)
      setCurrentSportsId(sportsId)
      setOddstr(strValue)
      setOddVolume(inputVolume)
    }
  }
  const handleCloseBetSection = () => {
    setShowBetSection(false)
  }
  const matchesData = useSelector((state) => state.match.data)
  const matchOddData = useSelector((state) => state.matchOdd.data)

  const bookmakerData = useSelector((state) => state.bookmaker.data)
  const fancyData = useSelector((state) => state.fancy.data)
  const eventDetails = getspecificEvent(matchesData, sportsEventId)
  // const restructuredBookmakerData = getBookmakerData(bookmakerData)
  const restructuredBookmakerData = getMatchOddsData(matchOddData)

  useMemo(() => {
    setFancyList(fancyData)
    return () => {
      setFancyList([])
      dispatch(getFancyData([]))
    }
  }, [fancyData])
  const avoidRerender = useRef(false)

  useEffect(() => {
    // if (!avoidRerender.current) {
    const socket = io('https://odds.100panel.org', {
      transports: ['websocket'],
    })
    const event_id = localStorage.getItem('sportEventId')
    socket.emit('event', event_id)
    if (isAuthenticated) {
      const socketbets = io('https://bet.100panel.org', {
        transports: ['websocket'],
      })
      const uuid = localStorage.getItem('userId') || ''
      socketbets.emit('uuid', uuid)
      socketbets.on('client_eligibility_status', (data) => {
        dispatch(getPlaceBetStatus(data))
        // lock the odds on the basis of status
      })
    } else {
      dispatch(getPlaceBetStatus(false))
    }
    socket.on(`match_odds_${event_id}`, (data) => {
      dispatch(getMatchOddData(data))
      setLoading(false)
    })

    socket.on(`admin_session_active_${event_id}`, (data) => {
      dispatch(getFancyData(transformInput(data)))
      setLoading(false)
    })
    socket.on(`bookmaker_${event_id}`, (data) => {
      dispatch(getBookmakerDataState(transformInput(data)))
    })
    return () => {
      socket.disconnect()
      socket.off()
      socket.disconnect(true)
      // avoidRerender.current = true
    }
  }, [])

  return (
    <>
      <Layout>
        {loading ? (
          <Loader />
        ) : (
          <Styled.Section>
            <div className="row mt-3">
              <Styled.Col1 className="col-md-12 col-lg-8">
                {/* header */}
                <Styled.GameHeading>
                  <Styled.Title>{eventDetails?.name}</Styled.Title>
                  <span>({eventDetails?.date})</span>
                </Styled.GameHeading>
                {/* details */}

                {restructuredBookmakerData?.map((item, indx) => {
                  return (
                    item.eventId === eventDetails.event_id && (
                      <MatchDetails
                        handleBetSection={handleBetSection}
                        handleCloseBetSection={handleCloseBetSection}
                        currentSportsId={currentSportsId}
                        showBetSection={showBetSection}
                        sportsName={constants.CRICKET}
                        eventDetails={eventDetails}
                        restructuredBookmakerData={item}
                        matchOddData={matchOddData}
                        restructuredFancyData={fancyList}
                        key={item.bookmaker_id + indx}
                        backlayAmount={backlayAmount}
                        bgColor={bgColor}
                        oddStr={oddStr}
                        placebetStatus={placebetStatus}
                      />
                    )
                  )
                })}

                <Fancy
                  restructuredFancyData={fancyList}
                  showHeading={false}
                  showPlaceBets={false}
                  handleBetSection={handleBetSection}
                  handleCloseBetSection={handleCloseBetSection}
                  currentSportsId={currentSportsId}
                  showBetSection={showBetSection}
                  backlayAmount={backlayAmount}
                  bgColor={bgColor}
                  placebetStatus={placebetStatus}
                />
              </Styled.Col1>
              <Styled.Col2 className="col-md-12 col-lg-4  d-none d-lg-block px-0">
                <Styled.PlaceBet className="mb-1">
                  <Styled.Cardheader>
                    <Styled.H6>{_constants.Bet}</Styled.H6>
                  </Styled.Cardheader>
                  {showBetSection && (
                    <PlaceBetBox
                      backlayAmount={backlayAmount}
                      handleCloseBetSection={handleCloseBetSection}
                      min_bet={minBet || 100}
                      max_bet={maxBet || 100}
                      bgColor={bgColor}
                      bet_delay={betDelay}
                      odd_type={oddType}
                      teamName={teamName}
                      oddStr={oddStr}
                      oddVolume={oddVolume}
                    />
                  )}
                </Styled.PlaceBet>
                <Styled.OpenBet className="mb-1">
                  <Styled.Cardheader>
                    <Styled.H6>{_constants.open_bets}</Styled.H6>
                  </Styled.Cardheader>
                  <TabComponent
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    openMatchedBets={openMatchedBets}
                    openFancyBets={openFancyBets}
                    event_id={localStorage.getItem('sportEventId') || ''}
                  />
                </Styled.OpenBet>
              </Styled.Col2>
            </div>
          </Styled.Section>
        )}
      </Layout>
    </>
  )
}
export default FootballDetails
