import ACTIONTYPES from './actionTypes'
import { endPoints } from '../../services/EndPoints'
import { _get, _getPanel, _postPanel } from '../../services/HttpMethods'
import { startLoading, stopLoading } from '../loading/action'
import { toast } from 'react-toastify'
import { getToastPosition } from '../../utils/constant'
import { fetchUserDetails } from '../auth/action'

export const depositAmount = (data, token) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _postPanel(endPoints.deposit, data, token)
      const { msg, status, data: _data } = response?.data
      if (status === 201) {
        dispatch(stopLoading())
        toast.success(msg, getToastPosition())
        dispatch({
          type: ACTIONTYPES.DEPOSIT_SUCCESS,
          payload: _data,
        })
        dispatch(getDepositDetails())
        dispatch(fetchUserDetails())
      } else {
        dispatch(stopLoading())
        dispatch({
          type: ACTIONTYPES.DEPOSIT_FAIL,
          payload: msg,
        })
        dispatch(getDepositDetails())
        toast.warn(msg)
      }
    } catch (error) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.DEPOSIT_FAIL,
        payload: error,
      })
      toast.error(error)
      dispatch(getDepositDetails())
    }
  }
}
export const getDepositDetails = () => {
  const token = localStorage.getItem('token')
  return async (dispatch) => {
    try {
      const response = await _getPanel(endPoints.deposit, token)
      const { status, data } = response
      if (status === 200) {
        dispatch({
          type: ACTIONTYPES.GET_DEPOSIT_SUCCESS,
          payload: data,
        })
      } else {
        dispatch({
          type: ACTIONTYPES.GET_DEPOSIT_FAIL,
        })
      }
    } catch (error) {
      dispatch({
        type: ACTIONTYPES.GET_DEPOSIT_FAIL,
        payload: error,
      })
    }
  }
}

export const getDepositBanks = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const id = 1716388649067

      const response = await _getPanel(endPoints.deposit_bank, token, id)
      const { status, data } = response
      if (status === 200) {
        dispatch({
          type: ACTIONTYPES.GET_DEPOSIT_BANK_SUCCESS,
          payload: data,
        })
      } else {
        dispatch({
          type: ACTIONTYPES.GET_DEPOSIT_BANK_FAIL,
        })
      }
    } catch (error) {
      dispatch({
        type: ACTIONTYPES.GET_DEPOSIT_BANK_FAIL,
        payload: error,
      })
    }
  }
}
