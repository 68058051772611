import styled from 'styled-components'
import { color } from '../../../color/color'

export const MainContainer = styled.div`
  background-color: ${color._F7F7F7};
  color: ${color._000};
`

export const ColOuterContainer = styled.div`
  padding: 15px;
  border: 1px solid ${color._C7C8CA};
`

export const MidSpan = styled.span`
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 1;
`
export const MidContainer = styled.div`
  background-image: linear-gradient(to right, ${color._08C}, ${color._2C3E50});
  font-weight: 700 !important;
  line-height: 1;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  color: ${color._fff};
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
`
export const MidSuspendedContainer = styled.div`
  background-color: ${color._opacityBg};
  border: 0;
  color: ${color._fff};
  font-size: 18px;
  font-weight: 700;
  height: 48px;
  position: relative;
  line-height: 2.5;
`
export const SuitedContainer = styled.div`
  background-image: linear-gradient(to right, ${color._08C}, ${color._2C3E50});
  font-weight: 700 !important;
  line-height: 1;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  color: ${color._fff};
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 15px 0 15px 0;
  text-wrap: nowrap;
`

export const BackgroundContainer = styled.div`
  background-color: ${color._F2F2F2} !important;
`
export const ColLeftContainer = styled.div`
  padding-left: 0px !important;
`
export const ColRightContainer = styled.div`
  padding-right: 0 !important;
`

export const Divider = styled.div`
  width: 5px;
  height: 80px;
  background-color: ${color._2C3E50};
`
export const Col11 = styled.div`
  padding-left: 10px !important;
`

export const Image = styled.img`
  height: 38px;
`
export const ImageContainer = styled.div`
  background-image: linear-gradient(to right, ${color._08C}, ${color._2C3E50});
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
`
export const ImageSuspendedContainer = styled.div`
  background-color: ${color._opacityBg};
  border: 0;
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
`
export const CardImage = styled.img`
  height: 45px;
`
export const SuspendedContainer = styled.div`
  background-color: ${color._opacityBg};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  line-height: 2.5;
`
export const SuspendedImgContainer = styled.div`
  background-color: ${color._opacityBg};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  line-height: 2.5;
  border-radius: 4px;
`
