import styled, { keyframes } from 'styled-components'
import { color } from '../../color/color'

export const Wrapper = styled.div`
  background-color: ${color._343435};
  margin-bottom: 5px;
  font-size: 13px;
  text-transform: uppercase;
  padding: 5px;
  color: ${color._FFFFFF};
`

export const BackButton = styled.button`
  border: none;
  color: ${color._FFFFFF};
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 400;
  background-color: ${color._6D081D};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
`

export const ContentWrapper = styled.div`
  display: flex;
  @media (max-width: 776px) {
    display: block;
  }
`

export const LeftSection = styled.div`
  flex: 0.7;
  // padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 776px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const InfoWrapper = styled.div`
  background: ${color._FFFFFF};
  box-shadow: none;
  border: 1px solid ${color._EEEEEE};
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 1.25rem;
  display: flex;
  gap: 5px;

  ol {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0 !important;
  }

  li {
    font-size: 10px !important;
    color: red;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';

    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 0 ${color._DDDDDD};
  }
  @media (max-width: 776px) {
    padding: 10px;
  }
`

export const GreenWrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  background: ${color._FFFFFF};
  box-shadow: none;
  border: 1px solid ${color._EEEEEE};
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 12px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 0 ${color._DDDDDD};
  }
  @media (max-width: 776px) {
    padding: 2px;
  }
`

export const WithdrawBox = styled.div`
  position: relative;
  min-width: 47%;
  max-width: 264px;
  width: fit-content;
  margin-top: 10px;
  background: ${color._FFFFFF};
  box-shadow: none;
  border: 1px solid ${color._EEEEEE};
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 5px;
  textarea {
    max-height: 100px;
    border-radius: 5px;
    font-size: 13px;
    color: #000;
    font-weight: 300;
    height: 50px;
    text-align: left;
  }
  input {
    border-radius: 5px;
    font-size: 13px;
    color: #000;
    font-weight: 300;
    height: 50px;
    text-align: left;
  }
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 0 ${color._DDDDDD};
  }
  @media (max-width: 776px) {
    padding: 2px;
  }
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
  }
`
export const GreenContainer = styled.div`
  background: ${color._008d05};
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  @media (max-width: 776px) {
    padding: 10px;
  }
`
const getColor = (item) => {
  if (item == 'APPROVED') {
    return '#28a745'
  } else if (item == 'PENDING') {
    return '#ffc107'
  } else if (item == 'REJECTED') {
    return '#f44336'
  } else if (item == 'CANCELLED') {
    return '#ffc107'
  } else {
    return 'transparent'
  }
}
const getBackgroundColor = (item) => {
  if (item == 'APPROVED') {
    return '#28a745'
  } else if (item == 'PENDING') {
    return '#ffc107'
  } else if (item == 'REJECTED') {
    return '#f44336'
  } else if (item == 'CANCELLED') {
    return 'red'
  } else {
    return 'transparent'
  }
}
const getBorderColor = (item) => {
  if (item == 'APPROVED') {
    return '#28a745'
  } else if (item == 'PENDING') {
    return '#ffc107'
  } else if (item == 'REJECTED') {
    return '#f44336'
  } else if (item == 'CANCELLED') {
    return '#ffc107'
  } else {
    return 'transparent'
  }
}
const getSectionColor = (item) => {
  if (item == 'APPROVED') {
    return '#000000'
  } else if (item == 'PENDING') {
    return '#000000'
  } else if (item == 'REJECTED') {
    return '#000000'
  } else if (item == 'CANCELLED') {
    return '#ffffff'
  } else {
    return '#000000'
  }
}

export const NoRecords = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  font-family: sans-serif;
  width: 350px;
  @media (max-width: 776px) {
    width: 100%;
  }
`
export const RightSection = styled.div`
  flex: 0.3;
  padding-left: 15px;
  .table-responsive {
    overflow-x: scroll;
    overflow-y: scroll;
    height: auto;
    max-height: 180px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    width: 350px;
    max-width: 100%;
  }
  table {
    margin-bottom: 0px !important;
  }
  td {
    font-size: 10px;
    border: 1px solid #e5e5e5 !important;
    max-width: fit-content;
    width: auto;
  }
  th {
    padding: 7px;
    text-align: center;
    border: none;
    background: ${color._6D081D};
    color: ${color._FFFFFF};
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    position: sticky;
    top: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }
  .withdraw-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media (max-width: 776px) {
    margin-left: 0px;
    padding-right: 10px;
    .table-responsive {
      width: 98%;
      margin-top: 10px;
    }
  }
`

export const EllipsisTableData = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70px;

  background-color: ${({ item }) =>
    item === 'CANCELLED' && getBackgroundColor(item)} !important;
  .withdraw-status {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding: 2px;
    border: 1px solid ${({ item }) => getBorderColor(item)};
    color: ${({ item }) => getColor(item)};
    cursor: pointer;
    font-weight: bold;
    font-size: 9px;
    font-family: 'Titillium Web', sans-serif;
    &:hover {
      color: black;
      background-color: ${({ item }) => getColor(item)};
    }
  }
  .withdraw-section {
    color: ${({ item }) => getSectionColor(item)};
  }
  svg {
    color: ${color._FFFFFF};
    background-color: ${color._F44336};
    border-radius: 2px;
    padding: 2px;
    width: 1.3em;
    height: 1.3em;
    cursor: pointer;
  }
`

const zoomInZoomOut = keyframes`
  0% {
    transform: scale(1, 1)
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
`

export const IconSpan = styled.span`
  font-size: 12px;
  color: ${color._FFFFFF};
  animation: ${zoomInZoomOut} 2s ease-out infinite;
  font-weight: 700;
  svg {
    font-size: 12px;
    color: ${color._FFFFFF};
    animation: ${zoomInZoomOut} 2s ease-out infinite;
    font-weight: 700;
  }
`

export const Bold = styled.b`
  font-size: 10px;
  font-weight: 900;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: ${color._FFFFFF};
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
`
export const ButtonSection = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed ${color._6D081D};
  text-align: left !important;
  // padding-left: 15px;
  @media (max-width: 776px) {
    padding-bottom: 0px;
    padding-left: 5px;
  }
`
export const Button = styled.button`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  margin-left: 0;
  border-radius: 50px;
  color: ${color._000000};
  background: none;
  font-size: 13px;
  border: 1px solid ${color._EEEEEE};
  padding: 5px 7px;
  transition: 0.9s;
  font-weight: 900;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  &:hover {
    border-color: ${color._C10930};
    color: ${color._6D081D};
    font-weight: 900;
    letter-spacing: 2px;
    background-color: transparent;
  }

  &:before {
    content: '+';
    margin-right: 5px;
    height: 20px;
    width: 20px;
    display: inline-block;
    background: ${color._C10930};
    color: ${color._FFFFFF};
    border-radius: 50px;
    line-height: 17px;
    font-weight: 500;
    vertical-align: bottom;
    font-size: 13px;
    letter-spacing: 1px;
  }
`

export const Modalheader = styled.div`
  border: none;
  background-color: '${color._C10930} !important';
`

export const Container = styled.main`
  background: ${color._F3F2F6};
  position: absolute;
  max-width: 500px;
  width: 500px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  & .modal-footer-section {
    justify-content: center !important;
    padding: 15px;
    background-color: ${color._C10930};
  }
  form {
    padding: 1rem;
    background-color: ${color._FFFFFF};
  }
  & .close-icon {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    z-index: 9999;
  }
  & .form-control {
    border: none;
    background: transparent;
    border-bottom: 1px solid ${color._DDDDDD};
    border-radius: 0;
    font-size: 13px;
    color: ${color._495057};
  }
  @media (max-width: 991px) {
    width: 90%;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Btn = styled.button`
  /* font-family: 'Titillium Web', sans-serif; */
  color: ${color._FFFFFF};
  background-color: ${color._6C757D};
  border-color: ${color._6C757D};
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color: ${color._FFFFFF};
    background-color: ${color._5A6268};
    border-color: ${color._545B62};
  }
`

export const SaveBtn = styled.button`
  background: ${color._FFFFFF};
  border-color: ${color._FFFFFF};
  color: ${color._C10930};
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background: ${color._C10930};
    color: ${color._FFFFFF};
    border-color: ${color._FFFFFF};
  }
`
export const Label = styled.label`
  font-size: 13px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.5;
  color: ${color._212529};
  text-align: left;
  margin-bottom: 0.5rem;
`

export const ModalHeaderSpan = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
`
export const HeaderBox = styled.span`
  width: 100%;
  height: 56px;
  background: ${color._C10930};
  color: ${color._FFFFFF};
`

export const DeleteButton = styled.button`
  top: 4px;
  right: 4px;
  position: absolute;
  border-top-right-radius: 15px;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.325rem 0.55rem;
  font-size: 1rem;
  line-height: 1.5;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  svg {
    transform: scale(1.1);
  }
`
export const CardTitle = styled.h5`
  margin-top: 15px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  position: relative;
  font-family: inherit;
  line-height: 1.2;
  color: inherit;
  margin-bottom: 25px;
  &:after {
    content: '';
    width: 20%;
    display: block;
    background: #d5d5d5;
    height: 1px;
    margin: 0 auto;
    bottom: -7px;
    position: absolute;
    left: 0;
    right: 0;
  }
`
export const AccountData = styled.div`
  background: #f3f2f6;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 10px;
`
export const CopyButton = styled.button`
  right: 0px !important;
  border: 1px solid #f3f2f6;
  background: none;
  padding: 0;
  font-size: 15px;
  position: absolute;
  right: 10%;
  color: var(--theme-secondary);
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  line-height: 1.5;
  border-radius: 0.2rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
`
export const CopyToolTip = styled.div`
  position: absolute;
  top: 100%;
  right: 0%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 5;
`
export const CardText = styled.p`
  font-weight: 300;
  font-size: 15px;
  position: relative;
  margin-bottom: 2px;
  text-align: left;
  padding: 2px 0px;
  color: #222;
  text-overflow: ellipsis;
  b {
    font-weight: bolder;
  }
`
export const FormFooterButton = styled.button`
  background: ${({ inputDisabled }) =>
    inputDisabled ? color._343435 : color._B80000};
  border-color: ${({ inputDisabled }) =>
    inputDisabled ? color._343435 : color._B80000};
  padding: 10px 30px;
  text-transform: uppercase;
  font-weight: 200;
  border-radius: 2px;
  width: 90%;
  margin-top: 10px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  cursor: ${({ inputDisabled }) =>
    inputDisabled ? 'not-allowed' : 'pointer'} !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
  &:hover {
    background: ${({ inputDisabled }) =>
      inputDisabled ? color._343435 : 'red'};
    color: #fff !important;
  }
`
