export const playerData = [
  {
    name: 'Player A',
    blueValue: { value: 1.28, isSuspended: false },
    pinkValue: { value: 1.92, isSuspended: false },
    style: { paddingLeft: 0 },
  },
  {
    name: 'Player B',
    blueValue: { value: 1.35, isSuspended: true },
    pinkValue: { value: 2.1, isSuspended: true },
    style: { paddingRight: 0 },
  },
]

export const bonusData = [
  {
    score: { value: '2 Card Bonus A', isSuspended: false },
    className: 'col-md-3',
    style: { paddingLeft: 0 },
  },
  {
    score: { value: '7 Card Bonus A', isSuspended: false },
    className: 'col-md-3',
    style: { paddingLeft: 0 },
  },
  {
    score: { value: '2 Card Bonus B', isSuspended: true },
    className: 'col-md-3',
    style: { paddingRight: 0 },
  },
  {
    score: { value: '7 Card Bonus B', isSuspended: true },
    className: 'col-md-3',
    style: { paddingRight: 0 },
  },
]

export const constants = {
  playAndWin: 'Play & Win',
}
