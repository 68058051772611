import React from 'react'
import * as Styled from './style'
const Select = ({ name, options }) => {
  return (
    <Styled.Select name={name}>
      {options.map((elem, indx) => {
        return (
          <option value={elem} key={elem + indx}>
            {elem}
          </option>
        )
      })}
    </Styled.Select>
  )
}

export default Select
