import React, { useEffect, useRef, useState } from 'react'
import * as Styled from './style'
import CasinoImg from '../../assets/sidebar/menu-99999.png'
import PagesHeader from '../../components/PagesHeader/PagesHeader'
import ImageOption from '../../components/ImageOptionGallery/ImageOption'
import { _constants } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { getCasinoProviderGames } from '../../redux/casino/action'
import Loader from '../../components/Loader/Loader'

const useIntersectionObserver = (callback, options) => {
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)
    if (ref.current) observer.observe(ref.current)

    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [callback, options])

  return ref
}
const CasinoProvider = () => {
  const allCasinoGames = useSelector((state) => state.casino.casinoProvider)
  const isLoading = useSelector((state) => state.casino.isProviderLoading)

  const [casGames, setCasGames] = useState([])

  const dispatch = useDispatch()
  const avoidRerender = useRef(false)

  useEffect(() => {
    if (!avoidRerender.current && allCasinoGames?.length == 0) {
      dispatch(getCasinoProviderGames())
    }
    return () => (avoidRerender.current = true)
  }, [dispatch])

  useEffect(() => {
    const formattedGames =
      allCasinoGames?.length &&
      allCasinoGames?.map((item) => {
        return {
          src: item.imageUrl,
          alt: item.name,
          id: item.name,
        }
      })
    setCasGames(formattedGames)
  }, [allCasinoGames])

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target
        img.src = img.dataset.src
        img.onload = () => img.classList.add('loaded')
      }
    })
  }

  const options = { threshold: 0.1 }
  const imgRef = useIntersectionObserver(handleIntersection, options)

  return (
    <Styled.Casino className="display-flex">
      <Styled.Content>
        <PagesHeader src={CasinoImg} heading={_constants.CasinoProvider} />
        {isLoading ? (
          <Loader />
        ) : (
          <ImageOption
            options={
              casGames.length
                ? casGames?.map((game) => ({
                    ...game,
                    src: game?.src,
                    dataSrc: game?.src,
                    ref: imgRef,
                  }))
                : []
            }
          />
        )}
      </Styled.Content>
    </Styled.Casino>
  )
}

export default CasinoProvider
