import React from 'react'
import * as Styled from './style'
import PlayerComp from './TrapUtils/PlayerComp'
import LowHigh7 from './TrapUtils/LowHigh7'
import Marquee from '../../../components/Marquee/Marquee'
import { color } from '../../../color/color'
import { constants } from './constants'
const TheTrap = () => {
  return (
    <Styled.Trap>
      <Styled.PlayerData>
        <PlayerComp playerName={constants.playerA} showCard={false} />
        <PlayerComp playerName={constants.playerB} showCard={false} />
      </Styled.PlayerData>
      <Styled.PlayerData>
        <LowHigh7 />
        <PlayerComp playerName={constants.playerA} showCard={true} />
      </Styled.PlayerData>
      <Marquee
        label={constants.marqueeLabel}
        bgColor={color._086F3F}
        fontColor={color._FFFFFF}
        time="20s"
      />
    </Styled.Trap>
  )
}

export default TheTrap
