import styled from 'styled-components'
import { color } from '../../../color/color'

export const Container = styled.div`
  background-color: ${color._F2F2F2} !important;
  color: ${color._000};
`
export const ColContainer = styled.div`
  padding: 0px !important;
`
export const ColRightContainer = styled.div`
  padding-right: 0px !important;
  padding-left: 2px !important;
`
export const Table = styled.table`
  width: 100% !important;
  border: 1px solid ${color._C7C8CA} !important;
`
export const RightColContainer = styled.div`
  border: 1px solid ${color._C7C8CA};
  padding: 5px 10px;
`
export const RightColDataContainer = styled.div`
  background-image: linear-gradient(to right, ${color._08C}, ${color._2C3E50});
  font-weight: 700 !important;
  line-height: 1;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  color: ${color._fff};
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
`

export const RightSuspendedContainer = styled.div`
  background-color: ${color._opacityBg};
  border: 0;
  color: ${color._fff};
  font-size: 18px;
  font-weight: 700;
  height: 48px;
  position: relative;
  line-height: 2.5;
  margin-top: 5px;
  margin-bottom: 10px;
`
export const InnerContainer = styled.div`
  height: 100%;
`

export const RowHeadingContainer = styled.td`
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 2px 0px 2px 10px !important;
`
export const Image = styled.img`
  height: 15px;
`
export const CardImage = styled.img`
  height: 25px;
`

export const BoxDataBlueContainer = styled.td`
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 2px 10px 2px 18px !important;
  background-color: ${color._72BBEF} !important;
  text-align: center !important;
  border-right: 1px solid ${color._fff} !important;
  text-wrap: nowrap !important;
`
export const BoxDataPinkContainer = styled.td`
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 2px 10px 2px 10px !important;
  background-color: ${color._FAA9BA} !important;
  text-align: center !important;
  border-right: 1px solid ${color._fff} !important;
`
export const BoxDataBlue = styled.td`
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._72BBEF};
  width: 70px;
  text-align: center;
  border-right: 1px solid ${color._fff};
  border-top: 1px solid ${color._C7C8CA};
`
export const BoxDataPink = styled.td`
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._FAA9BA};
  width: 70px;
  text-align: center;
  border-right: 1px solid ${color._fff};
  border-top: 1px solid ${color._C7C8CA};
`

export const RowHeadingDataContainer = styled.td`
  font-size: 18px !important;
  font-weight: 700 !important;
  padding: 10px !important;
  border-top: 1px solid ${color._C7C8CA} !important;
`

export const SuspendedContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
export const SuspendedRightContainer = styled.div`
  background-color: ${color._opacityBg};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  line-height: 2.5;
`

export const EmptyRow = styled.td`
  width: 90px !important;
`

export const CardTableRow = styled.td`
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 2px 0px 2px 10px !important;
  border: 1px solid ${color._C7C8CA} !important;
  width: 80px !important;
  text-align: center !important;
`
export const CardTableImageRow = styled.td`
  padding: 0px !important;
  border: 1px solid ${color._C7C8CA} !important;
  width: 80px !important;
  text-align: center !important;
`

export const CardTableDataHeading = styled.td`
  padding: 10px;
  border: 1px solid ${color._C7C8CA};
  font-size: 18px !important;
  font-weight: 700 !important;
`
export const Span = styled.span`
  font-size: 18px !important;
  font-weight: 700 !important;
`

export const CardTableData = styled.td`
  padding: 10px;
  border: 1px solid ${color._C7C8CA};
  font-size: 18px !important;
  font-weight: 700 !important;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._72BBEF};
`
