import styled from 'styled-components'
import { color } from '../../color/color'

export const Dropdown = styled.div`
  & .dropdown-toggle {
    color: ${color._000000};
    background: none !important;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
    border: none !important;
    padding: 0 !important;
    box-shadow: 0 0 0 0 !important;
    @media (max-width: 991px) {
      color: ${color._FFFFFF};
    }
  }

  & .show > .btn-primary.dropdown-toggle {
    color: ${color._000000};
    @media (max-width: 991px) {
      color: ${color._FFFFFF};
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
  }

  & .dropdown-menu {
    width: 240px !important;
    transform: translate(0px, 41px) !important;
    color: green;
    background-color: ${color._C10930};
    border-radius: 0 !important;
  }

  & .dropdown-item {
    background-color: ${color._FFFFFF};

    padding: 7px 15px;
    cursor: pointer;
    transition: 0.3s;
    text-transform: capitalize;
    border-bottom: 1px solid ${color._F9F9F9};
  }
  & .deposit-box {
    background-color: ${color._006400};
    color: ${color._FFFFFF};
  }
  & .deposit-box:hover {
    color: ${color._006400};
    img {
      filter: invert(0);
    }
  }
  & .withdrawal-box {
    background-color: ${color._8B0000};
    color: ${color._FFFFFF};
  }
  & .withdrawal-box:hover {
    color: ${color._8B0000};
    img {
      filter: invert(0);
    }
  }

  & .dropdown-item:hover {
    background-color: ${color._FFF6EE};
  }
`

export const Icon = styled.span`
  font-size: 12px;
`

export const Item = styled.div``

export const Title = styled.span`
  padding: 8px 0px 0px 5px;
  font-size: 12px;
`
export const DropdownHeading = styled.span`
  font-size: 13px;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  color: ${color._FFFFFF};
  background-color: ${color._C10930};
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SignOutBtn = styled.div`
  background: linear-gradient(
    180deg,
    ${color._FA7E29} 0%,
    ${color._F6682F} 80%,
    ${color._F6682F} 100%
  );
  box-shadow: inset 0px -10px 20px 0px ${color._9F0101};
  position: absolute;
  width: 100.5%;
  text-align: center;
  padding: 10px 15px;
  color: ${color._FFFFFF};
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`

export const Text = styled.span`
  font-size: 15px;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  text-transform: uppercase;
`
export const Img = styled.img`
  width: 14px;
  margin-right: 5px;
  filter: invert(1);
`
