import React from 'react'
import * as Styled from './styles'
import { useNavigate } from 'react-router-dom'

const PageNotFoundContainer = () => {
  const navigate = useNavigate()
  return (
    <Styled.Container>
      <Styled.Title>404</Styled.Title>
      <Styled.Message>Page Not Found</Styled.Message>
      <Styled.HomeLink onClick={() => navigate('/')}>
        Go Back Home
      </Styled.HomeLink>
    </Styled.Container>
  )
}

export default PageNotFoundContainer
