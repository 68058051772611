import React, { useEffect, useMemo, useState } from 'react'
import { sportsName } from './data'
import Announcement from '../../components/Announcement/Announcement'
import HomeSports from './HomeSports'
import InPlay from './InPlay'
import Cricket from './Cricket'
import Football from './Football'
import Kabaddi from './Kabaddi'
import Tennis from './Tennis'
import Politics from './Politics'
import HorseRacing from './HorseRacing'
import GreyRacing from './GreyRacing'
import Casino from './Casino'
import SportsBooks from './SportsBooks'
import IntCasino from './IntCasino'
import Binary from './Binary'
import { SocketProvider } from '../../SocketContext'
import { useSelector } from 'react-redux'

const Sports = ({ view, matchData, loading }) => {
  const [matches, setMatches] = useState(matchData)
  const odds = useSelector((state) => state.match.odds)

  useEffect(() => {
    const sportsData =
      matchData.find((item) => item.sportsName === view.name)?.matches || []
    setMatches(sportsData)
  }, [view, matchData])

  const RenderComponent = () => {
    // const renderedComponent = useMemo(() => {
    // switch (view?.name) {
    //   case sportsName.home:
    //     return (
    //       <HomeSports
    //         matches={matches}
    //         name={view.name}
    //         loading={loading}
    //         odds={odds}
    //       />
    //     )
    //   case sportsName.inplay:
    //     return <InPlay matches={matches} name={view.name} loading={loading} />
    //   case sportsName.cricket:
    //     return (
    //       <Cricket matches={matches} name={view.name} loading={loading} />
    //     )
    //     case sportsName.football:
    //       return (
    //         <Football matches={matches} name={view.name} loading={loading} />
    //       )
    //     case sportsName.tennis:
    //       return <Tennis matches={matches} name={view.name} loading={loading} />
    //     case sportsName.politics:
    //       return (
    //         <Politics matches={matches} name={view.name} loading={loading} />
    //       )
    //     case sportsName.horseRacing:
    //       return (
    //         <HorseRacing matches={matches} name={view.name} loading={loading} />
    //       )
    //     case sportsName.greyRacing:
    //       return (
    //         <GreyRacing matches={matches} name={view.name} loading={loading} />
    //       )
    //     case sportsName.kabaddi:
    //       return (
    //         <Kabaddi matches={matches} name={view.name} loading={loading} />
    //       )
    //     case sportsName.casino:
    //       return <Casino matches={matches} name={view.name} loading={loading} />
    //     case sportsName.sportsBook:
    //       return (
    //         <SportsBooks matches={matches} name={view.name} loading={loading} />
    //       )
    //     case sportsName.intCasino:
    //       return (
    //         <IntCasino matches={matches} name={view.name} loading={loading} />
    //       )
    //     case sportsName.binary:
    //       return <Binary matches={matches} name={view.name} loading={loading} />
    //     default:
    //       return null
    //   }
    // }, [view?.name, matches, loading, odds])
    // return renderedComponent

    if (view.name === sportsName.home) {
      return (
        <HomeSports
          matches={matches}
          name={view.name}
          loading={loading}
          odds={odds}
        />
      )
    } else if (view.name === sportsName.inplay) {
      return <InPlay matches={matches} name={view.name} loading={loading} />
    } else if (view.name === sportsName.cricket) {
      return <Cricket matches={matches} name={view.name} loading={loading} />
    }
  }

  return (
    <>
      <Announcement />
      <SocketProvider>
        {view.name === sportsName.home ? (
          <HomeSports
            matches={matches}
            name={view.name}
            loading={loading}
            odds={odds}
          />
        ) : view.name === sportsName.inplay ? (
          <InPlay matches={matches} name={view.name} loading={loading} />
        ) : (
          <Cricket matches={matches} name={view.name} loading={loading} />
        )}
      </SocketProvider>
    </>
  )
}

export default Sports
