import React from 'react'
import * as Styled from '../styles'
import { Constants } from '../constants'

const GameRuleHeader = ({ handleModal, toggle, gameName, roundId, handlePlayerHistory }) => {
    return (
        <>
            <Styled.GameDiv>
                <Styled.GameSpan>{gameName}</Styled.GameSpan>
                <Styled.RulesSpan onClick={handleModal} toggle={toggle}><small>{Constants.rules}</small></Styled.RulesSpan>
            </Styled.GameDiv>
            <Styled.GameDiv>
                <small>
                    <Styled.Span> {Constants.roundId}</Styled.Span>&nbsp;
                    <Styled.Span>{roundId}</Styled.Span>&nbsp;|  &nbsp;
                    <Styled.PlayerHistorySpan onClick={handlePlayerHistory}>{Constants.playerHistory}</Styled.PlayerHistorySpan>
                </small>
            </Styled.GameDiv>
        </>
    )
}

export default GameRuleHeader