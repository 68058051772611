export const constants = {
  player: 'Player',
}

export const tableHeaders = ['Winner', 'Top 9', 'M Bacarrat B']

export const playersData = [
  {
    player: 'A',
    stats: {
      winner: { value: 1.98, isSuspended: false },
      top: { value: 2.88, isSuspended: false },
      bacarrat: { value: 3, isSuspended: false },
    },
  },
  {
    player: 'B',
    stats: {
      winner: { value: 1.98, isSuspended: true },
      top: { value: 2.88, isSuspended: true },
      bacarrat: { value: 3, isSuspended: true },
    },
  },
]
