import styled from 'styled-components'
import { color } from '../../color/color'
export const ModalHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid ${color._DEE2E6};
  border-top-left-radius: calc(0.5rem - (1px));
  border-top-right-radius: calc(0.5rem - (1px));
  height: 21px;
  justify-content: space-between;
  background-color: ${color._000000};
  color: ${color._FFFFFF};
`
export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  margin: 0.5rem;
`

export const ModalBodySpan = styled.span`
  background: ${color._6D081D};
  font-size: 14px;
  text-transform: uppercase;
  color: ${color._FFFFFF};
  border: none;
  width: 80px;
  text-align: center;
  display: block;
  padding: 0.375rem 0.75rem;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  small {
    font-size: 0.775em !important;
  }
`
export const ModalInput = styled.input`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.775em;
  font-weight: 400;
  line-height: 1.5;
  color: ${color._212529};
  background-color: ${color._FFFFFF};
  background-clip: padding-box;
  border: 1px solid ${color._6D081D};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

export const ModalButton = styled.button`
  width: 98%;
  color: ${color._FFFFFF};
  margin: 0 auto;
  display: block;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  border-color: ${color._6D081D};
  font-size: 13px;
  background-color: ${({ inputDisabled }) =>
    inputDisabled ? color._343435 : color._6D081D};
  margin-bottom: 0.5rem;
  padding: 0.375rem 0.375rem;
  text-decoration: none;
  border: ${color._6D081D};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background: ${({ inputDisabled }) =>
      inputDisabled ? color._343435 : color._C10930};
    border-color: ${color._C10930};
  }
`
export const ModalInputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-bottom: 0.375rem;
`

export const ModalFooter = styled.div`
  padding: 0;
  border-top: 0;
`
