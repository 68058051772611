import React from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Styled from './style'
import { IoMdClose } from 'react-icons/io'
import Login from '../Login/Login'
const ModalCont = ({
  show,
  handleClose,
  setToggle,
  toggle,
  setShow,
  setResetShow,
}) => {
  return (
    <Styled.Modal>
      <Modal show={show} centered onHide={handleClose} size="lg">
        <Styled.ModalInnerDiv>
          <Login
            handleClose={handleClose}
            setToggle={setToggle}
            toggle={toggle}
            setShow={setShow}
            setResetShow={setResetShow}
          />
          <IoMdClose className="close-icon" onClick={handleClose} />
        </Styled.ModalInnerDiv>
      </Modal>
    </Styled.Modal>
  )
}

export default ModalCont
