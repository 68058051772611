import React, { useState } from 'react'
import Cricket from '../../assets/sidebar/menu-4.png'
import * as Styled from './style'
import * as FaIcons from 'react-icons/fa6'
import * as GoIcons from 'react-icons/go'
import { color } from '../../color/color'
import { constants, types } from './constants'

const MatchHeader = ({
  name,
  handleActiveChange,
  handleVirtualActiveChange,
}) => {
  // State to show and hide the virtual and Live Matches
  const [activeVirtual, setActiveVirtual] = useState(true)
  const [activeLive, setActiveLive] = useState(true)

  // it will show/hide the virtual and Live button when users clicks
  const showActiveVirtual = () => {
    setActiveVirtual(!activeVirtual)
    handleVirtualActiveChange(activeVirtual)
  }
  const showActiveLive = () => {
    setActiveLive(!activeLive)
    handleActiveChange(activeLive)
  }

  return (
    <Styled.MatchContainer>
      <Styled.Row>
        <Styled.Col6>
          <Styled.SportsTitle>
            <Styled.Img src={Cricket} alt="cricket" /> {name}
          </Styled.SportsTitle>
          <Styled.Ul>
            <Styled.Li>
              <Styled.Input
                type={types.Checkbox}
                value={1}
                id={types.Checkbox + '_1'}
              />
              <Styled.Label
                for={types.Checkbox + '_1'}
                active={activeLive}
                onClick={showActiveLive}
              >
                {!activeLive ? (
                  <FaIcons.FaCheck
                    color={activeLive ? color._C10930 : color._FFFFFF}
                  />
                ) : (
                  <GoIcons.GoPlus color={color._C10930} />
                )}
                {constants.Live}
              </Styled.Label>
            </Styled.Li>
            <Styled.Li>
              <Styled.Input
                type={types.Checkbox}
                value={2}
                id={types.Checkbox + '_2'}
              />

              <Styled.Label
                for={types.Checkbox + '_2'}
                active={activeVirtual}
                onClick={showActiveVirtual}
              >
                {!activeVirtual ? (
                  <FaIcons.FaCheck
                    color={activeVirtual ? color._C10930 : color._FFFFFF}
                  />
                ) : (
                  <GoIcons.GoPlus color={color._C10930} />
                )}
                {constants.Virtual}
              </Styled.Label>
            </Styled.Li>
          </Styled.Ul>
        </Styled.Col6>
        <Styled.Col2>
          <Styled.Small>1</Styled.Small>
        </Styled.Col2>
        <Styled.Col2>
          <Styled.Small>X</Styled.Small>
        </Styled.Col2>
        <Styled.Col2>
          <Styled.Small>2</Styled.Small>
        </Styled.Col2>
      </Styled.Row>
    </Styled.MatchContainer>
  )
}

export default MatchHeader
