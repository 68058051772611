import styled from 'styled-components'
import { color } from '../../color/color'

export const Casino = styled.div`
  background: ${color._DFE8E9};
`

export const Content = styled.div`
  flex-direction: column;
  background: ${color._fff};
  width: 100%;
`
