import React from 'react'
import { cardImages, middleContainerData, tableData } from './constant'
import { color } from '../../../color/color'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import * as Styled from './styles'

function AmarAkbarAnthony() {
  return (
    <Styled.MainContainer>
      <div className="row mb-3 gap-1 d-flex justify-content-center">
        {tableData?.map(({ title, stats }) => (
          <Styled.InnerContainer>
            <div>
              <Styled.TitleContainer>
                <div>{title}</div>
              </Styled.TitleContainer>

              <Styled.TableContainer>
                {Object.keys(stats)?.map((statKey, index) => {
                  const stat = stats[statKey]
                  return (
                    <Styled.TableData
                      key={index + stats[statKey]}
                      isSuspended={stat.isSuspended}
                    >
                      <Styled.TableInnerContainer className="align-items-center justify-content-center position-relative">
                        {stat.isSuspended && (
                          <Styled.SuspendedContainer>
                            <Lock fill={color._fff} />
                          </Styled.SuspendedContainer>
                        )}
                        <Styled.Span>{stat.value}</Styled.Span>
                      </Styled.TableInnerContainer>
                    </Styled.TableData>
                  )
                })}
              </Styled.TableContainer>
            </div>
          </Styled.InnerContainer>
        ))}
      </div>
      <div className="row mb-3">
        {middleContainerData?.map((item) => {
          return (
            <div className="col-4 text-center">
              <Styled.ColOuterContainer>
                <div>
                  <Styled.MidSpan>{item.odd1}</Styled.MidSpan>
                  <Styled.MidContainer>{item.btn1text}</Styled.MidContainer>
                </div>
                <div className="mt-4 mb-2">
                  <Styled.MidSpan>{item.odd2}</Styled.MidSpan>
                  <Styled.MidContainer>{item.btn2text}</Styled.MidContainer>
                </div>
              </Styled.ColOuterContainer>
            </div>
          )
        })}
      </div>
      <Styled.RowContainer className="row mb-3">
        <Styled.MidSpan className="mb-3 mt-2 col-12 text-center">
          12
        </Styled.MidSpan>
        <div className="mb-3 col-12 text-center px-4">
          <div className="d-flex justify-content-between">
            {cardImages.map((card) => (
              <img src={card.src} alt={card.alt} />
            ))}
          </div>
        </div>
      </Styled.RowContainer>
    </Styled.MainContainer>
  )
}

export default AmarAkbarAnthony
