import React from 'react'
import * as Styled from './styles'
import { bonusData, constants, playersData } from './constant'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { color } from '../../../color/color'

function TeenPatti2020() {
  return (
    <Styled.TableContainer className="container">
      <div className="row">
        {playersData.length &&
          playersData.map(({ player, stats, header, style }) => (
            <div className="col-6" key={player + stats.winner} style={style}>
              <Styled.Table>
                <tr>
                  <Styled.PrimaryTableHeadRow>{`${constants.player} ${player}`}</Styled.PrimaryTableHeadRow>
                  <Styled.TableRowEmpty />
                  <Styled.TableRowEmpty />
                  <Styled.TableRowRightEmpty />
                </tr>
                <tr>
                  {header.length &&
                    header.map((headerText, index) => (
                      <Styled.SecondaryTableHeadRow key={index + header}>
                        {headerText}
                      </Styled.SecondaryTableHeadRow>
                    ))}
                </tr>
                <tr>
                  {Object.keys(stats).map((statKey, index) => {
                    const stat = stats[statKey]
                    return (
                      <Styled.TableData
                        key={index + stats[statKey]}
                        isSuspended={stat.isSuspended}
                      >
                        <div className="align-items-center justify-content-center position-relative">
                          {stat.isSuspended && (
                            <Styled.SuspendedContainer>
                              <Lock fill={color._fff} />
                            </Styled.SuspendedContainer>
                          )}
                          <span>{stat.value}</span>
                        </div>
                      </Styled.TableData>
                    )
                  })}
                </tr>
              </Styled.Table>
            </div>
          ))}
      </div>

      <div className="row mt-2">
        {bonusData.length &&
          bonusData.map((bonus, index) => (
            <div
              key={index + bonus.score.value}
              className={bonus.className}
              style={bonus.style}
            >
              <Styled.BonusContainer isSuspended={bonus.score.isSuspended}>
                <div className="position-relative text-center">
                  {bonus.score.isSuspended && (
                    <Styled.SuspendedBonusContainer>
                      <Lock fill={color._fff} />
                    </Styled.SuspendedBonusContainer>
                  )}
                  <div className="d-flex justify-content-around">
                    <div className="d-grid">
                      <Styled.Image src={bonus.score.imageTop} />
                      <Styled.Image src={bonus.score.imageBelow} />
                    </div>
                    <div className="align-content-center">
                      <span>{bonus.score.value}</span>
                    </div>
                  </div>
                </div>
              </Styled.BonusContainer>
            </div>
          ))}
      </div>
    </Styled.TableContainer>
  )
}

export default TeenPatti2020
