import { useEffect, useMemo, useRef, useState } from 'react'
import Layout from '../../Layout'
import PlaceBetBox from '../../components/PlaceBetBox/PlaceBetBox'
import MatchDetails from '../../container/MatchDetails/MatchDetails'
import { _constants } from './constants'
import * as Styled from './style'
import ScoreBg from '../../assets/images/score_bg.webp'
import {
  getBookmakerData,
  getMatchOddsData,
  getspecificEvent,
  transformInput,
} from '../../utils/utils'
import Loader from '../../components/Loader/Loader'
import { constants } from '../../utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import Fancy from '../../components/Fancy/Fancy'
import { getFancyData } from '../../redux/fancy/action'
import { getBookmakerData as getBookmakerDataState } from '../../redux/bookmaker/action'
import { TabComponent } from './utils'
import { io } from 'socket.io-client'
import { getMatchScoreData, getPlaceBetStatus } from '../../redux/match/action'
import { getMatchOddData } from '../../redux/matchOdds/action'
import VideoPlayer from '../../container/CasinoDetail/CasinoDetailsUtil/VideoPlayer'
import { FiTv } from 'react-icons/fi'

const CricketDetails = ({ sportsEventId }) => {
  const [showBetSection, setShowBetSection] = useState(false)
  const [backlayAmount, setBackLayAmount] = useState(0)
  const [currentSportsId, setCurrentSportsId] = useState(0)
  const [minBet, setMinBet] = useState(0)
  const [maxBet, setMaxBet] = useState(0)
  const [betDelay, setBetDelay] = useState(0)
  const [teamName, setTeamName] = useState('')
  const [oddType, setOddType] = useState('')
  const [oddStr, setOddstr] = useState('')
  const [oddVolume, setOddVolume] = useState(0)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const [openLiveTv, setOpenLiveTv] = useState(false)

  const placebetStatus = useSelector((state) => state.match.betStatus)
  const openMatchedBets =
    useSelector((state) => state.match.openMatchedBets) || []
  const openFancyBets = useSelector((state) => state.match.openFancyBets) || []

  const [activeTab, setActiveTab] = useState('Matched')
  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  const [fancy, setFancy] = useState(false)
  const [fancyList, setFancyList] = useState([])
  const [loading, setLoading] = useState(true)
  const [bgColor, setBgColor] = useState()
  const dispatch = useDispatch()
  const handleBetSection = (
    data,
    sportsId,
    min_bet,
    max_bet,
    isFancy = false,
    color = false,
    bet_delay,
    odd_type,
    teamName,
    strValue,
    inputVolume,
  ) => {
    setShowBetSection(true)
    setBackLayAmount(data)
    setCurrentSportsId(sportsId)
    setFancy(false)
    setBgColor(color)
    setMinBet(min_bet)
    setMaxBet(max_bet)
    setBetDelay(bet_delay)
    setTeamName(teamName)
    setOddType(odd_type)
    setOddstr(strValue)
    setOddVolume(inputVolume)

    if (isFancy) {
      setFancy(true)
      setMinBet(min_bet)
      setMaxBet(max_bet)
      setBgColor(color)
      setBackLayAmount(data)
      setCurrentSportsId(sportsId)
      setOddstr(strValue)
      setOddVolume(inputVolume)
    }
  }
  const handleCloseBetSection = () => {
    setShowBetSection(false)
  }
  const matchesData = useSelector((state) => state.match.data)
  const matchOddData = useSelector((state) => state.matchOdd.data)

  const bookmakerData = useSelector((state) => state.bookmaker.data)
  const fancyData = useSelector((state) => state.fancy.data)
  const eventDetails = getspecificEvent(matchesData, sportsEventId)
  console.log('🚀 ~ eventDetails:', eventDetails)
  // const restructuredBookmakerData = getBookmakerData(bookmakerData)
  const restructuredBookmakerData = getMatchOddsData(matchOddData)

  useMemo(() => {
    setFancyList(fancyData)
    return () => {
      setFancyList([])
      dispatch(getFancyData([]))
    }
  }, [fancyData])
  const avoidRerender = useRef(false)

  useEffect(() => {
    // if (!avoidRerender.current) {
    const socket = io('https://odds.100panel.org', {
      transports: ['websocket'],
    })
    const event_id = localStorage.getItem('sportEventId')
    const scoreSocket = io('v4score.bet99.io', {
      transports: ['websocket'],
    })
    scoreSocket.emit('score', event_id)

    scoreSocket.on('score', (data) => {
      dispatch(getMatchScoreData(data))
      setLoading(false)
    })

    socket.emit('event', event_id)

    if (isAuthenticated) {
      const socketbets = io('https://bet.100panel.org', {
        transports: ['websocket'],
      })
      const uuid = localStorage.getItem('userId') || ''
      socketbets.emit('uuid', uuid)
      socketbets.on('client_eligibility_status', (data) => {
        dispatch(getPlaceBetStatus(data))
      })
    } else {
      dispatch(getPlaceBetStatus(false))
    }
    socket.on(`match_odds_${event_id}`, (data) => {
      dispatch(getMatchOddData(data))
      setLoading(false)
    })

    socket.on(`admin_session_active_${event_id}`, (data) => {
      dispatch(getFancyData(transformInput(data)))
      setLoading(false)
    })
    socket.on(`bookmaker_${event_id}`, (data) => {
      dispatch(getBookmakerDataState(transformInput(data)))
    })
    return () => {
      socket.disconnect()
      socket.off()
      socket.disconnect(true)
      scoreSocket.disconnect()
      scoreSocket.off()
      avoidRerender.current = true
    }
    // }
  }, [])

  // SCORE START

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  const score = useSelector((state) => state.match.score)

  const Scorecard = ({ score, classNames = () => null }) => {
    if (!score) return null

    return (
      <div className="m_scorecard m-b-5">
        <div className="row container">
          <div className="col-md-12 justify-content-between d-flex">
            <p className="team-1">
              <span className="team-name">
                {score?.spnnation1?.toUpperCase()}
              </span>
              <span className="score text-right p-0">{score.score1}</span>
            </p>
            <p className="team-1">
              <span className="team-name">
                {score?.spnnation2?.toUpperCase()}
              </span>
              <span className="score text-right p-0">{score?.score2}</span>
            </p>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="text-right">
                  {score?.spnmessage?.length > 2 && (
                    <span className="small pt-2 font-lg d-none">
                      {score?.spnmessage?.toUpperCase()}
                    </span>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {score.activenation1 === 1 && (
                      <span className="team-name">
                        <span className="d-inline-block">
                          CRR {score?.spnrunrate1}
                        </span>
                        <span className="ml-4">RR {score.spnreqrate1}</span>
                      </span>
                    )}
                    {score?.activenation2 === 1 && (
                      <span className="team-name">
                        <span className="d-inline-block">
                          CRR {score?.spnrunrate2}
                        </span>
                        <span className="ml-4">RR {score.spnreqrate2}</span>
                      </span>
                    )}
                    <p className="text-center ball-by-ball m-t-10 mb-1">
                      {score?.balls && score?.balls?.length >= 6 && (
                        <span
                          className={classNames('ball-runs m-l-5', {
                            six: score?.balls[score?.balls?.length - 6] === '6',
                            four:
                              score?.balls[score?.balls?.length - 6] === '4',
                            wicket:
                              score?.balls[score?.balls?.length - 6].includes(
                                'W',
                              ) &&
                              score?.balls[score?.balls?.length - 6] !== 'Wd',
                            wide:
                              score?.balls[score?.balls?.length - 6] === 'Wd',
                            dot: score?.balls[score?.balls?.length - 6] === '0',
                          })}
                        >
                          {score?.balls[score?.balls?.length - 6]}
                        </span>
                      )}
                      {score?.balls && score?.balls?.length >= 5 && (
                        <span
                          className={classNames('ball-runs m-l-5', {
                            six: score?.balls[score?.balls?.length - 5] === '6',
                            four:
                              score?.balls[score?.balls?.length - 5] === '4',
                            wicket:
                              score?.balls[score?.balls?.length - 5].includes(
                                'W',
                              ) &&
                              score?.balls[score?.balls?.length - 5] !== 'Wd',
                            wide:
                              score?.balls[score?.balls?.length - 5] === 'Wd',
                            dot: score?.balls[score?.balls?.length - 5] === '0',
                          })}
                        >
                          {score?.balls[score?.balls?.length - 5]}
                        </span>
                      )}
                      {score?.balls && score?.balls?.length >= 4 && (
                        <span
                          className={classNames('ball-runs m-l-5', {
                            six: score?.balls[score?.balls?.length - 4] === '6',
                            four:
                              score?.balls[score?.balls?.length - 4] === '4',
                            wicket:
                              score?.balls[score?.balls?.length - 4].includes(
                                'W',
                              ) &&
                              score?.balls[score?.balls?.length - 4] !== 'Wd',
                            wide:
                              score?.balls[score?.balls?.length - 4] === 'Wd',
                            dot: score?.balls[score?.balls?.length - 4] === '0',
                          })}
                        >
                          {score?.balls[score?.balls?.length - 4]}
                        </span>
                      )}
                      {score?.balls && score?.balls?.length >= 3 && (
                        <span
                          className={classNames('ball-runs m-l-5', {
                            six: score?.balls[score?.balls?.length - 3] === '6',
                            four:
                              score?.balls[score?.balls?.length - 3] === '4',
                            wicket:
                              score?.balls[score?.balls?.length - 3].includes(
                                'W',
                              ) &&
                              score?.balls[score?.balls?.length - 3] !== 'Wd',
                            wide:
                              score?.balls[score?.balls?.length - 3] === 'Wd',
                            dot: score?.balls[score?.balls?.length - 3] === '0',
                          })}
                        >
                          {score?.balls[score?.balls?.length - 3]}
                        </span>
                      )}
                      {score?.balls && score?.balls?.length >= 2 && (
                        <span
                          className={classNames('ball-runs m-l-5', {
                            six: score?.balls[score?.balls?.length - 2] === '6',
                            four:
                              score?.balls[score?.balls?.length - 2] === '4',
                            wicket:
                              score?.balls[score?.balls?.length - 2].includes(
                                'W',
                              ) &&
                              score?.balls[score?.balls?.length - 2] !== 'Wd',
                            wide:
                              score?.balls[score?.balls?.length - 2] === 'Wd',
                            dot: score?.balls[score?.balls?.length - 2] === '0',
                          })}
                        >
                          {score?.balls[score?.balls?.length - 2]}
                        </span>
                      )}
                      {score?.balls && score?.balls?.length >= 1 && (
                        <span
                          className={classNames('ball-runs m-l-5', {
                            six: score?.balls[score?.balls?.length - 1] === '6',
                            four:
                              score?.balls[score?.balls?.length - 1] === '4',
                            wicket:
                              score?.balls[score?.balls?.length - 1].includes(
                                'W',
                              ) &&
                              score?.balls[score?.balls?.length - 1] !== 'Wd',
                            wide:
                              score?.balls[score?.balls?.length - 1] === 'Wd',
                            dot: score?.balls[score?.balls?.length - 1] === '0',
                          })}
                        >
                          {score?.balls[score?.balls?.length - 1]}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // SCORE END
  const event_id = localStorage.getItem('sportEventId')

  return (
    <>
      <Layout>
        <Styled.Section>
          <div className="row mt-3">
            <Styled.Col1 className="col-md-12 col-lg-8">
              {/* header */}
              <Styled.GameHeading>
                <Styled.Title>{eventDetails?.name}</Styled.Title>
                <span>({eventDetails?.date})</span>
              </Styled.GameHeading>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Scorecard score={score} classNames={classNames} />

                  {/* details */}
                  {restructuredBookmakerData?.map((item, indx) => {
                    return (
                      <MatchDetails
                        handleBetSection={handleBetSection}
                        handleCloseBetSection={handleCloseBetSection}
                        currentSportsId={currentSportsId}
                        showBetSection={showBetSection}
                        sportsName={constants.CRICKET}
                        eventDetails={eventDetails}
                        restructuredBookmakerData={item}
                        matchOddData={matchOddData}
                        restructuredFancyData={fancyList}
                        key={item.bookmaker_id + indx}
                        backlayAmount={backlayAmount}
                        bgColor={bgColor}
                        oddStr={oddStr}
                        placebetStatus={placebetStatus}
                      />
                    )
                  })}
                  <Fancy
                    restructuredFancyData={fancyList}
                    showHeading={false}
                    showPlaceBets={false}
                    handleBetSection={handleBetSection}
                    handleCloseBetSection={handleCloseBetSection}
                    currentSportsId={currentSportsId}
                    showBetSection={showBetSection}
                    backlayAmount={backlayAmount}
                    bgColor={bgColor}
                    placebetStatus={placebetStatus}
                  />
                </>
              )}
            </Styled.Col1>
            <Styled.Col2 className="col-md-12 col-sm-12 col-lg-4  px-0">
              <Styled.LiveStream className="mb-1">
                <Styled.Cardheader
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Styled.H6>{_constants.live_stream}</Styled.H6>
                  <Styled.H6
                    onClick={() => {
                      setOpenLiveTv(!openLiveTv)
                    }}
                  >
                    <FiTv />
                  </Styled.H6>
                </Styled.Cardheader>
                {openLiveTv && (
                  <VideoPlayer
                    videoUrl={`https://tv.bet99.io/test.php?id=${event_id}`}
                    showImage={false}
                    showTimer={false}
                  />
                )}
              </Styled.LiveStream>
              <Styled.PlaceBet className="mb-1">
                <Styled.Cardheader>
                  <Styled.H6>{_constants.Bet}</Styled.H6>
                </Styled.Cardheader>

                {showBetSection && (
                  <PlaceBetBox
                    backlayAmount={backlayAmount}
                    handleCloseBetSection={handleCloseBetSection}
                    min_bet={minBet || 100}
                    max_bet={maxBet || 100}
                    bgColor={bgColor}
                    bet_delay={betDelay}
                    odd_type={oddType}
                    teamName={teamName}
                    oddStr={oddStr}
                    oddVolume={oddVolume}
                  />
                )}
              </Styled.PlaceBet>
              <Styled.OpenBet className="mb-1">
                <Styled.Cardheader>
                  <Styled.H6>{_constants.open_bets}</Styled.H6>
                </Styled.Cardheader>
                <TabComponent
                  activeTab={activeTab}
                  handleTabChange={handleTabChange}
                  openMatchedBets={openMatchedBets}
                  openFancyBets={openFancyBets}
                  event_id={localStorage.getItem('sportEventId') || ''}
                />
              </Styled.OpenBet>
            </Styled.Col2>
          </div>
        </Styled.Section>
      </Layout>
    </>
  )
}
export default CricketDetails
