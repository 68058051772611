import React from 'react'
import * as Styled from './style'
import fancy from '../../assets/images/fancy.svg'
import { useNavigate } from 'react-router'

const Score = ({ item, sportsName, odds }) => {
  const navigate = useNavigate()

  const handleScoreItem = () => {
    localStorage.setItem('sportName', sportsName)
    localStorage.setItem('sportEventId', item.eventId)
    navigate(`/sports/detail/${item.eventId}`)
  }

  const filteredOdds = odds?.find((_item) => {
    return _item.gmid == item.eventId
  })?.section

  if (filteredOdds?.length === 2) {
    filteredOdds.splice(1, 0, {
      sid: '',
      gstatus: '',
      nat: '',
      sno: 2,
      gscode: '',
      odds: [
        {
          odds: '-',
          oname: 'BACK1',
          otype: 'BACK',
          sid: 0,
          tno: 0,
          size: '-',
        },
        {
          odds: '-',
          oname: 'LAY1',
          otype: 'LAY',
          sid: 0,
          tno: 0,
          size: '-',
        },
      ],
    })
  }

  return (
    <Styled.Box onClick={() => handleScoreItem()}>
      <Styled.BetTableRow>
        <Styled.Row>
          <Styled.ColMd6>
            <Styled.GameBox>
              <Styled.GameLeftColumn>
                <Styled.GameName>
                  <Styled.GameNameAnchor>
                    <Styled.TeamName>{item?.teamName}</Styled.TeamName>
                    <Styled.TeamEvent>
                      {'(' + item?.eventName + ')'}
                    </Styled.TeamEvent>
                  </Styled.GameNameAnchor>
                </Styled.GameName>
                <Styled.InplayDateDiv>
                  <Styled.GameDateInplay>
                    {item.isPlay === '1' ? (
                      <Styled.GameDateSpan>Live</Styled.GameDateSpan>
                    ) : (
                      <></>
                    )}
                  </Styled.GameDateInplay>
                  <Styled.GameDate>
                    <Styled.GameDatePara>{item?.matchDate}</Styled.GameDatePara>
                    <Styled.GameDatePara>{item?.matchTime}</Styled.GameDatePara>
                  </Styled.GameDate>
                </Styled.InplayDateDiv>
              </Styled.GameLeftColumn>
              <Styled.GameIcons>
                <Styled.ImageBox>
                  <Styled.GameIconAnchor>
                    <Styled.GameIconImage src={fancy} alt="fancyLogo" />
                  </Styled.GameIconAnchor>
                  <Styled.GameIconSpan>BM</Styled.GameIconSpan>
                </Styled.ImageBox>
              </Styled.GameIcons>
            </Styled.GameBox>
          </Styled.ColMd6>
          <Styled.ColMd6>
            <Styled.Row>
              {filteredOdds?.length ? (
                filteredOdds?.map((odds) => (
                  <Styled.Col4>
                    <Styled.BackLay>
                      {odds?.odds.map((elm) => {
                        if (elm.otype?.toLowerCase() == 'back') {
                          return (
                            <Styled.BlBox>
                              <Styled.Span>
                                {elm?.odds || '-'}
                                <Styled.Em> {elm?.size || '-'}</Styled.Em>
                              </Styled.Span>
                            </Styled.BlBox>
                          )
                        } else if (elm.otype?.toLowerCase() == 'lay') {
                          return (
                            <Styled.BlBoxLay>
                              <Styled.Span>
                                {elm?.odds || '-'}
                                <Styled.Em> {elm?.size || '-'}</Styled.Em>
                              </Styled.Span>
                            </Styled.BlBoxLay>
                          )
                        }
                      })}
                    </Styled.BackLay>
                  </Styled.Col4>
                ))
              ) : (
                <>
                  <Styled.Col4>
                    <Styled.BackLay>
                      <Styled.BlBox>
                        <Styled.Span>
                          {item?.t31 || '-'}
                          <Styled.Em> {item?.t32 || '-'}</Styled.Em>
                        </Styled.Span>
                      </Styled.BlBox>
                      <Styled.BlBoxLay>
                        <Styled.Span>
                          {item?.odds || '-'}
                          <Styled.Em> {item?.odds || '-'}</Styled.Em>
                        </Styled.Span>
                      </Styled.BlBoxLay>
                    </Styled.BackLay>
                  </Styled.Col4>
                  <Styled.Col4>
                    <Styled.BackLay>
                      <Styled.BlBox>
                        <Styled.Span>
                          {item?.t31 || '-'}
                          <Styled.Em> {item?.t32 || '-'}</Styled.Em>
                        </Styled.Span>
                      </Styled.BlBox>
                      <Styled.BlBoxLay>
                        <Styled.Span>
                          {item?.odds || '-'}
                          <Styled.Em> {item?.odds || '-'}</Styled.Em>
                        </Styled.Span>
                      </Styled.BlBoxLay>
                    </Styled.BackLay>
                  </Styled.Col4>
                  <Styled.Col4>
                    <Styled.BackLay>
                      <Styled.BlBox>
                        <Styled.Span>
                          {item?.t31 || '-'}
                          <Styled.Em> {item?.t32 || '-'}</Styled.Em>
                        </Styled.Span>
                      </Styled.BlBox>
                      <Styled.BlBoxLay>
                        <Styled.Span>
                          {item?.odds || '-'}
                          <Styled.Em> {item?.odds || '-'}</Styled.Em>
                        </Styled.Span>
                      </Styled.BlBoxLay>
                    </Styled.BackLay>
                  </Styled.Col4>
                </>
              )}
              {/* <Styled.Col4>
                <Styled.BackLay>
                  <Styled.BlBox>
                    <Styled.Span>
                      {item?.t31 || '-'}
                      <Styled.Em> {item?.t32 || '-'}</Styled.Em>
                    </Styled.Span>
                  </Styled.BlBox>
                  <Styled.BlBoxLay>
                    <Styled.Span>
                      {item?.t41 || '-'}
                      <Styled.Em> {item?.t42 || '-'}</Styled.Em>
                    </Styled.Span>
                  </Styled.BlBoxLay>
                </Styled.BackLay>
              </Styled.Col4>
              <Styled.Col4>
                <Styled.BackLay>
                  <Styled.BlBox>
                    <Styled.Span>
                      {item?.t51 || '-'}
                      <Styled.Em> {item?.t52 || '-'}</Styled.Em>
                    </Styled.Span>
                  </Styled.BlBox>
                  <Styled.BlBoxLay>
                    <Styled.Span>
                      {item?.t61 || '-'}
                      <Styled.Em> {item?.t62 || '-'}</Styled.Em>
                    </Styled.Span>
                  </Styled.BlBoxLay>
                </Styled.BackLay>
              </Styled.Col4> */}
            </Styled.Row>
          </Styled.ColMd6>
        </Styled.Row>
      </Styled.BetTableRow>
    </Styled.Box>
  )
}

export default Score
