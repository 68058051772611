import React from 'react'
import * as Styled from './style'
const TabMenu = ({ options, handleMenu, activeMenu }) => {
  return (
    <Styled.Menu>
      {options.map((elem, indx) => {
        return (
          <Styled.Option
            key={elem.alt + indx}
            onClick={() => handleMenu(elem)}
            activeMenu={activeMenu.alt === elem.alt}
          >
            <Styled.Icon
              activeMenu={activeMenu.alt === elem.alt}
              src={elem.src}
              alt={elem.alt}
            />
            <Styled.Title>{elem.alt}</Styled.Title>
          </Styled.Option>
        )
      })}
    </Styled.Menu>
  )
}

export default TabMenu
