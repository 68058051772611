import styled from 'styled-components'
import { color } from '../../../color/color'

export const Container = styled.div`
  background-color: ${color._fff};
  color: ${color._000};
`
export const InnerContainer = styled.div`
  background-color: ${color._F2F2F2};
`
export const ImageContainer = styled.div`
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._373636D6 : color._F2F2F2} !important;
  position: relative;
  justify-content: center !important;
  align-items: center !important;
`

export const ThemedCardLabel = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
`
export const ThemedCard = styled.div`
  background-image: linear-gradient(to right, ${color._08C}, ${color._2C3E50});
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  color: ${color._fff};
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  padding: 15px;
`
export const ThemedImageCard = styled.div`
  background-image: linear-gradient(to right, ${color._08C}, ${color._2C3E50});
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
`
export const ThemedCardSuspended = styled.div`
  background-color: ${color._opacityBg};
  border: 0;
  border-radius: 4px;
  color: ${color._fff};
  font-size: 18px;
  font-weight: 700;
  height: 48px;
  position: relative;
  line-height: 2.5;
`

export const CardImage = styled.img`
  vertical-align: middle;
`
export const Image = styled.img`
  height: 45px;
`
export const SuspendedContainer = styled.div`
  background-color: ${color._opacityBg};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  line-height: 2.5;
  border-radius: 4px;
`
