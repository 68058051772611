export const data = [
  {
    title: 'Match Odds',
    value1: '2.08',
    value2: '2.25',
    isLock: false,
  },
  {
    title: 'Big Card (7,8,9) -4',
    value1: '2.18',
    value2: '2.65',
    isLock: false,
  },
  {
    title: 'Zero Card -4',
    value1: '2.11',
    value2: '2.29',
    isLock: true,
  },
  {
    title: 'Any Zero',
    value1: '0',
    value2: '0',
    isLock: true,
  },
]
