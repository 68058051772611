import ACTIONTYPES from './actionTypes'

const initialState = {
  data: [],
  isLoading: true,
  casinoProvider: [],
  isProviderLoading: true,
}

const casinoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONTYPES.CASINO_GAMES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }
    case ACTIONTYPES.CASINO_PROVIDER_GAMES_SUCCESS:
      return {
        ...state,
        casinoProvider: action.payload,
        isProviderLoading: false,
      }

    case ACTIONTYPES.CASINO_GAMES_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        isLoading: false,
      }
    case ACTIONTYPES.CASINO_PROVIDER_GAMES_FAIL:
      return {
        ...state,
        error: action.payload,
        casinoProvider: [],
        isProviderLoading: false,
      }

    default:
      return state
  }
}

export default casinoReducer
