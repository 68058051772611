import React from 'react'
import { NavButton } from '../styles'

const TabNavItem = ({ tabId, activeTab, handleTabChange }) => {
    return (
      <NavButton
        className={`nav-link ${activeTab === tabId ? 'active' : ''}`}
        onClick={() => handleTabChange(tabId)}
      >
        {tabId.charAt(0).toUpperCase() + tabId.slice(1)}
      </NavButton>
    )
  }

export default TabNavItem
