import React, { useState } from 'react'
import List from './List'
import * as Styled from './style'

const SubMenu = ({ item, indexNumber }) => {
  const [subnav, setSubnav] = useState(false)

  const showSubnav = () => setSubnav(!subnav)
  return (
    <>
      <Styled.SidebarLink
        item={item}
        to={item.path}
        onClick={item.subNav && showSubnav}
        key={item.title + indexNumber}
      >
        <div>
          <Styled.Img
            src={item.icon}
            title={item.title}
            alt={item.title + indexNumber}
          />
          <Styled.SidebarLabel item={item} key={indexNumber}>
            {item.title}
          </Styled.SidebarLabel>
        </div>
        <Styled.SvgSection>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </Styled.SvgSection>
      </Styled.SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return <List item={item} key={index} />
        })}
    </>
  )
}

export default SubMenu
