import React from 'react'
import * as Styled from './style'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Bar } from 'react-chartjs-2'
import * as faker from 'faker'
import Wicket from '../../../../assets/svg/sports/Wicket.svg'

const MDGraph = ({ graphOption }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    graphOption === 'Line' && PointElement,
    graphOption === 'Line' ? LineElement : BarElement,
    Title,
    Tooltip,
    Legend,
  )

  const pointImage = new Image(15, 15)
  pointImage.src = Wicket
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    elements: {
      point: {
        pointStyle: [pointImage],
      },
    },
  }

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ]

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() =>
          faker.datatype.number({ min: -1000, max: 1000 }),
        ),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        lineTension: 0.3,
        fill: true,
      },
    ],
  }
  return (
    <Styled.MDGraph>
      {graphOption === 'Line' ? (
        <Line options={options} data={data} className="linechart" />
      ) : (
        <Bar options={options} data={data} className="linechart" />
      )}
    </Styled.MDGraph>
  )
}

export default MDGraph
