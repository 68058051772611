export const _constants = {
  Name: 'James Duckworth v Mattia Bellucci',
  Date: '(01/03/2024 04:30:00 PM)',
  Bet: 'Place Bet',
  open_bets: 'Open Bets',
  live_stream: 'Live Stream',
}
export const cricketData = [
  {
    title: 'Football Fancy',
    subNav: [
      {
        sportsId: 1,
        teamName: 'Gujarat Titans',
        t11: 1000,
        t12: 180.16,
        t21: 2000,
        t22: 200.22,
        t31: 2000,
        t32: 123.88,
        t41: 3000,
        t42: 240.23,
        t51: 4000,
        t52: 240.23,
        t61: 5000,
        t62: 240.23,
      },
      {
        teamName: 'Royal Challengers Bangalore',
        sportsId: 2,
      },
      {
        teamName: 'Rajasthan Royals',
        sportsId: 3,
      },
      {
        teamName: 'Delhi Capitals',
        sportsId: 4,
      },
    ],
  },
]

export const footballData = [
  {
    title: 'UEFA EUROPA LEAGUE CUP WINNER',
    subNav: [
      {
        sportsId: 1,
        teamName: 'Liverpool',
        suspended: true,
        t11: 1000,
        t12: 180.16,
        t21: 2000,
        t22: 200.22,
        t31: 2000,
        t32: 123.88,
        t41: 3000,
        t42: 240.23,
        t51: 4000,
        t52: 240.23,
        t61: 5000,
        t62: 240.23,
      },
      {
        teamName: 'Bayer Leverkusen',
        sportsId: 2,
      },
      {
        teamName: 'Roma',
        sportsId: 3,
        suspended: true,
      },
      {
        teamName: 'Benifica',
        sportsId: 4,
      },
    ],
  },
]

export const tennisData = [
  {
    title: 'Match Odds',
    subNav: [
      {
        sportsId: 1,
        teamName: 'James Duckworth',

        t11: 1.77,
        t12: 2.22,
        t21: 2.33,
        t22: 1.22,
        t31: 2.33,
        t32: 1.88,
        t41: 1.66,
        t42: 2.23,
        t51: 1.55,
        t52: 2.23,
        t61: 1.44,
        t62: 2.23,
      },
      {
        teamName: 'Bayer',
        sportsId: 2,
        suspended: true,
      },
    ],
  },
]
