import Loader from '../../components/Loader/Loader'
import CasinoProvider from '../../container/CasinoProvider/CasinoProvider'
import ScoreCard from '../../container/ScoreCard/ScoreCard'
import { _constants } from './constants'
import * as Styled from './style'
import { useEffect, useRef } from 'react'
import { getMatchOddsData } from '../../redux/match/action'
import { useSocket } from '../../SocketContext'
import { useSelector, useDispatch } from 'react-redux'

import {
  convertBMDataForOdds,
  getAllMatches,
  getOddsData,
  mergeArraysByMarketId,
} from '../../utils/utils'
const Cricket = (props) => {
  const { name, matches } = props
  const matchesData = useSelector((state) => state.match.data)
  const matchesDataOdds = useSelector((state) => state.match.odds)
  const { socket } = useSocket()
  const dispatch = useDispatch()
  const stopEmit = useSelector((state) => state.match.stopEmit)

  // const isLoading = useSelector((state) => state.match.isLoading)

  const matchData = getAllMatches(matchesData)

  const sortedMatches = matchData?.length
    ? matchData.find((item) => item.sportsName === name)?.matches || []
    : matches

  const avoidRerender = useRef(false)

  useEffect(() => {
    if (socket) {
      if (!avoidRerender.current && stopEmit) {
        avoidRerender.current = true
        socket.emit('odds', 4)
        socket.on('odds', (_data) => {
          dispatch(getMatchOddsData(getOddsData(_data?.data, matchesData)))
        })
      }
      return () => (avoidRerender.current = true)
    }
  }, [socket])

  return (
    <>
      {sortedMatches?.length ? (
        <>
          <ScoreCard
            matches={sortedMatches}
            name={name}
            odds={matchesDataOdds}
          />
          <CasinoProvider />
        </>
      ) : (
        <Styled.Span>{_constants.noRecord}</Styled.Span>
      )}
    </>
  )
}
export default Cricket
