import React, { useState } from 'react'
import * as Styled from './style'
import { FaStar } from 'react-icons/fa'
import MDTeamScore from './MDTeamScore'
import { color } from '../../../../color/color'
import MDGraphData from './MDGraphData'
import MDScoreData from './MDScoreData'
import MDOptions from './MDOptions'
import { IoIosArrowDown } from 'react-icons/io'
import { IoIosArrowUp } from 'react-icons/io'
const MatchDetails = () => {
  const [viewGraph, setViewGraph] = useState(false)
  const [selectedOption, setSelectedOption] = useState('Scorecard')

  return (
    <Styled.MatchDetails>
      <Styled.MatchDetailsHeading className="display-flex">
        <Styled.MatchTitle>
          Afghanistan/T20 Qosh Tepa National Cup
        </Styled.MatchTitle>
        <Styled.MinMaxFav className="display-flex">
          <Styled.MinMax className="display-flex">
            <Styled.Min>Min:100</Styled.Min>
            <Styled.Max>Max:10000</Styled.Max>
          </Styled.MinMax>
          <Styled.Fav>
            <FaStar color={color._717274} />
          </Styled.Fav>
        </Styled.MinMaxFav>
      </Styled.MatchDetailsHeading>
      <Styled.MatchDetailsContent>
        <MDTeamScore />
        <MDGraphData />
        {viewGraph && (
          <>
            <MDScoreData selectedOption={selectedOption}/>
            <MDOptions
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </>
        )}
        <Styled.GraphView onClick={() => setViewGraph(!viewGraph)}>
          {viewGraph ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </Styled.GraphView>
      </Styled.MatchDetailsContent>
    </Styled.MatchDetails>
  )
}

export default MatchDetails
