import spade from '../CasinoMeter/cardImages/spade.png'
import club from '../CasinoMeter/cardImages/club.png'
import heart from '../CasinoMeter/cardImages/heart.png'
import diamond from '../CasinoMeter/cardImages/diamond.png'

export const constants = {
  player: 'Player',
}

export const playersData = [
  {
    player: 'A',
    style: { paddingLeft: 0 },
    header: ['Player A', '3 Baccarat A', 'Total A', 'Pair Plus A'],
    stats: {
      winner: { value: 1.98, isSuspended: false },
      highCard: { value: 3.23, isSuspended: false },
      pair: { value: 2.88, isSuspended: false },
      colorPlusA: { value: 3, isSuspended: false },
    },
  },
  {
    player: 'B',
    style: { paddingRight: 0 },
    header: ['Player B', '3 Baccarat B', 'Total B', 'Pair Plus B'],
    stats: {
      winner: { value: 1.98, isSuspended: true },
      highCard: { value: 3.23, isSuspended: true },
      pair: { value: 2.88, isSuspended: true },
      colorPlusA: { value: 3, isSuspended: true },
    },
  },
]

export const bonusData = [
  {
    score: {
      value: 1.98,
      imageTop: spade,
      imageBelow: club,
      isSuspended: false,
    },
    className: 'col-md-3',
    style: { paddingLeft: 0 },
  },
  {
    score: {
      value: 1.98,
      imageTop: heart,
      imageBelow: diamond,
      isSuspended: false,
    },
    className: 'col-md-3',
    style: { paddingLeft: 0 },
  },
  {
    score: {
      value: 1.98,
      imageTop: spade,
      imageBelow: club,
      isSuspended: true,
    },
    className: 'col-md-3',
    style: { paddingRight: 0 },
  },
  {
    score: {
      value: 1.98,
      imageTop: heart,
      imageBelow: diamond,
      isSuspended: true,
    },
    className: 'col-md-3',
    style: { paddingRight: 0 },
  },
]
