import ACTIONTYPES from './actionTypes'

const initialState = {
  data: [],
  error: '',
  bankAccount: [],
  isLoader: null,
}

const DepositReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONTYPES.DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoader: false,
      }
    case ACTIONTYPES.GET_DEPOSIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoader: null,
      }
    case ACTIONTYPES.DEPOSIT_FAIL:
      return {
        ...state,
        isLoader: false,
      }
    case ACTIONTYPES.GET_DEPOSIT_BANK_SUCCESS:
      return {
        ...state,
        bankAccount: action.payload,
        isLoader: null,
      }
    default:
      return state
  }
}

export default DepositReducer
