import styled from 'styled-components'
import { color } from '../../../color/color'

export const Container = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-size: 16px !important;
  font-weight: 400 !important;
  border: 1px solid grey;
  padding: 20px;
  color: ${color._000};
`
export const BoxContainer = styled.div`
  color: white;
  font-size: 16px;
  padding: 5px;
  width: 100% !important;
  height: 100%;
  text-align: center;
  background-color: ${(isSuspended) =>
    isSuspended ? color._opacityBg : color._2C3E50};
`
export const EmptyContainer = styled.div`
  position: absolute;
  z-index: 0;
`
export const SuspendedContainer = styled.div`
  background-color: ${color._opacityBg};
  position: absolute;
  width: 100%;
  height: 100%;
`

export const LockContainer = styled.div`
  position: absolute;
  z-index: 1;
`
export const Heading = styled.h4`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  color: ${color._575757};
`
export const RowContainer = styled.div`
  justify-content: space-between !important;
`
export const NumContainer = styled.div`
  height: 55.7px !important;
  padding: 5px !important;
`
export const BottomContainer = styled.div`
  height: 55.7px !important;
`
