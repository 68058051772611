import React from 'react'
import * as Styled from './style'
const Statistics = () => {
  return <Styled.Statistics className="hide-scrollbar">
      <Styled.ScoreCardHeading>
        <Styled.Span>STATISTICS</Styled.Span>
      </Styled.ScoreCardHeading>
  </Styled.Statistics>
}

export default Statistics
