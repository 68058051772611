import React, { useState } from 'react'
import MDGraph from './MDGraph'
import * as Styled from './style'
import { SlGraph } from 'react-icons/sl'
import { ImParagraphRight } from 'react-icons/im'
const MDGraphData = () => {
  const [activeTeam, setActiveTeam] = useState('Team 1')
  const [graphOption, setGraphOption] = useState('Line')
  return (
    <Styled.MDGraphData>
      <Styled.TeamNames>
        <Styled.Team
          onClick={() => setActiveTeam('Team 1')}
          active={activeTeam === 'Team 1'}
        >
          Team 1
        </Styled.Team>
        <Styled.Team
          onClick={() => setActiveTeam('Team 2')}
          active={activeTeam === 'Team 2'}
        >
          Team 2
        </Styled.Team>
      </Styled.TeamNames>
      <MDGraph graphOption={graphOption}/>
      <Styled.GraphIcon>
        <Styled.GraphI
          onClick={() => setGraphOption('Line')}
          active={graphOption === 'Line'}
        >
          <SlGraph/>
        </Styled.GraphI>
        <Styled.GraphI
          onClick={() => setGraphOption('Bar')}
          active={graphOption === 'Bar'}
          rotate={true}
        >
          <ImParagraphRight/>
        </Styled.GraphI>
      </Styled.GraphIcon>
    </Styled.MDGraphData>
  )
}

export default MDGraphData
