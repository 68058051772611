import React from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Styled from './styles'
import { CONSTANT, rulesDatas } from '../constants'

const RulesModal = ({ isModalOpen, handleModal }) => {
  return (
    <Modal show={isModalOpen} onHide={handleModal} size="lg">
      <Styled.RulesModalHeaderWrapper>
        <Styled.RulesModalHeaderContainer>
          <Styled.RulesModalHeaderTitle>
            {CONSTANT.SEVEN_GAME}
          </Styled.RulesModalHeaderTitle>
          {/* <Styled.RulesModalHeaderSubTitle>
            {CONSTANT.RULES_REGULATION}
          </Styled.RulesModalHeaderSubTitle> */}
        </Styled.RulesModalHeaderContainer>
        <Styled.RulesModalHeaderCross onClick={handleModal}>
          <Styled.RulesModalHeaderCrossSpan>&times;</Styled.RulesModalHeaderCrossSpan>
        </Styled.RulesModalHeaderCross>
      </Styled.RulesModalHeaderWrapper>
      <Styled.RulesModalBody>
        {/* <Styled.SportsTitle>{CONSTANT.GAME_OBJECTIVE}</Styled.SportsTitle> */}
        {rulesDatas.map((rulesData) => {
          return (
            <React.Fragment key={rulesData.id}>
              {/* <Styled.GameObjective>
                {rulesData.objectives}
              </Styled.GameObjective> */}
              {/* <Styled.SportsTitle>{CONSTANT.GAME_RULE}</Styled.SportsTitle> */}
              <>
                <Styled.HandsDealt>
                  {CONSTANT.HAND_DEALT}: 1 {CONSTANT.SEVEN_GAME_OPTION}
                </Styled.HandsDealt>
              </>
              <Styled.BetHeading>{CONSTANT.BETS}</Styled.BetHeading>
              {rulesData?.rules.bets?.map((rule) => (
                <React.Fragment keys={rule.id}>
                  {rule.condition && (
                    <Styled.UnOrderedList>
                      <Styled.ListItem>
                        {rule.type} {CONSTANT.WIN} :{rule.condition}
                      </Styled.ListItem>
                    </Styled.UnOrderedList>
                  )}
                </React.Fragment>
              ))}
              {/* <Styled.SportsTitle>{CONSTANT.GAME_RULE}</Styled.SportsTitle> */}
              <Styled.PayoutContainer>
                <Styled.PayoutTitle>{CONSTANT.PAYOUT_TITLE}</Styled.PayoutTitle>
                <Styled.RulesModalTable>
                  <tbody>
                    {rulesData?.rules.bets?.map((bet) => (
                      <tr>
                        <Styled.RulesModalTableData>
                          {bet.type}
                        </Styled.RulesModalTableData>
                        <Styled.RulesModalTableData>
                          {bet.payout}
                        </Styled.RulesModalTableData>
                      </tr>
                    ))}
                  </tbody>
                </Styled.RulesModalTable>
              </Styled.PayoutContainer>
            </React.Fragment>
          )
        })}
      </Styled.RulesModalBody>
    </Modal>
  )
}

export default RulesModal
