import React from 'react'
import * as Styled from '../style'
import { constants } from '../../../utils/constant'
import { useNavigate } from 'react-router'
const Header = () => {
  const navigate = useNavigate();
  return (
    <Styled.Header>
      <Styled.BackBtn onClick={() => navigate("/")}>{constants.Back}</Styled.BackBtn>
      <Styled.Title>{constants.Sports_Book}</Styled.Title>
    </Styled.Header>
  )
}

export default Header
