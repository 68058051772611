import React from 'react'
import * as Styled from './style'
const Input = ({
  label,
  type,
  key,
  customStyle,
  onChange,
  inputBgColor,
  value,
  maxLength,
}) => {
  return (
    <Styled.InputDiv key={key} customStyle={customStyle}>
      <Styled.Label customStyle={customStyle} for={label}>
        {label}
      </Styled.Label>
      <Styled.Input
        inputBgColor={inputBgColor}
        id={label}
        type={type}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
      ></Styled.Input>
    </Styled.InputDiv>
  )
}

export default Input
