export const rulesData = [
  {
    title: 'Football Fancy',
    subNav: [
      {
        title:
          'Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided...',
      },
      {
        title:
          'Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.',
      },
      {
        title: 'Tournament Corners - Only corners taken in 90 minutes count.',
      },
      {
        title:
          'Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.',
      },
    ],
  },
  {
    title: 'Big Bash League',
    subNav: [
      {
        title:
          'Total match 1st over run:- Average 6 runs will be given if total 20 overs is not played, only 1st innings will be considered as valid',
        color: '#000000',
      },
      {
        title: 'Highest innings run - Only first innings is valid',
        color: '#000000',
      },
      {
        title: 'Lowest innings run - Only first innings is valid',
        color: '#000000',
      },
      {
        title:
          'Total 1st 6 over run:- Average 46 runs will be given if total 20 overs is not played, This event is valid only for the 1st innings',
        color: '#000000',
      },
      {
        title:
          'Total Fours - Average 25 fours will be given in case match abandoned or over reduced',
        color: '#000000',
      },
      {
        title:
          'Total Sixes - Average 10 sixes will be given in case match abandoned or over reduced',
        color: '#000000',
      },
      {
        title:
          'Total Wickets - Average will 12 Wickets be given in case match abandoned or over reduced',
        color: '#000000',
      },
      {
        title:
          'Total Wides - Average 8 wides will be given in case match abandoned or over reduced',
        color: '#000000',
      },
      {
        title:
          'Total Extras - Average 14 extras will be given in case match abandoned or over reduced',
        color: '#000000',
      },
      {
        title:
          'Total Fifties - Average 2 fifties will be given in case match abandoned or over reduced',
        color: '#000000',
      },
      {
        title:
          'Total Caught out - Average 8 catch out will be given in case match abandoned or over reduced',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Lunch Favourite',
    subNav: [
      {
        title:
          '1. The team which is favourite at lunch will be considered as lunch favourite or the team which is favourite after first inning last ball will be considered as lunch favourite in our exchange.',
      },
      {
        title: '2. In any circumstances management decision will be final.',
      },
      {
        title:
          '3. In case of tie in T20 or one day in lunch favourite game , all bets will be deleted in our exchange.',
      },
      {
        title:
          '4.1 For example :- if match is reduced to 18 over per side in t20 or Oneday then after 18 over the team which is favourite at lunch will be considered as lunch favourite.',
        color: '#000000',
      },
      {
        title:
          '5. In case of weather, 1st innings match overs are reduced and direct target is given to team which will bat in 2nd inning then lunch favourite will be considered after target is given at lunch.',
      },
      {
        title:
          '5.1 For example :- in T20 match rain comes at 14 over and match is interrupted due to rain and direct target is given to 2nd batting team, then team which is favourite in match odds after target is given in match, will be considered as lunch favourite.',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Bookmaker',
    subNav: [
      {
        title:
          'Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided...',
      },
      {
        title:
          'Due to any reason any team will be getting advantage or disadvantage we are not concerned.',
      },
      {
        title:
          'Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at the same time (Punching) and others. Note : only winning bets will be voided.',
      },
      {
        title:
          'We will simply compare both teams 25 overs score higher score team will be declared winner in ODI (25 over comparison)',
      },
      {
        title:
          'We will simply compare both teams 10 overs higher score team will be declared winner in T20 matches (10 over comparison)',
      },
      {
        title:
          'Any query about the result or rates should be contacted within 7 days of the specific event, the same will not be considered valid post 7 days from the event.',
      },
      {
        title:
          'If two team ends up with equal points, then result will be given based on the official point table',
      },
      {
        title: 'Tennis:- Advance fancy market',
      },
      {
        title:
          'If the second set is not completed all bets regarding this market will be voided',
        color: '#000000',
      },
      {
        title:
          'If a player retires after completion of second set, then the market will be settled as three sets',
        color: '#000000',
      },
      {
        title: 'Virtual Cricket',
      },
      {
        title:
          'At any situation if the video gets interrupted/stopped then the same cannot be continued due to any technical issues bookmaker market will be voided',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Politics',
    subNav: [
      {
        title: 'Indian state legislative assembly elections.',
      },
      {
        title:
          '1. This event is to decide the winner of various legislative assemblies of india .',
        color: '#000000',
      },
      {
        title:
          '2. The final result declared by election commission of india for assembly elections of various states of india for a particular year will be valid in our exchange ,The customers are entirely responsible for their bets at all times.',
        color: '#000000',
      },
      {
        title:
          '3. All bets will be voided if the election doesn’t take place in given time by election commission or as per our exchange management decision.',
        color: '#000000',
      },
      {
        title:
          '4. Company reserves the right to suspend/void any bets on this event at any time if we find the same not to be legitimate with the certainty of the outcome.',
        color: '#000000',
      },
      {
        title:
          '5. Accidental issues during assembly elections will not be counted in our exchange ,If required Additional candidates may be added on request.',
        color: '#000000',
      },
      {
        title:
          '6. Kindly be informed no candidates will be partially settled and will remain in the market until it is fully settled. This is to ensure that all customers can continue trading for the candidates that they have positions on, since each candidate is still a valid runner in this market.',
        color: '#000000',
      },
      {
        title:
          '7. Please be informed that the transmissions described as “live” by few broadcasters may actually be delayed due to multiple scenarios.',
        color: '#000000',
      },
      {
        title:
          '8. If any candidate withdraws for any reason, including death, all bets on the market will be valid and be settled as per the defined rules.',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Fancy Market ',
    subNav: [
      {
        title: '1. Even odd game betting rate rules.',
      },
      {
        title:
          '1.1 Completed game is valid , in case due to rain over are reduced or match abandoned particular game will be deleted.',
        color: '#000000',
      },
      {
        title:
          '1.2 All bets regarding to ODD/EVEN player/partnership are valid if one legal delivery is being played, else the bets will be deleted. Player odd/even all advance bets will be valid if one legal delivery is being played in match otherwise voided.',
        color: '#000000',
      },
      {
        title:
          '1.3 All bets regarding Odd/Even sessions will be deleted if the particular session is incomplete, for example match got abandoned or finished before the end of particular session.',
        color: '#000000',
      },
      {
        title: '1.4 In any circumstances management decision will be final.',
        color: '#000000',
      },
    ],
  },
  {
    title: 'IPL ',
    subNav: [
      {
        title:
          'IPL Special 2024 Valid Only For First 21 Matches. If IPL fixture of 21 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this)',
      },
      {
        title: 'Management decision will be final',
      },
      {
        title:
          'Total 1st over runs: Average 6 runs will be given in case match abandoned or over reduced (only 1st innings valid)',
        color: '#000000',
      },

      {
        title:
          'Total 1st 6 over run:- Average 48 runs will be given in case match abandoned or over reduced (Only 1st Innings valid)',
        color: '#000000',
      },
      {
        title:
          'Total fours: Average 28 fours will be given in case match abandoned or over reduced',
        color: '#000000',
      },
      {
        title:
          'Total sixes: Average 14 sixes will be given in case match abandoned or over reduced',
        color: '#000000',
      },
      {
        title:
          'Total Wickets - Average will 12 Wickets be given in case match abandoned or over reduced',
        color: '#000000',
      },
    ],
  },
  {
    title: "Women's Premier League (WPL)",
    subNav: [
      {
        title:
          '1. If WPL fixture of 22 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this).',
        color: '#000000',
      },
      {
        title:
          '2. Total matches 1st over runs : Average 5 runs will be given in case match abandoned or over reduced (only 1st innings valid).',
        color: '#000000',
      },
      {
        title:
          '3. Total matches 1st 6 over runs:- Average 40 runs will be given in case match abandoned or over reduced (Only 1st Innings valid).',
        color: '#000000',
      },
      {
        title:
          "4. Total 4's : Average 32 fours will be given in case match abandoned or over reduced.",
        color: '#000000',
      },
      {
        title:
          "5. Total 30's: Average 2 sixes will be given in case match abandoned or over reduced.",
        color: '#000000',
      },
      {
        title:
          "6. Total 50's - Average 1 fifties will be given in case match abandoned or over reduced.",
        color: '#000000',
      },
      {
        title:
          '7. Total Wickets - Average will 12 Wickets be given in case match abandoned or over reduced.',
        color: '#000000',
      },
      {
        title:
          '8. At any situation if result is given for any particular event based on the rates given for the same, then the particular result will be considered valid, similarly if the tournament gets canceled due to any reason the previously given result will be considered valid.',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Pakistan Super League (PSL) ',
    subNav: [
      {
        title:
          '1. If PSL fixture of 34 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this).',
        color: '#000000',
      },
      {
        title:
          '2. Total Matches 1st over runs : Average 6 runs will be given in case match abandoned or over reduced (only 1st innings valid).',
        color: '#000000',
      },
      {
        title:
          '3. Total Matches 1st 6 over runs : Average 50 runs will be given in case match abandoned or over reduced (Only 1st Innings valid).',
        color: '#000000',
      },
      {
        title:
          '4. Total fours: Average 32 fours will be given in case match abandoned or over reduced.',
        color: '#000000',
      },
      {
        title:
          '5. Total sixes: Average 13 sixes will be given in case match abandoned or over reduced.',
        color: '#000000',
      },
      {
        title:
          "6. Total 30's: Average 2 Thirties will be given in case match abandoned or over reduced.",
        color: '#000000',
      },
      {
        title:
          '7. Total Fifties - Average 2 Fifties will be given in case match abandoned or over reduced.',
        color: '#000000',
      },
      {
        title:
          '8. Total Wickets - Average 13 Wickets will be given in case match abandoned or over reduced.',
        color: '#000000',
      },
      {
        title:
          '9. Total Wides - Average 11 Wides will be given in case match abandoned or over reduced.',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Kabaddi ',
    subNav: [
      {
        title:
          'In any circumstances management decision will be final related to all Fancy of kabaddi of our exchange.',
        color: '#000000',
      },
      {
        title: 'All fancy bets will be validated when match has been tied.',
        color: '#000000',
      },
      {
        title:
          'Result of individual player of fancy will be validated only when player play that match.',
        color: '#000000',
      },
      {
        title:
          'In any case wrong rate has been given in fancy that particular bets will be deleted.',
        color: '#000000',
      },
      {
        title:
          'For Playoffs Final Result Of 40 Minutes Of Two Halves Will Be Valid In Our Exchange',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Horse Racing ',
    subNav: [
      {
        title: 'General',
      },
      {
        title:
          "1. All individual race markets will be determined according to the official result at the time of the 'weigh-in' announcement (or equivalent). Subsequent disqualifications, appeals or amendments to the result will be disregarded.",
        color: '#000000',
      },
      {
        title:
          '2. If a race is abandoned or otherwise declared void, or in the event of a walkover, all bets on that race will be void.',
        color: '#000000',
      },
      {
        title:
          '3. If the scheduled venue is changed after the market has been loaded by us, all bets will be void. Where a race does not take part on its scheduled day, all bets will be void.',
        color: '#000000',
      },
      {
        title:
          '4. If a scheduled surface type is changed (e.g., turf to dirt) all bets will stand.',
        color: '#000000',
      },
    ],
  },
  {
    title: 'World Cup ',
    subNav: [
      {
        title:
          'Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided...',
      },
      {
        title:
          'In case of any circumstances, management decision will be final for all the fancies under world cup.',
      },
      {
        title: 'WC:-WORLD CUP',
      },
      {
        title: 'MOM:-MAN OF THE MATCH.',
      },
      {
        title:
          'Match 1st over run:- This fancy is valid only for first innings of the match, Average 4 runs will be given in case of match abandoned or the entire 50 over is not played.',
        color: '#000000',
      },
      {
        title:
          'Highest inning run:- This fancy is valid only for first innings of the match.',
        color: '#000000',
      },
      {
        title:
          'Lowest innings run:- This fancy is valid only for first innings of the match.',
        color: '#000000',
      },
      {
        title:
          'Total Fours:- Average 48 Fours will be given if the match is abandoned or over reduced',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Binary  ',
    subNav: [
      {
        title: "1. All session's bets will be confirmed at market rate only.",
      },
      {
        title:
          "2. All session's settlement price means result can be checked from exchange's official sites.",
      },
      {
        title:
          "3. All session's result will be settlement price provided by exchange after market close.",
      },
      {
        title:
          "4. Every product has two types of prices SPOT and FUTURE. We provide only near month's FUTURE price in Binary Session. You can check it from the official website of that product.",
      },
      {
        title:
          "5. Session's timings : NFY, B-NFY, AXS, ICI, RIL, SBI, TT STL - Monday to Friday 10:00 a.m. to 2:30 p.m. GOLD, SILVER, CRUDE - Monday to Friday 11:30 a.m. to 10:30 p.m. CMX CRUDE, DOWJONES, NASDAQ, SNP - Monday to Friday 7:30 p.m. to 12:00 a.m.",
      },
      {
        title: 'Same bets same time from multiple id not allowed.',
        color: '#000000',
      },
      {
        title:
          'Operating and market making bets (cheating/line/chamka bets) are not allowed.',
        color: '#000000',
      },
      {
        title:
          'if any case wrong rate has been given in fancy that particular bets will be cancelled.',
        color: '#000000',
      },
      {
        title:
          'Deleted bets will remove under 24hr and clients will be notified.',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Match ',
    subNav: [
      {
        title:
          'Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided, For example: If we find such entries (above mentioned) from any id and their bets are (200000 lay in a 6 over session for the rate 40 and 200000 back for the rate of 48) and the actual score is 38, bets of 40 lay will be voided and the bets for 48 back will be considered valid.',
        color: '#000000',
      },
      {
        title:
          'TENNIS Match Odds :- If 1st set has been not completed at the time of the retirement or disqualification, then all bets relating to that individual match will be void.',
        color: '#000000',
      },
      {
        title:
          "FOOTBALL Match Odds :- All bets apply to the relevant full 'regular time' period including stoppage time. Any extra-time and/or penalty shoot-out is not included. For the cancellation of a goal, due to VAR, bets matched between the time of the goal being scored and the time at which the video assistant referee finishes the review will be voided. For the cancellation of a red card, due to VAR, bets matched after the time at which the video assistant referee commences the review will be voided.",
        color: '#000000',
      },
      {
        title:
          'FOOTBALL Under_Over Goals :- In the event of a match starting but not being completed, all bets will be void, unless the specific market outcome is already determined,',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Khado ',
    subNav: [
      {
        title: 'Only First inning valid for T20 and one day matches.',
        color: '#000000',
      },
      {
        title:
          'Same will be work like Lambi. If match abandoned or over reduced, all bets will be deleted.',
        color: '#000000',
      },
      {
        title: 'You can choose your own value in this event.',
        color: '#000000',
      },
    ],
  },
  {
    title: 'Election ',
    subNav: [
      {
        title:
          '1. The final result declared by election commission of India for Loksabha election 2019 will be valid in our exchange.',
      },
      {
        title:
          '2. Accidental issues during Loksabha election 2019 will not be counted in our exchange.',
      },
    ],
  },
  {
    title: 'Virtual Tennis ',
    subNav: [
      {
        title:
          '1. If streaming stops or some technical issue occurs, the match will be abandoned.',
        color: '#000000',
      },
      {
        title:
          '2. If there is any technical interference in the match then also the match will be abandoned.',
        color: '#000000',
      },
      {
        title:
          '3. *There will be 3 sets in the match. There are 3 games in 1 set.',
        color: '#000000',
      },
      {
        title:
          '4. *In the match, within any set, there are 3-3 games between the two players (level game) till a tie break of 5 points is played, according to which the one who gets 2 points more than the difference of points will win the set.',
        color: '#000000',
      },
    ],
  },
]
