export const endPoints = {
  login: 'login',
  logout: 'logout',
  send_otp: 'send-otp',
  verify_otp: 'verify-otp',
  register: 'client',
  forgetPasswordOtp: 'otp/sendotp',
  reset_password: 'client/resetpass',
  change_password: 'client/changepass',
  withdraw: 'withdrawals/withdrawal-transaction',
  deposit: 'deposits/deposit-transaction',
  withdraw_password_change: 'withdrawals/resetpass',
  withdraw_add_account: 'withdrawals/client-bank-account',
  withdraw_set_pass: 'withdrawals/setpass',
  deposit_bank: 'bank-account',
  withdraw_cancel: 'withdrawals/withdrawal-transaction/cancel',
  client: 'client',
  casino: 'casino',
  profile: 'client/profile',
  unsettledBets: 'client/bets?settled=false',
  accountStatement: 'client/account-statement',
  profitLoss: 'client/account-statement?profitLoss=true',
  sports: 'sports',
  casinoProvider: 'casino/provider',
}
