import React from 'react'
import Layout from '../Layout'
import SportsBook from '../pages/SportsBook/SportsBook'
import { ThemeProvider } from '../context/themeContext'
import AccountsStatement from '../pages/AccountsStatement/AccountsStatement'
import ProfitLoss from '../pages/ProfitLoss/ProfitLoss'
import UnsettledBets from '../pages/UnsettledBets/UnsettledBets'
import Casino from '../pages/Casino/Casino'
import CasinoDetails from '../pages/CasioDetails/CasinoDetails'
import ChangePassword from '../pages/ChangePassword/ChangePassword'
import HomePage from '../pages/HomePage'
import ICasino from '../pages/ICasino/ICasino'
import LoginPage from '../pages/LoginPage'
import ProfilePage from '../pages/ProfilePage/ProfilePage'
import StakeSettings from '../pages/StakeSettings/StakeSettings'
import SportsDetails from '../pages/SportsDetails/SportsDetails'
import DepositPage from '../pages/Deposit/DepositPage'
import WithdrawPage from '../pages/Withdraw/WithdrawPage'

export const componentRoutes = {
  SIGNUP: '/signup',
  HOME: '/',
  Profile: '/profile',
  AccountStatement: '/reports/account-statement',
  StakeSettings: '/profile/stake-settings',
  ProfitLoss: '/reports/profit-loss',
  UnsettledBets: '/reports/unsettled-bets',
  ChangePassword: '/profile/change-password',
  Casino: '/sports/99999',
  Inplay: '/inplay',
  Cricket: '/sports/4',
  Football: '/sports/1',
  Tennis: '/sports/2',
  Politics: '/sports/2378961',
  HorseRacing: '/sports/7',
  HomeSports: '/',
  GreyRacing: '/sports/4339',
  Kabaddi: '/sports/99994',
  ICasino: '/sports/99998',
  Binary: '/sports/99990',
  CasinoDetails: '/casino-detail/:id',
  SportsBook: '/sports_book',
  SportsDetails: '/sports/detail/:id',
  deposit: '/deposit',
  widthdraw: '/withdraw',
}

export const PageComponent = {
  HomePage: <HomePage />,
  SignupPage: <LoginPage />,
  ProfilePage: (
    <Layout>
      <ProfilePage />
    </Layout>
  ),
  AccountsStatementPage: <AccountsStatement />,
  ChangePasswordPage: <ChangePassword />,
  ProfitLossPage: <ProfitLoss />,
  StakeSettingsPage: <StakeSettings />,
  UnsettledBetsPage: <UnsettledBets />,
  Casino: <Casino />,
  HomeSports: <HomePage />,
  Inplay: <HomePage />,
  Cricket: <HomePage />,
  Football: <HomePage />,
  Tennis: <HomePage />,
  Politics: <HomePage />,
  HorseRacing: <HomePage />,
  GreyRacing: <HomePage />,
  Kabaddi: <HomePage />,
  ICasino: <ICasino />,
  Binary: <HomePage />,
  CasinoDetails: <CasinoDetails />,
  SportsBook: <SportsBook />,
  SportsDetails: <SportsDetails />,
  widthdraw: (
    <Layout>
      <WithdrawPage />
    </Layout>
  ),
  deposit: (
    <Layout>
      <DepositPage />
    </Layout>
  ),
}
