import { Constants } from '../constants'
import * as Styled from '../styles'

const GameCard = ({ gameImage, gameName, gameParticipant, toggle }) => {
  return (
    <Styled.CardWrapper className="col-lg-6 col-md-12">
      <Styled.Image src={gameImage} alt="gameImg" />
      <Styled.Description toggle={toggle}>
        <Styled.GameNameSpan>{gameName}</Styled.GameNameSpan>
        <Styled.MemberURL>
          <Styled.IconImage src={Constants.memberIconUrl} alt="member-icon" />
          <Styled.GameNameSpan>{gameParticipant}</Styled.GameNameSpan>
        </Styled.MemberURL>
      </Styled.Description>
    </Styled.CardWrapper>
  )
}

export default GameCard
