import ACTIONTYPES from './actionTypes'

const initialState = {
  data: [],
  error: '',
  otp: null,
  account: [],
  isShow: localStorage.getItem('isFirstTime') || true,
}

const widthdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONTYPES.WITHDRAW_SUCCESS:
      return {
        ...state,
      }
    case ACTIONTYPES.GET_WITHDRAW_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case ACTIONTYPES.OTP_SENT_SUCCESS:
      return {
        ...state,
        otp: action.payload,
      }
    case ACTIONTYPES.OTP_SENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ACTIONTYPES.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
      }
    case ACTIONTYPES.ADD_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ACTIONTYPES.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload,
      }
    case ACTIONTYPES.GET_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ACTIONTYPES.WITHDRAW_MODAL_SHOW_SUCCESS:
      return {
        ...state,
        isShow: false,
      }
    case ACTIONTYPES.WITHDRAW_MODAL_SHOW_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ACTIONTYPES.DELETE_ACCOUNT_CANCEL_SUCCESS:
      return {
        ...state,
        // error: action.payload,
      }
    case ACTIONTYPES.DELETE_ACCOUNT_CANCEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default widthdrawReducer
