import React, { useEffect, useState } from 'react'
import * as Styled from './style'
import { color } from '../../color/color'
import PlaceBetBox from '../../components/PlaceBetBox/PlaceBetBox'
import { _constants } from './constants'
import { constants } from '../../utils/constant'
import { formatNumber, handleAccessOfOdds } from '../../utils/utils'
import { useSelector } from 'react-redux'
import ModalCont from '../Modal/Modal'

const MatchList = ({
  item,
  key,
  handleBetSection,
  handleCloseBetSection,
  currentSportsId,
  showBetSection,
  maxBet,
  minBet,
  backlayAmount,
  bgColor,
  bet_delay,
  odd_type,
  teamName,
  strValue,
  oddStr,
  placebetStatus = true,
}) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const isDemoLogin = localStorage.getItem('isDemoLogin')
  const [showLogin, setShowLogin] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const getLoginState = () => {
    if (isAuthenticated) {
      return false
    } else if (isDemoLogin) {
      return false
    }
    return true
  }

  const handleCloseModal = () => {
    setShowLogin(false)
  }
  const handleWindowSizeChange = () => {
    window.innerWidth < 1200 ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const handleLoginModal = () => {
    if (getLoginState()) {
      setShowLogin(true)
    } else {
      return () => {}
    }
  }

  const BackLay = ({ backPrice1, backPrice2, layPrice1, layPrice2, item }) => {
    return (
      <Styled.BackLay
        placebetStatus={handleAccessOfOdds(
          backPrice1,
          layPrice1,
          item.mtype,
          placebetStatus,
        )}
      >
        <Styled.BlBox
          _color={color._C7EEFF}
          placebetStatus={handleAccessOfOdds(
            backPrice1,
            layPrice1,
            item.mtype,
            placebetStatus,
          )}
        >
          <Styled.Span
            placebetStatus={handleAccessOfOdds(
              backPrice1,
              layPrice1,
              item.mtype,
              placebetStatus,
            )}
          >
            {backPrice1 || '-'}
          </Styled.Span>
        </Styled.BlBox>
        <Styled.BlBox
          placebetStatus={handleAccessOfOdds(
            backPrice2,
            layPrice2,
            item.mtype,
            placebetStatus,
          )}
          _color={color._C7EEFF}
        >
          <Styled.Span
            placebetStatus={handleAccessOfOdds(
              backPrice2,
              layPrice2,
              item.mtype,
              placebetStatus,
            )}
          >
            {backPrice2 || '-'}
          </Styled.Span>
        </Styled.BlBox>
      </Styled.BackLay>
    )
  }

  const BetBackBox = ({
    item,
    backPrice1,
    backPrice2,
    layPrice1,
    layPrice2,
  }) => {
    return (
      <Styled.BlBox
        placebetStatus={handleAccessOfOdds(
          backPrice1,
          layPrice1,
          item.mtype,
          placebetStatus,
        )}
        onClick={
          // handleAccessOfOdds(backPrice1, layPrice1, item.mtype, placebetStatus)
          isAuthenticated
            ? () =>
                handleBetSection(
                  backPrice1,
                  item.sportsId || item.selection_id,
                  minBet,
                  maxBet,
                  false,
                  false,
                  bet_delay,
                  odd_type,
                  teamName,
                  strValue,
                  item?.back_volume,
                )
            : () => handleLoginModal()
        }
      >
        <Styled.Span
          placebetStatus={handleAccessOfOdds(
            backPrice2,
            layPrice2,
            item.mtype,
            placebetStatus,
          )}
        >
          {backPrice1}
          <Styled.Em
            placebetStatus={handleAccessOfOdds(
              backPrice2,
              item?.teamData.lay2,
              item.mtype,
              placebetStatus,
            )}
          >
            {' '}
            {/* {formatNumber(backPrice2) || '0.0'} */}
          </Styled.Em>
        </Styled.Span>
      </Styled.BlBox>
    )
  }

  const BetLayBox = ({
    item,
    backPrice1,
    backPrice2,
    layPrice1,
    layPrice2,
  }) => {
    return (
      <Styled.BlBoxLay
        placebetStatus={handleAccessOfOdds(
          item?.teamData?.back2,
          item?.lay_price,
          item.mtype,
          placebetStatus,
        )}
        _color={color._F9C8D3}
        onClick={
          isAuthenticated
            ? () =>
                handleBetSection(
                  item?.lay_price,
                  item.sportsId || item.selection_id,
                  minBet,
                  maxBet,
                  false,
                  true,
                  bet_delay,
                  odd_type,
                  teamName,
                  strValue,
                  item?.lay_volume,
                )
            : () => handleLoginModal()
        }
      >
        <Styled.Span
          placebetStatus={handleAccessOfOdds(
            item?.teamData?.back2,
            layPrice2,
            item.mtype,
            placebetStatus,
          )}
          onClick={
            // handleAccessOfOdds(
            //   item?.teamData?.back2,
            //   item?.teamData?.lay2,
            //   item.mtype,
            //   placebetStatus,
            // )
            isAuthenticated
              ? () =>
                  handleBetSection(
                    item?.lay_price,
                    item.sportsId || item.selection_id,
                    minBet,
                    maxBet,
                    false,
                    true,
                    bet_delay,
                    odd_type,
                    teamName,
                    strValue,
                    item?.lay_volume,
                  )
              : () => handleLoginModal()
          }
        >
          {layPrice1}
        </Styled.Span>
      </Styled.BlBoxLay>
    )
  }

  const BackLayBetContainer = ({
    item,
    backPrice1,
    layPrice1,
    backPrice2,
    layPrice2,
  }) => {
    return (
      <Styled.BackLay
        placebetStatus={handleAccessOfOdds(
          backPrice1,
          layPrice1,
          item.mtype,
          placebetStatus,
        )}
      >
        <BetBackBox
          item={item}
          backPrice1={backPrice1}
          backPrice2={backPrice2}
          layPrice1={layPrice1}
          layPrice2={layPrice2}
        />
        <BetLayBox
          item={item}
          backPrice1={backPrice1}
          backPrice2={backPrice2}
          layPrice1={layPrice1}
          layPrice2={layPrice2}
        />
      </Styled.BackLay>
    )
  }

  const RowData = ({ name, backPrice1, layPrice1, backPrice2, layPrice2 }) => {
    return (
      <Styled.BetTableRow>
        <Styled.Row>
          <Styled.ColMd6>
            <Styled.GameBox>
              <Styled.GameLeftColumn>
                <Styled.GameName>
                  <Styled.GameNameAnchor>
                    <Styled.TeamName>{name}</Styled.TeamName>
                  </Styled.GameNameAnchor>
                </Styled.GameName>
                <Styled.GameDateInplay></Styled.GameDateInplay>
              </Styled.GameLeftColumn>
            </Styled.GameBox>
          </Styled.ColMd6>
          <Styled.ColMd6>
            {backPrice1 == constants.Back_price ||
            layPrice1 == constants.Back_price ? (
              <Styled.Suspended>{_constants.Suspended}</Styled.Suspended>
            ) : (
              <></>
            )}
            <Styled.Row>
              <Styled.Col4>
                <BackLay
                  backPrice1={item?.back1 ?? '-'}
                  backPrice2={item?.back2 ?? ''}
                  layPrice1={item?.lay1 ?? '-'}
                  layPrice2={item?.lay2 ?? '-'}
                  item={item}
                />
              </Styled.Col4>
              <Styled.Col4>
                <BackLayBetContainer
                  backPrice1={backPrice1}
                  backPrice2={backPrice2}
                  layPrice1={layPrice1}
                  layPrice2={layPrice2}
                  item={item}
                />
              </Styled.Col4>
              <Styled.Col4>
                <BackLay
                  backPrice1={item?.back1 ?? '-'}
                  backPrice2={item?.back2 ?? ''}
                  layPrice1={item?.lay1 ?? '-'}
                  layPrice2={item?.lay2 ?? '-'}
                  item={item}
                />
              </Styled.Col4>
            </Styled.Row>
          </Styled.ColMd6>
        </Styled.Row>
      </Styled.BetTableRow>
    )
  }

  return (
    <>
      <ModalCont show={showLogin} handleClose={handleCloseModal} />

      <Styled.DropdownLink key={key}>
        <Styled.Box>
          <Styled.Box onClick={() => {}}>
            {item.loop.map((elm) => {
              return (
                <RowData
                  name={elm?.full_name}
                  backPrice1={elm?.back}
                  layPrice1={elm?.lay}
                  backPrice2={elm?.back2 ?? '-'}
                  layPrice2={elm?.lay2 ?? '-'}
                />
              )
            })}
            {showBetSection ? (
              <Styled.Col2 className='className="col-md-12 col-lg-8"'>
                <PlaceBetBox
                  backlayAmount={backlayAmount}
                  handleCloseBetSection={handleCloseBetSection}
                  bgColor={bgColor}
                  max_bet={maxBet}
                  min_bet={minBet}
                  bet_delay={bet_delay}
                  odd_type={odd_type}
                  teamName={teamName}
                  oddStr={oddStr}
                />
              </Styled.Col2>
            ) : (
              <></>
            )}
          </Styled.Box>
        </Styled.Box>
      </Styled.DropdownLink>
    </>
  )
}

export default MatchList
