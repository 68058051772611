import { combineReducers } from 'redux'
import authReducer from './auth/reducer'
import matchReducer from './match/reducer'
import bookMakerReducer from './bookmaker/reducer'
import fancyReducer from './fancy/reducer'
import widthdrawReducer from './widthdraw/reducer'
import DepositReducer from './deposit/reducer'
import loadingReducer from './loading/reducer'
import casinoReducer from './casino/reducer'
import matchOddReducer from './matchOdds/reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  match: matchReducer,
  bookmaker: bookMakerReducer,
  fancy: fancyReducer,
  withdraw: widthdrawReducer,
  deposit: DepositReducer,
  loading: loadingReducer,
  casino: casinoReducer,
  matchOdd: matchOddReducer,
})

export default rootReducer
