import React from 'react'
import * as Styled from './style'
import { item } from './constants'
const TransactionCard = () => {
  return (
    <>
      <Styled.Container id="transaction-card">
        {item.map((item) => {
          return (
            <Styled.Anchor
              color={item.color}
              key={item.name + item.id}
              to={item.link}
            >
              <Styled.Img src={item.image} alt="transaction-card" />
              <Styled.Span>{item.name}</Styled.Span>
            </Styled.Anchor>
          )
        })}
      </Styled.Container>
    </>
  )
}

export default TransactionCard
