import React from 'react'
import { Box } from '../styles'
import TabNav from './TabNav'
import TabContents from './TabContents'

const TabComponent = ({ activeTab, handleTabChange }) => {
    return (
      <Box flex={3}>
        <TabNav activeTab={activeTab} handleTabChange={handleTabChange} />
        <TabContents activeTab={activeTab} />
      </Box>
    )
}

export default TabComponent
