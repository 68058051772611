export const color = {
  _FFFFFF: '#FFFFFF',
  _0088CC: '#0088cc',
  _212529: '#212529',
  _B71E2D: '#b71e2d',
  _F1F1F1: '#f1f1f1',
  _CCCCCC: '#cccccc',
  _6D081D: '#6d081d',
  _E5CB63: '#e5cb63',
  _C10930: '#c10930',
  _FF0000: '#ff0000',
  _000000: '#000000',
  _012970: '#012970',
  _CED4DA: '#ced4da',
  _343435: '#343435',
  _EEEEEE: '#eeeeee',
  _AAB7CF: '#aab7cf',
  _808080: '#808080',
  _F6F9FF: '#f6f9ff',
  _FFF6EE: '#fff6ee',
  _04457b: '#04457b',
  _F9F9F9: '#f9f9f9',
  _FA7E29: '#fa7e29',
  _F6682F: '#f6682f',
  _9F0101: '#9f0101',
  _333333: '#333333',
  _E9EFF8: '#e9eff8',
  _00AD6F: '#00ad6f',
  _DFE8E9: '#dfe8e9',
  _EBEEF4: '#EBEEF4',
  _959595: '#959595',
  _333: '#333',
  _dfdde0: '#dfdde0',
  _dfe8e9: '#dfe8e9',
  _DEE2E6: '#dee2e6',
  _fff: '#fff',
  _000: '#000',
  _1E1E1E: '#1e1e1e',
  _F8F8F8: '#f8f8f8',
  _DBEDFF: '#dbedff',
  _838383: '#838383',
  _444444: '#444',
  _03B37F: '#03b37f',
  _7F7F7F: '#7f7f7f',
  _F7F7F7: '#f7f7f7',
  _66D1FF: '#66d1ff',
  _FFB4C5: '#ffb4c5',
  _222: '#222',
  _8B0000: '#8b0000',
  _DC3545: '#dc3545',
  _C7EEFF: '#c7eeff',
  _F9C8D3: '#f9c8d3',
  _EFE1E5: '#efe1e5',
  _CF8D2E: '#cf8d2e',
  _1C1C1C: '#1c1c1c',
  _116257: '#116257',
  _d6d6d6: '#d6d6d6',
  _B97F2B: '#b97f2b',
  _024F99: '#024f99',
  _F96F72: '#f96f72',
  _008000: '#008000',
  _005098: '#005098',
  _F1E806: '#f1e806',
  _07Af42: '#07af42',
  _181818: '#181818',
  _E6E6E6: '#e6e6e6',
  _605C5C: '#605c5c',
  _171717: '#171717',
  _272827FE: '#272827fe',
  _FFFFFFF2: '#fffffff2',
  _484444: '#484444',
  _191919: '#191919',
  _F2F2F2: '#f2f2f2',
  _804A47: '#804a47',
  _343534: '#343534',
  _4D4D4D: '#4d4d4d',
  _0F0F0F: '#0f0f0f',
  _72BBEF: '#72bbef',
  _FAA9BA: '#faa9ba',
  _717274: '#717274',
  _F2EDF4: '#f2edf4',
  _43444A: '#43444a',
  _94DFFF: '#94dfff',
  _006400: '#006400',
  _F3F2F6: '#f3f2f6',
  _6F7174: '#6f7174',
  _6C757D: '#6c757d',
  _5A6268: '#5a6268',
  _545B62: '#545b62',
  _DDDDDD: '#dddddd',
  _495057: '#495057',
  _008d05: '#008d05',
  _B80000: '#b80000',
  _F44336: '#f44336',
  _C7C8cA: '#c7c8ca',
  _2C3E50: '#2c3e50',
  _575757: '#575757',
  _opacityBg: 'rgba(0, 0, 0, 0.71)',
  _E5E7EB: '#e5e7eb',
  _C7C8CA: '#c7c8ca',
  _373636D6: '#373636d6',
  _2F2F2F: '#2f2f2f',
  _D4F0FF: '#d4f0ff',
  _FFE3E3: '#ffe3e3',
  _086F3F: '#086f3f',
  _08C: '#08c',
  _E22739: '#e22739',
  _C8C8C8: '#c8c8c8',
  _ECC116: '#ecc116',
  _F8F9FA: '#f8f9fa',
  _343A40: '#343a40',
  _007BFF: '#007bff',
}
