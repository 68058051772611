import React, { useContext, useEffect, useState } from 'react'
import * as Styled from './styles'
import { ThemeContext } from '../../context/themeContext'
import { CardData, Constants, buttonData } from './constants'
import GameCard from './CasinoDetailsUtil/GameCard'
import ButtonGroup from './CasinoDetailsUtil/ButtonGroup'
import Search from './CasinoDetailsUtil/Search'
import MiddleContent from './CasinoDetailsUtil/MiddleContent'
import RulesModal from './CasinoDetailsUtil/RulesModal'
import NormalView from './CasinoDetailsUtil/NormalView'
import ClassicView from './CasinoDetailsUtil/ClassicView'
import TopUserInfo from './CasinoDetailsUtil/TopUserInfo'
import BottomUserInfo from './CasinoDetailsUtil/BottomUserInfo'
import AndarBahar from './AndarBahar/AndarBahar'
import Footer from './CasinoDetailsUtil/Footer'
import Baccarat from './Baccarat/Baccarat'
import CardBaccarat from './CardBaccarat/CardBaccarat'
import BaccaratOneDay from './BaccaratOneDay/BaccaratOneDay'
import DragonTigerLion from './DragonTigerLion/DragonTigerLion'
import Poker2020 from './Poker2020/Poker2020'
import PokerOneDay from './PokerOneDay/PokerOneDay'
import Cards32 from './Cards32/Cards32'
import AmarAkbarAnthony from './AmarAkbarAnthony/AmarAkbarAnthony'
import WorliMatka from './WorliMatka/WorliMatka'
import OneCardMeter from './OneCardMeter/OneCardMeter'
import CasinoMeter from './CasinoMeter/CasinoMeter'
import RaceTo17 from './RaceTo17/RaceTo17'
import { useLocation, useNavigate } from 'react-router-dom'
import TeenPattiOneDay from './TeenPattiOneDay/TeenPattiOneDay'
import TestTeenPatti from './TestTeenPatti/TestTeenPatti'
import SecondCardOneDayTeenPatti from './2CardOneDayTeenPatti/2CardOneDayTeenPatti'
import TheTrap from './TheTrap/TheTrap'
import Lucky7 from './Lucky7/Lucky7'
import SecondCardTeenPatti from './2CardTeenPatti/2CardTeenPatti'
import MuflisTeenPatti from './MuflisTeenPatti/MuflisTeenPatti'
import TeenPatti2020 from './TeenPatti2020/TeenPatti2020'
import DragonTiger from './DragonTiger/DragonTiger'
import DayDragonTiger from './1DayDragonTiger/1DayDragonTiger'
import OpenTeenPatti from './OpenTeenPatti/OpenTeenPatti'
import { io } from 'socket.io-client'

export default function CasinoDetail() {
  const { toggle, toggleFunction } = useContext(ThemeContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [switchValue, setSwitchValue] = useState(false)
  const navigate = useNavigate()

  const location = useLocation()

  const { id, name } = location.state
  const handleModal = () => {
    setIsModalOpen((prev) => !prev)
  }

  const event_id = location?.pathname?.split('/')?.[2]
  useEffect(() => {
    const casinoSocket = io('wss://cards.royal777.io/', {
      transports: ['websocket'],
    })

    console.log(casinoSocket, 'casinoSocket')

    // const event_id = '4444100002041024'

    casinoSocket.emit('event', event_id)

    casinoSocket.on(`match_odds_${event_id}`, (data) =>
      console.log(data, 'DATA CASINO'),
    )
  }, [location?.pathname])

  const handleSwitchChange = (event) => {
    setSwitchValue(event.target.checked)
  }
  function renderFunction(key) {
    console.log(key, 'KEY')
    switch (key) {
      case 'Andar Bahar':
        return <AndarBahar />
      case 'Baccarat':
        return <Baccarat />
      case '29 Baccarat':
        return <CardBaccarat />
      case 'Baccarat One Day':
        return <BaccaratOneDay />
      case 'Dragon Tiger lion':
        return <DragonTigerLion />
      case '20-20 Poke':
        return <Poker2020 />
      case '32_CARDS':
        return <Cards32 />
      case 'Amar Akbar Anthony':
        return <AmarAkbarAnthony />
      case 'Poker 1-Day':
        return <PokerOneDay />
      case 'Worli Matka':
        return <WorliMatka />
      case 'Trap':
        return <TheTrap />
      case '1 Card Meter':
        return <OneCardMeter />
      case 'Casino Meter':
        return <CasinoMeter />
      case 'Race To 17':
        return <RaceTo17 />
      case 'One Day Teenpatti':
        return <TeenPattiOneDay />
      case 'Test Teen Patti':
        return <TestTeenPatti />
      case 'Open Teenpatti':
        return <OpenTeenPatti />
      case '2 Cards Teenpatti':
        return <SecondCardTeenPatti />
      case 'Muflis Teen Patti 1 Day':
        return <MuflisTeenPatti />
      case 'Teenpatti 20-20':
        return <TeenPatti2020 />
      case '2 Cards Teenpatti 1 Day':
        return <SecondCardOneDayTeenPatti />
      case 'Lucky 7':
        return <Lucky7 />
      case 'Dragon Tiger':
        return <DragonTiger />
      case '1 Day Dragon Tiger':
        return <DayDragonTiger />
      default:
        break
    }
  }
  const handleBack = () => {
    navigate(-1)
  }

  return (
    <Styled.WrapperContainer toggle={toggle}>
      <Styled.HeaderContainer>
        <Styled.BackButtonContainer>
          <Styled.Button onClick={() => handleBack()}>
            {Constants.backButtonText}
          </Styled.Button>
        </Styled.BackButtonContainer>
        <Styled.UserName>{Constants.demoUsername}</Styled.UserName>
      </Styled.HeaderContainer>
      <Styled.Container className="px-3">
        <div className="row">
          <Styled.LeftColContainer className="col-md-2 pt-2">
            <Styled.LeftContainer toggle={toggle} className="p-1">
              <Search toggle={true} />
              <div className="row">
                <div className="col-md-12 mt-4">
                  {CardData.length &&
                    CardData.map((card, index) => (
                      <div className="col-md-12" key={index + card.gameName}>
                        <GameCard
                          gameImage={card.gameImage}
                          gameName={card.gameName}
                          gameParticipant={card.gameParticipant}
                          toggle={toggle}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </Styled.LeftContainer>
          </Styled.LeftColContainer>
          <Styled.MidColContainer className="col-md-7 pt-2">
            <Styled.MiddleContainer>
              <MiddleContent
                handleModal={handleModal}
                toggle={toggle}
                gameName={name}
                roundId={'YDTL1240806093228'}
                handlePlayerHistory={() => {}}
                event_id={event_id}
                images={[
                  {
                    src: 'https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png',
                  },
                  {
                    src: 'https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png',
                  },
                  {
                    src: 'https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png',
                  },
                  {
                    src: 'https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png',
                  },
                  {
                    src: 'https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png',
                  },
                  {
                    src: 'https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png',
                  },
                ]}
              />
              {isModalOpen && (
                <RulesModal
                  isModalOpen={isModalOpen}
                  handleModal={handleModal}
                />
              )}

              {renderFunction(name)}
              <Footer compCheck={id} />
            </Styled.MiddleContainer>
          </Styled.MidColContainer>
          <Styled.RightColContainer className="col-md-3">
            <Styled.RightContainer>
              <TopUserInfo toggleFunction={toggleFunction} />
              <BottomUserInfo />
            </Styled.RightContainer>
          </Styled.RightColContainer>
        </div>
      </Styled.Container>
    </Styled.WrapperContainer>
  )
}
