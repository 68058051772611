import React, { useEffect, useRef, useState } from 'react'
import { Table } from 'react-bootstrap'
import { color } from '../../color/color'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaWhatsapp } from 'react-icons/fa'
import { FaCopy } from 'react-icons/fa6'
import { GoDash } from 'react-icons/go'
import Gpay from './Images/googlepay.png'
import Phonepe from './Images/phonepe.png'
import USDT from './Images/usdt-icon.png'
import Bank from './Images/bank-account.png'
import UPI from './Images/upi-icon.png'
import WhatsappIcon from './Images/whatsapp-icon.png'
import UTRImage from './Images/utr-image.png'

import {
  Button,
  PaymentsContainer,
  PaymentBoxs,
  Card,
  CardBody,
  CardTitle,
  AccountData,
  CopyButton,
  CardText,
  LinkCard,
  LinkCardBody,
  GreenContainer,
  Bold,
  IconSpan,
  GreenWrapper,
  PaymentForm,
  FormBody,
  FormFooter,
  FormFooterButton,
  FormFooterGroup,
  PermissionCheckbox,
  CopyToolTip,
  Wrapper,
  BackButton,
  ContentWrapper,
  LeftSection,
  InputWrapper,
  AmountSpan,
  RightSection,
  InfoWrapper,
  SubmitButton,
  Input,
  InputContainer,
  SportsTitle,
  Image,
  BottomSection,
  LoadingOverlay,
  EllipsisTableData,
  NoRecords,
} from './style'
import { useDispatch, useSelector } from 'react-redux'
import {
  depositAmount,
  getDepositBanks,
  getDepositDetails,
} from '../../redux/deposit/action'
import PolicyModal from '../../components/PolicyModal/PolicyModal'
import moment from 'moment'
import { fetchUserDetails } from '../../redux/auth/action'

const BankAccount = ({
  bankAccountsArr,
  handlePaymentMethod,
  activePaymentMethod,
}) => {
  return (
    <>
      {bankAccountsArr &&
        bankAccountsArr.map((item, index) => {
          let paymentBoxContent
          let iconSrc
          switch (item.account_type) {
            case 'Bank Account':
              paymentBoxContent = 'Account'
              iconSrc = Bank
              break
            case 'Gpay':
              paymentBoxContent = 'Gpay' // this has to get changed, will be based on cases(account type)
              iconSrc = Gpay
              break
            case 'phonepe':
              paymentBoxContent = 'Phonepe'
              iconSrc = Phonepe
              break
            case 'usdt':
              paymentBoxContent = 'USDT'
              iconSrc = USDT
              break
            case 'UPI':
              paymentBoxContent = 'UPI'
              iconSrc = UPI
              break
            default:
              return null
          }
          return (
            <PaymentBoxs
              key={item.id}
              isactive={activePaymentMethod === item.id}
              onClick={() => handlePaymentMethod(item.id)}
            >
              <span>{paymentBoxContent}</span>
              <img src={iconSrc} alt={paymentBoxContent} />
            </PaymentBoxs>
          )
        })}
    </>
  )
}

export default function DepositPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const deposit = useSelector((state) => state.deposit.data)
  const bankAccounts = useSelector((state) => state.deposit.bankAccount)
  const loaderVal = useSelector((state) => state.deposit.isLoader)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  const [amount, setAmount] = useState()
  const [showPaymentSection, setShowPaymentSection] = useState(false)
  const [activePaymentMethod, setActivePaymentMethod] = useState()
  const [utrNumber, setUtrNumber] = useState(null)
  const [selectedFile, setSelectedFile] = useState('')
  const [base64String, setBase64String] = useState('')
  const [paymentDetails, setPaymentDetails] = useState(deposit)
  const [showTooltip, setShowTooltip] = useState({})
  const [depositdata, setDepositdata] = useState([])
  const [showPolicyModal, setShowPolicyModal] = useState(false)
  const [bankAccountsArr, setBankAccountArr] = useState([])
  const [checkboxState, setCheckboxState] = useState({
    termsCondition: false,
  })
  const [loading, setLoading] = useState(false)
  const [noRecords, setNoRecords] = useState(null)

  useEffect(() => {
    if (deposit) {
      if (deposit?.deposits?.length) {
        const { deposits } = deposit
        const depositData = [...deposits]
        setDepositdata(depositData)
        setNoRecords(depositData?.length)
      } else {
        setDepositdata([])
      }
    }
  }, [deposit])
  const avoidRerender = useRef(false)

  useEffect(() => {
    if (!avoidRerender.current) {
      isAuthenticated && dispatch(fetchUserDetails())
    }
    return () => (avoidRerender.current = true)
  }, [])

  useEffect(() => {
    if (!avoidRerender.current) {
      dispatch(getDepositBanks())
    }
    return () => (avoidRerender.current = true)
  }, [])
  const fileInputRef = useRef(null)
  const loader = useSelector((state) => state.loading)

  const getStatus = (status) => {
    if (status === 0) {
      return 'Pending'
    } else if (status === 1) {
      return 'Approved'
    } else if (status === 2) {
      return 'Rejected'
    } else {
      return 'Unknown'
    }
  }
  const formattedArr =
    depositdata &&
    depositdata
      ?.sort((a, b) => {
        return parseInt(b.created) - parseInt(a.created)
      })
      ?.map((item) => {
        return {
          bankName: item?.bankName,
          accountNumber: item?.bankAccount?.account_number,
          ifsc: item?.bankAccount?.ifsc_code,
          accountName: item?.bankAccount?.account_name,
          minAmount: item?.minAmount,
          maxAmount: item?.maxAmount,
          paymentMethod: item?.paymentMethod,
          reason: item?.reason,
          file: item?.file || '',
          userId: localStorage.getItem('userId'),
          type: item?.type,
          amount: item?.amount || '',
          utrNumber: item?.utrNumber,
          status: getStatus(item.status),
          created: item?.created,
          qr_url: item?.qr_url,
          accountId: item?.id,
        }
      })
  useEffect(() => {
    const banks = bankAccounts
      ?.filter((i) => Number(i?.min_amount) <= Number(amount))
      .map((item) => {
        return {
          bankName: item?.bank_name,
          accountNumber: item?.account_number,
          ifsc: item?.ifsc_code,
          accountName: item?.account_name,
          minAmount: item?.min_amount,
          maxAmount: item?.max_amount,
          paymentMethod: item?.paymentMethod,
          qr_url: item?.qr_url,
          userId: localStorage.getItem('userId'),
          amount: amount?.toString() || '',
          status: getStatus(item.status),
          created: item?.created,
          id: item?.id,
          account_type: item?.account_type,
          accountId: item?.id,
        }
      })
    setBankAccountArr(banks)
  }, [amount])

  const handleBack = () => {
    navigate(-1)
  }
  const handleAmount = (e) => {
    const inputVal = e.target.value
    if (inputVal && /^\d*\.?\d{0,2}$/.test(inputVal)) {
      setAmount(Number(inputVal))
    } else {
      setAmount()
    }
  }
  useEffect(() => {
    if (!avoidRerender.current) {
      dispatch(getDepositDetails())
    }
    return () => (avoidRerender.current = true)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (amount <= 0 || !amount) {
      toast.error('The Amount Field is required')
    } else {
      setShowPaymentSection(true)
      setSelectedFile(null)
      setUtrNumber(null)
    }
  }
  const handleBackPayment = () => {
    setShowPaymentSection(false)
    setAmount()
  }

  const handleChange = (e) => {
    const cleanedValue = e.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
    setUtrNumber(cleanedValue)
  }

  const getBankById = (id) => {
    return bankAccountsArr.find((item) => {
      return item.id === id
    })
  }
  const handlePaymentMethod = (id) => {
    setPaymentDetails(getBankById(id))
    setActivePaymentMethod(id)
    setSelectedFile('')
    setUtrNumber('')
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
    setCheckboxState({
      termsCondition: false,
    })
  }
  const redirectUrl = (url) => {
    window.open(url)
  }

  useEffect(() => {
    const data =
      bankAccountsArr.filter((i) => i.account_type === 'Bank Account')[0] ||
      bankAccountsArr.filter((i) => i.account_type === 'Gpay')[0] ||
      {}
    bankAccountsArr && setPaymentDetails(data)
    setActivePaymentMethod(data?.id)
  }, [bankAccountsArr])

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }
  const handleDeposit = async (e) => {
    e.preventDefault()
    if (!utrNumber || !selectedFile || !checkboxState?.termsCondition) {
      toast.error('Please fill in all required fields.')
      return
    }
    if (Object.keys(paymentDetails)?.length === 0) {
      toast.error('Choose one payment method')
      return
    }
    const saveDepositData = Object.assign(
      paymentDetails,
      getApiRequest(
        paymentDetails.account_type,
        checkboxState,
        utrNumber,
        base64String,
      ),
    )
    if (loader.isLoading) return
    setLoading(true)
    const apireq = {
      amount: paymentDetails?.amount,
      accountId: paymentDetails?.accountId,
      clientId: '',
      ...getApiRequest(
        paymentDetails?.account_type,
        checkboxState,
        utrNumber,
        base64String,
      ),
    }
    const token = localStorage.getItem('token')
    dispatch(depositAmount(apireq, token))
    setSelectedFile('')
    setUtrNumber('')
    setCheckboxState({
      termsCondition: false,
    })

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }
  useEffect(() => {
    setLoading(loaderVal)
  }, [loaderVal])

  const handleCopy = (copiedText, action) => {
    navigator.clipboard.writeText(copiedText)
    setShowTooltip({ ...showTooltip, [action]: true })
    setTimeout(() => {
      setShowTooltip({ ...showTooltip, [action]: false })
    }, 2000)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    if (file && file?.size > 2 * 1024 * 1024) {
      toast.error('Fize size should be less than 2MB')
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]
        setBase64String(base64String)
      }
      reader.readAsDataURL(file)
    }
  }

  function getApiRequest(account_type, checkboxState, utrNumber, base64String) {
    const commonData = {
      utrNumber: utrNumber,
      hasPolicyAgreed: checkboxState.termsCondition,
      reason: '',
      type: 'client',
      site: 'reddybookclub',
      qrCode: {
        name: selectedFile.name,
        contentType: selectedFile.type,
        data: base64String,
      },
    }

    switch (account_type) {
      case 'Bank Account':
        return {
          ...commonData,
          paymentMethod: 1,
        }
      case 'usdt':
        return {
          ...commonData,
          paymentMethod: 2,
        }
      case 'UPI':
        return {
          ...commonData,
          paymentMethod: 3,
        }
      case 'Gpay':
        return {
          ...commonData,
          paymentMethod: 3,
        }
      case 'phonepe':
        return {
          ...commonData,
          paymentMethod: 3,
        }
      case 'Paytm':
        return {
          ...commonData,
          paymentMethod: 3,
        }
      default:
        return {
          ...commonData,
          paymentMethod: 0,
        }
    }
  }

  const handlePolicyModal = () => {
    setShowPolicyModal(!showPolicyModal)
  }
  const getCreated = (data) => {
    return moment(parseInt(data, 10) / 1000).format('MM-DD-YY hh:mm:ss a')
  }

  return (
    <>
      <PolicyModal show={showPolicyModal} handleClose={handlePolicyModal} />
      <Wrapper>
        <BackButton onClick={handleBack}>Back</BackButton>
      </Wrapper>
      <ContentWrapper>
        <LeftSection>
          {!showPaymentSection ? (
            <InputWrapper style={{ marginLeft: '10px', marginRight: '10px' }}>
              <AmountSpan> Amount</AmountSpan>
              <InputContainer>
                <Input
                  placeholder="ENTER AMOUNT"
                  type="number"
                  onChange={(e) => handleAmount(e)}
                  value={amount}
                />
                <SubmitButton onClick={(e) => handleSubmit(e)}>
                  SUBMIT
                </SubmitButton>
              </InputContainer>
            </InputWrapper>
          ) : (
            <>
              <div
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: '100%',
                }}
              >
                <Button onClick={handleBackPayment}>Back</Button>
                <PaymentsContainer>
                  <PaymentBoxs
                    onClick={() =>
                      redirectUrl(
                        'https://api.whatsapp.com/send?phone=918989898989',
                      )
                    }
                  >
                    Whatsapp Deposit
                    <img src={WhatsappIcon} alt="whatsApp-icon" />
                  </PaymentBoxs>
                  <BankAccount
                    bankAccountsArr={bankAccountsArr}
                    handlePaymentMethod={handlePaymentMethod}
                    activePaymentMethod={activePaymentMethod}
                  />
                </PaymentsContainer>
              </div>
              <div
                className="row"
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
              >
                <div class="col-md-12">
                  <Card>
                    <CardBody>
                      {loading && (
                        <LoadingOverlay>
                          <span class="spinner"></span>
                        </LoadingOverlay>
                      )}
                      <>
                        <CardTitle>Bank Account</CardTitle>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row justify-content-center">
                              <div className="col-md-11">
                                {Object.keys(paymentDetails).length ? (
                                  <AccountData>
                                    {paymentDetails?.walletName && (
                                      <CardText>
                                        <b>Wallet Name</b>:{' '}
                                        {paymentDetails?.walletName}
                                        <CopyButton>
                                          <FaCopy
                                            color={color._C10930}
                                            onClick={() =>
                                              handleCopy(
                                                paymentDetails?.walletName,
                                                'walletName',
                                              )
                                            }
                                          />
                                        </CopyButton>
                                        {showTooltip['walletName'] && (
                                          <CopyToolTip>Copied!</CopyToolTip>
                                        )}
                                      </CardText>
                                    )}
                                    {paymentDetails?.walletAddress && (
                                      <CardText>
                                        <b>Wallet Address</b>:{' '}
                                        {paymentDetails?.walletAddress}
                                        <CopyButton>
                                          <FaCopy
                                            color={color._C10930}
                                            onClick={() =>
                                              handleCopy(
                                                paymentDetails?.walletAddress,
                                                'walletAddress',
                                              )
                                            }
                                          />
                                        </CopyButton>
                                        {showTooltip['walletAddress'] && (
                                          <CopyToolTip>Copied!</CopyToolTip>
                                        )}
                                      </CardText>
                                    )}
                                    {paymentDetails?.bankName && (
                                      <CardText>
                                        <b>Bank Name</b>:{' '}
                                        {paymentDetails?.bankName}
                                        <CopyButton>
                                          <FaCopy
                                            color={color._C10930}
                                            onClick={() =>
                                              handleCopy(
                                                paymentDetails?.bankName,
                                                'bankName',
                                              )
                                            }
                                          />
                                        </CopyButton>
                                        {showTooltip['bankName'] && (
                                          <CopyToolTip>Copied!</CopyToolTip>
                                        )}
                                      </CardText>
                                    )}
                                    {paymentDetails?.accountNumber && (
                                      <CardText>
                                        <b>A/C No</b>:{' '}
                                        {paymentDetails?.accountNumber}
                                        <CopyButton>
                                          {' '}
                                          <FaCopy
                                            color={color._C10930}
                                            onClick={() =>
                                              handleCopy(
                                                paymentDetails?.accountNumber,
                                                'accountNumber',
                                              )
                                            }
                                          />
                                        </CopyButton>
                                        {showTooltip['accountNumber'] && (
                                          <CopyToolTip>Copied!</CopyToolTip>
                                        )}
                                      </CardText>
                                    )}
                                    {paymentDetails?.ifscCode && (
                                      <CardText>
                                        <b>IFSC Code</b>:{' '}
                                        {paymentDetails?.ifscCode}
                                        <CopyButton>
                                          {' '}
                                          <FaCopy
                                            color={color._C10930}
                                            onClick={() =>
                                              handleCopy(
                                                paymentDetails?.ifscCode,
                                                'ifscCode',
                                              )
                                            }
                                          />
                                        </CopyButton>
                                        {showTooltip['ifscCode'] && (
                                          <CopyToolTip>Copied!</CopyToolTip>
                                        )}
                                      </CardText>
                                    )}
                                    {paymentDetails?.accountName && (
                                      <CardText>
                                        <b>Account Name</b>:
                                        {paymentDetails?.accountName}
                                        <CopyButton>
                                          {' '}
                                          <FaCopy
                                            color={color._C10930}
                                            onClick={() =>
                                              handleCopy(
                                                paymentDetails?.accountName,
                                                'accountName',
                                              )
                                            }
                                          />
                                        </CopyButton>
                                        {showTooltip['accountName'] && (
                                          <CopyToolTip>Copied!</CopyToolTip>
                                        )}
                                      </CardText>
                                    )}

                                    {paymentDetails?.ifsc && (
                                      <CardText>
                                        <b>IFSC Code</b>: {paymentDetails?.ifsc}
                                        <CopyButton>
                                          {' '}
                                          <FaCopy
                                            color={color._C10930}
                                            onClick={() =>
                                              navigator.clipboard.writeText(
                                                paymentDetails?.ifsc,
                                              )
                                            }
                                          />
                                        </CopyButton>
                                      </CardText>
                                    )}

                                    {paymentDetails?.minAmount && (
                                      <CardText>
                                        <b>Min Amount</b>:{' '}
                                        {Number(
                                          paymentDetails?.minAmount,
                                        )?.toFixed(0)}
                                      </CardText>
                                    )}
                                    {paymentDetails?.maxAmount && (
                                      <CardText>
                                        <b>Max Amount</b>:{' '}
                                        {Number(
                                          paymentDetails?.maxAmount,
                                        )?.toFixed(0)}
                                      </CardText>
                                    )}
                                    {paymentDetails?.qr_url && (
                                      <CardText>
                                        <img
                                          src={paymentDetails?.qr_url}
                                          alt="QR_CODE"
                                        />
                                      </CardText>
                                    )}
                                  </AccountData>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="col-md-11">
                                <LinkCard
                                  onClick={() =>
                                    redirectUrl('https://upitobank.com/')
                                  }
                                >
                                  <LinkCardBody>
                                    <p>
                                      <b>
                                        How To Transfer Upi To Bank
                                        <br />
                                        CLICK HERE WWW.UPITOBANK.COM
                                      </b>
                                    </p>
                                  </LinkCardBody>
                                </LinkCard>
                              </div>
                              <div className="col-md-11">
                                <GreenWrapper
                                  onClick={() =>
                                    redirectUrl(
                                      'https://api.whatsapp.com/send?phone=918989898989',
                                    )
                                  }
                                >
                                  <GreenContainer>
                                    <Bold>
                                      FOR PAYMENT RELATED ISSUES CLICK HERE
                                    </Bold>
                                    <IconSpan>
                                      <FaWhatsapp />
                                    </IconSpan>
                                  </GreenContainer>
                                </GreenWrapper>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <PaymentForm onSubmit={(e) => handleDeposit(e)}>
                              <FormBody>
                                <FormFooterGroup>
                                  <label>
                                    Unique Transaction Reference
                                    <small>*</small>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="8 to 20 Digit UTR Number"
                                    className="form-control"
                                    maxLength="20"
                                    minLength="8"
                                    onChange={(e) => handleChange(e)}
                                    required
                                    value={utrNumber}
                                  />
                                </FormFooterGroup>
                                <FormFooterGroup>
                                  <label>
                                    Upload Your Payment Proof
                                    <small>[Required]</small>
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    required
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                  />
                                </FormFooterGroup>
                                <FormFooterGroup>
                                  <label>
                                    Amount
                                    <small>*</small>
                                  </label>
                                  <input
                                    readOnly
                                    placeholder=""
                                    value={amount}
                                    className="form-control read-only"
                                  />
                                </FormFooterGroup>

                                <PermissionCheckbox>
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="termsCondition"
                                    id="termsCheck15298"
                                    checked={checkboxState.termsCondition}
                                    onChange={handleCheckboxChange}
                                    value={checkboxState}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="termsCheck15298"
                                  >
                                    I have read and agree with{' '}
                                    <a
                                      onClick={handlePolicyModal}
                                      className="terms-condition"
                                    >
                                      the terms of payment and withdrawal
                                      policy.
                                    </a>
                                  </label>
                                </PermissionCheckbox>
                              </FormBody>
                              <FormFooter>
                                <FormFooterButton className="btn btn-info">
                                  Submit
                                </FormFooterButton>
                              </FormFooter>
                            </PaymentForm>
                          </div>
                        </div>
                      </>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </>
          )}

          <InfoWrapper style={{ marginLeft: '10px', marginRight: '10px' }}>
            <ol>
              <li>
                1. Deposit money only in the below available accounts to get the
                fastest credits and avoid possible delays.
              </li>
              <li>
                2. Deposits made 45 minutes after the account removal from the
                site are valid & will be added to their wallets.
              </li>
              <li>
                3. Site is not responsible for money deposited to Old, Inactive
                or Closed accounts.
              </li>
              <li>
                4. After deposit, add your UTR and amount to receive balance.
              </li>
              <li>5. NEFT receiving time varies from 40 minutes to 2 hours.</li>
              <li>
                6. In case of account modification: payment valid for 1 hour
                after changing account details in deposit page.
              </li>
            </ol>
          </InfoWrapper>
        </LeftSection>
        <RightSection style={{ minHeight: noRecords === 0 ? '0px' : '180px' }}>
          <Table responsive>
            <thead>
              <tr>
                <th>TRANSACTION NO</th>
                <th>Amount</th>
                <th>Account</th>
                <th>Status</th>
                <th>Date</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {formattedArr?.length ? (
                formattedArr?.map((item, index) => (
                  <tr key={index}>
                    <EllipsisTableData>
                      <span className="withdraw-section">
                        {Number(item?.utrNumber) || (
                          <GoDash
                            style={{ background: 'white', color: 'black' }}
                          />
                        )}
                      </span>
                    </EllipsisTableData>
                    <EllipsisTableData>
                      <span className="withdraw-section">
                        {Number(item?.amount) || (
                          <GoDash
                            style={{ background: 'white', color: 'black' }}
                          />
                        )}
                      </span>
                    </EllipsisTableData>
                    <EllipsisTableData>
                      <span className="withdraw-section">
                        {item?.accountName || (
                          <GoDash
                            style={{ background: 'white', color: 'black' }}
                          />
                        )}
                      </span>
                    </EllipsisTableData>
                    <EllipsisTableData item={item}>
                      <span className="withdraw-status">
                        {' '}
                        {item?.status || (
                          <GoDash
                            style={{ background: 'white', color: 'black' }}
                          />
                        )}{' '}
                      </span>
                    </EllipsisTableData>
                    <EllipsisTableData>
                      <span className="withdraw-section">
                        {getCreated(item?.created) || (
                          <GoDash
                            style={{ background: 'white', color: 'black' }}
                          />
                        )}
                      </span>
                    </EllipsisTableData>
                    <EllipsisTableData>
                      <span className="withdraw-section">
                        {item?.reason || (
                          <GoDash
                            style={{ background: 'white', color: 'black' }}
                          />
                        )}
                      </span>
                    </EllipsisTableData>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </Table>
          {noRecords == 0 ? (
            <NoRecords
              style={{
                background: 'white',
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'sans-serif',
              }}
            >
              No Records
            </NoRecords>
          ) : (
            <></>
          )}
          <BottomSection>
            <SportsTitle>Please note the UTR number 👇</SportsTitle>
            <div
              style={{
                width: 'auto',
                height: 'auto',
                borderRadius: '10px',
                border: `2px solid ${color._C10930}`,
                background: ` ${color._C10930}`,
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                padding: '2px',
              }}
            >
              <Image src={UTRImage} style={{}} alt="demo-utr" />
            </div>
            <SportsTitle>PPHONEPAY TO BANK TRANSFER 👇</SportsTitle>
            <div className="mb-2">
              <Link to="https://www.appopener.ai/yt/7ku56ivmr">
                https://www.appopener.ai/yt/7ku56ivmr
              </Link>
            </div>
            <SportsTitle>GPAY TO BANK TRANSFER 👇</SportsTitle>
            <div className="mb-2">
              <Link to="https://www.appopener.ai/yt/7ku56ivmr">
                https://www.appopener.ai/yt/7ku56ivmr
              </Link>
            </div>
            <SportsTitle>PAYTM TO BANK TRANSFER 👇</SportsTitle>
            <div className="mb-2">
              <Link to="https://www.appopener.ai/yt/7ku56ivmr">
                https://www.appopener.ai/yt/7ku56ivmr
              </Link>
            </div>
          </BottomSection>
        </RightSection>
      </ContentWrapper>
    </>
  )
}
