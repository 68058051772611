export const cardData = {
  headers: ['Tiger', 'Lion', 'Dragon'],
  rows: [
    {
      label: 'Winner',
      values: [
        { value: '1.86', isSuspended: false },
        { value: '0', isSuspended: true },
        { value: '3.67', isSuspended: false },
      ],
    },
    {
      label: 'Pair',
      values: [
        { value: '2.34', isSuspended: false },
        { value: '0', isSuspended: false },
        { value: '4.46', isSuspended: false },
      ],
    },
    {
      label: 'Flush',
      values: [
        { value: '2.34', isSuspended: false },
        { value: '0', isSuspended: false },
        { value: '4.46', isSuspended: true },
      ],
    },
    {
      label: 'Straight',
      values: [
        { value: '2.34', isSuspended: false },
        { value: '0', isSuspended: false },
        { value: '4.46', isSuspended: true },
      ],
    },
    {
      label: 'Straight Flush',
      values: [
        { value: '2.34', isSuspended: true },
        { value: '0', isSuspended: false },
        { value: '4.46', isSuspended: false },
      ],
    },
    {
      label: 'Trio',
      values: [
        { value: '2.34', isSuspended: true },
        { value: '0', isSuspended: true },
        { value: '4.46', isSuspended: false },
      ],
    },
  ],
}
