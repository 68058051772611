import styled from 'styled-components'
import { color } from '../../color/color'

export const Subtabs = styled.div`
  display: flex;
  background-color: ${color._343435};
  color: ${color._fff};
  overflow-x: ${(props) => (props.isScroll ? 'scroll' : 'none')};
  text-wrap: nowrap;
  cursor: pointer;
  width: calc(100vw - 275px);
  @media (max-width: 776px) {
    width: 100%;
  }

  & div:hover {
    background: ${color._6D081D};
  }
`

export const Option = styled.div`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  padding: 6px 19px;
  border: none !important;
  border-radius: 0;
  margin: 0 !important;
  background-color: ${({ active }) => (active ? color._6D081D : null)};
`
