import React, { useEffect, useState } from 'react'
import * as Styled from './style'
import RulesList from './RulesList'

const SubRules = ({ item, index }) => {
  const isFirst = index === 0
  const [subnav, setSubnav] = useState(isFirst)

  useEffect(() => {
    if (isFirst && item.subNav && item.subNav.length > 0) {
      setSubnav(true)
    }

    return () => {
      setSubnav(false)
    }
  }, [isFirst])

  const showSubnav = () => setSubnav(!subnav)

  return (
    <>
      <Styled.SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          <Styled.SidebarLabel item={item}>{item.title}</Styled.SidebarLabel>
        </div>
      </Styled.SidebarLink>
      {subnav &&
        item.subNav.map((subItem, index) => {
          return <RulesList item={subItem} key={subItem.title + index} />
        })}
    </>
  )
}

export default SubRules
