import styled from 'styled-components'
import { color } from '../../color/color'
import { Link } from 'react-router-dom'
export const Container = styled.div`
  width: 100%;
  display: none;
  text-decoration: none;
  z-index: 2;
  color: ${color._FFFFFF};
  @media (max-width: 991px) {
    display: flex;
    position: sticky;
    top: 60px;
  }
`

export const Anchor = styled(Link)`
  background-color: ${({ color }) => color};
  width: 50%;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px;
  color: ${color._FFFFFF};
  margin: 0;
  text-decoration: none;
  font-family: 'Titillium Web', sans-serif;
`

export const Span = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
`

export const Img = styled.img`
  width: 18px;
  filter: invert(1);
  margin-right: 5px;
`
