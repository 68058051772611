import styled from 'styled-components'
import { color } from '../../color/color'

export const Button = styled.button`
  background: ${color._FFFFFF};
  color: ${color._000000};
  font-weight: 600;
  width: 100%;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 13px;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px ${color._FFFFFF};
  transition: 0.9s;
  letter-spacing: 1px;

  @media (min-width: 768px) and (max-width: 991px) {
    width: 190px;
    margin-left: 10%;
  }
`
