import styled from 'styled-components'
import { color } from '../../color/color'

export const SportsTitle = styled.span`
  &:before {
    content: '';
    background: linear-gradient(180deg, ${color._C10930} 0, ${color._C10930});
    clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
    width: 18px;
    height: calc(100% + 0px);
    position: absolute;
    right: -17px;
    top: 0;
  }
  background: ${color._C10930};
  text-transform: uppercase;
  position: relative;
  padding: 7px 12px;
  color: ${color._FFFFFF};
  display: inline-flex;
  width: 200px;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  font-family: 'Titillium Web', sans-serif;
  @media (max-width: 991px) {
    padding: 5px;
  }
`

export const Img = styled.img`
  max-width: 100%;
  height: auto;
  width: 20px;
  height: 20px;
  max-width: 20px;
  margin-right: 3px;
  height: auto;
  vertical-align: middle;
`

export const PagesHeader = styled.div``
