import ACTIONTYPES from './actionType'

export const getMatchData = (data) => {
  return {
    type: ACTIONTYPES.MATCH_DATA_FETCH_SUCCESS,
    payload: data ?? [],
  }
}

export const getMatchOddsData = (data) => {
  return {
    type: ACTIONTYPES.MATCH_DATA_ODD_FETCH_SUCCESS,
    payload: data ?? [],
  }
}

export const getMatchScoreData = (data) => {
  return {
    type: ACTIONTYPES.MATCH_DATA_SCORE_FETCH_SUCCESS,
    payload: data ?? [],
  }
}

export const getPlaceBetStatus = (data) => {
  return {
    type: ACTIONTYPES.PLACE_BET_FETCH_SUCCESS,
    payload: data ?? [],
  }
}

export const setMatchedBetsData = (data) => {
  return {
    type: ACTIONTYPES.MATCHED_BET_SUCCESS,
    payload: data ?? [],
  }
}
export const setFancyBetsData = (data) => {
  return {
    type: ACTIONTYPES.FANCY_BET_SUCCESS,
    payload: data ?? [],
  }
}
