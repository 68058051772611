export const _constants = {
  accountType: 'accountType',
  name: 'name',
  accountNumber: 'accountNumber',
  ifscCode: 'ifscCode',
  withdrawPassword: 'withdrawPassword',
  selectAccountType: 'Please select an account type',
  selectName: 'Please enter a name',
  selectAccountNumber: 'Please enter an account number',
  selectIfsc: 'Please enter an IFSC code',
  selectWithdraw: 'Please enter a withdraw password',
  EnterName: 'Enter Name',
  EnterACNumber: 'Enter A/C NUMBER',
  EnterIFSCCode: 'Enter IFSC Code',
  ENTERWITHDRAWPASSWORD: 'ENTER WITHDRAW PASSWORD',
  Close: 'Close',
  Save: 'Save',
  AddAccount: ' Add Account',
  ACCOUNTTYPE: 'ACCOUNT TYPE',
  BankAccount: 'Bank Account',
  Name: 'Name',
  WITHDRAWPASSWORD: 'WITHDRAW PASSWORD',
  IFSCCODE: 'IFSC CODE',
  ACNUMBER: 'A/C NUMBER',
  Select: 'Select Account Type',
}
