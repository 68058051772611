import React, { useState } from 'react'
import MatchHeader from '../../components/MatchHeader/MatchHeader'
import Score from '../../components/Score/Score'
import * as Styled from './style'

const ScoreCard = (props) => {
  const { name, matches, isPlay = false, odds } = props
  const [match, setmatch] = useState(matches)
  const handleActiveChange = (isactive) => {
    if (isactive) {
      const filteredMatches =
        matches?.length &&
        matches.filter((item) => {
          return item.isPlay == '1'
        })
      setmatch(filteredMatches)
    } else {
      setmatch(matches)
    }
  }

  const handleVirtualActiveChange = (isactive) => {
    if (isactive) {
      const filteredMatches =
        matches?.length &&
        matches.filter((item) => {
          return item.eventType == '44'
        })
      setmatch(filteredMatches)
    } else {
      setmatch(matches)
    }
  }

  return (
    // this component will return the whole card of Matches and display the
    //  live and virtual matches
    <Styled.Box>
      <MatchHeader
        name={name}
        handleActiveChange={handleActiveChange}
        handleVirtualActiveChange={handleVirtualActiveChange}
      />
      {match &&
        match?.map((item) => {
          if (isPlay) {
            return item.isPlay === '1' ? (
              <Score item={item} sportsName={name} odds={odds} />
            ) : (
              <></>
            )
          } else {
            return <Score item={item} sportsName={name} odds={odds} />
          }
        })}
    </Styled.Box>
  )
}

export default ScoreCard
