import React from 'react'
import * as Styled from './style'
import SVGMatchData from './SVGMatchData'
import NormalScoreCard from './NormalScoreCard'
import Statistics from './Statistics'
const MDScoreData = ({ selectedOption }) => {
  return (
    <Styled.MDScoreData>
      <SVGMatchData />
      {selectedOption === 'Scorecard' ? <NormalScoreCard /> : <Statistics />}
    </Styled.MDScoreData>
  )
}

export default MDScoreData
