import { Constants } from '../constants'
import * as Styled from '../styles'


const BottomUserInfo = () => (
  <Styled.BottomContainer>
    <Styled.CreditContainer>
      <Styled.CreditWrapper>
        <Styled.Image
          src={Constants.balanceIconUrl}
          alt={Constants.balanceIconUrl}
        />
        <Styled.CreditSpan>{Constants.availableCreditText}</Styled.CreditSpan>
      </Styled.CreditWrapper>
      <Styled.BalanceContainer>
        <Styled.Span>0</Styled.Span>
        <Styled.Span>0</Styled.Span>
      </Styled.BalanceContainer>
    </Styled.CreditContainer>
    <Styled.OpenBetContainer>
      <Styled.BetSpan>{Constants.openBetsText}</Styled.BetSpan>
      <Styled.Divider />
    </Styled.OpenBetContainer>
    <Styled.MarketContainer>
      <Styled.Span>{Constants.marketsText}</Styled.Span>
      <Styled.Span>{Constants.oddsStakeText}</Styled.Span>
    </Styled.MarketContainer>
    <Styled.NoBetContainer>
      <Styled.Span>{Constants.noBetsText}</Styled.Span>
    </Styled.NoBetContainer>
  </Styled.BottomContainer>
)

export default BottomUserInfo
