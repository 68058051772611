import styled from 'styled-components'
import { color } from '../../color/color'

export const Container = styled.div`
  background: ${color._FFFFFF};
  color: ${color._1E1E1E};
  border-bottom: 1px solid ${color._F8F8F8};
  transition: 0.9s;
  display: flex;
`

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  flex: 1;
`

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  flex-wrap: wrap;
  flex: 3;
`
