import styled from 'styled-components'
import { color } from '../../color/color'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color._DFE8E9};
`

export const ErrorMessage = styled.small`
  color: ${color._FF0000};
  font-size: 13px;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

export const Box = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin: 5px 0px 0px 0px;
  padding: 20px;
  background-color: ${color._FFFFFF};
  width: 100%;
  @media (max-width: 400px) {
    padding: 10px;
  }
`

export const ProfileSpan = styled.span`
  text-transform: uppercase;
  color: ${color._FFFFFF} !important;
  padding: 10px 0px 5px 10px;
`

export const TopContainer = styled.div`
  flex: 1;
  background-color: ${color._343435};
  display: flex;
  align-items: center;
  > span {
    font-size: 13px;
    text-transform: uppercase;
    color: ${color._FFFFFF};
  }
`

export const TopBox = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin: 5px 5px 0px 0px;
  padding: 20px;
  background-color: ${color._FFFFFF};
  width: 100%;
  display: grid;
  place-items: center;
`

export const StackContainer = styled.div`
  & .row-width {
    width: 50%;
  }
  & .input-box {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
`

export const Image = styled.img`
  max-width: 120px;
  @media (max-width: 991px) {
    max-width: 90px;
  }
`

export const Title = styled.h2`
  font-size: 30px;
  font-weight: 900;
  color: ${color._000};
  margin: 10px 0 0 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  display: grid;
  place-items: center;
  @media (max-width: 991px) {
    font-size: 18px;
  }
`

//FOR TAB SECTION

export const Nav = styled.nav`
  width: 100%;
`

export const NavTabs = styled.div`
  border-bottom: 2px solid ${color._EBEEF4};
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 658px) {
    padding: 0;
  }
  @media (max-width: 400px) {
    gap: 0px;
  }
  @media (max-width: 320px) {
    display: flex;
  }
`

export const NavButton = styled.button`
  color: ${color._000};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  width: 31%;
  &.active {
    border-bottom: 2px solid ${color._6D081D};
  }
  @media (max-width: 658px) {
    padding: 0px;
    width: auto;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
`

export const TabContent = styled.div``

export const TabPanelChild = styled.div`
  padding: 20px 0 15px 0;
`

export const OverviewTitle = styled.h5`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Titillium Web', sans-serif;
  color: ${color._6D081D};
`

export const OverviewRow = styled.div`
  border-bottom: 1px solid ${color._DEE2E6} !important;
  display: flex;
  font-size: 12px;

  > side {
    font-weight: 600;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  > aside {
    padding: 5px;
    flex: 1;
  }
`

export const StyledSpan = styled.button`
  font-size: 15px;
  background-color: ${({ inputDisabled }) =>
    inputDisabled ? color._343435 : color._6D081D};
  color: ${color._FFFFFF};
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  line-height: 1.5;
  padding: 0.75rem 0.375rem;
  border-radius: 0.375rem;
  border: none;
  margin-top: 15px;
`

export const StyledInput = styled.input`
  display: block;
  padding: 0.375rem 0.75rem;
  border-color: ${color._6D081D};
`

export const StyledInputGroupText = styled.span`
  background-color: ${color._6D081D};
  color: ${color._FFFFFF};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  width: 80px;
  text-align: center;
  display: block;
  padding: 0.375rem 0.75rem;
  height: 40px;
`

export const PasswordFooter = styled.div`
  font-size: 0.75em;
`

export const NoteSpan = styled.span`
  font-weight: bold;
`
export const StackBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
`
export const StackInput = styled.div`
  width: 48%;
`
