import { CiSearch } from 'react-icons/ci'
import * as Styled from '../styles'
import { Constants } from '../constants'

const Search = ({ toggle }) => {
  return (
    <Styled.SearchContainer toggle={toggle}>
      <Styled.SearchInput
        type="text"
        placeholder={Constants.searchPlaceholder}
      />
      {/* <CiSearch /> */}
    </Styled.SearchContainer>
  )
}

export default Search
