import React from 'react'
import * as Styled from './style'

const RulesList = ({ item }) => {
  return (
    <>
      <Styled.DropdownLink>
        <Styled.Box>
          <Styled.SidebarSubLabel _color={item?.color}>
            {item.title}
          </Styled.SidebarSubLabel>
        </Styled.Box>
      </Styled.DropdownLink>
    </>
  )
}

export default RulesList
