import React from 'react'
import {
  CasinoTable,
  CasinoTableBox,
  MeterButtons,
  MeterButton,
  BtnBox,
  Fighter1,
  Fighter2,
} from './style'
import { _constants } from './contants'
import fight from '../../../assets/images/fight.png'

const OneCardMeter = () => {
  return (
    <div>
      <CasinoTable>
        <CasinoTableBox>
          <MeterButtons>
            <MeterButton>
              <BtnBox>
                <Fighter1 class="btn back">
                  {_constants.fighter1}
                  <img src={fight} alt={_constants.fighter1} />
                </Fighter1>
              </BtnBox>
            </MeterButton>
            <MeterButton>
              <BtnBox c>
                <Fighter2 class="btn back">
                  <img src={fight} alt={_constants.fighter1} />
                  {_constants.fighter2}
                </Fighter2>
              </BtnBox>
            </MeterButton>
          </MeterButtons>
        </CasinoTableBox>
      </CasinoTable>
    </div>
  )
}

export default OneCardMeter
