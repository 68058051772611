import moment from 'moment'
import * as Styled from './style'
const tabs = ['Matched', 'Unmatched', 'Fancy']
const TableComponent = ({ tBody = [] }) => {
  return (
    <table>
      <tr>
        <Styled.TableHead>{'Selname'}</Styled.TableHead>
        <Styled.TableHead>{'Odds'}</Styled.TableHead>
        <Styled.TableHead>{'Stake'}</Styled.TableHead>
        <Styled.TableHead>{'Date/Time'}</Styled.TableHead>
      </tr>
      <tbody>
        {tBody?.map((item) => (
          <tr key={item?.id}>
            <Styled.TableBodyData>{item?.selname}</Styled.TableBodyData>
            <Styled.TableBodyData>{item?.stake}</Styled.TableBodyData>
            <Styled.TableBodyData>{item?.odds}</Styled.TableBodyData>
            <Styled.TableBodyData>{item?.date_time}</Styled.TableBodyData>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
const TabNav = ({ activeTab, handleTabChange }) => {
  return (
    <Styled.Nav>
      <Styled.NavTabs className="nav" id="nav-tab" role="tablist">
        {tabs.map((tab) => (
          <TabNavItem
            key={tab}
            tabId={tab}
            activeTab={activeTab}
            handleTabChange={handleTabChange}
          />
        ))}
      </Styled.NavTabs>
    </Styled.Nav>
  )
}
const TabNavItem = ({ tabId, activeTab, handleTabChange }) => {
  return (
    <Styled.NavButton
      className={`nav-link ${activeTab === tabId ? 'active' : ''}`}
      onClick={() => handleTabChange(tabId)}
    >
      {tabId.charAt(0).toUpperCase() + tabId.slice(1)}
    </Styled.NavButton>
  )
}
const getCreated = (data) =>
  moment(parseInt(data, 10) / 1000).format('MM-DD-YY hh:mm:ss a')
const getOpenBetsTabledata = (data, eventId) => {
  return (
    data?.length &&
    data
      ?.filter((i) => i?.event_id == eventId)
      ?.map((item) => {
        return {
          selname: item.team,
          stake: item.amount,
          odds: item.rate,
          date_time: getCreated(item.created),
        }
      })
  )
}
const TabContent = ({ activeTab, openMatchedBets, openFancyBets, eventId }) => {
  const tabData = {
    Matched: getOpenBetsTabledata(openMatchedBets, eventId),
    Unmatched: [],
    Fancy: getOpenBetsTabledata(openFancyBets, eventId),
  }
  const defaultData = []
  const dataToShow = tabData[activeTab] || defaultData
  return <TableComponent tBody={dataToShow} />
}
export const TabComponent = ({
  activeTab,
  handleTabChange,
  openMatchedBets,
  openFancyBets,
  event_id,
}) => {
  return (
    <div>
      <TabNav activeTab={activeTab} handleTabChange={handleTabChange} />
      <TabContent
        activeTab={activeTab}
        openMatchedBets={openMatchedBets}
        openFancyBets={openFancyBets}
        eventId={event_id}
      />
    </div>
  )
}
