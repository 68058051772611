import React from 'react'
import * as Styled from './style'

import commentary from '../../assets/images/commentary.png'
const Announcement = () => {
  return (
    <Styled.Row>
      <Styled.Row12>
        <Styled.Commentary className="announcement-container">
          <Styled.Img
            src={commentary}
            className="announcement-image"
            alt="announcement"
          />
          {/* this will come dynamic from the API 
          Right now putting it static */}
          <marquee>
            🏆 𝐈𝐍𝐃𝐈𝐀𝐍 𝐏𝐑𝐄𝐌𝐈𝐄𝐑 𝐋𝐄𝐀𝐆𝐔𝐄 (𝐈𝐏𝐋) 𝟐𝟎𝟐𝟒 𝐂𝐔𝐏 𝐖𝐈𝐍𝐍𝐄𝐑 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑
            𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🏆 ⚽ 𝐔𝐄𝐅𝐀 𝐂𝐇𝐀𝐌𝐏𝐈𝐎𝐍𝐒 𝐋𝐄𝐀𝐆𝐔𝐄 & 𝐔𝐄𝐅𝐀 𝐄𝐔𝐑𝐎𝐏𝐀 𝐋𝐄𝐀𝐆𝐔𝐄 🏆 𝐂𝐔𝐏
            𝐖𝐈𝐍𝐍𝐄𝐑 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄🏆 🗳 𝐏𝐎𝐋𝐈𝐓𝐈𝐂𝐒 : 𝐋𝐎𝐊 𝐒𝐀𝐁𝐇𝐀 𝟐𝟎𝟐𝟒
            𝐄𝐋𝐄𝐂𝐓𝐈𝐎𝐍 𝐌𝐀𝐑𝐊𝐄𝐓 𝐍𝐎𝐖 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄
          </marquee>
        </Styled.Commentary>
      </Styled.Row12>
    </Styled.Row>
  )
}

export default Announcement
