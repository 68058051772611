import React, { useState } from 'react'
import * as Styled from './style'

const List = ({ item, key }) => {
  const [subnav, setSubnav] = useState(false)

  const showSubnav = () => setSubnav(!subnav)

  return (
    <>
      <Styled.DropdownLink
        key={item.title + key}
        to={item?.path}
        onClick={item && showSubnav}
      >
        <div>
          <Styled.SidebarSubLabel>{item.title}</Styled.SidebarSubLabel>
        </div>
        <Styled.SvgListSection>
          {item && subnav ? item.iconOpened : item ? item.iconClosed : null}
        </Styled.SvgListSection>
      </Styled.DropdownLink>
      {subnav &&
        item.list.map((item, index) => {
          return (
            <Styled.DropdownLink to={item?.path} key={index}>
              <Styled.ListItem>{item?.title}</Styled.ListItem>
            </Styled.DropdownLink>
          )
        })}
    </>
  )
}

export default List
