import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './redux/rootReducers'
// import logger from 'redux-logger'

// const middleware = [logger]

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable immutable state invariant middleware
      serializableCheck: false, // Optionally disable serializable state invariant middleware
    }),
  //We can add optionally middleware, enhancers, etc.
})
export default store
