import React from 'react'
import SubRules from './SubRules'
import { rulesData } from './RulesData'

const Rules = () => {
  return rulesData.map((item, index) => {
    return <SubRules item={item} key={item.title + index} index={index} />
  })
}

export default Rules
