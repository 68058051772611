export const CONSTANT = {
  RULES_REGULATION: 'Rules and Regulations',
  GAME_RULE: 'GAME RULES',
  GAME_OBJECTIVE: 'GAME OBJECTIVES',
  BETS: 'BETS',
  WIN: 'wins',
  PAYOUT_TITLE: 'payouts as per the bet placed',
  SEVEN_GAME: 'SEVEN_GAME',
  SEVEN_GAME_OPTION: '(7 Up/ 7 Down/ 7)',
  HAND_DEALT: 'Hands dealt',
}

export const CardData = [
  {
    gameImage:
      'https://game.royalgaming.online/static/media/Teenpatti.b39a310dc39bab816caa.png',

    gameName: 'TeenPatti',
    gameParticipant: 223,
  },
  {
    gameImage:
      'https://game.royalgaming.online/static/media/Teen%20patti%20one%20day.cd6a5b83460a9d972c1e.png',

    gameName: 'TeenPatti one day',
    gameParticipant: 223,
  },
  {
    gameImage:
      'https://game.royalgaming.online/static/media/Side%20bet%20city.eb8166be403df8fc714c.png',

    gameName: 'Sidebet City',
    gameParticipant: 223,
  },
  {
    gameImage:
      'https://rg-assests.royalgaming.online/lobby-icon/2%20card%20teen%20patti.jpg',

    gameName: '2 card Teen Patti',
    gameParticipant: 223,
  },
]

export const buttonData = [
  { text: 'Popular games(22)', even: false },
  { text: 'Dealer games(22)', even: true },
  { text: 'Virtual games(22)', even: false },
]

export const Constants = {
  searchPlaceholder: 'Search Game',
  backButtonText: 'Back',
  lobbyIconUrl:
    'https://game.royalgaming.online/static/media/lobby-icon.e7554e1bd993e73e435d8e20b5c9ae5f.svg',
  logoContainerUrl:
    'https://rg-assests.royalgaming.online/common/games-logo.svg',
  balanceIconUrl:
    'https://rg-assests.royalgaming.online/common/balance-light-icon.svg',
  toggleIconUrl: 'https://rg-assests.royalgaming.online/common/toggle-icon.svg',
  desktopIconUrl: 'https://rg-assests.royalgaming.online/common/desktop.svg',
  memberIconUrl:
    'https://rg-assests.royalgaming.online/lobby-icon/member-icon.svg',
  demoUsername: 'DEMO123',
  availableCreditText: 'AVAILABLE CREDIT EXPOSURE',
  openBetsText: 'Open Bets',
  noBetsText: 'You have no bets',
  marketsText: 'MARKETS',
  oddsStakeText: 'ODDS STAKE',
  Theme: 'Theme',
  Lobby: 'Lobby',
  username: 'demo-1234567',
  noBetsText_: 'No Bets',
  rules: 'Rules',
  playerHistory: 'Players History',
  roundId: 'Round ID:',
}

export const moneyData = [
  {
    id: 0,
    amount: '100',
    image:
      'https://game.royalgaming.online/static/media/red-chip.4ce2318e3b95b9819bc3208887046bc3.svg',
  },
  {
    id: 1,
    amount: '500',
    image:
      'https://game.royalgaming.online/static/media/maroon-chip.710f6d2460b4f11fdbb03f532a861c52.svg',
  },
  {
    id: 2,
    amount: '1k',
    image:
      'https://game.royalgaming.online/static/media/green-chip.d074eb36fdc3076a115bbdf6d61ff942.svg',
  },
  {
    id: 3,
    amount: '5k',
    image:
      'https://game.royalgaming.online/static/media/blue-chip.aeef0d487ca9055a09fdf6ddb53715a0.svg',
  },
  {
    id: 4,
    amount: '10k',
    image:
      'https://game.royalgaming.online/static/media/purple-chip.e399df8a2988c15f5d4ff17042721e4c.svg',
  },
  {
    id: 5,
    amount: '25k',
    image:
      'https://game.royalgaming.online/static/media/pink-chip.ab1ff2607f4b0642f20becd97651524d.svg',
  },
]

export const gamesData = [
  {
    id: 1,
    imageSrc:
      'https://game.royalgaming.online/static/media/seven-up.611a86df40cc6ca39e488a6657a4dadb.svg',
    gameName: '7UP',
    betPrice: '1.98',
    bgcolor:
      'linear-gradient(180deg,#a11425,#400b11),linear-gradient(180deg,#fff 0%,hsla(0,0%,100%,0))',
  },
  {
    id: 2,
    imageSrc:
      'https://game.royalgaming.online/static/media/seven.c61f6e233993d4fe9a51304ed2ff5d9f.svg',
    gameName: 'Seven',
    betPrice: '1.98',
    bgcolor:
      'linear-gradient(180deg,#0d8014,#022204 79.82%),linear-gradient(180deg,#fff 0%,hsla(0,0%,100%,0))',
  },
  {
    id: 3,
    imageSrc:
      'https://game.royalgaming.online/static/media/seven-down.9f3d9c350eb8818b57c062f6b8cf9cad.svg',
    gameName: '7Down',
    betPrice: '1.98',
    bgcolor:
      'linear-gradient(180deg,#1064a9,#032440),linear-gradient(180deg,#fff 0%,hsla(0,0%,100%,0))',
  },
]

export const bettingData = [
  {
    gameName: 'MIN-100 | MAX-150000',
    gameData: [
      {
        bet1: 123,
        bet2: undefined,
        text: '7UP',
      },
      {
        bet1: 123,
        bet2: undefined,
        text: 'Seven',
      },
      {
        bet1: 123,
        bet2: undefined,
        text: '7Down',
      },
    ],
  },
  {
    gameName: 'ODD-EVEN',
    gameData: [
      {
        bet1: 123,
        bet2: 96,
        text: 'ODD',
      },
      {
        bet1: 123,
        bet2: 22,
        text: 'EVEN',
      },
    ],
  },
  {
    gameName: 'COLOR',
    gameData: [
      {
        bet1: undefined,
        bet2: undefined,
        text: 'RED',
      },
      {
        bet1: undefined,
        bet2: undefined,
        text: 'Black',
      },
    ],
  },
  {
    gameName: 'SUIT',
    gameData: [
      {
        bet1: undefined,
        bet2: undefined,
        text: 'Spades',
      },
      {
        bet1: undefined,
        bet2: undefined,
        text: 'Heart',
      },
      {
        bet1: undefined,
        bet2: undefined,
        text: 'Diamond',
      },
      {
        bet1: undefined,
        bet2: undefined,
        text: 'Club',
      },
    ],
  },

  {
    gameName: '3CRAD',
    gameData: [
      {
        bet1: undefined,
        bet2: undefined,
        text: 'A23',
      },
      {
        bet1: undefined,
        bet2: undefined,
        text: '456',
      },
      {
        bet1: undefined,
        bet2: undefined,
        text: '8910',
      },
      {
        bet1: undefined,
        bet2: undefined,
        text: 'JQK',
      },
    ],
  },
]

export const rulesDatas = [
  {
    id: 1,
    objectives:
      'To guess the value of the card drawn is Above 7, Below 7 or Equal to 7.',
    rules: {
      handsDealt: 1,
      bets: [
        {
          type: '7 Up',
          condition: 'If dealt hand is > 7 (8,9,10,J,Q,K)',
          payout: '1 to 0.98',
        },
        {
          type: '7 Down',
          condition: 'If dealt hand is < 7 (A,2,3,4,5,6)',
          payout: '1 to 0.98',
        },
        { type: 'Seven', condition: 'If dealt hand is = 7', payout: '1 to 11' },
        { type: 'Even', condition: '', payout: '1 to 1.1' },
        { type: 'Odd', condition: '', payout: '1 to 0.8' },
        { type: 'Red/Black', condition: '', payout: '1 to 0.98' },
        { type: 'Suit', condition: '', payout: '1 to 2.75' },
      ],
    },
  },
]
