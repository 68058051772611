import React, { useEffect, useState } from 'react'
import * as Styled from '../style'
import { FaStar } from 'react-icons/fa'
import Lock from '../../../assets/images/Lock.svg'
import { color } from '../../../color/color'
const EventsDetails = ({ handleViewDetailPage, matchTitle }) => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <Styled.EventDetail className="display-flex" onClick={handleViewDetailPage}>
      <Styled.TimeTeam className="display-flex">
        <Styled.Time className="flex-column-aicenter-jccenter">
          {width > 928 && <Styled.Values>Today</Styled.Values>}
          <Styled.Values>05:00 PM</Styled.Values>
        </Styled.Time>
        <Styled.Team>
          {' '}
          {/* Here all are mock data which will come from api.... */}
          <Styled.TeamTitle>{matchTitle}</Styled.TeamTitle>
          <Styled.ValueDiv>
            <Styled.Values>
              <Styled.SportImg
                src="https://sports-v3.mysportsfeed.io/static/media/Cricket.68e7b2af.svg"
                alt="bat-cricket"
                image="first"
              />
              Chennia Super Kings
            </Styled.Values>
            {width < 928 && <Styled.VS>VS</Styled.VS>}
            <Styled.Values>
              <Styled.SportImg
                src="https://sports-v3.mysportsfeed.io/static/media/Cricket.68e7b2af.svg"
                alt="bat-cricket-1"
              />
              Paris Knight Riders
            </Styled.Values>
          </Styled.ValueDiv>
        </Styled.Team>
      </Styled.TimeTeam>
      <Styled.FavScore className="display-flex">
        <Styled.Fav className="display-flex">
          <FaStar color={color._CCCCCC} />
        </Styled.Fav>
        <Styled.Score>
          {' '}
          {/* will convert this code after api implementation with map */}
          <Styled.ScoreValues className="display-flex">
            <Styled.ScoreTitleBox className="flex-column-aicenter-jccenter">
              <Styled.ScoreTitle>Score</Styled.ScoreTitle>
              <Styled.ScoreBox fontcolor={color._000000}>0:0</Styled.ScoreBox>
            </Styled.ScoreTitleBox>
            <Styled.ScoreTitleBox>
              <Styled.ScoreBox
                backgroundcolor={color._116257}
                extramargintop={true}
              >
                1.8
              </Styled.ScoreBox>
            </Styled.ScoreTitleBox>
            <Styled.ScoreTitleBox>
              <Styled.ScoreTitle>1x2</Styled.ScoreTitle>
              <Styled.ScoreBox backgroundcolor={color._116257}>
                <img src={Lock} alt="lock-score" />
              </Styled.ScoreBox>
            </Styled.ScoreTitleBox>
            <Styled.ScoreTitleBox>
              <Styled.ScoreBox
                backgroundcolor={color._116257}
                extramargintop={true}
              >
                2
              </Styled.ScoreBox>
            </Styled.ScoreTitleBox>
          </Styled.ScoreValues>
        </Styled.Score>
      </Styled.FavScore>
    </Styled.EventDetail>
  )
}

export default EventsDetails
