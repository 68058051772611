import React from 'react'
import * as Styled from './styles'

function Footer({ compCheck }) {
  const numbersArr = Array.from({ length: 10 }, (_, index) => index)
  return (
    <Styled.FooterContainer className="mt-3">
      <Styled.FooterTopContainer className="d-flex align-items-center justify-content-between">
        <Styled.Col>List Result</Styled.Col>
        <Styled.Col>View All</Styled.Col>
      </Styled.FooterTopContainer>
      {compCheck === 'Worli Matka' ? (
        <Styled.FooterBottomContainer className="d-flex align-items-center justify-content-end mt-2 gap-1">
          {numbersArr.map((color, index) => (
            <Styled.CircularBox key={index} color={'yellow'}>
              {'R'}
            </Styled.CircularBox>
          ))}
        </Styled.FooterBottomContainer>
      ) : (
        <Styled.FooterBottomContainer className="d-flex align-items-center justify-content-end mt-2 gap-1">
          {[
            'yellow',
            'orange',
            'yellow',
            'yellow',
            'orange',
            'orange',
            'yellow',
            'orange',
            'yellow',
            'orange',
            'yellow',
          ].map((color, index) => (
            <Styled.CircularBox key={index} color={color}>
              {color === 'yellow' ? 'A' : 'B'}
            </Styled.CircularBox>
          ))}
        </Styled.FooterBottomContainer>
      )}
    </Styled.FooterContainer>
  )
}

export default Footer
