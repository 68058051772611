import React from 'react'
import * as Styled from './styles'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { cardImages, cardTypeImages, topRowData } from './constant'

const Box = ({ children, isSuspended = true }) => (
  <Styled.BoxContainer className="d-flex align-items-center justify-content-center">
    {isSuspended ? <Lock /> : children}
  </Styled.BoxContainer>
)

function AndarBahar() {
  return (
    <>
      <Styled.Container className="container g-3">
        <Styled.Row className="row">
          {topRowData?.map((topRow) => (
            <Styled.Col className="col-md-6">
              <Styled.RowGap className="row gap-1">
                <Styled.NamedContainer className="col-md-1">
                  {topRow.rowName}
                </Styled.NamedContainer>
                <Styled.BetInitialContainer className="col-md-2">
                  <Box isSuspended={false}>{topRow.rowTitle}</Box>
                </Styled.BetInitialContainer>
                <Styled.BetContainer className="col-md-3">
                  <Box>{topRow.firstBet}</Box>
                </Styled.BetContainer>
                <Styled.BetContainer className="col-md-4">
                  <Box>{topRow.secondBet}</Box>
                </Styled.BetContainer>
                <Styled.NamedContainer className="col-md-1">
                  {topRow.rowName}
                </Styled.NamedContainer>
              </Styled.RowGap>
            </Styled.Col>
          ))}
        </Styled.Row>
      </Styled.Container>
      <Styled.AndarBaharBottomContainerMiddleRowContainer className="container mt-3">
        <Styled.Row className="row">
          <Styled.Container className="col-6">
            <Styled.Row className="row mb-2 text-center">
              <Styled.CenteredText className="col-6 d-flex align-items-center justify-content-center">
                ODD
              </Styled.CenteredText>
              <Styled.CenteredText className="col-6 d-flex align-items-center justify-content-center">
                EVEN
              </Styled.CenteredText>
            </Styled.Row>
            <Styled.Row className="row">
              <Styled.Col className="col-6">
                <Box isSuspended={false}>1.3</Box>
              </Styled.Col>
              <Styled.Col className="col-6">
                <Box>0</Box>
              </Styled.Col>
            </Styled.Row>
          </Styled.Container>
          <Styled.Container className="col-6">
            <Styled.Row className="row mb-2 text-center">
              {cardTypeImages.map((image) => (
                <Styled.Col className="col-3">
                  <Styled.ImageColumn
                    src={image.src}
                    alt={image.alt}
                    className="img-fluid"
                  />
                </Styled.Col>
              ))}
            </Styled.Row>
            <Styled.Row className="row">
              <Styled.Col className="col-3">
                <Box>0</Box>
              </Styled.Col>
              <Styled.Col className="col-3">
                <Box isSuspended={false}>0.7</Box>
              </Styled.Col>
              <Styled.Col className="col-3">
                <Box>0</Box>
              </Styled.Col>
              <Styled.Col className="col-3">
                <Box>0</Box>
              </Styled.Col>
            </Styled.Row>
          </Styled.Container>
        </Styled.Row>
      </Styled.AndarBaharBottomContainerMiddleRowContainer>
      <Styled.AndarBaharBottomContainerBottomRowContainer className="container mt-3">
        <Styled.Row className="d-flex align-items-center justify-content-center gap-1">
          {cardImages.map((image) => (
            <Box>
              <Styled.ImageColumn src={image.src} alt={image.alt} />
            </Box>
          ))}
        </Styled.Row>
      </Styled.AndarBaharBottomContainerBottomRowContainer>
    </>
  )
}

export default AndarBahar
