import styled from 'styled-components'
import { color } from '../../../color/color'

export const WorliMatka = styled.div`
  color: ${color._000};
`

export const UpperNavigation = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Nav = styled.div`
  padding: 10px;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  font-weight: ${({ selected }) => (selected ? 'bold' : null)};
  background-color: ${({ selected }) => (selected ? '#2C3E50' : '#cccccc')};
  border-left: 1px solid black;
  border-right: 1px solid black;
  cursor: pointer;
`
export const MidValues = styled.div`
  display: flex;
  margin-top: 5px;
`
export const Val = styled.div`
  text-align: center;
  width: 33.3%;
  font-weight: bold;
  visibility: ${({ hideVal }) => (hideVal ? 'visible' : 'hidden')};
`

export const SingleComp = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columnCheck }) => columnCheck}, 1fr);
  grid-template-rows: repeat(2, 100px);
  gap: 5px;
`

export const SelectVal = styled.div`
  font-family: 'MyCasinoFont';
  font-size: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: #72bbef;
  cursor: pointer;
  height: ${({ height }) => (height ? height : null)};
`

export const UpperVal = styled.div``

export const LowerVal = styled.div`
  font-family: Titillium Web, sans-serif;
  font-size: 14px;
  text-align: center;
`

export const NumberSep = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  height: 100%;
`

export const NumberSepLeft = styled.div`
  width: 70%;
`
export const NumberSepRight = styled.div`
  width: 30%;
  height: 100%;
`
