import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Styled from './style'
import { IoMdClose } from 'react-icons/io'
import {
  Container,
  ModalInnerDiv,
  GlobalStyle,
  HeaderBox,
  ModalHeaderSpan,
  ResetForm,
  FormGroup,
  ButtonContainer,
  Btn,
  PhoneInputWrapper,
} from './style'

import { color } from '../../color/color'
import { _constants } from './contants'
import { useDispatch, useSelector } from 'react-redux'
import { showModaL } from '../../redux/widthdraw/action'
import { toast } from 'react-toastify'
import { getToastPosition } from '../../utils/constant'
import { PhoneInput } from 'react-international-phone'

const WithdrawPasswordModal = ({ show, handleClose }) => {
  const [withdrawPassword, setWithdrawPassword] = useState()
  const [confirmWithdrawPassword, setConfirmWithdrawPassword] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const phone = useSelector((state) => state.auth.phone)
  const phoneCountryCode = useSelector((state) => state.auth.phoneCountryCode)

  const dispatch = useDispatch()
  const closeModal = () => {
    handleClose()
  }
  const getPhoneDetails = (phone) => {
    if (phone?.length === 0) {
      return false
    } else if (/^0+$/.test(phone)) {
      return false
    } else {
      return true
    }
  }
  const handleWithdrawChange = (e) => {
    const value = e.target.value.replace(/\s{1,}/g, '')
    setWithdrawPassword(value)
  }
  const handleConfirmWithdrawChange = (e) => {
    const value = e.target.value.replace(/\s{1,}/g, '')
    setConfirmWithdrawPassword(value)
  }
  const handlePhoneChange = (e) => {
    setPhoneNumber(e)
  }
  const savePassword = () => {
    const phoneRegex = /^\+?\d{1,3}\s?\d{6,14}$/
    if (withdrawPassword !== confirmWithdrawPassword) {
      toast.warn(
        'Withdraw password and Confirm Withdraw password must be same',
        getToastPosition(),
      )
    } else if (!withdrawPassword?.length || !confirmWithdrawPassword?.length) {
      toast.warn(
        'Please enter withdraw password and confirm withdraw password',
        getToastPosition(),
      )
    } else if (
      !phoneRegex.test(phoneNumber?.trim()) &&
      !getPhoneDetails(phone)
    ) {
      toast.warn('Please enter valid phone number', getToastPosition())
    } else {
      const payload = {
        clientId: localStorage.getItem('userId'),
        withdrawalPassword: withdrawPassword,
        phoneCountryCode: getPhoneDetails(phone)
          ? phoneCountryCode
          : phoneNumber,
      }
      dispatch(showModaL(payload))
      handleClose()
    }
  }

  return (
    <Styled.Modal>
      <Modal show={show} onHide={closeModal} centered>
        <ModalInnerDiv>
          <GlobalStyle />

          <Container>
            <HeaderBox>
              <IoMdClose
                color={color._FFFFFF}
                className="close-icon"
                onClick={closeModal}
              />
              <ModalHeaderSpan className="text-center pt-3">
                {_constants.ModalHeader}
              </ModalHeaderSpan>
            </HeaderBox>
            <ResetForm id="withdraw_form">
              <input
                type="password"
                placeholder="Enter Withdraw Password"
                className="form-control"
                maxLength="12"
                minLength="6"
                onChange={(e) => handleWithdrawChange(e)}
                value={withdrawPassword}
                required
              />
            </ResetForm>
            <ResetForm id="withdraw_form">
              <input
                type="password"
                placeholder="Confirm Withdraw Password"
                className="form-control"
                maxLength="12"
                minLength="6"
                onChange={(e) => handleConfirmWithdrawChange(e)}
                required
                value={confirmWithdrawPassword}
              />
            </ResetForm>
            <ResetForm id="withdraw_form">
              {getPhoneDetails(phone) ? (
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="form-control"
                  disabled={true}
                  required
                  value={
                    getPhoneDetails(phone) ? phoneCountryCode : phoneNumber
                  }
                />
              ) : (
                <PhoneInputWrapper>
                  <PhoneInput
                    defaultCountry="in"
                    value={''}
                    placeholder={'Mobile no'}
                    onChange={(e) => handlePhoneChange(e)}
                  />
                </PhoneInputWrapper>
              )}
            </ResetForm>
            <FormGroup>
              <ButtonContainer>
                <Btn
                  type="submit"
                  className="btn btn-primary mt-1"
                  id="signup_submit"
                  onClick={savePassword}
                >
                  SAVE PASSWORD
                </Btn>
              </ButtonContainer>
            </FormGroup>
          </Container>
        </ModalInnerDiv>
      </Modal>
    </Styled.Modal>
  )
}

export default WithdrawPasswordModal
