import React, { useEffect, useState } from 'react'
import { StackContainer, StyledSpan, StackBox, StackInput } from '../styles'
import InputAddon from './InputAddon'
import {
  addonValues,
  constants,
  getToastPosition,
} from '../../../utils/constant'
import { removeEmptyItemsFromArray } from '../../../utils/utils'
import { toast } from 'react-toastify'
const StackContent = () => {
  const [values, setValues] = useState([])
  const [storedValues, setStoredValues] = useState(addonValues)
  const [stack, setStack] = useState([])

  const [inputDisabled, setInputDisabled] = useState(false)

  useEffect(() => {
    setStack(JSON.parse(localStorage.getItem('stackValue')) || addonValues)
  }, [])

  const handleSaveStack = () => {
    localStorage.setItem(
      'stackValue',
      JSON.stringify(removeEmptyItemsFromArray(storedValues)),
    )
    toast.success(constants.Stack_Success_Message, getToastPosition())
  }
  return (
    <>
      <StackContainer className="row">
        <StackBox>
          {stack?.map((addonValue, index) => (
            <StackInput>
              <InputAddon
                addon={addonValue}
                index={index}
                setValues={setValues}
                values={values}
                setInputDisabled={setInputDisabled}
                setStoredValues={setStoredValues}
                storedValues={storedValues}
              />
            </StackInput>
          ))}
        </StackBox>
      </StackContainer>
      <StyledSpan inputDisabled={inputDisabled} onClick={handleSaveStack}>
        Save
      </StyledSpan>
    </>
  )
}

export default StackContent
