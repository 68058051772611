export const constants = {
    player: "Player"
}

export const tableHeaders = ['Winner', 'High Card', 'Pair', 'Color Plus A'];

export const playersData = [
    {
        player: 'A',
        stats: {
            winner: { value: 1.98, isSuspended: false },
            highCard: { value: 3.23, isSuspended: false },
            pair: { value: 2.88, isSuspended: false },
            colorPlusA: { value: 3, isSuspended: false },
        },
    },
    {
        player: 'B',
        stats: {
            winner: { value: 1.98, isSuspended: true },
            highCard: { value: 3.23, isSuspended: true },
            pair: { value: 2.88, isSuspended: true },
            colorPlusA: { value: 3, isSuspended: true },
        },
    },
];
