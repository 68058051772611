import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import * as Styled from './style'
import { useNavigate } from 'react-router'
import { ProfileDropdownOptions, constants } from '../../utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import { demologout, logout } from '../../redux/auth/action'
import deposit from '../../assets/images/deposit.png'
import withdrawal from '../../assets/images/withdrawal.png'
import { removeDuplicateObjectFromArray } from '../../utils/utils'
import { _constants } from './constants'

const ProfileDropdown = ({ title, userName = 'Demo123' }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loader = useSelector((state) => state.loading)
  const token = useSelector((state) => state.auth.token)
  const userId = useSelector((state) => state.auth.userId)
  const [dropdownData, setDropdownData] = useState()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  const handleSignOut = () => {
    if (loader.isLoading) return
    if (token) {
      dispatch(logout(token, userId))
    } else {
      dispatch(demologout())
    }
    localStorage.removeItem('isDemoLogin')
    navigate('/')
  }

  useEffect(() => {
    let array = ProfileDropdownOptions

    const depositData = {
      icon: deposit,
      title: _constants.Deposit,
      navigate: '/deposit',
    }
    const withdrawalData = {
      icon: withdrawal,
      title: _constants.Withdrawal,
      navigate: '/withdraw',
    }

    let index = 0 // For after index 0,
    isAuthenticated && array.splice(index + 1, 0, depositData, withdrawalData)

    isAuthenticated
      ? setDropdownData(removeDuplicateObjectFromArray(array))
      : setDropdownData(removeDuplicateObjectFromArray(ProfileDropdownOptions))
  }, [isAuthenticated])

  const getClassName = (title) => {
    if (title === _constants.Deposit) {
      return _constants.DepositBox
    } else if (title === _constants.Withdrawal) {
      return _constants.WithdrawalBox
    } else {
      return ''
    }
  }
  return (
    <Styled.Dropdown dropdowndata={dropdownData}>
      <DropdownButton title={title.toUpperCase()}>
        <Styled.DropdownHeading className="text-align-center">
          {constants.HI} {title || userName?.toUpperCase()}
        </Styled.DropdownHeading>
        {dropdownData?.length &&
          dropdownData.map((elem, indx) => {
            return (
              <Styled.Item
                key={elem.title + indx}
                onClick={() =>
                  navigate(`${elem.navigate}`, {
                    state: { profileTab: elem.profileTab },
                  })
                }
              >
                <Dropdown.Item
                  href={elem.navigate}
                  key={elem.title + indx}
                  onClick={(e) => e.preventDefault()}
                  className={getClassName(elem.title)}
                >
                  <div onClick={() => navigate(`${elem.navigate}`)}>
                    {elem.title === _constants.Deposit ||
                    elem.title === _constants.Withdrawal ? (
                      <>
                        <Styled.Img src={elem.icon} alt={elem.title} />
                        <Styled.Title>{elem.title}</Styled.Title>
                      </>
                    ) : (
                      <>
                        <Styled.Icon>{elem.icon}</Styled.Icon>
                        <Styled.Title>{elem.title}</Styled.Title>
                      </>
                    )}
                  </div>
                </Dropdown.Item>
              </Styled.Item>
            )
          })}
        <Styled.SignOutBtn onClick={() => handleSignOut()}>
          <i class="bi bi-box-arrow-right"></i>
          <Styled.Text>{constants.Sign_Out}</Styled.Text>
        </Styled.SignOutBtn>
      </DropdownButton>
    </Styled.Dropdown>
  )
}

export default ProfileDropdown
