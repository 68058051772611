import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { demologin, login } from '../redux/auth/action'
import { toast } from 'react-toastify'
import { constants, getToastPosition } from '../utils/constant'

export const useLoginHook = () => {
  const navigate = useNavigate()
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  })
  const [loading, setLoading] = useState(false)
  const errorMessage = useSelector((state) => state.auth.errorMessage)
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const redirectToDashboard = useCallback(() => {
    navigate('/')
  }, [navigate])
  const loader = useSelector((state) => state.loading)

  // Check authentication status when component gets mount
  useEffect(() => {
    // If user is already authenticated, redirect to dashboard
    if (isAuthenticated) {
      return redirectToDashboard()
    }
  }, [isAuthenticated, redirectToDashboard])

  const handleLogin = (e, handleClose, setToggle, toggle) => {
    e.preventDefault()
    for (let key in credentials) {
      if (credentials.hasOwnProperty(key) && !credentials[key]) {
        toast.warning(
          constants.Invalid_Username_Or_Password,
          getToastPosition(),
        )
        return false
      }
    }
    setToggle(!toggle)
    setLoading(true)
    if (loader.isLoading) return
    dispatch(login(credentials))
    setLoading(false)
    redirectToDashboard()
  }
  const handleDemoLogin = (handleClose) => {
    localStorage.setItem('isDemoLogin', true)
    dispatch(demologin())
  }
  // Event change of username
  const onChangeInput = (e, type) => {
    let inputValue = e.target.value
    if (type === 'text') {
      setCredentials({
        ...credentials,
        username: inputValue,
      })
    } else {
      setCredentials({
        ...credentials,
        password: inputValue,
      })
    }
  }

  return {
    handleLogin,
    credentials,
    errorMessage,
    loading,
    onChangeInput,
    handleDemoLogin,
  }
}

export default useLoginHook
