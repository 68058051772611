import React, { useEffect, useRef, useState } from 'react'
import AccountInfoData from '../../container/AccountInfoData/AccountInfoData'
import { UnsettledBetTableHeader, constants } from '../../utils/constant'
import Layout from '../../Layout'
import { fetchUnsettledBets } from '../../redux/auth/action'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader/Loader'
const UnsettledBets = () => {
  const dispatch = useDispatch()
  const avoidRerender = useRef(false)
  const token = useSelector((state) => state.auth.token)
  const unsettledBets = useSelector((state) => state.auth.unSettledBets)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!avoidRerender.current) {
      dispatch(fetchUnsettledBets(token)).finally(() => {
        setLoading(false)
      })
    }
    return () => (avoidRerender.current = true)
  }, [dispatch, token])

  return (
    <>
      <Layout>
        {loading ? (
          <Loader />
        ) : (
          <AccountInfoData
            heading={constants.UNSETTLED_BETS}
            tableHeader={UnsettledBetTableHeader}
            hideFilters={true}
            tableBody={unsettledBets}
            unsettledBets={true}
          />
        )}
      </Layout>
    </>
  )
}

export default UnsettledBets
