import React, { useEffect, useState } from 'react'
import * as Styled from './style'
import Header from './utils/Header'
import Drawer from './utils/Drawer'
import ContentOptions from './utils/ContentOptions'
import { constants } from '../../utils/constant'
import SportsBookSlider from './utils/SportsBookSlider'
import AccordionFunc from './utils/Accordian'
import EventsDetails from './utils/EventsDetails'
import { IoIosArrowBack } from 'react-icons/io'
import BetsSidebar from './utils/BetsSidebar'
import { color } from '../../color/color'
import ResponsiveDrawer from './utils/ResponsiveDrawer'
import MyBets from './utils/MyBets'
import Result from './utils/Result'
import Favourite from './utils/Favourite'
import Search from './utils/Search'
import InnerPage from './utils/InnerPage'
const SportsBook = () => {
  const [openDrawer, setOpenDrawer] = useState(true)
  const [activeMatchOption, setActiveMatchOption] = useState(constants.Cricket)
  const [betsSlider, setBetsSlider] = useState(false)
  const [drawerSlider, setDrawerSlider] = useState(false)
  const [drawerOption, setDrawerOption] = useState(constants.Home)
  const [activeOption, setActiveOption] = useState(constants.Events)
  const [width, setWidth] = useState(window.innerWidth)
  const [addedBet, setAddedBet] = useState(false)
  const [matchDetailsView, setMatchDetailsView] = useState(false)
  const [betDetails, setBetDetails] = useState({})
  const [activeBetId, setActiveBetId] = useState('')
  const [availableBet, setAvailableBet] = useState(true)
  const [innerCompData, setInnerCompData] = useState({})

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (width > 928) {
      setDrawerSlider(false)
    }
    if (width < 928 && betsSlider) {
      setDrawerSlider(true)
    }
  }, [width, betsSlider, addedBet])

  const handleRightSlider = () => {
    if (width < 928) {
      setDrawerSlider(true)
    } else {
      setBetsSlider(true)
    }
  }

  useEffect(() => {
    if (drawerOption === constants.My_Bets) {
      setActiveOption(constants.MyBets)
    } else if (drawerOption === constants.Results) {
      setActiveOption(constants.Results)
    } else if (drawerOption === constants.Home) {
      setActiveOption(constants.Events)
    } else if (drawerOption === constants.Inplay) {
      setActiveOption(constants.Live)
    } else if (drawerOption === constants.Favourites) {
      setActiveOption(constants.Favourite)
    }
  }, [drawerOption])

  useEffect(() => {
    if (activeOption === constants.Events) {
      setDrawerOption(constants.Home)
    } else if (activeOption === constants.Live) {
      setDrawerOption(constants.Inplay)
    }
  }, [activeOption])

  const handleMatchDetailsView = (data) => {
    setInnerCompData({ ...innerCompData, match: data.match })
    setMatchDetailsView(true)
  }

  return (
    <Styled.SportsBook>
      <Header />
      <Styled.MidSection className="display-flex">
        {width > 928 && (
          <Drawer
            opendrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            activeMatchOption={activeMatchOption}
            setActiveMatchOption={setActiveMatchOption}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
          />
        )}

        <Styled.Content opendrawer={openDrawer}>
          {drawerOption === constants.My_Bets ||
          activeOption === constants.MyBets ? (
            <MyBets width={width} />
          ) : drawerOption === constants.Results ||
            activeOption === constants.Results ? (
            <Result />
          ) : drawerOption === constants.Favourites ||
            activeOption === constants.Favourite ? (
            <Favourite />
          ) : activeOption === constants.Search ? (
            <Search />
          ) : (
            <>
              {drawerOption !== constants.Inplay
                ? !matchDetailsView && <SportsBookSlider />
                : !matchDetailsView && (
                    <Styled.MyBetsTitle marginLeft={true}>
                      {constants.LIVE_EVENTS}
                    </Styled.MyBetsTitle>
                  )}
              <ContentOptions
                activeMatchOption={activeMatchOption}
                setActiveMatchOption={setActiveMatchOption}
                drawerOption={drawerOption}
                setDrawerOption={setDrawerOption}
                setMatchDetailsView={setMatchDetailsView}
              />
              {matchDetailsView ? (
                <InnerPage
                  activeBetId={activeBetId}
                  setActiveBetId={setActiveBetId}
                  setBetDetails={setBetDetails}
                  setBetsSlider={setBetsSlider}
                  setAddedBet={setAddedBet}
                  availableBet={availableBet}
                  innerCompData={innerCompData}
                />
              ) : (
                <Styled.AccordianData>
                  <AccordionFunc
                    title={constants.LIVE_EVENTS}
                    iconSrc="https://sports-v3.mysportsfeed.io/static/media/Inplay.6360c70b.svg"
                    altIcon="LiveImg"
                    showIcon={true}
                    bgColor={color._CCCCCC}
                  >
                    <EventsDetails
                      handleViewDetailPage={() =>
                        handleMatchDetailsView({
                          match: 'France/T10 ECS France',
                        })
                      }
                      matchTitle="France/T10 ECS France"
                    />
                  </AccordionFunc>
                  <AccordionFunc
                    title={constants.UPCOMING_EVENTS}
                    bgColor={color._CCCCCC}
                  >
                    <EventsDetails />
                  </AccordionFunc>
                </Styled.AccordianData>
              )}
            </>
          )}
        </Styled.Content>
      </Styled.MidSection>
      {!drawerSlider && (
        <Styled.RightSlideButton onClick={handleRightSlider}>
          <Styled.ArrowDiv className="arrowDiv">
            <IoIosArrowBack className="backArrow" />
          </Styled.ArrowDiv>
          {width > 928 && (
            <Styled.RightSlideIcon
              src="https://sports-v3.mysportsfeed.io/static/media/betSlipDoc.cf7a9641.svg"
              alt="betsData"
            />
          )}
        </Styled.RightSlideButton>
      )}

      {betsSlider && (
        <BetsSidebar
          setBetsSlider={setBetsSlider}
          screenWidth={width}
          addedBet={addedBet}
          setAddedBet={setAddedBet}
          betDetails={betDetails}
          setDrawerSlider={setDrawerSlider}
          setActiveBetId={setActiveBetId}
        />
      )}
      {drawerSlider && !betsSlider && (
        <ResponsiveDrawer
          drawerOption={drawerOption}
          setDrawerOption={setDrawerOption}
          setDrawerSlider={setDrawerSlider}
        />
      )}
    </Styled.SportsBook>
  )
}

export default SportsBook
