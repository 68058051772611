import styled from 'styled-components'
import { color } from '../../color/color'
import { Link } from 'react-router-dom'
export const ListItem = styled.div`
  background: ${color._343435};
  width: 100%;
  position: sticky;
  top: ${({ isauthenticated }) => (isauthenticated ? '60px' : '60px')};
  z-index: 9;
  padding-left: 0px;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    background: ${color._FFFFFF};
    top: ${({ isauthenticated }) => (isauthenticated ? '99px' : '60px')};
  }
  .dropdown-menu {
    inset: 0px 0px auto auto !important;
  }
`
export const ListItemWrap = styled.span`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  text-align: center;
  overflow-y: hidden;
  overflow-x: overlay;
  display: flex;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent; /* You can change this to any color you want */
  }
`
export const TabItem = styled.div`
  list-style: none;
  display: inline-flex;
  align-items: center;
  color: ${color._FFFFFF};
  font-family: 'Titillium Web', sans-serif;
  ${({ itemname }) =>
    (itemname === 'CASINO' || itemname === 'INT CASINO') &&
    `background-color: ${color._6D081D}; color: ${color._FFFFFF}; `}
  ${({ isactive }) =>
    isactive && `background-color: ${color._C10930}; color: ${color._FFFFFF}; `}
  @media (max-width: 991px) {
    ${({ isactive }) =>
      isactive &&
      `background-color: ${color._EEEEEE}; color: ${color._000000}; !important `}
  }
  cursor: pointer;
`
export const Item = styled.div`
  border-right: 1px solid ${color._343435};
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 25px;
  font-weight: 500;
  position: relative;
  @media (max-width: 991px) {
    color: ${color._000000};
    border-right: 1px solid ${color._EEEEEE};
    padding: 5px 10px;
    font-size: 10px;
    color: #5b5656;
    ${({ itemname }) =>
      (itemname === 'CASINO' || itemname === 'INT CASINO') &&
      ` color: ${color._FFFFFF}; !important `}
    ${({ isactive }) =>
      isactive &&
      `background-color: ${color._EEEEEE}; color: ${color._000000}; !important `}
  }
  img {
    display: block;
    height: 18px;
    margin: 0 auto;
    width: 17px;
    height: 17px;
    font-size: 8px;
  }
`

export const ProfilePosition = styled.div`
  position: absolute;
  right: ${({ isauthenticated }) => (isauthenticated ? ' 3.5rem' : '4.5rem')};
  top: -42px;
  @media (max-width: 710px) {
    position: absolute;
    right: 6px;
    top: -42px;
  }
  @media (max-width: 991px) {
    top: ${({ isauthenticated }) => (isauthenticated ? '-82px' : '-42px')};
  }
`
export const Nav = styled.div`
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ crossposition }) => crossposition};
  z-index: 10;
  position: absolute;
  background-color: ${color._FFFFFF};
  right: 0;
`
export const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 13px;
  font-size: 23px;
  display: none;
  @media (max-width: 991px) {
    display: block;
    height: auto;
    margin-top: -4px;
  }
`
