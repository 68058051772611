import VideoPlayer from './VideoPlayer'
import * as Styled from '../styles'
import GameRuleHeader from './GameRuleHeader'

const MiddleContent = ({
  handleModal,
  toggle,
  gameName,
  roundId,
  handlePlayerHistory,
  images,
  event_id,
}) => {
  return (
    <>
      <Styled.MiddleContainerHeader toggle={toggle}>
        <GameRuleHeader
          handleModal={handleModal}
          gameName={gameName}
          roundId={roundId}
          handlePlayerHistory={handlePlayerHistory}
        />
      </Styled.MiddleContainerHeader>
      <VideoPlayer
        videoUrl={`https://tv.bet99.io/ipl.php?id=${event_id}`}
        images={images}
      />
    </>
  )
}

export default MiddleContent
