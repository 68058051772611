import styled from 'styled-components'
import { color } from '../../../color/color'

export const Container = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-size: 14px !important;
  font-weight: 700;
  border: 1px solid grey;
  padding: 20px;
  color: ${color._000};
`

export const Row = styled.div``
export const RowGap = styled.div``
export const Col = styled.div``
export const NamedContainer = styled.div`
  padding: 5px;
`

export const BetInitialContainer = styled.div`
  border: 1px solid yellow;
  padding: 0 !important;
`

export const BetContainer = styled.div`
  border: 1px solid yellow;
  text-align: center;
  background-color: #05edde;
  padding: 0 !important;
`

export const BoxContainer = styled.div`
  background-color: gray;
  color: white;
  font-size: 16px;
  padding: 5px;
  width: 100% !important;
  height: 100%;
  text-align: center;
`

export const CenteredText = styled.div`
  font-size: 18px;
  font-weight: 700;
  height: 50px;
`

export const ImageColumn = styled.img``

export const AndarBaharBottomContainerMiddleRowContainer = styled.div``

export const AndarBaharBottomContainerBottomRowContainer = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-size: 14px !important;
  font-weight: 700;
  border: 1px solid grey;
  padding: 20px;
`
