import {
  getOpenFancyBetsData,
  getOpenMatchedBetsData,
  setOpenFancyData,
  setOpenMatchedData,
} from '../../utils/utils'
import ACTIONTYPES from './actionType'

const { openMatchedBets } = getOpenMatchedBetsData()
const { openFancyBets } = getOpenFancyBetsData()

const initialState = {
  data: [],
  errorMessage: null,
  odds: [],
  score: {},
  isLoading: true,
  stopEmit: true,
  betStatus: false,
  openMatchedBets: openMatchedBets || [],
  openFancyBets: openFancyBets || [],
}

const matchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONTYPES.MATCH_DATA_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        errorMessage: null,
      }
    case ACTIONTYPES.MATCH_DATA_ODD_FETCH_SUCCESS:
      return {
        ...state,
        odds: action.payload,
        errorMessage: null,
        isLoading: false,
        stopEmit: false,
      }
    case ACTIONTYPES.MATCH_DATA_SCORE_FETCH_SUCCESS:
      return {
        ...state,
        score: action.payload,
        errorMessage: null,
        isLoading: false,
        stopEmit: false,
      }
    case ACTIONTYPES.MATCH_DATA_FETCH_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case ACTIONTYPES.PLACE_BET_FETCH_SUCCESS:
      return {
        ...state,
        betStatus: action.payload,
      }
    case ACTIONTYPES.MATCHED_BET_SUCCESS:
      setOpenMatchedData({ openMatchedBets: action.payload })
      return {
        ...state,
        openMatchedBets: action.payload,
      }
    case ACTIONTYPES.FANCY_BET_SUCCESS:
      setOpenFancyData({ openFancyBets: action.payload })
      return {
        ...state,
        openFancyBets: action.payload,
      }
    default:
      return state
  }
}

export default matchReducer
