import styled from 'styled-components'
import { color } from '../../../color/color'

export const CasinoTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${color._333333};
  margin-top: -2px;
`
export const CasinoTableBox = styled.div`
  width: 100%;
`

export const MeterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`
export const MeterButton = styled.div`
  width: 48%;
`

export const BtnBox = styled.div`
  width: 100%;
  cursor: none;
  pointer-events: none;
  position: relative;
  & ::before {
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    background-image: url(https://g1ver.sprintstaticdata.com/v11/static/front/img/icons/lock.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 17px 17px;
    filter: invert(1);
    -webkit-filter: invert(1);
    z-index: 1;
  }
`

export const Fighter1 = styled.button`
  & ::before {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    transition: all 0.3s;
    width: 0;
    z-index: -1;
  }
  align-items: center;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  display: flex;
  font-size: 24px;
  font-weight: 700;
  height: 70px;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  width: 100%;
  background-color: rgb(82, 165, 249);
  z-index: 1;
  border: 3px solid ${color._E22739};

  & ::after {
    background-color: var(--back);
    border: 3px solid ${color._E22739};
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    bottom: 0;
    box-shadow: 0 0 6px ${color._C8C8C8};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -2;
  }
  & :hover {
    color: ${color._FFFFFF};
  }
  & btn:hover {
    background-color: ${color._CCCCCC};
    color: ${color._000000};
    opacity: 0.85;
  }
  img {
    height: 40px;
    margin-left: 10px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
`
export const Fighter2 = styled.button`
  align-items: center;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  display: flex;
  font-size: 24px;
  font-weight: 700;
  height: 70px;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  width: 100%;
  z-index: 1;
  background-color: rgb(82, 165, 249);
  border: 3px solid ${color._ECC116};
  img {
    height: 40px;
    margin-right: 10px;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
  }
  & ::before {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    bottom: 0;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    transition: all 0.3s;
    width: 0;
    z-index: -1;
  }
  & ::after {
    background-color: ${color._000000};

    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    bottom: 0;
    box-shadow: 0 0 6px ${color._C8C8C8};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -2;
  }
`
