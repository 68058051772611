import React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as BsIcons from 'react-icons/bs'
import IPL from '../../assets/sidebar/cricket.png'
import LOK from '../../assets/sidebar//menu-loksabha.png'
import Multi from '../../assets/sidebar/menu-market.png'
import Cricket from '../../assets/sidebar/menu-4.png'
import Football from '../../assets/sidebar/menu-1.png'
import Tennis from '../../assets/sidebar/menu-2.png'
import Politics from '../../assets/sidebar/menu-2378961.png'
import Horse from '../../assets/sidebar/menu-7.png'
import Greyhound from '../../assets/sidebar/menu-4339.png'
import Kabaddi from '../../assets/sidebar/menu-99994.png'
import Casino from '../../assets/sidebar/menu-99999.png'
import Sports from '../../assets/sidebar/menu-99991.png'
import Int from '../../assets/sidebar/menu-99998.png'
import Binary from '../../assets/sidebar/menu-99990.png'
import Whatsapp from '../../assets/sidebar/wp.png'
import image1 from '../../assets/sidebar/c_01.png'
import image2 from '../../assets/sidebar/c_02.png'
import image3 from '../../assets/sidebar/c_03.png'
import image4 from '../../assets/sidebar/c_04.png'
import deposit from '../../assets/images/deposit.png'
import withdrawal from '../../assets/images/withdrawal.png'

export const SidebarData = [
  {
    title: 'IPL 2024',
    path: '',
    icon: IPL,
  },
  {
    title: 'LOK SABHA 2024',
    path: '',
    icon: LOK,
  },
  {
    title: 'Multi Market',
    path: '',
    icon: Multi,
  },
  {
    title: 'Cricket',
    path: '',
    icon: Cricket,
    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'Indian Premier League',
        path: '',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'Indian Premier League',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: 'Rajasthan Royals v Gujarat Titans',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: 'Mumbai Indians v Royal Challengers Bengaluru',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
      {
        title: 'Others',
        path: '',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'Northerns Goodwood cc v Victoria cc',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: '🎮New Zealand T10 vs West Indies T10',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: '🎮Pakistan T10 vs Sri Lanka T10',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
      {
        title: 'Womens One Day Internationals',
        path: '',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'South Africa Women v Sri Lanka Women',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
    ],
  },
  {
    title: 'Football',
    path: '',
    icon: Football,
    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'UEFA Europa League',
        path: '/reports/reports1',
        cName: 'sub-nav',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'UEFA Europa League',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: 'Liverpool v Atalanta',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: 'Benfica v Marseille',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
      {
        title: 'UEFA Champions League',
        path: '/reports/reports2',
        cName: 'sub-nav',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'UEFA Champions League',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
      {
        title: 'Azerbaijan 1st Division',
        path: '/reports/reports3',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'Azerbaijan 1st Division',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
      {
        title: 'Armenian Premier League',
        path: '/reports/reports3',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'Armenian Premier League',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
      {
        title: 'Greek Super League 2',
        path: '/reports/reports3',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'Greek Super League 2',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
      {
        title: 'Egyptian Premier',
        path: '/reports/reports3',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'Egyptian Premier',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
    ],
  },
  {
    title: 'Tennis',
    path: '',
    icon: Tennis,

    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'ATP Monte Carlo Masters',
        path: '/messages/message1',
      },
      {
        title: 'ATP Split Challenger',
        path: '/messages/message2',
      },
      {
        title: 'ATP Madrid Challenger',
        path: '/messages/message2',
      },
      {
        title: 'ATP Sarasota Challenger',
        path: '/messages/message2',
      },
      {
        title: 'ATP Morelos Challenger',
        path: '/messages/message2',
      },
    ],
  },
  {
    title: 'Politics',
    path: '',
    icon: Politics,

    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'Others',
        path: '/messages/message1',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'LOK SABHA 2024',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
    ],
  },

  {
    title: 'Horse Racing',
    path: '',
    icon: Horse,

    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'Pakenham (AUS) 11th Apr (15:45)',
        path: '/messages/message1',
      },
      {
        title: 'Penrith (AUS) 11th Apr (15:51)',
        path: '/messages/message2',
      },
      {
        title: 'Pakenham (AUS) 11th Apr (16:15)',
        path: '/messages/message1',
      },
      {
        title: 'Penrith (AUS) 11th Apr (16:21)',
        path: '/messages/message2',
      },
      {
        title: 'Penrith (AUS) 11th Apr (16:52)',
        path: '/messages/message1',
      },
      {
        title: 'Penrith (AUS) 11th Apr (17:24)',
        path: '/messages/message2',
      },
    ],
  },
  {
    title: 'Greyhound Racing',
    path: '',
    icon: Greyhound,

    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'Nottingham 11th Apr (15:39)',
        path: '/messages/message1',
      },
      {
        title: 'Sheffield 11th Apr (15:47)',
        path: '/messages/message2',
      },
      {
        title: 'Hobart (AUS) 11th Apr (15:49)',
        path: '/messages/message2',
      },
      {
        title: 'Warrnambool (AUS) 11th Apr (15:52)',
        path: '/messages/message2',
      },
    ],
  },
  {
    title: 'Kabaddi',
    path: '',
    icon: Kabaddi,

    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'Kabaddi',
        path: '/messages/message1',
      },
    ],
  },

  {
    title: 'Casino',
    path: '',
    icon: Casino,

    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'Casino 1',
        path: '/messages/message1',
        iconClosed: <BiIcons.BiDownArrow color="black" />,
        iconOpened: <BsIcons.BsCaretUp color="black" />,
        list: [
          {
            title: 'Super Over',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: '3 Card Judgement',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: 'Live Teenpatti',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: 'Teenpatti T20',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
          {
            title: 'Teenpatti Test',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
    ],
  },
  {
    title: 'Sports Book',
    path: '',
    icon: Sports,
  },
  {
    title: 'Int Casino',
    path: '',
    icon: Int,
  },

  {
    title: 'Binary',
    path: '',
    icon: Binary,

    iconClosed: <BiIcons.BiDownArrow color="black" />,
    iconOpened: <BsIcons.BsCaretUp color="black" />,

    subNav: [
      {
        title: 'Others',
        path: '/messages/message1',
        list: [
          {
            title: 'Binary / 2024-04-11',
            iconClosed: <BiIcons.BiDownArrow color="black" />,
            iconOpened: <BsIcons.BsCaretUp color="black" />,
          },
        ],
      },
    ],
  },
  {
    title: 'Whatsapp Support',
    path: '',
    icon: Whatsapp,
  },
]
export const CarouselData = [
  {
    src: image1,
  },
  {
    src: image2,
  },
  {
    src: image3,
  },
  {
    src: image4,
  },
]
export const depositData = {
  title: 'Deposit',
  icon: deposit,
  path: '/deposit',
}
export const withdrawalData = {
  icon: withdrawal,
  title: 'Withdrawal',
  path: '/withdraw',
}
