import styled from 'styled-components'
import { color } from '../../../../color/color'

export const BetDetail = styled.div`
  width: 50%;
  align-items: center;
  justify-content: ${({ availableBet }) =>
    availableBet ? 'space-between' : 'space-around'};
  background-color: ${({ active }) => (active ? '#8A4E00' : color._116257)};
  color: ${color._FFFFFF};
  font-weight: bold;
  flex-basis: 48%;
  cursor: pointer;
  @media (max-width: 908px) {
    width: 100%;
  }
`

export const BetTitle = styled.div`
  width: 80%;
  margin-left: 5px;
`

export const IconValue = styled.div`
  width: 20%;
  align-items: center;
`

export const Icon = styled.div`
  font-size: 15px;
  margin-bottom: 3px;
  width: 50%;
`

export const Value = styled.div`
  width: 50%;
`
export const MatchDetails = styled.div``
export const MatchDetailsHeading = styled.div`
  align-items: center;
  justify-content: space-between;
  background-color: #cccccc;
  padding: 10px;
  border-radius: 20px 20px 0px 0px;
`

export const MatchTitle = styled.div`
  background-color: #116257;
  color: white;
  padding: 0.6rem;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
`
export const MinMaxFav = styled.div`
  width: 20%;
  align-items: center;
  justify-content: space-between;
`
export const MinMax = styled.div``
export const Min = styled.div`
  padding-right: 5px;
`
export const Max = styled.div``
export const Fav = styled.div`
  padding-bottom: 3px;
  cursor: pointer;
`
export const MatchDetailsContent = styled.div`
  height: auto;
  background-color: black;
  margin-bottom: 10px;
`

export const MDTeamScore = styled.div`
  color: white;
  align-items: center;
  justify-content: space-between;
`

export const MDTeam = styled.div`
  margin-left: ${({ left }) => (left ? '5px' : null)};
  margin-right: ${({ right }) => (right ? '5px' : null)};
`

export const TeamName = styled.div`
  margin-top: ${({ CRR }) => (CRR ? '10px' : null)};
  font-weight: 500;
  text-align: center;
`
export const CRR = styled.div`
  font-size: 10px;
`
export const MDContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`
export const InnOvers = styled.div`
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
  background-color: #222241;
  color: #fff;
  border-color: hsla(0, 0%, 100%, 0.12);
`
export const InnValues = styled.div`
  padding: 2px;
  border: 1px solid hsla(0, 0%, 100%, 0.12);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-style: solid;
  border-width: 0 1px 1px;
  font-size: 10px;
`
export const Score = styled.div`
  font-weight: 500;
`
export const Comparison = styled.div`
  font-size: 10px;
`

export const MDGraph = styled.div`
  width: 80%;
  height: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & .linechart {
    width: 600px;
  }
`

export const MDGraphData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

export const TeamNames = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6%;
`
export const Team = styled.div`
  color: #fff;
  border-radius: 8.5px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-size: 20px;
  margin: 4px 0;
  width: 100px;
  padding: 2px 8px;
  text-align: center;
  background-color: ${({ active }) => (active ? '#fd2954' : null)};
`

export const GraphIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6%;
`
export const GraphI = styled.div`
  width: 50px;
  height: 50px;
  color: ${({ active }) => (active ? '#fd2954' : '#fff')};
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  transform: ${({ rotate }) => (rotate ? 'rotate(90deg)' : null)};
  margin-left: ${({ rotate }) => (!rotate ? '15px' : null)};
  border-bottom: ${({ rotate, active }) =>
    rotate && active ? '1px solid #fd2954' : null};
  border-left: ${({ rotate, active }) =>
    !rotate && active ? '1px solid #fd2954' : null};
`
export const MDScoreData = styled.div`
  color: white;
  display: flex;
  height: 300px;
`

export const BallDataDiv = styled.div`
  background: #334932;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: scroll;
`
export const Over = styled.div`
  text-align: center;
`
export const BallData = styled.div``
export const SingleBallDiv = styled.div`
  border-left: 1px solid hsla(0, 0%, 100%, 0.13);
  margin: 4px;
`
export const Ball = styled.div`
  border: 1px solid transparent;
  border-radius: 50%;
  font-weight: 500;
  height: 25px;
  line-height: 20px;
  margin: 0 3px;
  width: 25px;
  background: ${({ wicket }) => (wicket ? '#fff' : '#707f6f')};
  color: ${({ wicket }) => (wicket ? '#000' : '#fff')};
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const SVGData = styled.div`
  width: 50%;
  height: 300px;
  background-color: #5f9a24;
  background-image: -webkit-radial-gradient(50% 0, ellipse 100% 400%, hsla(0, 0%, 100%, .14) 0, rgba(0, 0, 0, .11) 100%);
  background-image: radial-gradient(ellipse 100% 400% at 50% 0, hsla(0, 0%, 100%, .14) 0, rgba(0, 0, 0, .11) 100%);
  color: hsla(0, 0%, 100%, .64);
  position: relative;
  background-image: url(https://widgets.sir.sportradar.com/assets/media/noise.f019ce8d.png);
}
`
export const NormalScoreCard = styled.div`
  width: 50%;
  padding: 10px;
  overflow: scroll;
`
export const StatsDiv = styled.div``
export const BattingInfo = styled.div`
  padding: 20px;
  font-size: 12px;
`
export const BattingDiv = styled.div`
  justify-content: space-around;
`
export const BatterTable = styled.div`
  border-bottom: ${({ heading }) =>
    heading ? '1px solid hsla(0, 0%, 100%, .2)' : null};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : null)};
`
export const BatterStatus = styled.div`
  font-size: 10px;
  padding-bottom: 4px;
  color: ${({ notOut }) => (notOut ? '#FD2954' : null)};
`
export const Batter = styled.div`
  width: 40%;
  color: ${({ batterColor }) => batterColor};
`
export const BatTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15%;
`

export const Comments = styled.div`
  font-size: 12px;
`

export const BowlerInningsStats = styled.div`
  justify-content: space-around;
  background-image: url(https://widgets.sir.sportradar.com/assets/media/pitch.daa4b87d.png);
  background-repeat: no-repeat;
  font-size: 12px;
`
export const CurrentBowler = styled.div`
  width: 44%;
`
export const InningsStats = styled.div`
  width: 44%;
`
export const StatsHeading = styled.div`
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
`
export const Stats = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StatsTitle = styled.div``
export const StatsValue = styled.div``
export const ScoreCardHeading = styled.div`
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  font-size: 12px;
`
export const TeamButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
`
export const TeamButton = styled.div`
  display: flex;
`
export const TeamTitle = styled.div`
  margin: 0px 5px 0px 5px;
  font-size: 12px;
  border-bottom: ${({ active }) => (active ? '1px solid #fd2954' : null)};
  cursor: pointer;
`
export const Span = styled.div``
export const MDOptions = styled.div``
export const MDOption = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-around;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ active }) => (active ? '5px solid #fd2954' : null)};
`
export const IconImage = styled.img`
  filter: ${({ active }) =>
    active
      ? 'brightness(0) saturate(100%) invert(36%) sepia(43%) saturate(6694%) hue-rotate(330deg) brightness(96%) contrast(108%)'
      : null};
`
export const Option = styled.div`
  display: flex;
  color: ${({ active }) => (active ? '#fd2954' : '#fff')};
`
export const OptionIcon = styled.div`
  margin-right: 5px;
`
export const OptionTitle = styled.div``

export const GraphView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fd2954;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
`
export const TeamScorecard = styled.div`
  font-size: 14px;
`
export const Statistics = styled.div`
width: 50%;
padding: 10px;
overflow: scroll;
`
export const StatsInings = styled.div`

`
export const HeadingDiv = styled.div`

`
export const HeadIcon = styled.div`

`
export const HeadingIcon = styled.div`

`
export const HeadScore = styled.div`

`