import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { Block, Box, Container, LeftBlock, RightBlock, Row } from './styles'
import { Tabs } from '../components/Tabs/Tab'
import { highlightedData } from '../container/HomePage/constants'
import HighlightedCard from '../components/HighlightedCard/HighlightedCard'
import { useNavigate, useLocation } from 'react-router-dom'
import Toast from '../components/Toast/Toast'
import TransactionCard from '../components/TransactionCard/TransactionCard'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSports } from '../redux/auth/action'

function Layout({ children }) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const [sidebar, setSidebar] = useState(
    window.innerWidth < 1200 ? false : true,
  )
  const [toggle, setToggle] = useState(false)
  const [view, setView] = useState({ name: 'HOME', id: 1 })
  const avoidRerender = useRef(false)
  const [sports, setSports] = useState([])
  const sportsData = useSelector((state) => state.auth.sportsData) || []

  const showSidebar = () => setSidebar(!sidebar)

  useEffect(() => {
    const data =
      sportsData?.length &&
      sportsData?.sort((a, b) => {
        return a?.sortOrder - b?.sortOrder
      })
    setSports(data)
  }, [sportsData])

  useEffect(() => {
    if (!location.pathname.includes('detail')) {
      const currentView = sportsData?.find(
        (item) => item.path === location.pathname,
      )
      const name = currentView ? currentView.name : ''
      const id = currentView ? currentView.id : 0
      setView({ name, id })
    }
  }, [location.pathname, sportsData])

  useEffect(() => {
    if (!avoidRerender.current) {
      sportsData?.length === 0 && dispatch(fetchSports())
    }
    return () => (avoidRerender.current = true)
  }, [])

  return (
    <Container>
      <Row>
        <Box></Box>
      </Row>
      <Row>
        <Box flex={1} ismobile={true}>
          <Header setToggle={setToggle} toggle={toggle} />
          {isAuthenticated ? <TransactionCard /> : <></>}
          <Tabs
            selectedTab={view}
            tabs={sports}
            onTabChange={(e) => {
              setView(e)
              navigate(e.path)
            }}
            setSidebar={setSidebar}
            sidebar={sidebar}
            showSidebar={showSidebar}
          />
          <HighlightedCard item={highlightedData} />
          <Block>
            <LeftBlock ismobile={true}>
              <Sidebar
                setSidebar={setSidebar}
                sidebar={sidebar}
                showSidebar={showSidebar}
              />
            </LeftBlock>
            <RightBlock>
              {React.cloneElement(children, { view: view })}
            </RightBlock>
          </Block>
        </Box>
      </Row>
      <Toast />
    </Container>
  )
}

export default React.memo(Layout)
