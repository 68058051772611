export const players_data = [
  { label: 'Winner', stats: { value: 1.98, isSuspended: true } },
  { label: 'One Pair', stats: { value: 2.84, isSuspended: true } },
  { label: 'Two Pair', stats: { value: 1.98, isSuspended: true } },
  { label: 'Three of a Kind', stats: { value: 1.98, isSuspended: false } },
  { label: 'Straight', stats: { value: 2, isSuspended: false } },
  { label: 'Flush', stats: { value: 1.98, isSuspended: false } },
  { label: 'Full House', stats: { value: 1.98, isSuspended: false } },
  { label: 'Four of a Kind', stats: { value: 2, isSuspended: false } },
  { label: 'Straight Flush', stats: { value: 1.98, isSuspended: false } },
]

export const players_row = ['Player A', 'Player B']
