import React from 'react'
import * as Styled from '../style'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import {
  MyBetsOptions,
  MyBetsTableHeader,
  constants,
} from '../../../utils/constant'
import MuiTable from '../../../components/MuiTable/MuiTable'
import dayjs from 'dayjs'
const MyBets = ({ width }) => {
  const MyBetsSelect = [
    {
      label: 'Select Sports',
      options: MyBetsOptions,
      id: 'select_sports',
    },
    {
      label: 'Bet Status',
      options: MyBetsOptions,
      id: 'bet_status',
    },
  ]

  const date = new Date()
  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  const MyBetsDatePicker = [
    {
      label: 'From Date',
      defaultValue: dayjs(
        `${sevenDaysAgo.getFullYear()}-${
          sevenDaysAgo.getMonth() + 1
        }-${sevenDaysAgo.getDate()}`,
      ),
    },
    {
      label: 'To Date',
      defaultValue: dayjs(
        `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      ),
    },
  ]
  // needed for reference at the time of api integration....
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein }
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ]

  return (
    <Styled.MyBets>
      <Styled.MyBetsTitle marginLeft={true}>
        {constants.MY_BETS}
      </Styled.MyBetsTitle>
      <Styled.Filters>
        {MyBetsDatePicker.map((elem, indx) => {
          return (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              key={elem.label + indx}
            >
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label={elem.label}
                  defaultValue={elem.defaultValue}
                />
              </DemoContainer>
            </LocalizationProvider>
          )
        })}
        {MyBetsSelect.map((elem, indx) => {
          return (
            <Box sx={{ width: 200, paddingTop: '8px' }} key={elem.label + indx}>
              <FormControl fullWidth>
                <InputLabel id={elem.label}>{elem.label}</InputLabel>
                <Select labelId={elem.label} id={elem.label} label={elem.label}>
                  {elem.options.map((elem, indx) => {
                    return (
                      <MenuItem key={elem + indx} value={10}>
                        {elem}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          )
        })}
      </Styled.Filters>
      <MuiTable header={MyBetsTableHeader(width)} rows={rows} />
    </Styled.MyBets>
  )
}

export default MyBets
