import React from 'react'
import * as Styled from '../style'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import {
  CloseDrawerArr,
  UpperContent,
  LowerContent,
  constants,
} from '../../../utils/constant'
import GridOption from './GridOption'
import SBMatchOption from './SBMatchOption'

const Drawer = ({
  openDrawer,
  setOpenDrawer,
  setActiveMatchOption,
  activeMatchOption,
  activeOption,
  setActiveOption,
}) => {
  const handleGridOption = (e) => {
    setActiveOption(e)
  }

  const handleMatchOption = (e) => {
    setActiveMatchOption(e)
  }

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }
  return (
    <Styled.Drawer opendrawer={openDrawer} className="sport-book-drawer">
      {openDrawer && (
        <Styled.CloseBtn onClick={handleDrawer} activeOption={activeOption}>
          <IoIosArrowBack />
        </Styled.CloseBtn>
      )}
      {!openDrawer && (
        <Styled.OpenBtn onClick={handleDrawer} activeOption={activeOption}>
          <IoIosArrowForward />
        </Styled.OpenBtn>
      )}
      <Styled.UpperContent opendrawer={openDrawer}>
        {openDrawer
          ? UpperContent.map((elem, indx) => {
              return (
                <GridOption
                  key={elem.title + indx}
                  title={elem.title}
                  svg={elem.svg}
                  handleGridOption={handleGridOption}
                  activeOption={activeOption}
                  opendrawer={openDrawer}
                />
              )
            })
          : CloseDrawerArr.map((elem, indx) => {
              return (
                <GridOption
                  key={elem.title + indx}
                  title={elem.title}
                  svg={elem.svg}
                  handleGridOption={handleGridOption}
                  activeOption={activeOption}
                  opendrawer={openDrawer}
                />
              )
            })}
      </Styled.UpperContent>
      <Styled.LowerContent>
        {openDrawer && (
          <Styled.LowerContentTitle>
            {constants.All_Sports}
          </Styled.LowerContentTitle>
        )}
        {LowerContent.map((elem, indx) => {
          return (
            <SBMatchOption
              key={elem.title + indx}
              title={elem.title}
              src={elem.icon}
              handleMatchOption={handleMatchOption}
              activeMatchOption={activeMatchOption}
              openDrawer={openDrawer}
            />
          )
        })}
      </Styled.LowerContent>
    </Styled.Drawer>
  )
}

export default Drawer
