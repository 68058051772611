import React, { useEffect, useState } from 'react'
import * as Styled from './style'
import * as BiIcons from 'react-icons/bi'
import * as BsIcons from 'react-icons/bs'
import MatchList from './MatchList'
import { _constants } from './constants'
import { color } from '../../color/color'
const MatchSubDetails = ({
  handleBetSection,
  eventDetails,
  handleCloseBetSection,
  currentSportsId,
  showBetSection,
  restructuredBookmakerData,
  backlayAmount,
  bgColor,
  oddStr,
  placebetStatus,
}) => {
  const [subnav, setSubnav] = useState(true)
  const [restructuredBookmakerDataState, setRestructuredBookmakerDataState] =
    useState(true)
  const showSubnav = () => setSubnav(!subnav)

  useEffect(() => {
    setRestructuredBookmakerDataState(restructuredBookmakerData)
  }, [restructuredBookmakerData])

  const MatchTitle = () => {
    return (
      <div>
        <Styled.MatchContainer>
          <Styled.Row>
            <Styled.Col6>
              <Styled.SportsTitle>
                {restructuredBookmakerData?.mtype}
              </Styled.SportsTitle>
            </Styled.Col6>
          </Styled.Row>
        </Styled.MatchContainer>
      </div>
    )
  }

  return (
    <>
      <Styled.SidebarLink>
        <MatchTitle />
        <div>
          <Styled.MinMax class="min-max">
            {_constants.Min} {restructuredBookmakerData?.bet_min} |{' '}
            {_constants.Max}
            {restructuredBookmakerData?.bet_max}
            <a class="ms-2 game-rules-icon">
              <span>
                <i
                  class="bi bi-info-square-fill"
                  style={{ color: color._6D081D }}
                ></i>
              </span>
            </a>
            {!subnav ? (
              <div className="m-1" onClick={showSubnav}>
                <BsIcons.BsCaretUp color={color._000000} />
              </div>
            ) : (
              <div className="m-1" onClick={showSubnav}>
                <BiIcons.BiDownArrow color={color._000000} />
              </div>
            )}
          </Styled.MinMax>
        </div>
      </Styled.SidebarLink>
      <Styled.BackLayContainer>
        <Styled.LeftContainer></Styled.LeftContainer>
        <Styled.RightContainer>
          <Styled.Heading>{_constants.Back}</Styled.Heading>
          <Styled.Heading>{_constants.Lay}</Styled.Heading>
        </Styled.RightContainer>
      </Styled.BackLayContainer>
      {/* {subnav &&
        restructuredBookmakerDataState &&
        restructuredBookmakerDataState.teamData &&
        restructuredBookmakerDataState.teamData.length > 0 &&
        restructuredBookmakerDataState.teamData.map((item, index) => {
          return (
            <MatchList
              item={item}
              key={item.name + index}
              handleBetSection={handleBetSection}
              handleCloseBetSection={handleCloseBetSection}
              currentSportsId={currentSportsId}
              showBetSection={showBetSection}
              maxBet={restructuredBookmakerData?.data.max_bet}
              minBet={restructuredBookmakerData?.data.min_bet}
              backlayAmount={backlayAmount}
              bgColor={bgColor}
              bet_delay={restructuredBookmakerData?.data?.bet_delay}
              odd_type={restructuredBookmakerData?.data?.odd_type}
              teamName={item?.name}
              strValue={restructuredBookmakerData?.str}
              restructuredBookmakerDataState={restructuredBookmakerDataState}
              oddStr={oddStr}
              placebetStatus={placebetStatus}
              matchType={restructuredBookmakerData?.data?.type}
            />
          )
        })} */}

      <MatchList
        item={restructuredBookmakerData}
        key={restructuredBookmakerData.eventId}
        handleBetSection={handleBetSection}
        handleCloseBetSection={handleCloseBetSection}
        currentSportsId={currentSportsId}
        showBetSection={showBetSection}
        maxBet={restructuredBookmakerData?.bet_max}
        minBet={restructuredBookmakerData?.bet_max}
        backlayAmount={backlayAmount}
        bgColor={bgColor}
        bet_delay={restructuredBookmakerData?.delay}
        odd_type={restructuredBookmakerData?.type}
        teamName={restructuredBookmakerData?.name1}
        strValue={restructuredBookmakerData?.str}
        restructuredBookmakerDataState={restructuredBookmakerDataState}
        oddStr={oddStr}
        placebetStatus={placebetStatus}
        matchType={restructuredBookmakerData?.data?.type}
      />
    </>
  )
}

export default MatchSubDetails
