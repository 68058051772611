import React, { useState } from 'react'
import * as Styled from '../style'
import { LowerContent } from '../../../utils/constant'
import IconTitle from './IconTitle'
const Favourite = () => {
  const [activeSport, setActiveSport] = useState('Cricket')

  const handleActiveSport = (e) => {
    setActiveSport(e)
  }
  return (
    <Styled.Favourite>
      <Styled.MyBetsTitle>FAVOURITES</Styled.MyBetsTitle>
      <Styled.ResultOptions className="display-flex result-options">
        {LowerContent.map((elem, indx) => {
          return (
            <IconTitle
              key={elem.title + indx}
              title={elem.title}
              src={elem.icon}
              openDrawer={true}
              individualView={true}
              activeMatchOption={activeSport}
              handleMatchOption={handleActiveSport}
            />
          )
        })}
      </Styled.ResultOptions>
      <Styled.FavouriteContent>
        !!! No Favourite Events !!!
      </Styled.FavouriteContent>
    </Styled.Favourite>
  )
}

export default Favourite
