import { useReducer } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetPassword, forgetPasswordOTP } from '../redux/auth/action'
import { constants } from '../constants/constants'
export default function useResetPassword() {
  const navigate = useNavigate()
  // Initial state for the login form

  const initialState = {
    phone: '',
    country: '',
    showOTP: false,
    otpSent: false,
    otp: '',
    password: '',
    confirmPassword: '',
  }

  const ActionTypes = {
    SET_PHONE: 'SET_PHONE',
    SET_COUNTRY: 'SET_COUNTRY',
    SHOW_OTP: 'SHOW_OTP',
    OTP_RESENT: 'OTP_RESENT',
    OTP_SENT: 'OTP_SENT',
    SET_PASSWORD: 'SET_PASSWORD',
    SET_OTP: 'SET_OTP',
    SET_CONFIRM_PASSWORD: 'SET_CONFIRM_PASSWORD',
  }

  const resetReducer = (state, action) => {
    switch (action.type) {
      case ActionTypes.SET_PHONE:
        return { ...state, phone: action.payload }

      case ActionTypes.SET_COUNTRY:
        return { ...state, country: action.payload }

      case ActionTypes.SET_PASSWORD:
        return { ...state, password: action.payload }

      case ActionTypes.SET_CONFIRM_PASSWORD:
        return { ...state, confirmPassword: action.payload }

      case ActionTypes.SHOW_OTP:
        return { ...state, showOTP: action.payload }

      case ActionTypes.OTP_RESENT:
        return { ...state, otpResent: true }

      case ActionTypes.OTP_SENT:
        return { ...state, otpSent: true }

      case ActionTypes.SET_OTP:
        return { ...state, otp: action.payload }

      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(resetReducer, initialState)

  const dispatchRedux = useDispatch()
  const loader = useSelector((state) => state.loading)
  // Named constants
  const { MOBILE_REQUIRED, INVALID_MOBILE } = constants

  const showToastError = (message) => {
    toast.error(message)
    return false
  }

  const isMobileValid = () => {
    const { phone } = state
    if (phone.trim() === '') {
      return showToastError(MOBILE_REQUIRED)
    }
    const phoneRegex = /^\+?\d{1,3}\s?\d{6,14}$/
    if (!phoneRegex.test(phone.trim())) {
      return showToastError(INVALID_MOBILE)
    }
    return true
  }

  const validateForm = () => {
    const { showOTP, otp, password, confirmPassword } = state

    const isOTPValid = () => {
      if (showOTP && otp.trim() === '') {
        return showToastError(constants.OTP_REQUIRED)
      }
      return true
    }

    const isPasswordValid = () => {
      if (password.trim() === '') {
        return showToastError(constants.PASSWORD_REQUIRED)
      }
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/
      if (!passwordRegex.test(password.trim()) || password.trim().length < 6) {
        return showToastError(constants.INVALID_PASSWORD)
      }
      return true
    }

    const doPasswordsMatch = () => {
      if (confirmPassword.trim() === '') {
        return showToastError(constants.CONFIRM_PASSWORD_REQUIRED)
      }
      if (password !== confirmPassword) {
        return showToastError(constants.PASSWORD_MISMATCH)
      }
      return true
    }

    // Perform validations
    if (
      !state.showOTP ||
      !isOTPValid() ||
      !isPasswordValid() ||
      !doPasswordsMatch()
    ) {
      return false
    }

    return true
  }

  const resetState = () => {
    dispatch({ type: ActionTypes.SET_PHONE, payload: '' })
    dispatch({ type: ActionTypes.SET_COUNTRY, payload: '' })
    dispatch({ type: ActionTypes.SET_PASSWORD, payload: '' })
    dispatch({ type: ActionTypes.SET_CONFIRM_PASSWORD, payload: '' })
    dispatch({ type: ActionTypes.SHOW_OTP, payload: false })
    dispatch({ type: ActionTypes.OTP_RESENT, payload: false })
    dispatch({ type: ActionTypes.OTP_SENT, payload: false })
    dispatch({ type: ActionTypes.SET_OTP, payload: '' })
  }

  const handleFormSubmit = (event, closeModal) => {
    event.preventDefault()
    const { phone, showOTP } = state
    if (!showOTP && isMobileValid()) {
      if (loader.isLoading) return
      dispatchRedux(forgetPasswordOTP({ phone }))
      dispatch({ type: ActionTypes.SHOW_OTP, payload: true })
      dispatch({ type: ActionTypes.OTP_SENT })
    } else {
      if (validateForm()) {
        if (showOTP) {
          if (loader.isLoading) return
          dispatchRedux(resetPassword(state, navigate, closeModal))
        }
      }
    }
  }

  return {
    state,
    handleFormSubmit,
    dispatch,
    ActionTypes,
    resetState,
  }
}
