import styled from 'styled-components'
import { color } from '../../color/color'
import { Link } from 'react-router-dom'

export const Nav = styled.div`
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ crossposition }) => crossposition};
  z-index: 10;
  position: fixed;
  background-color: ${color._FFFFFF};
  right: 0;
`

export const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 13px;
  font-size: 23px;
  display: none;
  @media (max-width: 991px) {
    display: block;
    height: auto;
    margin-top: -4px;
  }
`
export const SidebarNav = styled.nav`
  background: ${color._FFFFFF};
  height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: sticky;

  top: 6.6rem;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
  min-width: 250px;
  width: 250px;
  z-index: 996;
  transition: all 0.3s;
  padding: 0;
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent; /* You can change this to any color you want */
  }
  scrollbar-color: ${color._AAB7CF} transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: ${color._FFFFFF};
  @media (max-width: 1200px) {
    display: ${({ sidebar }) => (!sidebar ? 'none ' : 'flex')};
  }
  @media (min-width: 1200px) {
    display: flex;
    left: 0;
  }
  @media (max-width: 991px) {
    top: 0;
    position: fixed;
    z-index: 1000;
    height: 100vh;
  }
`

export const SidebarWrap = styled.div`
  width: 100%;
`

// Submenu Css
const getBackgroundColor = (item) => {
  if (item.title == 'Deposit') {
    return color._006400
  } else if (item.title == 'Withdrawal') {
    return color._8B0000
  } else {
    return color._FFFFFF
  }
}
const getColor = (item) => {
  if (item.title == 'Deposit') {
    return color._FFFFFF
  } else if (item.title == 'Withdrawal') {
    return color._FFFFFF
  } else {
    return color._6D081D
  }
}

const getSpanColor = (item) => {
  if (item.title == 'Deposit') {
    return color._006400
  } else if (item.title == 'Withdrawal') {
    return color._8B0000
  } else {
    return color._6D081D
  }
}

const getFilter = (title) => {
  if (title == 'Deposit') {
    return 'invert(1)'
  } else if (title == 'Withdrawal') {
    return 'invert(1)'
  } else {
    return 'invert(0)'
  }
}
export const SidebarLink = styled(Link)`
  display: flex;
  color: ${color._6D081D};
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${color._F1F1F1};
  list-style: none;
  height: 39px;
  text-decoration: none;
  font-size: 18px;
  background-color: ${({ item }) => getBackgroundColor(item)};

  &:hover {
    background: ${color._FFF6EE};
    cursor: pointer;
    text-decoration: none;
    img {
      filter: invert(0);
    }
    span {
      color: ${({ item }) => getSpanColor(item)};
    }
  }
`

export const SidebarLabel = styled.span`
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  margin-left: 16px;
  color: ${({ item }) => getColor(item)};
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
  ${({ item }) =>
    (item.title == 'IPL 2024' || item.title == 'LOK SABHA 2024') &&
    `animation:blinker 1s linear infinite; `};

  font-family: 'Titillium Web', sans-serif;
`

export const Img = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  filter: ${({ title }) => getFilter(title)};
`
export const SvgSection = styled.div`
  svg {
    color: ${color._808080};
    width: 9px;
    margin-right: 10px;
  }
`
// List section

export const SidebarSubLabel = styled.span`
  margin-left: 16px;
  color: ${color._343435};
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  &:hover {
    color: ${color._C10930};
  }
`

export const ListItem = styled.span`
  margin-left: 26px;
  color: ${color._343435};
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 10px 0px;
  &:hover {
    color: ${color._C10930};
  }
`
export const SvgListSection = styled.div`
  svg {
    color: grey;
    width: 6px;
    margin-right: 10px;
  }
`
export const DropdownLink = styled.div`
  background: ${color._F6F9FF};
  min-height: 39px;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: ${color._343435};
  font-size: 18px;
  border-bottom: 1px solid ${color._F1F1F1};

  &:hover {
    background: ${color._F6F9FF};
    cursor: pointer;
    color: ${color._C10930};
  }
`
export const MobileLogo = styled.img`
  width: 117px;
  height: 36px;
  display: none;
  @media (max-width: 991px) {
    display: block;
    max-height: 30px;
    width: 100px;
  }
`
export const SidebarMopbileWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

export const ProfilePosition = styled.div`
  position: absolute;
  right: 7rem;
  top: -42px;
  @media (max-width: 710px) {
    position: absolute;
    right: 6px;
    top: -42px;
  }
`
