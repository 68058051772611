import React from 'react'
import {
  OddView,
  CasinoTable,
  CasinoTableFullBox,
  CasinoTableBox,
  CasinoVideoCardBox,
  CmeterLow,
  CmeterHigh,
  TextFancy,
  TextSuccess,
  CmeterLowCards,
  CardImg,
  CmeterHighCards,
  CasinoTableRightBox,
  TextInfo,
  CardOddBox,
  CasinoNationBox,
  CmeterCardBox,
} from './styles'
import {
  _constants,
  highcards,
  highCardsData,
  lowcards,
  lowCardsData,
} from './constants'

const CasinoMeter = () => {
  return (
    <div>
      <OddView>
        <CasinoTable>
          <CasinoTableFullBox>
            <CasinoVideoCardBox>
              <CmeterLow>
                <div>
                  <TextFancy>{_constants.low}</TextFancy>
                  <TextSuccess class="ms-2 ">
                    <b> {_constants.lowNumber}</b>
                  </TextSuccess>
                </div>
                <CmeterLowCards>
                  {lowCardsData?.length &&
                    lowCardsData?.map((item, index) => (
                      <CardImg
                        key={item.alt + index}
                        alt={item.alt}
                        src={item.image}
                      />
                    ))}
                </CmeterLowCards>
              </CmeterLow>
              <CmeterHigh>
                <div>
                  <TextFancy>{_constants.high}</TextFancy>
                  <TextSuccess class="ms-2">
                    <b>{_constants.highNumber}</b>
                  </TextSuccess>
                </div>
                <CmeterHighCards>
                  {highCardsData?.length &&
                    highCardsData?.map((item, index) => (
                      <CardImg
                        key={item.alt + index}
                        alt={item.alt}
                        src={item.image}
                      />
                    ))}
                </CmeterHighCards>
              </CmeterHigh>
            </CasinoVideoCardBox>
          </CasinoTableFullBox>
          <CasinoTableBox class="mt-3">
            <CasinoTableRightBox>
              <div class="text-center">
                <TextInfo>{_constants.low}</TextInfo>
              </div>
              <CmeterCardBox class="mt-2">
                {lowcards?.length &&
                  lowcards.map((item, index) => (
                    <CardOddBox>
                      <img
                        key={item.alt + index}
                        src={item.image}
                        alt={item.alt}
                      />
                    </CardOddBox>
                  ))}
              </CmeterCardBox>
              <CasinoNationBox class=" text-center text-danger mt-2"></CasinoNationBox>
            </CasinoTableRightBox>
            <CasinoTableRightBox class="suspended-box">
              <div class="text-center">
                <TextInfo class="text-info">{_constants.high}</TextInfo>
              </div>
              <CmeterCardBox class="mt-2">
                {highcards?.length &&
                  highcards.map((item, index) => (
                    <CardOddBox>
                      <img
                        key={item.alt + index}
                        src={item.image}
                        alt={item.alt}
                      />
                    </CardOddBox>
                  ))}
              </CmeterCardBox>
              <CasinoNationBox class=" text-center text-danger mt-2"></CasinoNationBox>
            </CasinoTableRightBox>
          </CasinoTableBox>
        </CasinoTable>
      </OddView>
    </div>
  )
}

export default CasinoMeter
