export const constants = {
    stats: "Statistics",
    playerPair: 'Player Pair' + '\n' + 1 + ':' + 0,
    bankerPair: 'Banker Pair' + '\n' + 1 + ':' + 0
}

// Pie chart data
export const pieChartData = {
    labels: ['Player', 'Banker', 'Tie'],
    datasets: [
        {
            label: 'Players',
            data: [40, 10, 50],
            backgroundColor: ['red', 'blue', 'green'],
            borderColor: ['red', 'blue', 'green'],
            borderWidth: 1,
        },
    ],
}


//Pie chart options
export const pieChartOptions = {
    plugins: {
        legend: {
            position: 'right',
            rtl: true,
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                padding: 10,
                generateLabels: (chart) => {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                        return data.labels.map((label, i) => {
                            const abbreviatedLabel = label.length > 3 ? label.slice(0, 3) + '...' : label;
                            return {
                                text: abbreviatedLabel,
                                fillStyle: data.datasets[0].backgroundColor[i],
                                hidden: false,
                                lineCap: data.datasets[0].borderCapStyle,
                                lineDash: data.datasets[0].borderDash,
                                lineDashOffset: data.datasets[0].borderDashOffset,
                                lineJoin: data.datasets[0].borderJoinStyle,
                                lineWidth: data.datasets[0].borderWidth,
                                strokeStyle: data.datasets[0].borderColor[i],
                                pointStyle: data.datasets[0].pointStyle,
                                rotation: 0,
                                datasetIndex: 0,
                                index: i,
                            };
                        });
                    }
                    return [];
                },
            },
        },
    },
};

export const statsData = ['Big: 0.54:1', 'Small: 0.54:1', 'Small: 0.54:1']

export const middleContainerData = [
    {
        name: 'Player',
        value: '1',
        color: 'blue',
        isSuspended: false,
    },
    { name: 'Tie', value: '1', color: 'green', isSuspended: false },
    {
        name: 'Banker',
        value: '1',
        color: 'orange',
        isSuspended: false,
    },
]

export const leftBoxStyle = {
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '0px',
    backgroundColor: 'blue',
}

export const rightBoxStyle = {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '30px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '30px',
    backgroundColor: 'orange',
}