import A from '../../../assets/images/casino/royal-casino/ACard.png'
import two from '../../../assets/images/casino/royal-casino/2.png'
import three from '../../../assets/images/casino/royal-casino/3.png'
import four from '../../../assets/images/casino/royal-casino/4.png'
import five from '../../../assets/images/casino/royal-casino/5.png'
import six from '../../../assets/images/casino/royal-casino/6.png'
import seven from '../../../assets/images/casino/royal-casino/7.png'
import eight from '../../../assets/images/casino/royal-casino/8.png'
import nine from '../../../assets/images/casino/royal-casino/9.png'
import ten from '../../../assets/images/casino/royal-casino/10.png'
import jack from '../../../assets/images/casino/royal-casino/J.png'
import queen from '../../../assets/images/casino/royal-casino/Q.png'
import king from '../../../assets/images/casino/royal-casino/K.png'

export const tableData = [
  {
    title: 'A. Amar',
    stats: {
      Back: { value: 1.98, isSuspended: false },
      Lay: { value: 3.23, isSuspended: true },
    },
  },
  {
    title: 'B. Akbar',
    stats: {
      Back: { value: 1.98, isSuspended: false },
      Lay: { value: 3.23, isSuspended: false },
    },
  },
  {
    title: 'C. Anthony',
    stats: {
      Back: { value: 1.98, isSuspended: false },
      Lay: { value: 3.23, isSuspended: false },
    },
  },
]

export const middleContainerData = [
  {
    odd1: '2.12',
    odd2: '2.9',
    btn1text: 'Even',
    btn2text: 'Odd',
  },
  {
    odd1: '2.12',
    odd2: '2.9',
    btn1text: 'Even',
    btn2text: 'Odd',
  },
  {
    odd1: '2.12',
    odd2: '2.9',
    btn1text: 'under 7',
    btn2text: 'Over 7',
  },
]

export const cardImages = [
  {
    src: A,
    alt: 'A',
  },
  {
    src: two,
    alt: '2',
  },
  {
    src: three,
    alt: '3',
  },
  {
    src: four,
    alt: '4',
  },
  {
    src: five,
    alt: '5',
  },
  {
    src: six,
    alt: '6',
  },
  {
    src: seven,
    alt: '7',
  },
  {
    src: eight,
    alt: '8',
  },
  {
    src: nine,
    alt: '9',
  },
  {
    src: ten,
    alt: '10',
  },
  {
    src: jack,
    alt: 'J',
  },
  {
    src: queen,
    alt: 'Q',
  },
  {
    src: king,
    alt: 'K',
  },
]
