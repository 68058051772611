import styled from 'styled-components'
import { color } from '../../color/color'
import { Link } from 'react-router-dom'

export const SidebarLink = styled(Link)`
  display: flex;
  color: ${color._6D081D};
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid ${color._F1F1F1};
  list-style: none;
  height: 39px;
  text-decoration: none;
  background: ${color._8B0000};
`
export const SidebarLabel = styled.span`
  margin-left: 10px;
  color: ${color._FFFFFF};
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
  font-family: sans-serif;
`
export const SvgSection = styled.div`
  svg {
    color: ${color._808080};
    width: 9px;
    margin-right: 10px;
  }
`
export const SidebarSubLabel = styled.span`
  color: ${({ _color }) => (_color ? _color : color._DC3545)};
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
`
export const DropdownLink = styled.div`
  background: ${color._FFFFFF};
  min-height: 39px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  text-decoration: none;
  color: ${color._DC3545};
  border: 1px solid ${color._DEE2E6};
  margin: 0px 5px 0px 5px;
  &:hover {
    cursor: pointer;
  }
`
export const SvgListSection = styled.div`
  svg {
    color: grey;
    width: 6px;
    margin-right: 10px;
  }
`
export const Box = styled.div`
  padding: 5px;
`
