import React from 'react'
import * as Styled from '../style'
const SelectVal = ({ val, key, subVal, height }) => {
  return (
    <Styled.SelectVal key={key} height={height}>
      <Styled.UpperVal>{val}</Styled.UpperVal>
      {subVal && <Styled.LowerVal>{subVal}</Styled.LowerVal>}
    </Styled.SelectVal>
  )
}

export default SelectVal
