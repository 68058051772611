import React, { useState } from 'react'
import * as Styled from '../style'
import {
  LowerContent,
  constants,
  getResultOptions,
} from '../../../utils/constant'
import IconTitle from './IconTitle'
const Result = () => {
  const dateArr = getResultOptions(new Date())
  const [activeSport, setActiveSport] = useState(constants.Cricket)

  const handleActiveSport = (e) => {
    setActiveSport(e)
  }
  return (
    <Styled.Result>
      <Styled.MyBetsTitle>{constants.RESULTS}</Styled.MyBetsTitle>
      <Styled.DateDiv className="display-flex">
        {dateArr.reverse().map((elem, indx) => {
          return (
            <Styled.DateOption key={elem.currDate + elem.weekDay + indx}>
              <Styled.Date>{elem.currDate}</Styled.Date>
              <Styled.Day>{elem.weekDay}</Styled.Day>
            </Styled.DateOption>
          )
        })}
      </Styled.DateDiv>
      <Styled.ResultOptions className="display-flex result-options">
        {LowerContent.map((elem, indx) => {
          return (
            <IconTitle
              key={elem.title + indx}
              title={elem.title}
              src={elem.icon}
              openDrawer={true}
              individualView={true}
              activeMatchOption={activeSport}
              handleMatchOption={handleActiveSport}
            />
          )
        })}
      </Styled.ResultOptions>
    </Styled.Result>
  )
}

export default Result
