import React from 'react'
import * as Styled from '../style'
import { cardImages } from '../../AndarBahar/constant'
import { color } from '../../../../color/color'
const PlayerComp = ({ playerName, showCard }) => {
  return (
    <Styled.PlayerComp>
      <Styled.PlayerName>
        {showCard ? (
          <>
            <img
              src={cardImages[0].src}
              alt={cardImages[0].alt}
              width={'30px'}
              height={'30px'}
            />
            <img
              src={cardImages[0].src}
              alt={cardImages[0].alt}
              width={'30px'}
              height={'30px'}
            />
            <img
              src={cardImages[0].src}
              alt={cardImages[0].alt}
              width={'30px'}
              height={'30px'}
            />
          </>
        ) : (
          playerName
        )}
      </Styled.PlayerName>

      <Styled.BetDiv>
        <Styled.Bet bgcolor={color._72BBEF}>1.35</Styled.Bet>
        <Styled.Bet bgcolor={color._FAA9BA}>1.39</Styled.Bet>
      </Styled.BetDiv>
    </Styled.PlayerComp>
  )
}

export default PlayerComp
