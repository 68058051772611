import A from '../../../assets/images/casino/royal-casino/ACard.png'
import two from '../../../assets/images/casino/royal-casino/2.png'
import three from '../../../assets/images/casino/royal-casino/3.png'
import four from '../../../assets/images/casino/royal-casino/4.png'
import five from '../../../assets/images/casino/royal-casino/5.png'
import six from '../../../assets/images/casino/royal-casino/6.png'
import seven from '../../../assets/images/casino/royal-casino/7.png'
import eight from '../../../assets/images/casino/royal-casino/8.png'
import nine from '../../../assets/images/casino/royal-casino/9.png'
import ten from '../../../assets/images/casino/royal-casino/10.png'
import jack from '../../../assets/images/casino/royal-casino/J.png'
import queen from '../../../assets/images/casino/royal-casino/Q.png'
import king from '../../../assets/images/casino/royal-casino/K.png'
import spade from '../CasinoMeter/cardImages/spade.png'
import club from '../CasinoMeter/cardImages/club.png'
import heart from '../CasinoMeter/cardImages/heart.png'
import diamond from '../CasinoMeter/cardImages/diamond.png'
import * as Styled from './styles'

export const containerType = {
  midContainer: 'MidContainer',
  suitedContainer: 'SuitedContainer',
}

export const topContainerData = [
  {
    span: 2,
    containerText: { label: 'Dragon', isSuspended: false },
    colClass: 'col-3',
    containerType: containerType.midContainer,
  },
  {
    span: 50,
    containerText: { label: 'Suited Tie', isSuspended: false },
    colClass: 'col-2',
    containerType: containerType.suitedContainer,
  },
  {
    span: 2,
    containerText: { label: 'Tiger', isSuspended: false },
    colClass: 'col-4',
    containerType: containerType.midContainer,
  },
  {
    span: 12,
    containerText: { label: 'Pair', isSuspended: true },
    colClass: 'col-3',
    containerType: containerType.midContainer,
    isDivider: true,
  },
]

export const middleContainerData = [
  {
    odd1: '2.12',
    odd2: '2.9',
    btn1text: 'Even',
    image1: heart,
    image2: diamond,
    isSuspended: false,
  },
  {
    odd1: '2.12',
    odd2: '2.9',
    btn1text: 'Even',
    image1: club,
    image2: spade,
    isSuspended: false,
  },
  {
    odd1: '2.12',
    odd2: '2.9',
    btn1text: 'Even',
    image1: heart,
    image2: diamond,
    isSuspended: true,
  },
  {
    odd1: '2.12',
    odd2: '2.9',
    btn1text: 'Odd',
    image1: club,
    image2: spade,
    isSuspended: true,
  },
]

export const cardImages = [
  {
    row1: [
      {
        src: A,
        alt: 'A',
      },
      {
        src: two,
        alt: '2',
      },
      {
        src: three,
        alt: '3',
      },
      {
        src: four,
        alt: '4',
      },
      {
        src: five,
        alt: '5',
      },
      {
        src: six,
        alt: '6',
      },
      {
        src: seven,
        alt: '7',
      },
    ],
  },
  {
    row2: [
      {
        src: eight,
        alt: '8',
      },
      {
        src: nine,
        alt: '9',
      },
      {
        src: ten,
        alt: '10',
      },
      {
        src: jack,
        alt: 'J',
      },
      {
        src: queen,
        alt: 'Q',
      },
      {
        src: king,
        alt: 'K',
      },
    ],
  },
]

export const bottomCardData = [
  {
    title: 'Dragon 12',
    rows: [
      {
        cards: cardImages[0].row1.map((card) => ({
          ...card,
          isSuspended: true,
        })),
        className: 'd-flex justify-content-between',
      },
      {
        cards: cardImages[1].row2.map((card) => ({
          ...card,
          isSuspended: true,
        })),
        className: 'd-flex justify-content-evenly',
      },
    ],
    colContainer: Styled.ColLeftContainer,
  },
  {
    title: 'Tiger 12',
    rows: [
      {
        cards: cardImages[0].row1,
        className: 'd-flex justify-content-between',
      },
      {
        cards: cardImages[1].row2,
        className: 'd-flex justify-content-evenly',
      },
    ],
    colContainer: Styled.ColRightContainer,
  },
]
