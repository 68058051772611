import React from 'react'
import * as Styled from '../style'
import SelectVal from './SelectVal'

const NumberComp = ({mapArr,columnCount}) => {
  return (
    <Styled.SingleComp columnCheck={columnCount || 5}>
      {mapArr.map((item, index) => {
        return (
          <SelectVal val={item} key={item + index} subVal={item.subVal} />
        )
      })}
    </Styled.SingleComp>
  )
}

export default NumberComp
