import React from 'react'
import * as Styled from './styles'
import { cardData, pairData, playersData, playersMidData } from './constant'
import { color } from '../../../color/color'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'

const CardContainer = () => {
  return (
    <Styled.ColContainer className="col-12 mt-3">
      <Styled.Table>
        <tr>
          <Styled.EmptyRow></Styled.EmptyRow>
          {cardData.headers.length &&
            cardData.headers.map((header, index) => (
              <Styled.CardTableImageRow key={index + header}>
                <Styled.CardImage src={header} />
              </Styled.CardTableImageRow>
            ))}
        </tr>
        {cardData.rows.length &&
          cardData.rows.map((row, rowIndex) => (
            <tr key={rowIndex + row.label}>
              <Styled.CardTableDataHeading>
                {row.label}
              </Styled.CardTableDataHeading>
              {row.values.map((valueData, valueIndex) => (
                <Styled.CardTableData
                  key={valueIndex}
                  isSuspended={valueData.isSuspended}
                >
                  <div className="text-center position-relative">
                    {valueData.isSuspended && (
                      <Styled.SuspendedContainer>
                        <Lock fill={color._fff} />
                      </Styled.SuspendedContainer>
                    )}
                    {valueData.value}
                  </div>
                </Styled.CardTableData>
              ))}
            </tr>
          ))}
      </Styled.Table>
    </Styled.ColContainer>
  )
}

const PlayerTable = ({ player, style, labels, rows, isMidData }) => {
  return (
    <>
      <div className="col-6" style={style}>
        <Styled.Table>
          <tr>
            <Styled.RowHeadingContainer>{player}</Styled.RowHeadingContainer>
            <Styled.BoxDataBlueContainer>
              <div className="d-flex">
                {labels.back}
                <span className="d-flex align-items-center">
                  <Styled.Image src={labels.imageback1} />
                  <Styled.Image src={labels.imageback2} />
                </span>
              </div>
            </Styled.BoxDataBlueContainer>
            {isMidData ? (
              <Styled.BoxDataBlueContainer>
                <div className="d-flex">
                  {labels.lay}
                  <span className="d-flex align-items-center">
                    <Styled.Image src={labels.imagelay1} />
                    <Styled.Image src={labels.imagelay2} />
                  </span>
                </div>
              </Styled.BoxDataBlueContainer>
            ) : (
              <Styled.BoxDataPinkContainer>
                {labels.lay}
              </Styled.BoxDataPinkContainer>
            )}
          </tr>
          {rows.length &&
            rows?.map((row, index) => {
              const [key, value] = Object.entries(row)[0]
              return (
                <tr key={key + index + value.type}>
                  <Styled.RowHeadingDataContainer>
                    {value.type}
                  </Styled.RowHeadingDataContainer>
                  <Styled.BoxDataBlue isSuspended={value.isSuspended}>
                    <div className="align-items-center justify-content-center position-relative">
                      {value.isSuspended && (
                        <Styled.SuspendedContainer>
                          <Lock fill={color._fff} />
                        </Styled.SuspendedContainer>
                      )}
                      {value.back}
                    </div>
                  </Styled.BoxDataBlue>
                  {isMidData ? (
                    <Styled.BoxDataBlue isSuspended={value.isSuspended}>
                      <div className="align-items-center justify-content-center position-relative">
                        {value.isSuspended && (
                          <Styled.SuspendedContainer>
                            <Lock fill={color._fff} />
                          </Styled.SuspendedContainer>
                        )}
                        {value.lay}
                      </div>
                    </Styled.BoxDataBlue>
                  ) : (
                    <Styled.BoxDataPink isSuspended={value.isSuspended}>
                      <div className="align-items-center justify-content-center position-relative">
                        {value.isSuspended && (
                          <Styled.SuspendedContainer>
                            <Lock fill={color._fff} />
                          </Styled.SuspendedContainer>
                        )}
                        {value.lay}
                      </div>
                    </Styled.BoxDataPink>
                  )}
                </tr>
              )
            })}
        </Styled.Table>
      </div>
      {!isMidData &&
        pairData.map((item) => (
          <Styled.ColRightContainer
            key={item.id}
            className="col-md-6 text-center"
          >
            <Styled.RightColContainer>
              <Styled.Span>{item.number}</Styled.Span>
              {item.isSuspended ? (
                <Styled.RightSuspendedContainer>
                  <Styled.InnerContainer className="position-relative">
                    <Styled.SuspendedRightContainer>
                      <Lock fill={color._fff} />
                    </Styled.SuspendedRightContainer>
                    <div> {item.description}</div>
                  </Styled.InnerContainer>
                </Styled.RightSuspendedContainer>
              ) : (
                <Styled.RightColDataContainer>
                  {item.description}
                </Styled.RightColDataContainer>
              )}
            </Styled.RightColContainer>
          </Styled.ColRightContainer>
        ))}
    </>
  )
}

function DayDragonTiger() {
  return (
    <Styled.Container className="container">
      <div className="row">
        {playersData.length &&
          playersData.map((playerData, index) => (
            <PlayerTable
              key={index + playerData.labels}
              player={playerData.player}
              style={playerData.style}
              labels={playerData.labels}
              rows={playerData.rows}
              isMidData={false}
            />
          ))}
      </div>
      <div className="row mt-3">
        {playersMidData.length &&
          playersMidData.map((playerData, index) => (
            <PlayerTable
              key={index + playerData.labels}
              player={playerData.player}
              style={playerData.style}
              labels={playerData.labels}
              rows={playerData.rows}
              isMidData={true}
            />
          ))}
      </div>
      <div className="row">
        <CardContainer />
      </div>
    </Styled.Container>
  )
}

export default DayDragonTiger
