import React from 'react';
import * as Styled from './styles';
import lucky9 from '../../../assets/images/casino/royal-casino/lucky9.png';
import { constants, playersData, tableHeaders } from './constant';
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { color } from '../../../color/color';

function CardBaccarat() {
  return (
    <Styled.TableContainer>
      <div className="row">
        {playersData.length && playersData.map(({ player, stats }) => (
          <div className="col-6" key={player}>
            <Styled.Table>
              <tr>
                <Styled.PrimaryTableHeadRow>{`${constants.player} ${player}`}</Styled.PrimaryTableHeadRow>
                <Styled.TableRowEmpty />
                <Styled.TableRowEmpty />
                <Styled.TableRowRightEmpty />
              </tr>
              <tr>
                {tableHeaders.map((header) => (
                  <Styled.SecondaryTableHeadRow key={header}>
                    {header}
                  </Styled.SecondaryTableHeadRow>
                ))}
              </tr>
              <tr>
                {Object.keys(stats).map((statKey, index) => {
                  const stat = stats[statKey];
                  return (
                    <Styled.TableData key={index + stats[statKey]} isSuspended={stat.isSuspended}>
                      <div className='align-items-center justify-content-center position-relative'>
                        {stat.isSuspended && (
                          <Styled.SuspendedContainer>
                            <Lock fill={color._fff} />
                          </Styled.SuspendedContainer>
                        )}
                        <span>{stat.value}</span>
                      </div>
                    </Styled.TableData>
                  );
                })}
              </tr>
            </Styled.Table>
          </div>
        ))}
      </div>

      <div className="container">
        <div className="row">
          <Styled.ImageContainer className="col-12 mt-3 p-3">
            <Styled.Image src={lucky9} />
            <Styled.LeftSpan>6</Styled.LeftSpan>
            <Styled.RightSpan>6</Styled.RightSpan>
          </Styled.ImageContainer>
        </div>
      </div>
    </Styled.TableContainer>
  );
}

export default CardBaccarat;

