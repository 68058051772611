import styled from 'styled-components'
import { color } from '../../../color/color'

export const Container = styled.div`
  background-color: ${color._F2F2F2} !important;
  color: ${color._000};
`
export const ColContainer = styled.div`
  padding: 0px !important;
`
export const Table = styled.table`
  width: 100% !important;
  border: 1px solid ${color._C7C8CA} !important;
`

export const CardTableRow = styled.td`
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 2px 0px 2px 10px !important;
  border: 1px solid ${color._C7C8CA} !important;
  width: 150px !important;
  text-align: center !important;
`
export const CardTableDataHeading = styled.td`
  padding: 10px;
  border: 1px solid ${color._C7C8CA};
  font-size: 16px !important;
  font-weight: 700 !important;
`
export const CardTableData = styled.td`
  padding: 10px;
  border: 1px solid ${color._C7C8CA};
  font-size: 16px !important;
  font-weight: 700 !important;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._72BBEF};
`

export const SuspendedContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
export const Odds = styled.div`
  font-size: 16px;
  font-weight: 700;
`
