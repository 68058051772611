import React from 'react'
import { TabPanelChild } from '../styles'
import OverviewContent from './OverviewContent'
import StackContent from './StackContent'
import PasswordContent from './PasswordContent'
import { constants } from '../../../utils/constant'

function renderItem(name) {
  switch (name) {
    case constants.OVERVIEW:
      return <OverviewContent />
    case constants.STACK_SETTING:
      return <StackContent />
    case constants.CHANGE_PASSWORD_KEY:
      return <PasswordContent />
    default:
      return <></>
  }
}

const TabPanelItem = ({ tabId, activeTab }) => {
  return (
    <TabPanelChild
      className={`tab-pane fade ${activeTab === tabId ? 'show active' : ''}`}
      id={`nav-${tabId}`}
      role="tabpanel"
      aria-labelledby={`nav-${tabId}-tab`}
    >
      {renderItem(tabId)}
    </TabPanelChild>
  )
}

export default TabPanelItem
