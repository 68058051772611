import { useEffect, useState } from 'react'
import { constant, types } from './constants'
import * as Styled from './style'
import { BsSearch } from 'react-icons/bs'
import { IoPersonSharp } from 'react-icons/io5'
import ModalCont from '../../container/Modal/Modal'
import { useNavigate, useLocation } from 'react-router'
import RulesModalCont from '../../container/RulesModal/RulesModal'
import { useSelector } from 'react-redux'
import ResetModal from '../ResetModal/ResetModal'
import deposit from '../../assets/images/deposit.png'
import withdrawal from '../../assets/images/withdrawal.png'
import { componentRoutes } from '../../utils/componentRoutes'
import logo1 from '../../assets/images/logo1.png'

const Header = (props) => {
  const { setToggle, toggle } = props
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [showRules, setShowRules] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [resetShow, setResetShow] = useState(false)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const isChangePassword = useSelector((state) => state.auth.isChangePassword)
  const userDetails = useSelector((state) => state.auth.userData)

  const isDemoLogin = localStorage.getItem('isDemoLogin')
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleShowRules = () => setShowRules(true)
  const handleCloseRules = () => setShowRules(false)
  const location = useLocation()

  useEffect(() => {
    if (location.state?.loginModal || isChangePassword) {
      navigate('/')
      setShow(true)
    }
    return () => {
      setShow(false)
    }
  }, [isChangePassword])

  const getLoginSingupButtons = () => {
    if (isAuthenticated) {
      return false
    } else if (isDemoLogin) {
      return false
    }
    return true
  }

  const handleSearchInput = (e) => {
    let inputValue = e.target.value
      .replace(/^\s+/, '')
      // Remove non-alphanumeric characters except for spaces
      .replace(/[^a-zA-Z0-9 ]+/g, '')
      // Replace multiple consecutive whitespaces with a single space
      .replace(/\s{2,}/g, ' ')
    setSearchValue(inputValue)
  }
  const handleResetClose = () => {
    setResetShow(false)
  }
  const handleAnchor = () => {
    navigate('/')
  }
  return (
    <Styled.Header>
      <ResetModal
        show={resetShow}
        handleClose={handleResetClose}
        setShow={setShow}
      />
      <ModalCont
        show={show}
        handleClose={handleClose}
        setToggle={setToggle}
        toggle={toggle}
        setShow={setShow}
        setResetShow={setResetShow}
      />
      <RulesModalCont show={showRules} handleClose={handleCloseRules} />
      <Styled.HeaderComponent id="header">
        <Styled.Container>
          <Styled.Dflex>
            <Styled.LogoContainer>
              <Styled.Anchor onClick={handleAnchor}>
                <Styled.DesktopLogo alt={constant.ReddyClub} src={logo1} />
                <Styled.MobileLogo alt={constant.ReddyClubLogo} src={logo1} />
              </Styled.Anchor>
            </Styled.LogoContainer>
            <Styled.SearchContainer>
              <Styled.SearchBox>
                <Styled.SearchInput
                  type={types.text}
                  placeholder={constant.SearchEvents}
                  value={searchValue}
                  onChange={handleSearchInput}
                />
                <Styled.SearchButton
                  type={types.submit}
                  title={constant.Search}
                >
                  <BsSearch />
                </Styled.SearchButton>
              </Styled.SearchBox>
            </Styled.SearchContainer>
            <Styled.Rules onClick={handleShowRules}>
              {constant.Rules}
            </Styled.Rules>
            {isAuthenticated ? (
              <>
                <Styled.Deposit
                  onClick={() => navigate(componentRoutes?.deposit)}
                >
                  <Styled.Img src={deposit} alt="deposit-icon" />{' '}
                  {constant.DEPOSIT}
                </Styled.Deposit>
                <Styled.Withdrawal
                  onClick={() => navigate(componentRoutes.widthdraw)}
                >
                  <Styled.Img src={withdrawal} alt="withdraw" />
                  {constant.WITHDRAWAL}
                </Styled.Withdrawal>
              </>
            ) : (
              <></>
            )}
          </Styled.Dflex>
          <Styled.NavContainer>
            <Styled.NavOption>
              {isDemoLogin || isAuthenticated ? (
                <>
                  {/* Balance */}
                  <Styled.AccountList>
                    <Styled.AccountListAnchor>
                      <Styled.AccountListIcon></Styled.AccountListIcon>
                      <Styled.AccountListTitle>
                        {constant.Balance}
                        <b>{userDetails?.current || 0}</b>
                      </Styled.AccountListTitle>
                    </Styled.AccountListAnchor>
                  </Styled.AccountList>
                  {/* exposure */}
                  <Styled.AccountList>
                    <Styled.AccountListAnchor>
                      <Styled.AccountListIcon></Styled.AccountListIcon>
                      <Styled.AccountListTitle>
                        {constant.Exposer}
                        <b>{userDetails?.fix || 0}</b>
                      </Styled.AccountListTitle>
                    </Styled.AccountListAnchor>
                  </Styled.AccountList>

                  {/* Profile */}
                  <Styled.ProfileSection className="titillium-web-regular">
                    <Styled.ProfileAnchor>
                      <Styled.ProfileIcon>
                        <IoPersonSharp />
                      </Styled.ProfileIcon>
                      <Styled.ProfileTitle></Styled.ProfileTitle>
                    </Styled.ProfileAnchor>
                  </Styled.ProfileSection>
                </>
              ) : (
                <></>
              )}

              {getLoginSingupButtons() && (
                <Styled.UserSection>
                  <Styled.Button
                    type={types.button}
                    onClick={() => navigate(componentRoutes.SIGNUP)}
                  >
                    {constant.Signup}
                  </Styled.Button>
                  <Styled.Button type={types.button} onClick={handleShow}>
                    {constant.Login}
                  </Styled.Button>
                </Styled.UserSection>
              )}
            </Styled.NavOption>
          </Styled.NavContainer>
        </Styled.Container>
      </Styled.HeaderComponent>
    </Styled.Header>
  )
}

export default Header
