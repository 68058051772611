import ACard from '../CasinoMeter/cardImages/A.png'
import _2Card from '../CasinoMeter/cardImages/2.png'
import _3Card from '../CasinoMeter/cardImages/3.png'
import _4Card from '../CasinoMeter/cardImages/4.png'
import _5Card from '../CasinoMeter/cardImages/5.png'
import _6Card from '../CasinoMeter/cardImages/6.png'
import _7Card from '../CasinoMeter/cardImages/7.png'
import _8Card from '../CasinoMeter/cardImages/8.png'
import _9Card from '../CasinoMeter/cardImages/9.png'
import _10Card from '../CasinoMeter/cardImages/10.png'
import JCard from '../CasinoMeter/cardImages/J.png'
import QCard from '../CasinoMeter/cardImages/Q.png'
import KCard from '../CasinoMeter/cardImages/K.png'
import { ReactComponent as Heart } from '../../../assets/images/casino/royal-casino/heart.svg'
import { ReactComponent as Leaf } from '../../../assets/images/casino/royal-casino/leaf.svg'

export const cardData = [
  {
    label: 0,
    text: 'Low Card',
    colSize: 'col-md-5',
    padding: 'pt-1 pb-3',
    isSuspended: false,
  },
  {
    imageSrc: _7Card,
    colSize: 'col-md-2',
    padding: 'pt-2 pb-3',
    isSuspended: false,
  },
  {
    label: 2,
    text: 'High Card',
    colSize: 'col-md-5',
    padding: 'pt-1 pb-3',
    isSuspended: true,
  },
]

export const evenOddCardsData = [
  {
    label: 2.1,
    value: 'Even',
    isImage: false,
  },
  {
    label: 2.1,
    value: 'Odd',
    isImage: false,
  },
]

export const evenOddImageCardData = [
  {
    label: '1.95',
    image: <Heart width={100} height={35} />,
    isImage: true,
  },
  {
    label: '1.95',
    image: <Leaf width={100} height={35} />,
    isImage: true,
  },
]

export const casinoCardData = [
  {
    label: 2,
    style: { paddingLeft: 0 },
    images: { isSuspended: false, cards: [ACard, _2Card, _3Card] },
  },
  {
    isSuspended: false,
    label: 2,
    style: {},
    images: { isSuspended: false, cards: [_4Card, _5Card, _6Card] },
  },
  {
    isSuspended: false,
    label: 2,
    style: {},
    images: { isSuspended: false, cards: [_8Card, _9Card, _10Card] },
  },
  {
    isSuspended: true,
    label: 2,
    style: { paddingRight: 0 },
    images: { isSuspended: true, cards: [JCard, QCard, KCard] },
  },
]

export const cardImages = [
  {
    src: ACard,
    alt: 'A',
  },
  {
    src: _2Card,
    alt: '2',
  },
  {
    src: _3Card,
    alt: '3',
  },
  {
    src: _4Card,
    alt: '4',
  },
  {
    src: _5Card,
    alt: '5',
  },
  {
    src: _6Card,
    alt: '6',
  },
  {
    src: _7Card,
    alt: '7',
  },
  {
    src: _8Card,
    alt: '8',
  },
  {
    src: _9Card,
    alt: '9',
  },
  {
    src: _10Card,
    alt: '10',
  },
  {
    src: JCard,
    alt: 'J',
  },
  {
    src: QCard,
    alt: 'Q',
  },
  {
    src: KCard,
    alt: 'K',
  },
]
