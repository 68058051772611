import React, { useEffect } from 'react'
import * as Styled from './style'
import ProfileDropdown from '../../container/ProfileDropdown/ProfileDropdown'
import * as RxIcons from 'react-icons/rx'
import * as BsIcons from 'react-icons/bs'
import { color } from '../../color/color'
import { useLocation } from 'react-router-dom'
import { _constants } from './constants'
import { useSelector } from 'react-redux'
export const Tabs = ({
  selectedTab,
  tabs,
  onTabChange,
  sidebar,
  showSidebar,
}) => {
  const location = useLocation()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const isDemoUserName = useSelector((state) => state.auth.isDemoUserName)
  const username = useSelector((state) => state.auth.username)

  const phone = useSelector((state) => state.auth.phone)

  const getPhoneDetails = (phone) => {
    if (phone?.length === 0) {
      return username?.toUpperCase()
    } else if (/^0+$/.test(phone)) {
      return username?.toUpperCase()
    } else {
      return phone
    }
  }

  const phoneNumber = getPhoneDetails(phone)

  const isDemoLogin = localStorage.getItem('isDemoLogin')
  // This Section Map all the sports and display it on UI
  const tabItem =
    tabs &&
    tabs?.map((element) => (
      <Styled.TabItem
        key={element?.id || element}
        itemname={element.name}
        isactive={
          element?.id === selectedTab.id || element.name === selectedTab.name
        }
        onClick={element.disable ? () => {} : () => onTabChange(element)}
      >
        <Styled.Item
          isactive={
            element?.id === selectedTab.id || element.name === selectedTab.name
          }
          itemname={element.name}
        >
          <img src={element.imageUrl} alt={element.name} /> {`${element?.name}`}{' '}
        </Styled.Item>
      </Styled.TabItem>
    ))
  var elements = document.querySelectorAll('#highlighted-card')

  useEffect(() => {
    setCrossPosition()
  }, [location.pathname, elements])

  const setCrossPosition = () => {
    return '-2.85rem'
  }

  return (
    <Styled.ListItem isauthenticated={isAuthenticated}>
      <Styled.ListItemWrap>{tabItem}</Styled.ListItemWrap>{' '}
      <Styled.ProfilePosition isauthenticated={isAuthenticated}>
        {isDemoLogin || isAuthenticated ? (
          <ProfileDropdown
            title={username || phoneNumber || isDemoUserName || _constants.name}
          />
        ) : (
          <></>
        )}
      </Styled.ProfilePosition>
      <Styled.Nav
        crossposition={setCrossPosition()}
        className="nav-icon-mobile"
      >
        <Styled.NavIcon to="#">
          {!sidebar ? (
            <BsIcons.BsListNested onClick={showSidebar} color={color._000000} />
          ) : (
            <RxIcons.RxCross1 onClick={showSidebar} color={color._000000} />
          )}
        </Styled.NavIcon>
      </Styled.Nav>
    </Styled.ListItem>
  )
}
