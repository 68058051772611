import React from 'react'
import * as Styled from './styles'
import { bonusData, constants, playerData } from './constant'
import { color } from '../../../color/color'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import Marquee from '../../../components/Marquee/Marquee'

const PokerOneDay = () => {
  return (
    <Styled.Container className="container">
      <div className="row">
        {playerData.length &&
          playerData.map((player, index) => (
            <div
              key={index + player.name}
              className="col-6"
              style={player.style}
            >
              <Styled.Table>
                <tr>
                  <Styled.RowHeadingContainer>
                    {player.name}
                  </Styled.RowHeadingContainer>
                  <Styled.BoxDataBlue
                    isSuspended={player.blueValue.isSuspended}
                  >
                    <div className="align-items-center justify-content-center position-relative">
                      {player.blueValue.isSuspended && (
                        <Styled.SuspendedContainer>
                          <Lock fill={color._fff} />
                        </Styled.SuspendedContainer>
                      )}
                      {player.blueValue.value}
                    </div>
                  </Styled.BoxDataBlue>
                  <Styled.BoxDataPink
                    isSuspended={player.blueValue.isSuspended}
                  >
                    <div className="align-items-center justify-content-center position-relative">
                      {player.pinkValue.isSuspended && (
                        <Styled.SuspendedContainer>
                          <Lock fill={color._fff} />
                        </Styled.SuspendedContainer>
                      )}
                      {player.pinkValue.value}
                    </div>
                  </Styled.BoxDataPink>
                </tr>
              </Styled.Table>
            </div>
          ))}
      </div>

      <div className="row mt-3">
        {bonusData.length &&
          bonusData.map((bonus, index) => (
            <div key={index} className={bonus.className} style={bonus.style}>
              <Styled.BonusContainer isSuspended={bonus.score.isSuspended}>
                <div className="position-relative text-center">
                  {bonus.score.isSuspended && (
                    <Styled.SuspendedContainer>
                      <Lock fill={color._fff} />
                    </Styled.SuspendedContainer>
                  )}
                  {bonus.score.value}
                </div>
              </Styled.BonusContainer>
            </div>
          ))}
      </div>
      <div className="row mt-2">
        <div className="col-12 px-0">
          <Marquee
            label={constants.playAndWin}
            bgColor={color._086F3F}
            fontColor={color._fff}
          />
        </div>
      </div>
    </Styled.Container>
  )
}

export default PokerOneDay
