import styled from 'styled-components'
import { color } from '../../../color/color'

export const Table = styled.table`
border: 1px solid ${color._C7C8CA}; 
`
export const TableHeadRow = styled.th`
padding: ${({ locked }) => (locked ? '0.5rem .5rem' : '1.5rem .5rem')} !important; 
padding: ${({ image }) => (image ? '0.5rem .5rem' : '1.5rem .5rem')} !important; 
font-family: Roboto Condensed, sans-serif !important;
`
export const TableHeadColEmpty = styled.th`
padding: 0.1rem .2rem !important; 
width: 90px !important;
`
export const TableHeadCol = styled.th`
padding: 2px !important; 
width: 5px !important;
background-color:${color._72BBEF} !important;
border-left: 1px solid ${color._C7C8CA} !important;
text-align: center !important;
font-family: Roboto Condensed, sans-serif !important;
font-size:16px !important;
height: auto !important;
min-height: auto !important;
color: ${color._000} !important;
`

export const TableDataCell = styled.td`
background-color: ${({ locked }) => (locked ? color._373636D6 : color._72BBEF)} !important;
border-left: 1px solid ${color._C7C8CA} !important;
text-align: center !important;
padding: 1.5rem .5rem !important;
font-weight:700 !important;
font-family: Roboto Condensed, sans-serif !important;
font-size:16px !important;
color: ${color._000} !important;
width: 60px !important;
`;

export const CardImage = styled.img`
height:46px;
`