import { _post, _put, _get } from '../../services/HttpMethods'
import { endPoints } from '../../services/EndPoints'
import {
  removeAuthData,
  setAuthData,
  setSportsDetails,
  setUserDetails,
} from '../../utils/utils'
import ACTIONTYPES from './actionType'
import { startLoading, stopLoading } from '../loading/action'
import { toast } from 'react-toastify'

const getToastPosition = () => {
  return {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  }
}
export const login = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _post(endPoints.login, credentials)
      const { msg, status, data = {} } = response
      if (status === 200) {
        dispatch(stopLoading())
        const authData = {
          token: data?.token,
          userId: data?.clientId,
          limit: data?.limit,
          phone: data?.phone,
          isWithdrawPassword: data?.isWithdrawPassword,
          username: data?.username,
          phoneCountryCode: data?.phoneCountryCode,
        }
        dispatch(fetchUserDetails())
        setAuthData(authData)
        dispatch({
          type: ACTIONTYPES.LOGIN_SUCCESS,
          payload: authData,
        })
      } else {
        dispatch(stopLoading())
        dispatch({
          type: ACTIONTYPES.LOGIN_FAIL,
          payload: msg,
        })
      }
    } catch (error) {
      dispatch(stopLoading())

      dispatch({
        type: ACTIONTYPES.LOGIN_FAIL,
        payload: error,
      })
    }
  }
}
export const demologin = () => {
  return (dispatch) => {
    const userData = {
      username: 'Demo123',
    }
    dispatch({
      type: ACTIONTYPES.LOGIN_DEMO_SUCCESS,
      payload: userData,
    })
  }
}
export const demologout = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIONTYPES.LOGOUT_DEMO_SUCCESS,
    })
  }
}

export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _post(endPoints.logout, {})
      const { msg, status } = response
      if (status === 200) {
        dispatch(stopLoading())
        removeAuthData()
        dispatch({
          type: ACTIONTYPES.LOGOUT_SUCCESS,
          payload: msg || 'You have been successfully logged out!',
        })
      } else if (status === 500 || status === 403 || status === 401) {
        dispatch(stopLoading())
        removeAuthData()
        dispatch({
          type: ACTIONTYPES.LOGOUT_SUCCESS,
          payload: 'You have been successfully logged out!',
        })

        toast.success(
          'You have been successfully logged out!',
          getToastPosition(),
        )
      } else {
        dispatch(stopLoading())
        removeAuthData()
        dispatch({
          type: ACTIONTYPES.LOGOUT_SUCCESS,
          payload: 'You have been successfully logged out!',
        })
      }
    } catch (error) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.LOGOUT_FAIL,
        payload: error,
      })
    }
  }
}

export const sendOTP = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _post(endPoints.send_otp, credentials)
      const { status, data } = response
      if (status === 200) {
        dispatch(stopLoading())
        dispatch({
          type: ACTIONTYPES.OTP_VERIFY_SUCCESS,
          payload: data,
        })
      } else {
        dispatch(stopLoading())
      }
    } catch (err) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.OTP_SENT_FAIL,
        payload: err,
      })
    }
  }
}

export const verifyOTP = (state, navigate) => {
  const otpCred = { phone: state.phone, otp: state.otp }
  const registerCred = {
    phone: state.phone.replace(/\D/g, '').slice(-10),
    password: state.password,
    phoneCountryCode: state.phone,
  }

  return async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _post(endPoints.verify_otp, otpCred)
      const { msg, status, data } = response
      if (status === 200) {
        dispatch(stopLoading())
        dispatch({
          type: ACTIONTYPES.OTP_VERIFY_SUCCESS,
          payload: data,
        })
        // dispatch(startLoading())
        const registerResponse = await _post(endPoints.register, registerCred)
        const { msg: _msg, status: _status, data: _data } = registerResponse
        if (_status === 201) {
          dispatch(stopLoading())
          navigate('/', { state: { loginModal: true } })
        } else {
          dispatch(stopLoading())
          navigate('/', { state: { loginModal: true } })
        }
      } else {
        dispatch(stopLoading())
        dispatch({
          type: ACTIONTYPES.OTP_VERIFY_FAIL,
          payload: msg,
        })
      }
    } catch (err) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.OTP_VERIFY_FAIL,
        payload: err,
      })
    }
  }
}

export const forgetPasswordOTP = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _post(endPoints.forgetPasswordOtp, credentials)
      const { status, data } = response
      if (status === 200) {
        dispatch(stopLoading())
        dispatch({
          type: ACTIONTYPES.OTP_VERIFY_SUCCESS,
          payload: data,
        })
      }
    } catch (err) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.OTP_SENT_FAIL,
        payload: err,
      })
    }
  }
}

export const resetPassword = (state, navigate, closeModal) => {
  const resetCred = {
    phone: state.phone.replace(/\D/g, '').slice(-10),
    otp: state.otp,
    password: state.password,
    phonecode: '+91',
  }

  return async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _put(endPoints.reset_password, resetCred)
      const { status } = response
      if (status === 200) {
        dispatch(stopLoading())
        closeModal()
        navigate('/', { state: { loginModal: true } })
      } else {
        dispatch(stopLoading())
        closeModal()
        navigate('/', { state: { loginModal: true } })
      }
    } catch (err) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.OTP_VERIFY_FAIL,
        payload: err,
      })
      throw err
    }
  }
}

export const changePassword = (state, token, navigate) => {
  const changePasswordCredential = {
    clientId: localStorage.getItem('userId'),
    oldPassword: state.currentPassword,
    newPassword: state.newPassword,
    confirmNewPassword: state.reEnterPassword,
  }

  return async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _put(
        endPoints.change_password,
        changePasswordCredential,
        token,
      )
      const { status } = response
      if (status === 200) {
        dispatch(stopLoading())
        dispatch(logout(token))
        return
      }
    } catch (err) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.CHANGE_PASSOWRD_FAIL,
        payload: err,
      })
    }
  }
}

export const fetchUserDetails = () => async (dispatch) => {
  try {
    const response = await _get(endPoints.client)
    const { msg, status, data = {} } = response

    if (status === 200) {
      dispatch({
        type: ACTIONTYPES.USER_DETAILS_SUCCESS,
        payload: data,
      })
      const payload = {
        userDetails: data,
      }
      setUserDetails(payload)
    } else {
      dispatch({
        type: ACTIONTYPES.USER_DETAILS_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch({
      type: ACTIONTYPES.USER_DETAILS_FAIL,
      payload: error,
    })
  }
}
export const fetchProfileDetails = (token) => async (dispatch) => {
  try {
    const response = await _get(endPoints.profile, token)
    const { msg, status, data = {} } = response
    if (status === 200) {
      dispatch({
        type: ACTIONTYPES.PROFILE_DETAILS_SUCCESS,
        payload: data,
      })
    } else {
      dispatch({
        type: ACTIONTYPES.PROFILE_DETAILS_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch({
      type: ACTIONTYPES.PROFILE_DETAILS_FAIL,
      payload: error,
    })
  }
}

export const fetchUnsettledBets = (token) => async (dispatch) => {
  try {
    const response = await _get(endPoints.unsettledBets, token)
    const { msg, status, data = {} } = response
    if (status === 200) {
      dispatch({
        type: ACTIONTYPES.UNSETELLED_BETS_DETAILS_SUCCESS,
        payload: data,
      })
    } else {
      dispatch({
        type: ACTIONTYPES.UNSETELLED_BETS_DETAILS_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch({
      type: ACTIONTYPES.UNSETELLED_BETS_DETAILS_FAIL,
      payload: error,
    })
  }
}

export const fetchAccountStatement =
  (token, isProfitLoss = false) =>
  async (dispatch) => {
    try {
      const url = isProfitLoss
        ? endPoints.profitLoss
        : endPoints.accountStatement
      const response = await _get(url, token)
      const { msg, status, data = {} } = response

      if (status === 200) {
        dispatch({
          type: ACTIONTYPES.ACCOUNTSTATEMENT_DETAILS_SUCCESS,
          payload: data,
        })
      } else {
        dispatch({
          type: ACTIONTYPES.ACCOUNTSTATEMENT_DETAILS_FAIL,
          payload: msg,
        })
      }
    } catch (error) {
      dispatch({
        type: ACTIONTYPES.ACCOUNTSTATEMENT_DETAILS_FAIL,
        payload: error,
      })
    }
  }

export const fetchSports = () => async (dispatch) => {
  try {
    const response = await _get(endPoints.sports)
    const { msg, status, data = {} } = response
    if (status === 200) {
      dispatch({
        type: ACTIONTYPES.SPORTS_DETAILS_SUCCESS,
        payload: data,
      })

      const payload = {
        sportsDetails: data,
      }
      setSportsDetails(payload)
    } else {
      dispatch({
        type: ACTIONTYPES.SPORTS_DETAILS_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch({
      type: ACTIONTYPES.SPORTS_DETAILS_FAIL,
      payload: error,
    })
  }
}
