import React, { useState } from 'react'
import * as Styled from '../style'
import AccordionFunc from './Accordian'
import { color } from '../../../color/color'
import BetIDInput from './BetIDInput'
import MatchedBets from './MatchedBets'
import { constants } from '../../../utils/constant'
import { IoIosArrowForward } from 'react-icons/io'
import BetTab from './BetTab'
const BetsSidebar = ({
  setBetsSlider,
  screenWidth,
  addedBet,
  setAddedBet,
  betDetails,
  setDrawerSlider,
  setActiveBetId,
}) => {
  const [selectedBetOption, setSelectedBetOption] = useState('PLACE BETS')

  const betsOption = [
    {
      title: constants.PLACE_BETS,
      handleClick: () => setSelectedBetOption(constants.PLACE_BETS),
    },
    {
      title: constants.OPEN_BETS,
      handleClick: () => setSelectedBetOption(constants.OPEN_BETS),
    },
  ]

  const BetCalc = () => {
    return addedBet ? (
      <BetTab
        betTitle={betDetails.betTitle}
        addedBet={addedBet}
        setAddedBet={setAddedBet}
        oddsValue={betDetails.value}
        matchTitle={betDetails.matchTitle}
        setActiveBetId={setActiveBetId}
      />
    ) : (
      <>
        <Styled.PlacedBetsTitle>
          {constants.Place_your_bets}
        </Styled.PlacedBetsTitle>
        <Styled.PlacedBetsPara>
          {constants.Your_section_will_appear_here}
        </Styled.PlacedBetsPara>
      </>
    )
  }

  const BetDetails = () => {
    return (
      <AccordionFunc
        title={constants.BET_DETAILS}
        showIcon={false}
        bgColor={color._EEEEEE}
        titleBottom={true}
        detailsBackgroundColor={true}
      >
        <BetIDInput />
      </AccordionFunc>
    )
  }

  const MatchedDetails = () => {
    return (
      <AccordionFunc
        title="MATCHED BETS (0)"
        showIcon={false}
        bgColor={color._EEEEEE}
        titleBottom={true}
        detailsBackgroundColor={true}
      >
        <MatchedBets />
      </AccordionFunc>
    )
  }
  return (
    <Styled.BetsSidebar>
      {screenWidth > 929 ? (
        <>
          <AccordionFunc
            title={constants.BET_SLIP}
            showIcon={false}
            bgColor={color._EEEEEE}
            titleBottom={true}
            detailsBackgroundColor={true}
            defaultExpanded={true}
          >
            <Styled.PlacedBets className="flex-column-aicenter-jccenter">
              {BetCalc()}
            </Styled.PlacedBets>
          </AccordionFunc>
          <AccordionFunc
            title="OPEN BETS (0)" //It will be dynamic will change after api
            showIcon={false}
            bgColor={color._EEEEEE}
            titleBottom={true}
            detailsBackgroundColor={true}
          >
            {MatchedDetails()}
          </AccordionFunc>
          {BetDetails()}
          <Styled.CloseBets
            className="flex-column-aicenter-jccenter"
            onClick={() => setBetsSlider(false)}
          >
            <IoIosArrowForward />
          </Styled.CloseBets>
        </>
      ) : (
        <Styled.ResponsiveBetBar>
          <Styled.RBTitleDiv className="display-flex">
            <Styled.RBLeft className="display-flex">
              <Styled.RightSlideIcon
                src="https://sports-v3.mysportsfeed.io/static/media/betSlipDoc.cf7a9641.svg"
                alt="betsSportsData"
                className="title-icon"
              />
              <Styled.RBTitle>{constants.BET_SLIP_OPEN_BETS}</Styled.RBTitle>
            </Styled.RBLeft>
            <Styled.RBRight
              onClick={() => {
                setBetsSlider(false)
                setDrawerSlider(false)
              }}
            >
              <IoIosArrowForward color={color._FFFFFF} />
            </Styled.RBRight>
          </Styled.RBTitleDiv>
          <Styled.RBSelect className="display-flex">
            {betsOption.map((elem, indx) => {
              return (
                <Styled.RBOption
                  key={elem.title + indx}
                  className="display-flex"
                  active={selectedBetOption === elem.title}
                  onClick={elem.handleClick}
                >
                  {elem.title}
                </Styled.RBOption>
              )
            })}
          </Styled.RBSelect>
          <Styled.RBContent>
            {selectedBetOption === constants.PLACE_BETS ? (
              <>
                {BetCalc()} {BetDetails()}
              </>
            ) : (
              <> {MatchedDetails()}</>
            )}
          </Styled.RBContent>
        </Styled.ResponsiveBetBar>
      )}
    </Styled.BetsSidebar>
  )
}

export default BetsSidebar
