import React from 'react'
import Slider from 'react-slick'
import * as Styled from '../style'
const SportsBookSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  }

  const sliderImageData = [
    'https://operator-banner1.s3.eu-west-2.amazonaws.com/cric99-banner2',
    'https://operator-banner1.s3.eu-west-2.amazonaws.com/cric99-banner4',
    'https://operator-banner1.s3.eu-west-2.amazonaws.com/cric99-banner5',
    'https://operator-banner1.s3.eu-west-2.amazonaws.com/cric99-banner8',
    'https://operator-banner1.s3.eu-west-2.amazonaws.com/cric99-banner9',
  ]

  return (
    <Styled.SliderDiv>
      <Slider {...settings}>
        {sliderImageData.map((elem, indx) => (
          <Styled.SliderImage key={elem + indx} src={elem} alt="todaymatch" />
        ))}
      </Slider>
    </Styled.SliderDiv>
  )
}

export default SportsBookSlider
