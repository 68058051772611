import styled from 'styled-components'

export const Img = styled.img`
  width: ${({ width }) => (width ? width : '100%')};
  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 10px;
    width: ${({ width }) => (width ? width : '240px')};
    height: 463px;
  }

  @media (min-width: 574px) and (max-width: 767px) {
    width: 100%;
  }
`
