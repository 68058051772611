import React from 'react'
import * as Styled from './Styles'

function Loading() {
  return (
    <Styled.LoadingContainer>
      <Styled.Loader></Styled.Loader>
    </Styled.LoadingContainer>
  )
}

export default React.memo(Loading)
