import styled from 'styled-components'
import { color } from '../../color/color'

export const AccountInfoData = styled.div`
  width: 100%;
  padding: 20px;
  background: ${color._dfe8e9};
  @media (max-width: 991px) {
    padding: 0px;
  }

  & .filters > * {
    margin-right: 20px;
  }

  @media (max-width: 726px) {
    & .filters > * {
      width: 90%;
    }
  }
`

export const Heading = styled.h2`
  background: ${color._343435};
  margin-bottom: 5px;
  font-size: 13px;
  text-transform: uppercase;
  padding: 5px;
  color: ${color._fff} !important;
`

export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${color._dfdde0};
  margin-bottom: 5px;
  padding: 3px;

  @media (max-width: 726px) {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }
`
export const Table = styled.table`
  width: 100%;
  @media (max-width: 991px) {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
  }
`
export const Buttons = styled.div`
  margin-top: 5px;
`

export const TableButtons = styled.button`
  border: none;
  background: transparent;
  opacity: 0.5;
  padding: 5px;
  font-size: 14px;
`

export const DateInput = styled.input`
  background-color: ${color._fff};
  color: ${color._333};
  padding: 5px 12px 5px 5px;
  width: 160px;
  height: 30px !important;
  border: ${color._959595} 1px solid;
  font-size: 12px;
  border-radius: 4px;
`

export const Button = styled.button`
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  text-transform: uppercase;
  background: ${color._000};
  display: block;
  width: 160px;
  color: ${color._fff};
  padding: 0;
  border-radius: 5px;
  border: none;
`
export const TableHead = styled.thead``
export const TableRow = styled.tr`
  border-color: ${color._fff};
  border-width: 1px 1px;
`
export const TableHeadData = styled.th`
  border-width: 1px 1px;
  width: ${({ width }) => width};
  font-weight: 600;
  padding: 8px;
  font-size: 12px;
  background: ${color._dfdde0};
  text-transform: capitalize;
  border-bottom: none !important;
`
export const TableBody = styled.tbody``
export const TableData = styled.td`
  padding: 5px;
  font-size: 12px;
  background: ${color._fff};
`
