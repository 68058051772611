import React, { useEffect, useState } from 'react'
import * as Styled from './style'
import Input from '../../components/Input/Input'
import {
  CarouselImages,
  LoginInputArr,
  PasswordObj,
  constants,
  getToastPosition,
} from '../../utils/constant'
import Carousel from '../Carousel/Carousel'
import Button from '../../components/Button/Button'
import useLoginHook from '../../hooks/login-hook'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const Login = ({
  handleClose,
  setToggle = () => null,
  toggle,
  setShow,
  setResetShow,
}) => {
  const [width, setWidth] = useState(window.innerWidth)

  const { onChangeInput, handleLogin, credentials, handleDemoLogin } =
    useLoginHook()
  const isModalClose = useSelector((state) => state.auth.isModalClose)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleLoginWithDemoId = (e) => {
    e.preventDefault()
    handleClose()
    handleDemoLogin()
    toast.success(constants.Successfully_logged_in, getToastPosition())
  }

  const handleResetPassword = () => {
    setResetShow(true)
    setShow(false)
  }
  useEffect(() => {
    isModalClose && handleClose()
  }, [isModalClose])

  return (
    <Styled.Login>
      <Styled.LoginForm className="col-md-6" autocomplete="off">
        <Styled.LoginHeading className="font-family-common text-align-center login-heading">
          {constants.LOGIN_NOW}
        </Styled.LoginHeading>
        {LoginInputArr.map((elem, indx) => {
          return (
            <Input
              label={elem.label}
              type={elem.type}
              key={elem + indx}
              value={
                elem.value === 'username'
                  ? credentials.username
                  : credentials.password
              }
              customStyle={elem?.customStyle}
              onChange={(e) => onChangeInput(e, elem.type)}
              inputBgColor={elem.inputBgColor}
              maxLength={65}
            />
          )
        })}

        <Styled.ForgotPassword className="font-family-common text-align-center">
          <Input
            label={PasswordObj.label}
            type={PasswordObj.type}
            value={
              PasswordObj.value === 'username'
                ? credentials.username
                : credentials.password
            }
            customStyle={PasswordObj?.customStyle}
            onChange={(e) => onChangeInput(e, PasswordObj.type)}
            inputBgColor={PasswordObj.inputBgColor}
          />
          <Styled.Password onClick={handleResetPassword}>
            {constants.Forgot_Password}
          </Styled.Password>
        </Styled.ForgotPassword>
        <Button
          btnName={constants.LOG_IN}
          handleClick={(e) => handleLogin(e, handleClose, setToggle, toggle)}
        />
        <Button
          btnName={constants.LOGIN_WITH_DEMO_ID}
          handleClick={handleLoginWithDemoId}
        />

        <Styled.FooterDiv>
          <Styled.FooterUpper>
            {constants.Powered_by}
            <Styled.ReddyBook>{constants.reddybook}</Styled.ReddyBook>
          </Styled.FooterUpper>
          <Styled.Anchor href={`mailto:${constants.reddybook_email}`}>
            {constants.reddybook_email}
          </Styled.Anchor>
        </Styled.FooterDiv>
      </Styled.LoginForm>
      {width > constants.PhoneWidth && (
        <Styled.Carousel className="col-md-6">
          <Carousel data={CarouselImages} />
        </Styled.Carousel>
      )}
    </Styled.Login>
  )
}

export default Login
