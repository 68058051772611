import styled from 'styled-components'
import { color } from '../../color/color'

export const Login = styled.div`
  display: flex;
  @media (min-width: 574px) and (max-width: 767px) {
    display: grid;
  }
`

export const LoginForm = styled.form`
  display: grid;
  gap: 5px;
  height: 420px;
  margin: 12% 2% 0% 2%;
  & h2::after {
    content: ' ';
    width: 30%;
    height: 2px;
    background: ${color._FFFFFF};
    display: block;
    margin-top: 10px;
    border-radius: 0;
    margin-left: auto;
    margin-right: auto;
  }

  & .btn {
    font-family: 'Titillium Web', sans-serif;
  }

  @media (max-width: 573px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 12% 0% 0% 0%;
  }
`

export const LoginHeading = styled.h2`
  font-size: 30px;
  color: ${color._FFFFFF};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
`

export const ForgotPassword = styled.form`
  color: ${color._FFFFFF};
  font-size: 13px;
  font-family: 'Titillium Web', sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  label {
    font-size: 10px;
    font-family: 'Titillium Web', sans-serif;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 10%;
    margin-right: 10%;
  }
`

export const Carousel = styled.div``

export const FooterDiv = styled.form`
  font-size: 13px;
  text-align: center;
  color: ${color._FFFFFF};
  place-items: center;
`
export const ReddyBook = styled.form`
  color: ${color._FF0000};
  margin-left: 5px;
`
export const Anchor = styled.a`
  color: ${color._FFFFFF};
  text-decoration: none;
  font-size: 13px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
`
export const FooterUpper = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
`

export const Password = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  @media (max-width: 991px) {
    font-size: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-right: 7%;
  }
`
