import styled from 'styled-components'
import { color } from '../color/color'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color._DFE8E9};
`

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: 991px) {
    display: block;
  }
`

export const Box = styled.div`
  width: 100%;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  padding: ${(props) => (props.pad ? props.pad + 'px' : '0px')};
  overflow-y: ${(props) => (props.isScrollable ? 'scroll' : 'inherit')};
  height: ${(props) => (props.isScrollable ? 'calc(100vh - 110px)' : 'auto')};
  scrollbar-width: none;
  -ms-overflow-style: none;
  @media (max-width: 1200px) {
    flex: ${(props) => (props.ismobile ? 0 : props.flex)};
    padding: ${(props) => (props.isPaddingNeeded ? props.pad + 'px' : '0px')};
    position: relative;
  }
  & .modal-content {
    transform: translate(0%, 50%) !important;
  }
`
export const Block = styled.div`
  display: flex;
  @media (max-width: 991px) {
    display: block;
  }
`
export const LeftBlock = styled.div`
  flex: 0;
  @media (max-width: 1200px) {
    flex: ${(props) => (props.ismobile ? 0 : props.flex)};
    padding: ${(props) => (props.isPaddingNeeded ? props.pad + 'px' : '0px')};
  }
`
export const RightBlock = styled.div`
  flex: 5;
  padding: 15px;
  @media (max-width: 991px) {
    padding: 0px;
  }
`
