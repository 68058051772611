import React from 'react'
import * as Styled from './styles'
import { color } from '../../../color/color'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import {
  cardData,
  cardImages,
  casinoCardData,
  evenOddCardsData,
  evenOddImageCardData,
} from './constant'

const Lucky7 = () => {
  return (
    <Styled.Container>
      <Styled.InnerContainer className="container">
        <div className="row">
          {cardData.length &&
            cardData.map((card, index) => (
              <div
                key={index + card.label}
                className={`${card.colSize} text-center ${card.padding}`}
              >
                <Styled.ThemedCardLabel>{card.label}</Styled.ThemedCardLabel>
                {card.text &&
                  (card.isSuspended ? (
                    <Styled.ThemedCardSuspended>
                      <div
                        className="position-relative"
                        style={{ height: '100%' }}
                      >
                        <Styled.SuspendedContainer>
                          <Lock fill={color._fff} />
                        </Styled.SuspendedContainer>
                        <div>{card.text}</div>
                      </div>
                    </Styled.ThemedCardSuspended>
                  ) : (
                    <Styled.ThemedCard>{card.text}</Styled.ThemedCard>
                  ))}
                {card.imageSrc && <Styled.CardImage src={card.imageSrc} />}
              </div>
            ))}
        </div>
      </Styled.InnerContainer>

      <div className="container mt-3">
        <div className="row">
          <div className="col-6 text-center" style={{ paddingLeft: 0 }}>
            <Styled.InnerContainer className="px-3">
              <div className="row pt-1 pb-3">
                {evenOddCardsData.length &&
                  evenOddCardsData.map((card, index) => (
                    <div className="col-6" key={index + card.label}>
                      <Styled.ThemedCardLabel>
                        {card.label}
                      </Styled.ThemedCardLabel>
                      <Styled.ThemedCard>{card.value}</Styled.ThemedCard>
                    </div>
                  ))}
              </div>
            </Styled.InnerContainer>
          </div>
          <div className="col-6 text-center" style={{ paddingRight: 0 }}>
            <Styled.InnerContainer className="px-3">
              <div className="row pt-1 pb-3">
                {evenOddImageCardData.length &&
                  evenOddImageCardData.map((card, index) => (
                    <div className="col-6" key={index + card.label}>
                      <Styled.ThemedCardLabel>
                        {card.label}
                      </Styled.ThemedCardLabel>
                      <Styled.ThemedImageCard>
                        {card.image}
                      </Styled.ThemedImageCard>
                    </div>
                  ))}
              </div>
            </Styled.InnerContainer>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="row">
          {casinoCardData.length &&
            casinoCardData.map((card, index) => (
              <div
                key={index + card.label}
                className="col-3 text-center"
                style={card.style}
              >
                <Styled.InnerContainer className="px-4">
                  <div className="row pt-2 pb-3">
                    <div className="col-12">
                      <Styled.ThemedCardLabel>
                        {card.label}
                      </Styled.ThemedCardLabel>
                    </div>
                    {card.images.cards.length &&
                      card.images.cards.map((image, imgIndex) => (
                        <Styled.ImageContainer
                          key={imgIndex + card.images.isSuspended}
                          className="col-4"
                          isSuspended={card.images.isSuspended}
                        >
                          {card.images.isSuspended && (
                            <Styled.SuspendedContainer>
                              {imgIndex === 1 && <Lock fill={color._fff} />}
                            </Styled.SuspendedContainer>
                          )}
                          <Styled.Image src={image} alt={`Card ${imgIndex}`} />
                        </Styled.ImageContainer>
                      ))}
                  </div>
                </Styled.InnerContainer>
              </div>
            ))}
        </div>
      </div>

      <div className="container mt-3">
        <Styled.InnerContainer className="row mb-3">
          <Styled.ThemedCardLabel className="mb-3 mt-2 col-12 text-center">
            12
          </Styled.ThemedCardLabel>
          <div className="mb-3 col-12 text-center px-5">
            <div className="d-flex justify-content-between">
              {cardImages.length &&
                cardImages.map((card) => {
                  return <Styled.Image src={card.src} alt={card.alt} />
                })}
            </div>
          </div>
        </Styled.InnerContainer>
      </div>
    </Styled.Container>
  )
}

export default Lucky7
