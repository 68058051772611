export const UpperNavigationArr = [
  {
    nav: 'Single',
    firstVal: 9.8,
    thirdVal: 9.8,
    column: 7,
  },
  {
    nav: 'Pana',
    secondVal: 'SP:140 | DP:240 | TP:700',
  },
  {
    nav: 'SP',
    secondVal: '140',
    seperateDiv: 'SP ALL',
  },
  {
    nav: 'DP',
    secondVal: '240',
    seperateDiv: 'DP ALL',
  },
  {
    nav: 'Trio',
    secondVal: '700',

  },
  {
    nav: 'Cycle',
  },
  {
    nav: 'Motor SP',
    firstVal: 9.8,
    thirdVal: 9.8,
  },
  {
    nav: '56 Charts',
    secondVal: '140',
    seperateDiv: '56 ALL',
  },
  {
    nav: '64 Charts',
    secondVal: '140',
    seperateDiv: '64 ALL',
  },
  {
    nav: 'ABR',
    firstVal: 9.8,
    thirdVal: 9.8,
    column: 4,
  },
  {
    nav: 'Common SP',
    firstVal: 9.8,
    thirdVal: 9.8,
  },
  {
    nav: 'Common DP',
    firstVal: 9.8,
    thirdVal: 9.8,
  },
  {
    nav: 'Color DP',
    secondVal: '240',
    seperateDiv: 'COLOR DP ALL',
  },
]

export const SingleCompArr = [
  {
    val: '1',
  },
  {
    val: '2',
  },
  {
    val: '3',
  },
  {
    val: '4',
  },
  {
    val: '5',
  },
  {
    val: 'LINE 1',
    subVal: '1|2|3|4|5',
  },
  {
    val: 'ODD',
    subVal: '1|3|5|7|9',
  },
  {
    val: '6',
  },
  {
    val: '7',
  },
  {
    val: '8',
  },

  {
    val: '9',
  },
  {
    val: '0',
  },
  {
    val: 'LINE 2',
    subVal: '6|7|8|9|0',
  },
  {
    val: 'EVEN',
    subVal: '2|4|6|8|0',
  },
]

export const NumberArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
export const ABRArr = ['A', 'B', 'R', 'ABR', 'AB', 'AR', 'BR', 'ABR CUT']
