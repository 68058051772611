import React from 'react'
import * as Styled from '../style'
import { IoIosArrowForward } from 'react-icons/io'
import { color } from '../../../color/color'
import IconTitle from './IconTitle'
const SBMatchOption = ({
  key,
  title,
  src,
  activeMatchOption,
  handleMatchOption,
  openDrawer,
}) => {
  return (
    <Styled.SBMatch
      key={key}
      onClick={() => handleMatchOption(title)}
      activeMatchOption={activeMatchOption === title}
      className="sb-match-option"
    >
      <IconTitle
        title={title}
        src={src}
        openDrawer={openDrawer}
        activeMatchOption={activeMatchOption}
      />
      {openDrawer && (
        <Styled.SBMatchArrow>
          <IoIosArrowForward
            color={activeMatchOption === title ? color._FFFFFF : color._000000}
          />
        </Styled.SBMatchArrow>
      )}
    </Styled.SBMatch>
  )
}

export default SBMatchOption
