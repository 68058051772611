export const constants = {
  REGISTER: 'Register',
  PASSWORD_RULES: '(Must contain alphanumeric and be more than 6 characters)',
  TERMS_CONDITION:
    ' By continuing, you will receive a one-time verification code to your phone number via SMS.',
  RESEND_TIMER: 'Resend OTP in:',
  RESEND: 'Resend',
  ALREADY_ACCOUNT: 'Already have an account?',
  MOBILE_REQUIRED: 'Phone number is required.',
  INVALID_MOBILE: 'Please enter a valid phone number with country code.',
  PASSWORD_REQUIRED: 'Please enter the password.',
  CONFIRM_PASSWORD_REQUIRED: 'Please enter the Confirm Password.',
  INVALID_PASSWORD:
    'Password must contain alphanumeric characters and be more than 6 characters long.',
  OTP_REQUIRED: 'Please enter the OTP.',
  OTP_ALREADY_SENT: 'OTP already sent. Please wait before requesting again.',
  LOGIN: 'Log In',
  SIGNUP: 'SIGNUP',
  GET_OTP: 'GET OTP',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm password',
  PASSWORD_PLACEHOLDER: 'Choose your password',
  NUMBER: 'number',
  OTP: 'otp',
  Mobile_no: 'Mobile no',
  text: 'text',
  PASSWORD_MISMATCH: 'Password does not match',
}
export const responseCode = {
  OK: 200,
  InternalServerError: 500,
}
export const logoutErrors = {
  logoutFail: 'Logout failed. Please try again later.',
  unexpextedServer: 'An unexpected error occurred during logout.',
}
