import React from 'react'
import * as Styled from './style'
import BatterTable from './components/BatterTable'
import { GoDotFill } from 'react-icons/go'

const SVGMatchData = () => {
  return (
    <Styled.SVGData>
    <Styled.BallDataDiv className="display-flex hide-scrollbar">
      {/* map for different overs */}
      <Styled.SingleBallDiv>
        <Styled.Over>Over 4</Styled.Over>
        <Styled.BallData className="display-flex">
          {/* map for balls data */}
          <Styled.Ball>6</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>
            <GoDotFill />
          </Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
        </Styled.BallData>
      </Styled.SingleBallDiv>
      <Styled.SingleBallDiv>
        <Styled.Over>Over 4</Styled.Over>
        <Styled.BallData className="display-flex">
          {/* map for balls data */}
          <Styled.Ball>6</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>
            <GoDotFill />
          </Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
        </Styled.BallData>
      </Styled.SingleBallDiv>
      <Styled.SingleBallDiv>
        <Styled.Over>Over 4</Styled.Over>
        <Styled.BallData className="display-flex">
          {/* map for balls data */}
          <Styled.Ball>6</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>
            <GoDotFill />
          </Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
        </Styled.BallData>
      </Styled.SingleBallDiv>
      <Styled.SingleBallDiv>
        <Styled.Over>Over 4</Styled.Over>
        <Styled.BallData className="display-flex">
          {/* map for balls data */}
          <Styled.Ball>6</Styled.Ball>
          <Styled.Ball wicket={true}>W</Styled.Ball>
          <Styled.Ball>
            <GoDotFill />
          </Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
        </Styled.BallData>
      </Styled.SingleBallDiv>
      <Styled.SingleBallDiv>
        <Styled.Over>Over 4</Styled.Over>
        <Styled.BallData className="display-flex">
          {/* map for balls data */}
          <Styled.Ball>6</Styled.Ball>
          <Styled.Ball>1w</Styled.Ball>
          <Styled.Ball>
            <GoDotFill />
          </Styled.Ball>
        </Styled.BallData>
      </Styled.SingleBallDiv>
    </Styled.BallDataDiv>
    <Styled.StatsDiv>
      <Styled.BattingInfo>
        <BatterTable
          heading={true}
          batter="BATTER"
          runs="R"
          balls="B"
          four="4S"
          six="6S"
        />
        <BatterTable
          batter="Hrithik Varshney"
          runs="100"
          balls="20"
          four="10"
          six="10"
        />
        <BatterTable
          batter="Ayush Tripathi"
          runs="0"
          balls="50"
          four="0"
          six="0"
        />
      </Styled.BattingInfo>
      <Styled.BowlerInningsStats className="display-flex">
        <Styled.CurrentBowler>
          <Styled.StatsHeading>CURRENT BOWLER</Styled.StatsHeading>
        </Styled.CurrentBowler>
        <Styled.InningsStats>
          <Styled.StatsHeading>INNINGS STATS</Styled.StatsHeading>
          <Styled.Stats>
            <Styled.StatsTitle>Fours</Styled.StatsTitle>
            <Styled.StatsValue>10</Styled.StatsValue>
          </Styled.Stats>
          <Styled.Stats>
            <Styled.StatsTitle>Sixes</Styled.StatsTitle>
            <Styled.StatsValue>10</Styled.StatsValue>
          </Styled.Stats>
          <Styled.Stats>
            <Styled.StatsTitle>Extras</Styled.StatsTitle>
            <Styled.StatsValue>0</Styled.StatsValue>
          </Styled.Stats>
        </Styled.InningsStats>
      </Styled.BowlerInningsStats>
    </Styled.StatsDiv>
  </Styled.SVGData>
  )
}

export default SVGMatchData
