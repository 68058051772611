import styled from 'styled-components'
import { color } from '../../../color/color'

export const TableContainer = styled.div`
  background-color: ${color._F2F2F2} !important;
  color: ${color._000};
`
export const Table = styled.table`
  width: 100% !important;
`
export const PrimaryTableHeadRow = styled.td`
  border-left: 1px solid ${color._C7C8CA} !important;
  border-top: 1px solid ${color._C7C8CA} !important;
  padding: 2px 2px 2px 5px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding-left: 5px !important;
  font-family: Roboto Condensed, sans-serif !important;
`

export const TableRowEmpty = styled.td`
  border-top: 1px solid ${color._C7C8CA} !important;
  padding: 2px 2px 2px 5px !important;
`
export const TableRowRightEmpty = styled.td`
  border-right: 1px solid ${color._C7C8CA} !important;
  border-top: 1px solid ${color._C7C8CA} !important;
  padding: 2px 2px 2px 5px !important;
`
export const SecondaryTableHeadRow = styled.td`
  border: 1px solid ${color._C7C8CA} !important;
  padding: 10px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: Roboto Condensed, sans-serif !important;
  text-align: center !important;
  text-wrap: nowrap !important;
`
export const TableData = styled.td`
  border: 1px solid ${color._C7C8CA} !important;
  padding: 10px !important;
  text-align: center !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._72BBEF} !important;
`
export const LockContainer = styled.div`
  position: absolute;
  z-index: 1;
`
export const SuspendedContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
export const SuspendedBonusContainer = styled.div`
  ${
    '' /* position: absolute;
  width: 100%;
  height: 100%;
  line-height: 3;
  background-color: ${color._opacityBg}; */
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Adjust for padding */
  width: calc(
    100% + 20px
  ); /* Adjust for the horizontal padding (10px on each side) */
  height: calc(
    100% + 20px
  ); /* Adjust for the vertical padding (10px on each side) */
  margin: -10px; /* To account for the added space from the calc */
  background-color: ${color._opacityBg};
  line-height: 5;
  z-index: 1;
`

export const BonusContainer = styled.div`
 position: relative
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  background-color: ${({ isSuspended }) => (isSuspended ? '' : color._72BBEF)};
`
export const Image = styled.img`
  height: 35px;
`
