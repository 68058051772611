import { _get } from '../../services/HttpMethods'
import ACTIONTYPES from './actionTypes'
import { endPoints } from '../../services/EndPoints'
import { startLoading, stopLoading } from '../loading/action'

export const getCasinoGames = (token) => async (dispatch) => {
  try {
    dispatch(startLoading())
    const response = await _get(endPoints.casino, token)
    const { msg, status, data = {} } = response
    if (status === 200) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.CASINO_GAMES_SUCCESS,
        payload: data,
      })
    } else {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.CASINO_GAMES_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch(stopLoading())
    dispatch({
      type: ACTIONTYPES.CASINO_GAMES_FAIL,
      payload: error,
    })
  }
}

export const getCasinoProviderGames = () => async (dispatch) => {
  try {
    dispatch(startLoading())
    const response = await _get(endPoints.casinoProvider)
    const { msg, status, data = {} } = response
    if (status === 200) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.CASINO_PROVIDER_GAMES_SUCCESS,
        payload: data,
      })
    } else {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.CASINO_PROVIDER_GAMES_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch(stopLoading())
    dispatch({
      type: ACTIONTYPES.CASINO_PROVIDER_GAMES_FAIL,
      payload: error,
    })
  }
}
