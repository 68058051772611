import React, { useEffect, useState } from 'react'
import { SidebarData, depositData, withdrawalData } from './SidebarData'
import SubMenu from './SubMenu'
import { IconContext } from 'react-icons/lib'
import * as Styled from './style'
import { color } from '../../color/color'
import CarouselSection from '../../container/Carousel/Carousel'
import { CarouselData } from './SidebarData'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { removeDuplicateObjectFromArray } from '../../utils/utils'
import logo from '../../assets/images/logo2.png'

const Sidebar = ({ setSidebar, sidebar }) => {
  const [sidebarDataState, setSidebarDataState] = useState()
  const location = useLocation()
  // State to show and hide the sidebar
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const token = useSelector((state) => state.auth.token)

  // it will show/hide the sidebar when users click on NavIcon
  const handleWindowSizeChange = () => {
    window.innerWidth < 1200 ? setSidebar(false) : setSidebar(true)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    let array = [...SidebarData]
    let index = 1 // For after index 1,
    isAuthenticated && array.splice(index + 1, 0, depositData, withdrawalData)
    isAuthenticated
      ? setSidebarDataState(removeDuplicateObjectFromArray(array))
      : setSidebarDataState(removeDuplicateObjectFromArray(SidebarData))
  }, [isAuthenticated, token])

  var elements = document.querySelectorAll('#highlighted-card')
  useEffect(() => {
    setCrossPosition()
  }, [location.pathname, elements])

  const setCrossPosition = () => {
    let path = location.pathname.includes('detail')
    return path ? '-5.8rem' : elements.length ? '-4.8rem' : '-2.8rem'
  }
  return (
    <>
      <IconContext.Provider value={{ color: color._FFFFFF }}>
        <Styled.SidebarNav sidebar={sidebar}>
          <Styled.SidebarWrap>
            <Styled.SidebarMopbileWrap>
              <Styled.MobileLogo alt={'reddyClubMobile'} src={logo} />
            </Styled.SidebarMopbileWrap>
            {/* this will map the first Level of Data that user can 
            see on the UI as a sidebar Item */}
            {sidebarDataState?.length &&
              sidebarDataState.map((item, index) => {
                return <SubMenu item={item} indexNumber={index} />
              })}
            {/* show the carousel at bottom of the sidebar*/}
            <CarouselSection
              indicators={false}
              width={'250px'}
              data={CarouselData}
            />
          </Styled.SidebarWrap>
        </Styled.SidebarNav>
      </IconContext.Provider>
    </>
  )
}

export default Sidebar
