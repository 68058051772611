const ACTIONTYPES = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'LOGOUT_FAIL',
  OTP_SENT_FAIL: 'OTP_SENT_FAIL',
  OTP_SENT_SUCCESS: 'OTP_SENT_SUCCESS',
  OTP_VERIFY_SUCCESS: 'OTP_VERIFY_SUCCESS',
  OTP_VERIFY_FAIL: 'OTP_VERIFY_FAIL',
  CHANGE_PASSOWRD_SUCCESS: 'CHANGE_PASSOWRD_SUCCESS',
  CHANGE_PASSOWRD_FAIL: 'CHANGE_PASSOWRD_FAIL',
  LOGIN_DEMO_SUCCESS: 'LOGIN_DEMO_SUCCESS',
  LOGOUT_DEMO_SUCCESS: 'LOGOUT_DEMO_SUCCESS',
  USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
  USER_DETAILS_FAIL: 'USER_DETAILS_FAIL',
  PROFILE_DETAILS_SUCCESS: 'PROFILE_DETAILS_SUCCESS',
  PROFILE_DETAILS_FAIL: 'PROFILE_DETAILS_FAIL',
  UNSETELLED_BETS_DETAILS_SUCCESS: 'UNSETELLED_BETS_DETAILS_SUCCESS',
  UNSETELLED_BETS_DETAILS_FAIL: 'UNSETELLED_BETS_DETAILS_FAIL',
  ACCOUNTSTATEMENT_DETAILS_SUCCESS: 'ACCOUNTSTATEMENT_DETAILS_SUCCESS',
  ACCOUNTSTATEMENT_DETAILS_FAIL: 'ACCOUNTSTATEMENT_DETAILS_FAIL',
  SPORTS_DETAILS_SUCCESS: 'SPORTS_DETAILS_SUCCESS',
  SPORTS_DETAILS_FAIL: 'SPORTS_DETAILS_FAIL',
}

export default ACTIONTYPES
