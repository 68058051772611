import React from 'react'
import { players_data, players_row } from './constant'
import * as Styled from './styles'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { color } from '../../../color/color'

const Poker2020 = () => {
  return (
    <Styled.Container className="container">
      <div className="row">
        {players_row.length &&
          players_row.map((playerName, index) => (
            <div key={index + playerName} className="col-6 text-align-center">
              <Styled.Span>{playerName}</Styled.Span>
              <div className="row">
                {players_data.length &&
                  players_data.map(({ label, stats }, i) => (
                    <div
                      key={i + stats.value}
                      className="col-4 text-align-center"
                    >
                      <Styled.Span>{label}</Styled.Span>
                      <Styled.BoxData isSuspended={stats.isSuspended}>
                        <div className="align-items-center justify-content-center position-relative">
                          {stats.isSuspended && (
                            <Styled.SuspendedContainer>
                              <Lock fill={color._fff} />
                            </Styled.SuspendedContainer>
                          )}
                          {stats.value}
                        </div>
                      </Styled.BoxData>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </Styled.Container>
  )
}

export default Poker2020
