import styled from 'styled-components'
import { color } from '../../color/color'

export const Section = styled.section`
  & .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  @media (max-width: 992px) {
    & .row {
      margin-top: 0px !important;
    }
  }
`

export const GameHeading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: ${color._343435};
  background-color: ${color._343435} !important;
  margin-bottom: 0;
  color: ${color._FFFFFF};
  padding: 4px 8px;
  margin-bottom: 2px;
  font-family: 'Titillium Web', sans-serif;

  font-size: 14px;
  span {
    font-size: 12px;
    font-weight: 400;
    color: ${color._FFFFFF};
    font-family: 'Titillium Web', sans-serif;
  }
  &:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: self-start;
    span {
      font-size: 8px;
    }
    &:first-child {
      border-radius: 0;
    }
  }
`

export const Title = styled.div`
  font-size: 14px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
`
export const PlaceBet = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
`

export const LiveStream = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
`

export const Cardheader = styled.div`
  border-color: ${color._343435};
  background-color: ${color._343435} !important;
  margin-bottom: 0;
  color: ${color._FFFFFF};
  padding: 4px 0px;
  font-size: 14px;
  &:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  }
  @media (max-width: 992px) {
    &:first-child {
      border-radius: 0;
    }
  }
`
export const H6 = styled.h6`
  color: ${color._FFFFFF};
  padding: 2px 8px;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Titillium Web', sans-serif;
`
export const Col1 = styled.div`
  padding-left: 0px !important;
  @media (max-width: 992px) {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
`
export const Col2 = styled.div`
  padding-left: calc(1.5rem * 0.5);
  @media (max-width: 992px) {
    padding-left: 0 !important;
    width: 0;
  }
`

export const OpenBet = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
export const NavButton = styled.button`
  color: ${color._000};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  width: 33.33%;
  border-right: 0.5px solid gray;
  &:last-child {
    border-right: none; /* Remove border-right for the last child */
  }
  &.active {
    color: ${color._6D081D};
    border-bottom: 2px solid ${color._6D081D};
  }
  @media (max-width: 658px) {
    padding: 0px;
    width: auto;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
`
export const Nav = styled.nav`
  width: 100%;
`
export const NavTabs = styled.div`
  border-bottom: 2px solid ${color._EBEEF4};
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const TableHead = styled.th`
  width: 15%;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  color: #212529;
  font-weight: 400;
`
export const TableBodyData = styled.td`
  width: 15%;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  color: #212529;
  font-weight: 400;
`
