import {
  _post,
  _get,
  _put,
  _delete,
  _postPanel,
  _putPanel,
  _getPanel,
} from '../../services/HttpMethods'
import ACTIONTYPES from './actionTypes'
import { endPoints } from '../../services/EndPoints'
import { startLoading, stopLoading } from '../loading/action'
import { toast } from 'react-toastify'
import { getToastPosition } from '../../utils/constant'
import { fetchUserDetails } from '../auth/action'

export const withdrawAmount = (data, token) => async (dispatch) => {
  try {
    dispatch(startLoading())
    const response = await _postPanel(endPoints.withdraw, data, token)
    const { msg, status, data: _data = {} } = response.data
    if (status === 200) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.WITHDRAW_SUCCESS,
        payload: _data,
      })
      toast.success(msg, getToastPosition())
      dispatch(fetchUserDetails())
    } else {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.WITHDRAW_FAIL,
        payload: msg,
      })
      toast.error(msg, getToastPosition())
    }
    if (status === 200) {
      dispatch(getWithdrawDetails(token))
    }
  } catch (error) {
    dispatch(stopLoading())
    dispatch({
      type: ACTIONTYPES.WITHDRAW_FAIL,
      payload: error,
    })
  }
}

export const getWithdrawDetails = (token) => async (dispatch) => {
  try {
    dispatch(startLoading())
    const response = await _getPanel(endPoints.withdraw, token)
    const { msg, status, data = {} } = response
    if (status === 200) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.GET_WITHDRAW_SUCCESS,
        payload: data,
      })
    } else {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.GET_WITHDRAW_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch(stopLoading())
    dispatch({
      type: ACTIONTYPES.GET_WITHDRAW_FAIL,
      payload: error,
    })
  }
}

export const withdrawPasswordChange =
  (credentials, token) => async (dispatch) => {
    try {
      dispatch(startLoading())
      const response = await _put(
        endPoints.withdraw_password_change,
        credentials,
        token,
      )
      const { msg, status, data = {} } = response
      if (status === 200) {
        dispatch(stopLoading())
        dispatch({
          type: ACTIONTYPES.OTP_VERIFY_SUCCESS,
          payload: data,
        })
      } else {
        dispatch(stopLoading())
        dispatch({
          type: ACTIONTYPES.OTP_SENT_FAIL,
          payload: msg,
        })
      }
    } catch (error) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.OTP_SENT_FAIL,
        payload: error,
      })
    }
  }

export const addWithdrawAccount = (credentials, token) => async (dispatch) => {
  try {
    dispatch(startLoading())
    const response = await _post(
      endPoints.withdraw_add_account,
      credentials,
      token,
    )
    const { msg, status, data = {} } = response

    if (status === 201) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.ADD_ACCOUNT_SUCCESS,
        payload: data,
      })
    } else {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.ADD_ACCOUNT_FAIL,
        payload: msg,
      })
    }

    dispatch(getWithdrawAccountDetails())
  } catch (error) {
    dispatch(stopLoading())
    dispatch({
      type: ACTIONTYPES.ADD_ACCOUNT_FAIL,
      payload: error,
    })
  }
}

export const getWithdrawAccountDetails = () => async (dispatch) => {
  try {
    const response = await _get(endPoints.withdraw_add_account)
    const { msg, status, data = {} } = response
    if (status === 200) {
      dispatch({
        type: ACTIONTYPES.GET_ACCOUNT_SUCCESS,
        payload: data,
      })
    } else {
      dispatch({
        type: ACTIONTYPES.GET_ACCOUNT_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch({
      type: ACTIONTYPES.GET_ACCOUNT_FAIL,
      payload: error,
    })
  }
}

export const deleteAccount = (id) => async (dispatch) => {
  try {
    dispatch(startLoading())
    const response = await _delete(endPoints.withdraw_add_account + `/${id}`)
    const { msg, status, data = {} } = response

    if (status === 204) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.DELETE_ACCOUNT_SUCCESS,
        payload: data,
      })
    } else {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.DELETE_ACCOUNT_FAIL,
        payload: msg,
      })
    }

    dispatch(getWithdrawAccountDetails())
  } catch (error) {
    dispatch(stopLoading())
    dispatch({
      type: ACTIONTYPES.DELETE_ACCOUNT_FAIL,
      payload: error,
    })
  }
}

export const deleteWithdrawTable = (id, token) => async (dispatch) => {
  try {
    dispatch(startLoading())
    const response = await _putPanel(endPoints.withdraw_cancel, id, token)
    const { msg, status } = response?.data
    if (status === 200) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.DELETE_ACCOUNT_CANCEL_SUCCESS,
        payload: msg,
      })
      toast.success(msg, getToastPosition())
    } else {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.DELETE_ACCOUNT_CANCEL_FAIL,
        payload: msg,
      })
      toast.success(msg, getToastPosition())
    }

    dispatch(getWithdrawDetails(token))
  } catch (error) {
    dispatch(stopLoading())
    dispatch({
      type: ACTIONTYPES.DELETE_ACCOUNT_CANCEL_FAIL,
      payload: error,
    })
  }
}

export const showModaL = (creds) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    dispatch(startLoading())
    const response = await _post(endPoints.withdraw_set_pass, creds, token)
    const { msg, status, data = {} } = response
    if (status === 200) {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.WITHDRAW_MODAL_SHOW_SUCCESS,
        payload: data,
      })
    } else {
      dispatch(stopLoading())
      dispatch({
        type: ACTIONTYPES.WITHDRAW_MODAL_SHOW_FAIL,
        payload: msg,
      })
    }
  } catch (error) {
    dispatch(stopLoading())
    dispatch({
      type: ACTIONTYPES.WITHDRAW_MODAL_SHOW_FAIL,
      payload: error,
    })
  }
}
