import styled from 'styled-components'
import { color } from '../../../color/color'

export const RulesModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${'' /* padding: 10px 20px; */}
  padding: 0px 10px;
  align-items: center;
  background-color: ${color._08C};
`

export const RulesModalHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const RulesModalHeaderTitle = styled.span`
  color: ${color._fff};
  font-family: Roboto Condensed, sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  line-height: 1.5;
  margin: 0;
`

export const RulesModalHeaderSubTitle = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-size: 12px;
  font-weight: 700;
  opacity: 60%;
`

export const RulesModalHeaderCross = styled.div`
  display: flex;
  flex-direction: column;
`
export const RulesModalHeaderCrossSpan = styled.span`
  font-size: 32px;
  ${'' /* font-weight: 800; */}
  cursor: pointer;
  color: ${color._fff};
  line-height: 1.5;
  margin: 0;
`

export const RulesModalBody = styled.div`
  color: ${color._191919};
  font-family: Roboto Condensed, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 19px;
  padding: 5px 10px 10px;
  max-height: calc(100vh - 120px);
  overflow-y: scroll;
`

export const SportsTitle = styled.span`
  &:before {
    content: '';
    background: linear-gradient(180deg, ${color._CF8D2E} 0, ${color._CF8D2E});
    clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
    width: 18px;
    height: calc(100% + 0px);
    position: absolute;
    right: -17px;
    top: 0;
  }
  background: ${color._CF8D2E};
  text-transform: uppercase;
  position: relative;
  padding: 7px 12px;
  color: ${color._000000};
  display: inline-flex;
  width: 200px;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
`

export const RulesModalTable = styled.table`
  margintop: 10px;
`

export const RulesModalTableData = styled.td`
  border: 1px solid ${color._000000};
  padding: 5px;
`

export const UnOrderedList = styled.ul`
  margin-bottom: 5px;
  padding-left: 10px;
  font-size: 10px;
  font-weight: 500;
`

export const HandsDealt = styled.span`
  display: block;
  font-size: 10px;
  font-weight: 500;
  padding-left: 10px;
`

export const ListItem = styled.li`
  ${'' /* list-style: none; */}
`

export const GameObjective = styled.span`
  display: block;
  padding: 10px;
  font-size: 10px;
  font-weight: 500;
`

export const PayoutTitle = styled.span`
  font-size: 10px;
  font-weight: 500;
`

export const BetHeading = styled.div`
  padding-left: '10px';
`

export const PayoutContainer = styled.div`
  padding-left: '10px';
`

//FOOTER
export const FooterTopContainer = styled.div`
  background-color: #2c3e50d9;
  color: #fff;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 0px 5px;
`

export const Col = styled.div``
export const FooterBottomContainer = styled.div``
export const CircularBox = styled.div`
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(item) => (item.color ? item.color : '')};
  font-size: 14px;
  font-weight: bold;
`

export const FooterContainer = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-size: 14px !important;
  font-weight: 700;
`
