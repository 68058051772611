import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../components/Loader/Loader'
import CasinoProvider from '../../container/CasinoProvider/CasinoProvider'
import ScoreCard from '../../container/ScoreCard/ScoreCard'
import { _constants } from './constants'
import * as Styled from './style'
import { useEffect, useRef } from 'react'
import { getMatchOddsData } from '../../redux/match/action'
import { useSocket } from '../../SocketContext'
import {
  convertBMDataForOdds,
  getAllMatches,
  mergeArraysByMarketId,
  sortedAlphabeticallyMatches,
} from '../../utils/utils'
const InPlay = () => {
  const matchesData = useSelector((state) => state.match.data)
  const matchesDataOdds = useSelector((state) => state.match.odds)
  const { socket, turnOffEmit, disconnectAll, reconnectSocket } = useSocket()
  const dispatch = useDispatch()
  const stopEmit = useSelector((state) => state.match.stopEmit)

  const isLoading = useSelector((state) => state.match.isLoading)

  const matchData = getAllMatches(matchesData)
  const sortedMatches = matchData?.length
    ? sortedAlphabeticallyMatches(matchData)
    : []
  const avoidRerender = useRef(false)

  useEffect(() => {
    if (socket) {
      if (!avoidRerender.current && stopEmit) {
        avoidRerender.current = true
        socket.emit('all-bm')
        socket.emit('all-events')
        socket.on('home-bm', (data) => {
          let results = convertBMDataForOdds(JSON.parse(data))
          dispatch(
            getMatchOddsData(mergeArraysByMarketId(results, matchesData)),
          )
        })
      }
      return () => (avoidRerender.current = true)
    }
  }, [socket])

  return sortedMatches.length ? (
    <>
      {sortedMatches.map((item) => {
        return (
          <ScoreCard
            matches={item.matches}
            name={item.sportsName}
            isPlay={true}
            odds={matchesDataOdds}
          />
        )
      })}
      <CasinoProvider />
    </>
  ) : (
    <Styled.Span>{_constants.noRecord}</Styled.Span>
  )
}
export default InPlay
