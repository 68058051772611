import React from 'react'
import { constants, numberdata, playersData, tableHeaders } from './constant'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import * as Styled from './styles'
import { color } from '../../../color/color'
import { chunkArray } from '../../../utils/utils'

const TableCardComponent = ({ playersData }) => {
  return (
    <div className="col-6">
      <Styled.Table>
        <tr>
          {tableHeaders.map((header) => (
            <Styled.SecondaryTableHeadRow key={header}>
              {header}
            </Styled.SecondaryTableHeadRow>
          ))}
        </tr>
        {playersData?.length &&
          playersData?.map(({ player, stats }) => (
            <React.Fragment key={player + Math.random() * 10}>
              <tr>
                <Styled.PlayerTableRow>
                  {`${constants.player} ${player}`}
                </Styled.PlayerTableRow>

                {Object.keys(stats).map((statKey, index) => {
                  const stat = stats[statKey]
                  return (
                    <Styled.TableData
                      key={index + stats[statKey]}
                      isSuspended={stat.isSuspended}
                    >
                      <div className="align-items-center justify-content-center position-relative">
                        {stat.isSuspended && (
                          <Styled.SuspendedContainer>
                            <Lock fill={color._fff} />
                          </Styled.SuspendedContainer>
                        )}
                        <span>{stat.value}</span>
                      </div>
                    </Styled.TableData>
                  )
                })}
              </tr>
            </React.Fragment>
          ))}
      </Styled.Table>
    </div>
  )
}

function Cards32() {
  const row = chunkArray(numberdata, Math.ceil(numberdata.length / 3))

  return (
    <div className="container">
      <div className="row mb-3">
        <TableCardComponent playersData={playersData} />
        <TableCardComponent playersData={playersData} />
      </div>
      <div className="row mb-3">
        <TableCardComponent playersData={playersData} />
        <TableCardComponent playersData={playersData} />
      </div>

      <div className="row mb-3">
        <Styled.Table>
          <tr>
            <th colspan="5">0</th>
          </tr>
          <tr>
            {row?.map((numbers) =>
              numbers.map((number) => (
                <Styled.TableData
                  key={number.id}
                  isSuspended={number.isSuspended}
                >
                  <div className="align-items-center justify-content-center position-relative">
                    {number.isSuspended && (
                      <Styled.SuspendedContainer>
                        <Lock fill={color._fff} />
                      </Styled.SuspendedContainer>
                    )}
                    <span>{number.count}</span>
                  </div>
                </Styled.TableData>
              )),
            )}
          </tr>
        </Styled.Table>
      </div>
    </div>
  )
}

export default Cards32
