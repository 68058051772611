import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Styled from './style'
import { IoMdClose } from 'react-icons/io'
import {
  Container,
  ModalInnerDiv,
  GlobalStyle,
  HeaderBox,
  ModalHeaderSpan,
  ResetForm,
} from './style'

import { color } from '../../color/color'
import { _constants } from './contants'

const PolicyModal = ({ show, handleClose }) => {
  const closeModal = () => {
    handleClose()
  }

  return (
    <Styled.Modal>
      <Modal show={show} onHide={closeModal} centered>
        <ModalInnerDiv>
          <GlobalStyle />

          <Container>
            <HeaderBox>
              <IoMdClose
                color={color._FFFFFF}
                className="close-icon"
                onClick={closeModal}
              />
              <ModalHeaderSpan className="text-center pt-3">
                {_constants.PolicyHeader}
              </ModalHeaderSpan>
            </HeaderBox>
            <ResetForm id="withdraw_form">
              {_constants.Policy1}
              <br />
              <br />
              {_constants.Policy2}
              <br />
              <br />
              {_constants.Policy3}
            </ResetForm>
          </Container>
        </ModalInnerDiv>
      </Modal>
    </Styled.Modal>
  )
}

export default PolicyModal
