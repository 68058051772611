import React from 'react'
import { Pie } from 'react-chartjs-2'
import * as Styled from './styles'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { color } from '../../../color/color'
import {
  constants,
  leftBoxStyle,
  middleContainerData,
  pieChartData,
  pieChartOptions,
  rightBoxStyle,
  statsData,
} from './constant'

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend)

const Box = ({ children, isSuspended = true, styles }) => {
  return (
    <Styled.BoxContainer
      className="d-flex align-items-center justify-content-center position-relative"
      style={styles}
      isSuspended={isSuspended}
    >
      <Styled.EmptyContainer>{children}</Styled.EmptyContainer>

      {isSuspended && <Styled.SuspendedContainer></Styled.SuspendedContainer>}

      {isSuspended && (
        <Styled.LockContainer>
          <Lock fill={color._fff} />
        </Styled.LockContainer>
      )}
    </Styled.BoxContainer>
  )
}

// Baccarat component
const Baccarat = () => (
  <Styled.Container className="container">
    <div className="row">
      <div className="col-3">
        <Styled.Heading>{constants.stats}</Styled.Heading>
        <Pie data={pieChartData} options={pieChartOptions} />
      </div>

      <div className="col-9">
        <Styled.RowContainer className="row">
          {statsData.map((num, index) => (
            <Styled.NumContainer key={index} className="col-3 mb-3">
              <Box>{num}</Box>
            </Styled.NumContainer>
          ))}
        </Styled.RowContainer>

        <div className="row mt-3">
          <Styled.BottomContainer className="col-2">
            <Box styles={leftBoxStyle}>{constants.playerPair}</Box>
          </Styled.BottomContainer>
          <div className="col-8 p-0">
            <div className="d-flex">
              {middleContainerData.map((num, index) => (
                <Styled.BottomContainer key={index} className="col-4">
                  <Box
                    isSuspended={num.isSuspended}
                    styles={{ backgroundColor: num.color }}
                  >
                    {num.value}
                  </Box>
                </Styled.BottomContainer>
              ))}
            </div>
          </div>
          <Styled.BottomContainer className="col-2">
            <Box isSuspended={false} styles={rightBoxStyle}>
              {constants.bankerPair}
            </Box>
          </Styled.BottomContainer>
        </div>
      </div>
    </div>
  </Styled.Container>
)

export default Baccarat
