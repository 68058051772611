import React, { useEffect, useRef, useState } from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import WithdrawalModal from '../../components/WithdrawalModal/WithdrawalModal'
import AddAccountModal from './utils/AddAccountModal'
import {
  deleteAccount,
  deleteWithdrawTable,
  getWithdrawAccountDetails,
  getWithdrawDetails,
  withdrawAmount,
} from '../../redux/widthdraw/action'
import { MdDelete } from 'react-icons/md'
import { FaCopy } from 'react-icons/fa6'
import ClearIcon from '@mui/icons-material/Clear'
import ReactDOMServer from 'react-dom/server'

import {
  Wrapper,
  BackButton,
  ContentWrapper,
  LeftSection,
  InfoWrapper,
  GreenWrapper,
  GreenContainer,
  RightSection,
  IconSpan,
  ButtonSection,
  Button,
  Bold,
  DeleteButton,
  CardTitle,
  CopyButton,
  AccountData,
  CardText,
  CopyToolTip,
  FormFooterButton,
  WithdrawBox,
  EllipsisTableData,
  NoRecords,
} from './style'
import { color } from '../../color/color'
import { getToastPosition } from '../../utils/constant'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import WithdrawPasswordModal from '../../components/WithdrawPasswordModal/WithdrawPasswordModal'
import { GoDash } from 'react-icons/go'
import { fetchUserDetails } from '../../redux/auth/action'
import { MdDeleteOutline } from 'react-icons/md'

const WithdrawPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const withdrawData = useSelector((state) => state.withdraw.data)
  const isShowModal = useSelector((state) => state.withdraw.isShow)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  const isWithdrawPassword = useSelector(
    (state) => state.auth.isWithdrawPassword,
  )
  const accounts = useSelector((state) => state.withdraw.account)
  const iconHtml = ReactDOMServer.renderToString(
    <MdDeleteOutline size={50} color="#ffffff" />,
  )

  const [addAccountModal, setAddAccountModal] = useState(false)
  const [showTooltip, setShowTooltip] = useState({})
  const [resetWithdrawModal, setResetWithdrawModal] = useState(false)
  const [amount, setAmount] = useState(0)
  const [reason, setReason] = useState('')
  const [withdrawTableData, setWithdrawTableData] = useState([])
  const [accountsData, setAccountsData] = useState([])
  const [withdrawModal, setWithdrawModal] = useState()
  const [inputDisabled, setInputDisabled] = useState()
  const [copyId, setCopyId] = useState()
  const [noRecords, setNoRecords] = useState(null)
  const userDetails = useSelector((state) => state.auth.userData)

  const avoidRerender = useRef(false)
  const loader = useSelector((state) => state.loading)

  const handleAddAccountModal = () => setAddAccountModal(!addAccountModal)

  const handleResetWithdrawModal = () =>
    setResetWithdrawModal(!resetWithdrawModal)

  useEffect(() => {
    !isShowModal && setResetWithdrawModal(false)
  }, [])
  useEffect(() => {
    Number(isWithdrawPassword) == 0 && setWithdrawModal(true)
  }, [isWithdrawPassword])

  useEffect(() => {
    if (withdrawData) {
      const withdraw = [...withdrawData]
      const withdrawdetails = withdraw?.sort((a, b) => {
        return parseInt(b?.created) - parseInt(a?.created)
      })
      setWithdrawTableData(withdrawdetails)
      setNoRecords(withdrawdetails?.length)
    }
  }, [withdrawData])

  useEffect(() => {
    const data = [...accounts]
    const _accounts = data?.sort((a, b) => {
      return parseInt(b?.created) - parseInt(a?.created)
    })
    setAccountsData(_accounts)
  }, [accounts])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!avoidRerender.current) {
      if (loader.isLoading) return
      dispatch(getWithdrawDetails(token))
      dispatch(getWithdrawAccountDetails())
    }
    return () => (avoidRerender.current = true)
  }, [])

  useEffect(() => {
    if (!avoidRerender.current) {
      isAuthenticated && dispatch(fetchUserDetails())
    }
    return () => (avoidRerender.current = true)
  }, [])

  const handleResetAccountModal = (id) => {
    Swal.fire({
      title: 'Do you want to delete account?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#aaaaaa',
      iconHtml: iconHtml,
      iconimageHeight: 100,
      customClass: {
        container: 'my-swal-container',
        popup: 'my-swal-popup',
        title: 'my-swal-title',
        content: 'my-swal-content',
        cancelButton: 'my-button-swal-cancel',
        confirmButton: 'my-button-swal-confirm',
      },
    }).then(({ isConfirmed }) => {
      isConfirmed && dispatch(deleteAccount(id))
    })
  }
  const handleBack = () => navigate(-1)

  const getCreated = (data) =>
    moment(parseInt(data, 10) / 1000).format('MM-DD-YY hh:mm:ss a')

  const handleCopy = (copiedText, action, id) => {
    navigator.clipboard.writeText(copiedText)
    setShowTooltip({ ...showTooltip, [action]: true })
    setTimeout(() => {
      setShowTooltip({ ...showTooltip, [action]: false })
    }, 2000)
    setCopyId(id)
  }
  const handleAmount = (e, id) => {
    if (e.target.value > 5000000) {
      toast.warn('Withdraw amount cannot exceed 5000000', getToastPosition())
      setInputDisabled(true)
      return
    } else {
      const inputVal = e.target.value
      if (/^\d*\.?\d{0,2}$/.test(inputVal)) {
        setAmount(e.target.value)
        setInputDisabled(false)
        setAccountsData(
          accountsData?.map((input) =>
            input?.id === id
              ? { ...input, value: e.target.value, isInput: true }
              : input,
          ),
        )
      }
    }
  }

  const handleReason = (e, id) => {
    setAccountsData(
      accounts.map((input) =>
        input?.id === id ? { ...input, reason: e.target.value } : input,
      ),
    )
    setReason(e.target.value)
  }

  const handleWithdraw = (account, e) => {
    e.stopPropagation()
    const token = localStorage.getItem('token')
    if (amount < 100) {
      toast.warn('Amount must be greater than 100', getToastPosition())
    } else if (Number(amount) > Number(userDetails?.current)) {
      toast.warn('Insufficient funds', getToastPosition())
    } else {
      const apiRequest = {
        amount: account?.value || amount,
        site: 'reddybookclub',
        type: 'client',
        accountId: account?.id,
        // accountNumber: '',
        // account: account.name,
        // reason: '',
        // ifsc: '',
        // clientBank: {
        //   accountNumber: account.accountNumber,
        //   account: account.name,
        //   ifscCode: account.ifscCode,
        // },
      }
      if (loader.isLoading) return
      dispatch(withdrawAmount(apiRequest, token))
      setAmount(0)
      setReason('')
      setAccountsData(
        accountsData?.map((input) => ({
          ...input,
          value: '',
          reason: '',
          isInput: false,
        })),
      )
    }
  }
  const handleWithdrawModal = () => {
    setWithdrawModal(!withdrawModal)
  }
  const handleDelete = (item) => {
    const token = localStorage.getItem('token')

    Swal.fire({
      title: 'Do you want to cancel withdraw?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#aaaaaa',
      iconHtml: iconHtml,
      imageWidth: 100,
      imageHeight: 100,
      customClass: {
        container: 'my-swal-container',
        popup: 'my-swal-popup',
        title: 'my-swal-title',
        content: 'my-swal-content',
        cancelButton: 'my-swal-swal-cancel',
        confirmButton: 'my-button-swal-confirm',
      },
    }).then(({ isConfirmed }) => {
      const id = {
        id: item?.id,
      }
      isConfirmed && dispatch(deleteWithdrawTable(id, token))
    })
  }

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return 'PENDING'
      case 1:
        return 'APPROVED'
      case 2:
        return 'REJECTED'
      case 'cancel':
        return 'CANCELLED'
      default:
        return null
    }
  }
  return (
    <>
      <AddAccountModal
        show={addAccountModal}
        handleClose={handleAddAccountModal}
      />
      <WithdrawalModal
        show={resetWithdrawModal}
        handleClose={handleResetWithdrawModal}
        size="lg"
      />
      <WithdrawPasswordModal
        show={withdrawModal}
        handleClose={handleWithdrawModal}
      />
      <Wrapper>
        <BackButton onClick={handleBack}>Back</BackButton>
      </Wrapper>
      <ButtonSection className="col-md-12 text-center pt-3">
        <Button className="btn btn-info" onClick={handleAddAccountModal}>
          Add account
        </Button>
      </ButtonSection>
      <ContentWrapper>
        <LeftSection>
          <InfoWrapper>
            <ol>
              <li>
                1. This form is for withdrawing the amount from the main wallet
                only.
              </li>
              <li>
                2. The bonus wallet amount cannot be withdrawn by this form.
              </li>
              <li>
                3. Do not put Withdraw request without betting with deposit
                amount. Such activity may be identified as Suspicious.
              </li>
              <li>
                4. If multiple users are using the same withdraw account then
                all the linked users will be blocked.
              </li>
              <li>
                5. Maximum Withdraw time is 45 minutes then only complain on
                WhatsApp number.
              </li>
            </ol>
          </InfoWrapper>
          <GreenWrapper onClick={handleResetWithdrawModal}>
            <GreenContainer>
              <Bold>FOR RESET WITHDRAW PASSWORD CLICK HERE</Bold>
              <IconSpan>
                <FaWhatsapp />
              </IconSpan>
            </GreenContainer>
          </GreenWrapper>
          <div
            style={{ gap: '22px', marginRight: '0px', marginLeft: '0px' }}
            className="row justify-center justify-content-center "
          >
            {accountsData?.length ? (
              accountsData
                .filter((account) => !account.deleted)
                .map((account) => (
                  <WithdrawBox className="col-md-6 ">
                    <CardTitle>{account?.name}</CardTitle>
                    <div className="">
                      <div className="d-flex justify-content-center">
                        <div
                          className="col"
                          style={{ paddingLeft: '20px', paddingRight: '20px' }}
                        >
                          <AccountData>
                            <CardText>
                              Account No: {account?.accountNumber}
                              <CopyButton>
                                <FaCopy
                                  color={color._C10930}
                                  onClick={() =>
                                    handleCopy(
                                      account?.accountNumber,
                                      'accountName',
                                      account?.id,
                                    )
                                  }
                                />
                              </CopyButton>
                              {showTooltip['accountName'] &&
                                copyId === account?.id && (
                                  <CopyToolTip>Copied!</CopyToolTip>
                                )}
                            </CardText>
                            {console.log(account, 'account')}
                            <CardText>
                              IFSC Code: {account.ifscCode}
                              <CopyButton>
                                <FaCopy
                                  color={color._C10930}
                                  onClick={() =>
                                    handleCopy(
                                      account.ifscCode,
                                      'ifscCode',
                                      account?.id,
                                    )
                                  }
                                />
                              </CopyButton>
                              {showTooltip['ifscCode'] &&
                                copyId === account?.id && (
                                  <CopyToolTip>Copied!</CopyToolTip>
                                )}
                            </CardText>
                          </AccountData>
                          <br />
                          <div style={{ marginBottom: '10px' }}>
                            <input
                              type="number"
                              placeholder="Enter Amount"
                              className="form-control"
                              maxLength="50"
                              onChange={(e) => handleAmount(e, account?.id)}
                              required
                              value={account?.value}
                            />
                          </div>
                          {/* will use in future if needed */}
                          {/* <div>
                            <textarea
                              type="text"
                              placeholder="Enter Reason"
                              className="form-control"
                              maxLength="50"
                              onChange={(e) => handleReason(e, account?.id)}
                              value={account?.reason}
                              required
                            />
                          </div> */}
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <FormFooterButton
                          inputDisabled={inputDisabled || !account?.isInput}
                          className="btn btn-info"
                          onClick={(e) =>
                            inputDisabled || !account?.isInput
                              ? () => {}
                              : handleWithdraw(account, e)
                          }
                        >
                          Submit
                        </FormFooterButton>
                      </div>
                    </div>
                    <DeleteButton
                      class="btn btn-danger delete-account-btn"
                      data-id="713693"
                      data-url="https://reddypanel.com/client-account/reddybook.club/2578967/713693/delete"
                      data-original-title=""
                      title=""
                      fdprocessedid="zg3lxa"
                      onClick={() => handleResetAccountModal(account.id)}
                    >
                      <MdDelete color="#ffffff" />
                    </DeleteButton>
                  </WithdrawBox>
                ))
            ) : (
              <></>
            )}
          </div>
        </LeftSection>
        <RightSection style={{ minHeight: noRecords === 0 ? '0px' : '180px' }}>
          <Table responsive>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Status</th>
                <th>Account</th>
                <th>Date</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {withdrawTableData?.length ? (
                withdrawTableData
                  ?.sort((a, b) => {
                    return parseInt(b.created) - parseInt(a.created)
                  })
                  ?.map((item, index) => (
                    <tr key={index}>
                      <EllipsisTableData
                        item={getStatus(
                          getStatus(item?.status) === 'PENDING' &&
                            item?.cancelledAt?.length
                            ? 'cancel'
                            : null,
                        )}
                      >
                        <span className="withdraw-section">
                          {getStatus(item?.status) === 'PENDING' &&
                          !item?.cancelledAt?.length ? (
                            <ClearIcon
                              onClick={() => handleDelete(item)}
                              sx={{ fontSize: 14 }}
                            />
                          ) : (
                            <></>
                          )}
                          {Number(item?.amount)}
                        </span>
                      </EllipsisTableData>

                      <EllipsisTableData
                        item={getStatus(
                          getStatus(item?.status) === 'PENDING' &&
                            item?.cancelledAt?.length
                            ? 'cancel'
                            : item?.status,
                        )}
                      >
                        <span className="withdraw-status">
                          {getStatus(
                            getStatus(item?.status) === 'PENDING' &&
                              item?.cancelledAt?.length
                              ? 'cancel'
                              : item?.status,
                          ) || (
                            <GoDash
                              style={{
                                background: 'white',
                                color: 'black',
                              }}
                            />
                          )}
                        </span>
                      </EllipsisTableData>
                      <EllipsisTableData
                        item={getStatus(
                          getStatus(item?.status) === 'PENDING' &&
                            item?.cancelledAt?.length
                            ? 'cancel'
                            : null,
                        )}
                      >
                        <span className="withdraw-section">
                          {item?.clientBank?.accountName || (
                            <GoDash
                              style={{ background: 'white', color: 'black' }}
                            />
                          )}
                        </span>
                      </EllipsisTableData>
                      <EllipsisTableData
                        item={getStatus(
                          getStatus(item?.status) === 'PENDING' &&
                            item?.cancelledAt?.length
                            ? 'cancel'
                            : null,
                        )}
                      >
                        <span className="withdraw-section">
                          {' '}
                          {getCreated(item?.created) || (
                            <GoDash
                              style={{ background: 'white', color: 'black' }}
                            />
                          )}{' '}
                        </span>
                      </EllipsisTableData>
                      <EllipsisTableData
                        item={getStatus(
                          getStatus(item?.status) === 'PENDING' &&
                            item?.cancelledAt?.length
                            ? 'cancel'
                            : null,
                        )}
                      >
                        {' '}
                        <span className="withdraw-section">
                          {getStatus(item?.status) === 'PENDING' &&
                          item?.cancelledAt?.length
                            ? `Cancelled At: ${getCreated(item?.cancelledAt)}`
                            : item?.reason || (
                                <GoDash
                                  style={{
                                    background: 'white',
                                    color: 'black',
                                  }}
                                />
                              )}
                          {/* {}{' '} */}
                        </span>
                      </EllipsisTableData>
                    </tr>
                  ))
              ) : (
                <></>
              )}
            </tbody>
          </Table>
          {noRecords == 0 ? <NoRecords>No Records</NoRecords> : <></>}
        </RightSection>
      </ContentWrapper>
    </>
  )
}

export default React.memo(WithdrawPage)
