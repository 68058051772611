const ACTIONTYPES = {
  DEPOSIT_SUCCESS: 'DEPOSIT_SUCCESS',
  DEPOSIT_FAIL: 'DEPOSIT_FAIL',
  GET_DEPOSIT_SUCCESS: 'GET_DEPOSIT_SUCCESS',
  GET_DEPOSIT_FAIL: 'GET_DEPOSIT_FAIL',
  GET_DEPOSIT_BANK_SUCCESS: 'GET_DEPOSIT_BANK_SUCCESS',
  GET_DEPOSIT_BANK_FAIL: 'GET_DEPOSIT_BANK_FAIL',
}

export default ACTIONTYPES
