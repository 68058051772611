import React from 'react'
import FootballDetails from './FootballDetails'
import CricketDetails from './CricketDetails'
import TennisDetails from './TennisDetails'

const SportsDetails = () => {
  const getSportComponent = () => {
    let getSports = localStorage.getItem('sportName')
    let sportsEventId = localStorage.getItem('sportEventId')
    if (getSports === 'FOOTBALL')
      return <FootballDetails sportsEventId={sportsEventId} />
    else if (getSports === 'CRICKET')
      return <CricketDetails sportsEventId={sportsEventId} />
    else if (getSports === 'TENNIS')
      return <TennisDetails sportsEventId={sportsEventId} />
  }

  return getSportComponent()
}

export default SportsDetails
