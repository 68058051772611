const ACTIONTYPES = {
  WITHDRAW_SUCCESS: 'WITHDRAW_SUCCESS',
  WITHDRAW_FAIL: 'WITHDRAW_FAIL',
  GET_WITHDRAW_SUCCESS: 'GET_WITHDRAW_SUCCESS',
  GET_WITHDRAW_FAIL: 'GET_WITHDRAW_FAIL',
  OTP_VERIFY_SUCCESS: 'OTP_VERIFY_SUCCESS',
  OTP_SENT_FAIL: 'OTP_SENT_FAIL',
  ADD_ACCOUNT_SUCCESS: 'ADD_ACCOUNT_SUCCESS',
  ADD_ACCOUNT_FAIL: 'ADD_ACCOUNT_FAIL',
  GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAIL: 'GET_ACCOUNT_FAIL',
  DELETE_ACCOUNT_FAIL: 'DELETE_ACCOUNT_FAIL',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  WITHDRAW_MODAL_SHOW_FAIL: 'WITHDRAW_MODAL_SHOW_FAIL',
  WITHDRAW_MODAL_SHOW_SUCCESS: 'WITHDRAW_MODAL_SHOW_SUCCESS',
  DELETE_ACCOUNT_CANCEL_SUCCESS: 'DELETE_ACCOUNT_CANCEL_SUCCESS',
  DELETE_ACCOUNT_CANCEL_FAIL: 'DELETE_ACCOUNT_CANCEL_FAIL',
}

export default ACTIONTYPES
