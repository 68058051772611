import React from 'react'
import * as Styled from './style'
import Options from '../../../../assets/svg/sports/Options.svg'
import Stats from '../../../../assets/svg/sports/Stats.svg'

const MDOptions = ({ selectedOption, setSelectedOption }) => {
  return (
    <Styled.MDOptions className="display-flex">
      <Styled.MDOption
        onClick={() => setSelectedOption('Scorecard')}
        active={selectedOption === 'Scorecard'}
      >
        <Styled.Option active={selectedOption === 'Scorecard'}>
          <Styled.OptionIcon>
            <Styled.IconImage
              className="filter-image"
              src={Options}
              alt="options"
              width="20px"
              height="20px"
              active={selectedOption === 'Scorecard'}
            />
          </Styled.OptionIcon>
          <Styled.OptionTitle>Scorecard</Styled.OptionTitle>
        </Styled.Option>
      </Styled.MDOption>
      <Styled.MDOption
        onClick={() => setSelectedOption('Statistics')}
        active={selectedOption === 'Statistics'}
      >
        <Styled.Option active={selectedOption === 'Statistics'}>
          <Styled.OptionIcon>
            <Styled.IconImage
              src={Stats}
              alt="stats"
              width="20px"
              height="20px"
              active={selectedOption === 'Statistics'}
            />
          </Styled.OptionIcon>
          <Styled.OptionTitle>Statistics</Styled.OptionTitle>
        </Styled.Option>
      </Styled.MDOption>
    </Styled.MDOptions>
  )
}

export default MDOptions
