import Layout from '../../Layout'
import Sports from '../../pages/Sports/Sports'
import useDynamoDb from '../../hooks/dynamoDbHook'
import { getAllMatches } from '../../utils/utils'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMatchData } from '../../redux/match/action'
import useFirebase from '../../hooks/firebaseHook'
import { fetchUserDetails } from '../../redux/auth/action'

export default function HomePageContainer(props) {
  const { data, loading } = useDynamoDb({ tableName: 'rdy_matches' })
  // const { data, loading } = useFirebase({ tableName: 'rdy_matches' })
  const dispatch = useDispatch()
  const avoidRerender = useRef(false)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  useEffect(() => {
    dispatch(getMatchData(data))
  }, [data])

  useEffect(() => {
    if (!avoidRerender.current) {
      isAuthenticated && dispatch(fetchUserDetails())
    }
    return () => (avoidRerender.current = true)
  }, [])

  const matchData = getAllMatches(data)

  return (
    <>
      <Layout>
        <Sports matchData={matchData} loading={loading} />
      </Layout>
    </>
  )
}
