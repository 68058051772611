import React from 'react'
import { ErrorMessage } from '../styles'

const PasswordField = ({ label, errors, value, name, handleChange }) => {
  return (
    <div className="form-group row" style={{ marginBottom: '15px' }}>
      <label htmlFor="inputPassword" className="col-sm-3 col-form-label">
        {label} :
      </label>
      <div className="col-sm-9">
        <input
          type="password"
          className="form-control"
          id="inputPassword"
          placeholder="Password"
          maxLength={'10'}
          required
          onChange={handleChange}
          name={name}
          value={value}
        />
        <ErrorMessage>{errors[name]}</ErrorMessage>
      </div>
    </div>
  )
}

export default PasswordField
