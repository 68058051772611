import React from 'react'
import * as Styled from '../style'
import { FaStar } from 'react-icons/fa'
import { constants } from '../../../utils/constant'
const GridOption = ({
  svg,
  title,
  handleGridOption,
  activeOption,
  openDrawer,
}) => {
  return (
    <Styled.GridOption
      onClick={() => handleGridOption(title)}
      activeOption={activeOption === title}
      openDrawer={openDrawer}
      title={title}
    >
      {title === constants.Favourite ? (
        <FaStar
          className="Star"
          activeOption={activeOption === title}
          title={title}
        />
      ) : (
        <Styled.Svg title={title}>
          <Styled.IMG src={svg} alt={title} className="color-change" />
        </Styled.Svg>
      )}
      {openDrawer && (
        <Styled.SVGTitle activeOption={activeOption === title}>
          {title}
        </Styled.SVGTitle>
      )}
    </Styled.GridOption>
  )
}

export default GridOption
