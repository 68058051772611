import React, { useEffect, useState } from 'react'
import * as Styled from '../style'
import { IoIosSearch } from 'react-icons/io'
import { RxCross2 } from 'react-icons/rx'
import { constants } from '../../../utils/constant'
import { color } from '../../../color/color'
const Search = () => {
  const [viewCross, setViewCross] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const handleSearch = (e) => {
    console.log(e.target.value, 'e')
    setInputValue(e.target.value)
    setViewCross(true)
  }

  useEffect(() => {
    if (!inputValue.length) setViewCross(false)
  }, [inputValue])

  return (
    <Styled.QuickSearch>
      <Styled.MyBetsTitle>{constants.QUICK_SEARCH}</Styled.MyBetsTitle>
      <Styled.SearchDiv className="display-flex">
        <Styled.SearchLeft className="display-flex">
          <Styled.SearchIcon>
            <IoIosSearch color={color._FFFFFF} />
          </Styled.SearchIcon>
          <Styled.SearchInput
            type="text"
            placeholder={constants.Search}
            value={inputValue}
            onChange={handleSearch}
          />
        </Styled.SearchLeft>
        {viewCross && (
          <Styled.SearchRight onClick={() => setInputValue('')}>
            <RxCross2 color={color._FFFFFF} />
          </Styled.SearchRight>
        )}
      </Styled.SearchDiv>
      <Styled.Results>
        <Styled.ResultHeading>
          {inputValue.length > 0 && (
            <Styled.ResultSpan>0 Results Found</Styled.ResultSpan>
          )}
        </Styled.ResultHeading>
      </Styled.Results>
      <Styled.SearchContent className="display-flex">
        <Styled.QuicklySearchDiv>
          <Styled.QuicklySearch>
            {constants.Quickly_Search}
          </Styled.QuicklySearch>
          <Styled.QuickSearchTagLine>
            {constants.QuickSearchTagLine}
          </Styled.QuickSearchTagLine>
        </Styled.QuicklySearchDiv>
      </Styled.SearchContent>
    </Styled.QuickSearch>
  )
}

export default Search
