import React from 'react'
import { ToastContainer } from 'react-toastify'

const Toast = ({
  position = 'top-right',
  autoClose = 3000,
  hideProgressBar = false,
  newestOnTop = false,
  rtl = false,
  theme = 'light',
}) => {
  return (
    <ToastContainer
      position={position}
      autoClose={autoClose}
      hideProgressBar={hideProgressBar}
      newestOnTop={newestOnTop}
      closeOnClick
      rtl={rtl}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={theme}
    />
  )
}

export default Toast
