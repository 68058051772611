import React, { useState } from 'react'
import * as Styled from './style'
import BatterTable from './components/BatterTable'

const NormalScoreCard = () => {
  const [selectedTeam, setSelectedTeam] = useState('Team 1')
  return (
    <Styled.NormalScoreCard className="hide-scrollbar">
      <Styled.ScoreCardHeading>
        <Styled.Span>SCORECARD</Styled.Span>
      </Styled.ScoreCardHeading>
      <Styled.TeamButtons>
        <Styled.TeamButton>
          <Styled.TeamTitle
            onClick={() => setSelectedTeam('Team 1')}
            active={selectedTeam === 'Team 1'}
          >
            VS 1ST
          </Styled.TeamTitle>
          <Styled.TeamTitle
            onClick={() => setSelectedTeam('Team 2')}
            active={selectedTeam === 'Team 2'}
          >
            TRS 1ST
          </Styled.TeamTitle>
        </Styled.TeamButton>
      </Styled.TeamButtons>
      <Styled.TeamScorecard>
        <BatterTable
          heading={true}
          batter="BATTER"
          runs="R"
          balls="B"
          four="4S"
          six="6S"
          viewStrikeRate={true}
          strikeRate="S/R"
        />
        <BatterTable
          heading={true}
          batter="OJ Hairs"
          runs="9"
          balls="8"
          four="0"
          six="1"
          viewStrikeRate={true}
          strikeRate="112.50"
          batterStatus="c HT Tector b MR Adair"
          viewBatterStatus={true}
        />
        <BatterTable
          heading={true}
          batter="OJ Hairs"
          runs="9"
          balls="8"
          four="0"
          six="1"
          viewStrikeRate={true}
          strikeRate="112.50"
          batterStatus="c HT Tector b MR Adair"
          viewBatterStatus={true}
        />
        <BatterTable
          heading={true}
          batter="Hrithik Varshney"
          runs="100"
          balls="20"
          four="10"
          six="10"
          viewStrikeRate={true}
          strikeRate="400"
          batterStatus="Not Out"
          viewBatterStatus={true}
          notOut={true}
        />
        <BatterTable
          heading={true}
          batter="Ayush Tripathi"
          runs="0"
          balls="50"
          four="0"
          six="0"
          viewStrikeRate={true}
          strikeRate="0"
          batterStatus="Not Out"
          viewBatterStatus={true}
          notOut={true}
        />
        <BatterTable
          heading={true}
          batter="Ayush Tripathi"
          runs="0"
          balls="50"
          four="0"
          six="0"
          viewStrikeRate={true}
          strikeRate="0"
          batterStatus="Not Out"
          viewBatterStatus={true}
          notOut={true}
        />
        <BatterTable
          heading={true}
          batter="Ayush Tripathi"
          runs="0"
          balls="50"
          four="0"
          six="0"
          viewStrikeRate={true}
          strikeRate="0"
          batterStatus="Not Out"
          viewBatterStatus={true}
          notOut={true}
        />
        <BatterTable
          heading={true}
          batter="Extras"
          runs="12"
          viewStrikeRate={true}
          batterStatus="b: 0, lb: 4, nb: 1, w: 5"
          viewBatterStatus={true}
        />
        <BatterTable
          heading={true}
          batter="Total"
          runs="157"
          viewStrikeRate={true}
          batterStatus="9 Out,  20/20  Over,  RR 7.85"
          viewBatterStatus={true}
          batterColor={'#FD2954'}
        />
        <BatterTable
          heading={true}
          comments={
            'Fall of wickets | HG Munsey 16/1 (2.4), OJ Hairs 22/2 (3.4), RD Berrington 59/3 (6.3), MA Jones 65/4 (7.2), MA Leask 120/5 (14.6), MH Cross 139/6 (17.3), MRJ Watt 143/7 (18.1), CB Sole 154/8 (19.4),'
          }
        />
           <BatterTable
          heading={true}
          batter="BOWLING"
          runs="O"
          balls="M"
          four="R"
          six="W"
          viewStrikeRate={true}
          strikeRate="ECON"
          fontSize="13px"
        />
        <BatterTable
         fontSize="13px"
         heading={true}
         batter="MR Adair"
         runs="4"
         balls="0"
         four="36"
         six="3"
         viewStrikeRate={true}
         strikeRate="9.00"
        />
      </Styled.TeamScorecard>
    </Styled.NormalScoreCard>
  )
}

export default NormalScoreCard
