import React, { useState } from 'react'
import * as Styled from './style'
import { ABRArr, NumberArr, UpperNavigationArr } from './constant'
import SingleComp from './WorliUtils/SingleComp'
import NumberComp from './WorliUtils/NumberComp'
import NumberSep from './WorliUtils/NumberSep'
import SelectVal from './WorliUtils/SelectVal'
const WorliMatka = () => {
  const [selectNav, setSelectNav] = useState('Single')
  const [midValues, setMidValues] = useState({
    firstVal: 9.8,
    thirdVal: 9.8,
  })
  const [seperateDiv, setSeperateDiv] = useState()
  const [columnCount, setColumnCount] = useState(5)

  const selectedNavFunc = (nav) => {
    setSelectNav(nav.nav)
    setMidValues(nav)
    setColumnCount(nav.column)
    nav.seperateDiv && setSeperateDiv(nav.seperateDiv)
  }
  return (
    <Styled.WorliMatka>
      <Styled.UpperNavigation>
        {UpperNavigationArr.map((nav, index) => (
          <Styled.Nav
            onClick={() => selectedNavFunc(nav)}
            selected={nav.nav === selectNav ? true : false}
            key={nav.nav + index}
          >
            {nav.nav}
          </Styled.Nav>
        ))}
      </Styled.UpperNavigation>
      <Styled.MidValues>
        <Styled.Val hideVal={midValues.firstVal}>
          {midValues.firstVal}
        </Styled.Val>
        <Styled.Val hideVal={midValues.secondVal}>
          {midValues.secondVal}
        </Styled.Val>
        <Styled.Val hideVal={midValues.thirdVal}>
          {midValues.thirdVal}
        </Styled.Val>
      </Styled.MidValues>
      {selectNav === 'Single' ? (
        <SingleComp />
      ) : selectNav === 'DP' ||
        selectNav === 'Color DP' ||
        selectNav === '64 Charts' ||
        selectNav === '56 Charts' ||
        selectNav === 'DP' ||
        selectNav === 'SP' ? (
        <NumberSep seperateDiv={seperateDiv} />
      ) : selectNav === 'Trio' ? (
        <SelectVal val="ALL TRIO" height="100px" />
      ) : (
        <NumberComp
          mapArr={selectNav === 'ABR' ? ABRArr : NumberArr}
          columnCount={columnCount}
        />
      )}
    </Styled.WorliMatka>
  )
}

export default WorliMatka
