import 'react-international-phone/style.css'
import styled, { createGlobalStyle } from 'styled-components'
import { color } from '../../color/color'

export const LoginSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const GlobalStyle = createGlobalStyle`
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input::placeholder {
    color: white !important;
  }

  input{
    color:${color._FFFFFF} !important;
  }

  input:focus {
    background: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    outline:none !important;
    color:${color._FFFFFF} !important;
  }
`

export const Container = styled.main`
  background: ${color._6D081D};
  position: absolute;
  max-width: 430px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2% 0%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid ${color._FFFFFF};
  @media (max-width: 991px) {
    width: 90%;
  }
`

export const WrapperContainer = styled.section`
  background: ${color._6D081D};
  width: 100vw;
  height: 100vh;
`

export const Image = styled.img`
  width: 150px;
`

export const Header = styled.h4`
  color: ${color._E5CB63};
  text-align: center;
`

export const RegistrationForm = styled.form`
  border-radius: 5px;
  padding: 25px;
  @media (max-width: 540px) {
    padding: 15px;
  }
`

export const PasswordInput = styled.input`
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  position: relative;
`

export const OtpInput = styled.input`
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
`

export const TermsAndCondition = styled.div`
  color: ${color._FFFFFF};
`

export const SignUpInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  color: ${color._FFFFFF};
  margin-bottom: 15px;
  @media (max-width: 540px) {
    flex-direction: column;
    align-items: center;
  }
`

export const SignUpInfoSpan = styled.span`
  border-bottom: 1px solid ${color._E5CB63};
`

export const FormGroup = styled.div`
  margin-bottom: 20px;
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  align-items: center;

  > .react-international-phone-input-container {
    width: 100%;
    > .react-international-phone-input {
      width: 100%;
      background: transparent;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      color: white !important;
    }
    > .react-international-phone-country-selector {
      > .react-international-phone-country-selector-button {
        background: transparent;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
    }
  }
`

export const ResendButton = styled.button`
  background: #eee;
  padding: 3px 5px;
  border: 1px solid #c10930;
  color: #c10930;
  float: right;
  margin-top: 10px;
`

export const ErrorMessage = styled.small`
  color: ${color._FF0000};
  font-size: 13px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;
`

export const Btn = styled.button`
  line-height: 2;
  text-transform: uppercase;
  font-size: 15px;
  border-color: ${color._C10930};
  background-color: ${color._C10930};

  &:hover {
    border-color: ${color._C10930};
    background-color: ${color._C10930};
  }
`
export const Icon = styled.div`
  cursor: pointer;
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: -25px;
  }
`
