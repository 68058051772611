import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { color } from '../../color/color'
const MuiTable = ({ header, rows }) => {
  return (
    <TableContainer component={Paper} sx={{ margin: '0px 8px 0px 8px' }}>
      <Table aria-label="simple table">
        <TableHead
          sx={{
            backgroundColor: `${color._CCCCCC}`,
            border: `2px solid ${color._FFFFFF}`,
          }}
        >
          <TableRow>
            {header &&
              header.length > 0 &&
              header.map((elem, indx) => {
                return (
                  elem.view && (
                    <TableCell key={elem.name + indx}>{elem.name}</TableCell>
                  )
                )
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.calories}</TableCell>
              <TableCell>{row.fat}</TableCell>
              <TableCell>{row.carbs}</TableCell>
              <TableCell>{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MuiTable
