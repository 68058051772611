import styled from 'styled-components'
import { color } from '../../../color/color'

export const Menu = styled.div`
  display: flex;
  background-color: ${color._fff};
  overflow-x: scroll;
  width: calc(100vw - 275px);
  @media (max-width: 776px) {
    width: 100%;
  }
`

export const Option = styled.div`
  text-transform: capitalize;
  font-size: 12px;
  padding: 0.5rem 1rem;
  text-wrap: nowrap;
  border: 1px solid ${color._000};
  background-color: ${({ activeMenu }) => (activeMenu ? color._343435 : null)};
  color: ${({ activeMenu }) => (activeMenu ? color._fff : null)};
  cursor: pointer;
`

export const Icon = styled.img`
  display: block;
  width: 20px;
  margin: 0 auto;
  filter: ${({ activeMenu }) =>
    activeMenu
      ? 'invert(100%) sepia(100%) saturate(2%) hue-rotate(12deg) brightness(106%) contrast(101%)'
      : 'sepia(1)'};
`

export const Title = styled.div``
