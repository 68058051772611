import styled, { createGlobalStyle } from 'styled-components'
import { color } from '../../color/color'

export const ModalInnerDiv = styled.div`
  background-color: ${color._FFFFFF};
  position: relative;
  width: 100%;
  height: auto;

  & .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    color: ${color._000000};
    z-index: 9999;
  }

  @media (max-width: 993px) {
    & .close-icon {
      right: 5px;
    }
  }
`

export const Modal = styled.div``

export const Container = styled.main`
  background: ${color._F3F2F6};
  position: absolute;
  max-width: 800px;
  width: 800px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  & .modal-footer-section {
    justify-content: center !important;
    padding: 15px;
    background-color: ${color._C10930};
  }
  @media (max-width: 991px) {
    width: 90%;
  }
`

export const WrapperContainer = styled.section`
  background: ${color._6D081D};
  width: 100vw;
  height: 100vh;
`

export const Image = styled.img`
  width: 150px;
`

export const Header = styled.h4`
  color: ${color._E5CB63};
  text-align: center;
`

export const ResetForm = styled.form`
  border-radius: 5px;
  padding: 1rem;
  background-color: ${color._F3F2F6};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${color._212529};
  text-align: left;
  @media (max-width: 540px) {
    padding: 15px;
  }
`

export const PasswordInput = styled.input`
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  position: relative;
  font-family: 'Titillium Web', sans-serif;
  text-transform: capitalize;
`

export const OtpInput = styled.input`
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  font-family: 'Titillium Web', sans-serif;
  text-transform: capitalize;
`

export const TermsAndCondition = styled.div`
  color: ${color._FFFFFF};
`

export const SignUpInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  color: ${color._FFFFFF};
  margin-bottom: 15px;
  @media (max-width: 540px) {
    flex-direction: column;
    align-items: center;
  }
`

export const SignUpInfoSpan = styled.span`
  border-bottom: 1px solid ${color._E5CB63};
`

export const FormGroup = styled.div`
  margin-bottom: 20px;
  input {
    color: ${color._6F7174} !important;
    font-size: 12px !important;
  }
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  input {
    color: ${color._FFFFFF} !important;
  }
  > .react-international-phone-input-container {
    width: 100%;
    > .react-international-phone-input {
      width: 100%;
      background: transparent;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      color: black !important;
    }
    > .react-international-phone-country-selector {
      > .react-international-phone-country-selector-button {
        background: transparent;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
    }
  }
`

export const ResendButton = styled.button`
  background: ${color._EEEEEE};
  padding: 3px 5px;
  border: 1px solid ${color._C10930};
  color: ${color._C10930};
  float: right;
  margin-top: 10px;
`

export const ErrorMessage = styled.small`
  color: ${color._FF0000};
  font-size: 13px;
  margin-top: 0px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Btn = styled.button`
  color: ${color._FFFFFF};
  background-color: ${color._6C757D};
  border-color: ${color._6C757D};
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color: ${color._FFFFFF};
    background-color: ${color._5A6268};
    border-color: ${color._545B62};
  }
`

export const SaveBtn = styled.button`
  background: ${color._FFFFFF};
  border-color: ${color._FFFFFF};
  color: ${color._C10930};
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background: ${color._C10930};
    color: ${color._FFFFFF};
    border-color: ${color._FFFFFF};
  }
`
export const Icon = styled.div`
  cursor: pointer;
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: -25px;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid ${color._DEE2E6};
  border-top-left-radius: calc(0.5rem - (1px));
  border-top-right-radius: calc(0.5rem - (1px));
  justify-content: space-between;
  background-color: ${color._343435};
  border-color: ${color._343435};
  color: ${color._FFFFFF};
  height: 27px;
  margin-bottom: 5px;
`

export const GlobalStyle = createGlobalStyle`
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input::placeholder {
    color: black !important;
  }

  input{
    color:${color._000000} !important;
  }

  input:focus {
    background: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    outline:none !important;
    color:${color._000000} !important;
    border-color: 0 !important;
    box-shadow: none !important;
  }
`

export const ModalHeaderSpan = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
`
export const HeaderBox = styled.span`
  width: 100%;
  height: auto;
  padding-bottom: 15px;
  background: ${color._C10930};
  color: ${color._FFFFFF};
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
`

export const Label = styled.label`
  color: ${color._000000};
  font-size: 12px;
  width: 25%;
`
export const ConfirmLabel = styled.label`
  color: ${color._000000};
  font-size: 12px;
  width: 100%;
`
