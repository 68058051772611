import React from 'react'
import * as Styled from './style'
const PagesHeader = ({ src, heading }) => {
  return (
    <Styled.PagesHeader>
      <Styled.SportsTitle>
        <Styled.Img src={src} alt="header" /> {heading}
      </Styled.SportsTitle>
    </Styled.PagesHeader>
  )
}

export default PagesHeader
