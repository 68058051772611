import React from 'react'
import * as Styled from './style'
const MDTeamScore = () => {
  let CRR = true
  return (
    <Styled.MDTeamScore className="display-flex">
      <Styled.MDTeam left={true}>
        <Styled.TeamName CRR={CRR} >
          Trentino Aquila
        </Styled.TeamName>
        {CRR && <Styled.CRR>14.88 CRR</Styled.CRR>}
      </Styled.MDTeam>
      <Styled.MDContent>
        <Styled.InnOvers className="display-flex">
          <Styled.InnValues>INN 1</Styled.InnValues>
          <Styled.InnValues>8.0/10 OV</Styled.InnValues>
        </Styled.InnOvers>
        <Styled.Score>Yet to bat : 119/3</Styled.Score>
        <Styled.Comparison>
          Firends XI are 119 for 3 after 8.0 overs
        </Styled.Comparison>
      </Styled.MDContent>
      <Styled.MDTeam right={true}>
        <Styled.TeamName>Friends XI</Styled.TeamName>
        <Styled.CRR></Styled.CRR>
      </Styled.MDTeam>
    </Styled.MDTeamScore>
  )
}

export default MDTeamScore
