import React, { useEffect, useState } from 'react'
import { CiBookmark } from 'react-icons/ci'
import { IoIosBookmark } from 'react-icons/io'
import * as Styled from './style'
import Announcement from '../Announcement/Announcement'
import {
  FancyButtons,
  _constants,
  fancyData,
  getToastPosition,
} from './constants'
import { color } from '../../color/color'
import PlaceBetBox from '../PlaceBetBox/PlaceBetBox'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import ModalCont from '../../container/Modal/Modal'
const Fancy = ({
  restructuredFancyData,
  showHeading = true,
  handleBetSection,
  handleCloseBetSection,
  currentSportsId,
  showBetSection,
  backlayAmount,
  bgColor,
  placebetStatus = true,
}) => {
  const [fancyFilter, setFancyFilter] = useState('1')

  const [bookmarks, setBookmarks] = useState(
    restructuredFancyData.map(() => false),
  )
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const isDemoLogin = localStorage.getItem('isDemoLogin')
  const [showLogin, setShowLogin] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const getLoginState = () => {
    if (isAuthenticated) {
      return false
    } else if (isDemoLogin) {
      return false
    }
    return true
  }

  const handleCloseModal = () => {
    setShowLogin(false)
  }
  const handleWindowSizeChange = () => {
    window.innerWidth < 1200 ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const handleLoginModal = () => {
    if (getLoginState()) {
      setShowLogin(true)
    } else {
      return () => {}
    }
  }

  const handleBookMark = (index) => {
    const updatedBookmarks = [...bookmarks]
    updatedBookmarks[index] = !updatedBookmarks[index]
    setBookmarks(updatedBookmarks)
    toast.success(_constants.Bookmarked, getToastPosition())
  }

  return (
    <Styled.Section>
      <ModalCont show={showLogin} handleClose={handleCloseModal} />

      <div className="row mt-3">
        <Styled.Col1 className="col-md-12 col-lg-8">
          {showHeading && (
            <Styled.GameHeading>
              <Styled.Title>{_constants.name}</Styled.Title>
              <span>{_constants.date}</span>
            </Styled.GameHeading>
          )}

          {restructuredFancyData && restructuredFancyData.length > 0 && (
            <>
              <Styled.fancyContainer>
                <Styled.FancyButton>{_constants.title}</Styled.FancyButton>
              </Styled.fancyContainer>
              <Styled.ButtonBox>
                {FancyButtons.map((item, indx) => {
                  return (
                    <Styled.FancyBlockButton
                      onClick={() => setFancyFilter(item.filter)}
                      key={item.name + indx}
                    >
                      {item.name}
                    </Styled.FancyBlockButton>
                  )
                })}
              </Styled.ButtonBox>
              <Styled.BackLayContainer>
                <Styled.LeftContainer></Styled.LeftContainer>
                <Styled.RightContainer>
                  <Styled.Heading>NO</Styled.Heading>
                  <Styled.Heading>YES</Styled.Heading>
                </Styled.RightContainer>
              </Styled.BackLayContainer>

              {restructuredFancyData &&
                restructuredFancyData?.length > 0 &&
                restructuredFancyData
                  .filter((item) => item.grp === fancyFilter)
                  .map((i, index) => {
                    return (
                      <>
                        <Styled.ContentBox key={i.name + index}>
                          <Styled.MatchBox>
                            <Styled.MatchTitle>
                              {bookmarks[index] ? (
                                <IoIosBookmark
                                  color={color._C10930}
                                  onClick={() => handleBookMark(index)}
                                />
                              ) : (
                                <CiBookmark
                                  color={color._C10930}
                                  onClick={() => handleBookMark(index)}
                                />
                              )}
                              {i?.name}
                            </Styled.MatchTitle>
                          </Styled.MatchBox>
                          <Styled.BetsBox placebetStatus={placebetStatus}>
                            <Styled.BackLay placebetStatus={placebetStatus}>
                              <Styled.BlBox
                                placebetStatus={placebetStatus}
                                onClick={
                                  // !placebetStatus
                                  isAuthenticated
                                    ? () =>
                                        handleBetSection(
                                          i?.b1,
                                          Number(i.id),
                                          Number(i?.min_bet),
                                          Number(i?.max_bet),
                                          true,
                                          true,
                                          i?.bet_delay,
                                          i?.odd_type,
                                          i?.name,
                                          i?.str,
                                          i?.bs1,
                                        )
                                    : () => handleLoginModal()
                                }
                              >
                                <Styled.Span placebetStatus={placebetStatus}>
                                  {i?.b1 || '-'}
                                  <Styled.Em placebetStatus={placebetStatus}>
                                    {' '}
                                    {i?.bs1 || '-'}
                                  </Styled.Em>
                                </Styled.Span>
                              </Styled.BlBox>
                              <Styled.BlBoxLay
                                placebetStatus={placebetStatus}
                                onClick={
                                  // !placebetStatus
                                  isAuthenticated
                                    ? () =>
                                        handleBetSection(
                                          i?.b2,
                                          Number(i?.id),
                                          Number(i?.min_bet),
                                          Number(i?.max_bet),
                                          true,
                                          false,
                                          i?.bet_delay,
                                          i?.odd_type,
                                          i?.name,
                                          i?.str,
                                          i?.bs2,
                                        )
                                    : () => handleLoginModal()
                                }
                              >
                                <Styled.Span placebetStatus={placebetStatus}>
                                  {i?.b2 || '-'}
                                  <Styled.Em placebetStatus={placebetStatus}>
                                    {' '}
                                    {i?.bs2 || '-'}
                                  </Styled.Em>
                                </Styled.Span>
                              </Styled.BlBoxLay>
                            </Styled.BackLay>
                            <Styled.BetDetails>
                              <Styled.BetsTitle>
                                Max Bet: {i?.min_bet}
                              </Styled.BetsTitle>
                              <Styled.BetsTitle>
                                Max Market: {i?.max_bet}
                              </Styled.BetsTitle>
                            </Styled.BetDetails>
                          </Styled.BetsBox>
                        </Styled.ContentBox>
                        {showBetSection ? (
                          <Styled.IsMobile>
                            <PlaceBetBox
                              backlayAmount={backlayAmount}
                              handleCloseBetSection={handleCloseBetSection}
                              bgColor={bgColor}
                              min_bet={i?.min_bet}
                              max_bet={i?.max_bet}
                            />
                          </Styled.IsMobile>
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  })}

              <Styled.AnnouncementBox>
                <Announcement />
              </Styled.AnnouncementBox>
            </>
          )}
        </Styled.Col1>
      </div>
    </Styled.Section>
  )
}

export default Fancy
