import Modal from 'react-bootstrap/Modal'
import { IoMdClose } from 'react-icons/io'
import * as Styled from './style'
import { _constants } from './constants'

const EditStackModal = ({
  stackAmount,
  show,
  handleEditStack,
  handleClose,
  handleStackSave,
  inputDisabled,
}) => {
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Styled.ModalHeader closeButton>
          <Modal.Title>{_constants.Stake}</Modal.Title>
          <IoMdClose onClick={handleClose} />
        </Styled.ModalHeader>
        <Styled.ModalBody>
          <div className="row">
            {stackAmount.map((item, index) => {
              return (
                <div class="col-6">
                  <Styled.ModalInputGroup class="input-group">
                    <Styled.ModalBodySpan
                      id="basic-addon1"
                      class="input-group-text"
                    >
                      <b>
                        <small>{item.amount || 0}</small>
                      </b>
                    </Styled.ModalBodySpan>
                    <Styled.ModalInput
                      type="number"
                      class="form-control"
                      id="stackVal0"
                      value={item.amount}
                      onChange={(e) => handleEditStack(e, item, index)}
                    />
                  </Styled.ModalInputGroup>
                </div>
              )
            })}
          </div>
        </Styled.ModalBody>
        <Styled.ModalFooter>
          <Styled.ModalButton
            inputDisabled={inputDisabled}
            variant="primary"
            onClick={inputDisabled ? () => {} : () => handleStackSave()}
          >
            {_constants.Save}
          </Styled.ModalButton>
        </Styled.ModalFooter>
      </Modal>
    </div>
  )
}

export default EditStackModal
