import React from 'react'
import {
  CasinoTableBox,
  CasinoTableBoxContainer,
  CasinoNationName,
  CasinoOddsBoxBack,
  CasinoOddsBoxLay,
  CasinoOdds,
  Suspended,
} from './styles'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { data } from './constants'

const RaceTo17 = () => {
  return (
    <div>
      <CasinoTableBox>
        {data?.length &&
          data?.map((item, index) => (
            <>
              <CasinoTableBoxContainer
                key={item.title + index}
                class="casino-odd-box-container"
              >
                <CasinoNationName class="casino-nation-name">
                  {item.title}
                </CasinoNationName>
                <CasinoOddsBoxBack class="casino-odds-box back ">
                  {item.isLock ? (
                    <Suspended>
                      <Lock />
                    </Suspended>
                  ) : (
                    <CasinoOdds class="casino-odds">{item.value1}</CasinoOdds>
                  )}
                </CasinoOddsBoxBack>
                <CasinoOddsBoxLay class="casino-odds-box lay ">
                  {item.isLock ? (
                    <Suspended>
                      <Lock />
                    </Suspended>
                  ) : (
                    <CasinoOdds class="casino-odds">{item.value2}</CasinoOdds>
                  )}
                </CasinoOddsBoxLay>
              </CasinoTableBoxContainer>
            </>
          ))}
      </CasinoTableBox>
    </div>
  )
}

export default RaceTo17
