import styled from 'styled-components'
import { color } from '../../../color/color'

export const Container = styled.div`
  background-color: ${color._F2F2F2} !important;
`
export const Table = styled.table`
  width: 100% !important;
  border: 1px solid ${color._C7C8CA} !important;
`

export const RowHeadingContainer = styled.td`
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 10px !important;
`

export const BoxDataBlue = styled.td`
  font-size: 18px;
  font-weight: 700;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._72BBEF};
  width: 70px;
  text-align: center;
  border-right: 1px solid ${color._fff};
`
export const BoxDataPink = styled.td`
  font-size: 18px;
  font-weight: 700;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._FAA9BA};
  width: 70px;
  text-align: center;
  border-right: 1px solid ${color._fff};
`

export const BonusContainer = styled.div`
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  text-wrap: nowrap;
  background-color: ${({ isSuspended }) =>
    isSuspended ? color._opacityBg : color._72BBEF};
`

export const SuspendedContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
