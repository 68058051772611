import React from 'react'
import * as Styled from '../style'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { constants } from '../../../utils/constant'
const MatchedBets = () => {
  const rows = []
  return (
    <Styled.MatchedBets>
      <TableContainer component={Paper} className="matchedTable">
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell>{constants.Market}</TableCell>
              <TableCell align="right">{constants.Odds}</TableCell>
              <TableCell align="right">{constants.Stake}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length ? (
              rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.calories}</TableCell>
                  <TableCell align="right">{row.fat}</TableCell>
                </TableRow>
              ))
            ) : (
              <Styled.NoRecord>
                {constants.No_Matched_Bets_Found}
              </Styled.NoRecord>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Styled.MatchedBets>
  )
}

export default MatchedBets
