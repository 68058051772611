export const constant = {
  Rules: 'rules',
  Balance: 'Bal',
  Exposer: 'Exp',
  User: 'demo123',
  Signup: 'Signup',
  Login: 'Login',
  Search: 'Search',
  SearchEvents: 'Search Events',
  ReddyClub: 'reddyClub',
  ReddyClubLogo: 'reddyClubLogo',
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
}
export const types = {
  text: 'text',
  submit: 'submit',
  button: 'button',
}
