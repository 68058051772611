export const topRowData = [
  {
    rowName: 'A',
    rowTitle: 'SA',
    firstBet: 'First Bet',
    secondBet: 'Second Bet',
  },
  {
    rowName: 'B',
    rowTitle: 'SB',
    firstBet: 'First Bet',
    secondBet: 'Second Bet',
  },
]

export const cardTypeImages = [
  {
    src: 'https://g1ver.sprintstaticdata.com/v15/static/front/img/icons/spade.png',
    alt: 'Spade',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v15/static/front/img/icons/club.png',
    alt: 'Club',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v15/static/front/img/icons/diamond.png',
    alt: 'Diamond',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v15/static/front/img/icons/heart.png',
    alt: 'Heart',
  },
]

export const cardImages = [
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/A.png',
    alt: 'A',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/2.png',
    alt: '2',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/3.png',
    alt: '3',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/4.png',
    alt: '4',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/5.png',
    alt: '5',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/6.png',
    alt: '6',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/7.png',
    alt: '7',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/8.png',
    alt: '8',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/9.png',
    alt: '9',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/10.png',
    alt: '10',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/J.png',
    alt: 'J',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/Q.png',
    alt: 'Q',
  },
  {
    src: 'https://g1ver.sprintstaticdata.com/v12/static/front/img/cards/K.png',
    alt: 'K',
  },
]
