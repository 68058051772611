import styled from 'styled-components'
import { color } from '../../color/color'
export const MatchContainer = styled.div`
  background: ${color._E9EFF8};
  color: ${color._333333};
  font-weight: 600;
  font-size: 15px;
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
  }
`
export const Col6 = styled.div`
  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: 50%;
  }
`
export const Col2 = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const Small = styled.small`
  font-size: 0.75em;
`
export const Img = styled.img`
  max-width: 100%;
  height: auto;
  width: 20px;
  height: 20px;
  max-width: 20px;
  margin-right: 3px;
  height: auto;
  vertical-align: middle;
`

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  float: right;
  margin: 0 30% 0 0;
  @media (max-width: 1380px) {
    margin: 0 10% 0 0;
  }
  @media (max-width: 800px) {
    margin: 0;
  }
`
export const Li = styled.li`
  display: inline;
  margin: 1px;
  @media (max-width: 991px) {
    margin: 0;
  }
`

export const Label = styled.label`
  background-color: ${({ active }) => (active ? 'transparent' : color._00AD6F)};
  display: inline-block;
  border: 1px solid ${({ active }) => (active ? color._C10930 : color._00AD6F)};
  color: ${({ active }) => (active ? color._C10930 : color._FFFFFF)};
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 1px;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
  font-weight: 600;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Titillium Web', sans-serif;
  gap: 4px;
  @media (max-width: 991px) {
    font-size: 11px;
    margin: 1px 1px;
  }
`
export const Input = styled.input`
  position: absolute;
  opacity: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`
export const SportsTitle = styled.span`
  &:before {
    content: '';
    background: linear-gradient(180deg, ${color._C10930} 0, ${color._C10930});
    clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
    width: 18px;
    height: calc(100% + 0px);
    position: absolute;
    right: -17px;
    top: 0;
  }
  background: ${color._C10930};
  text-transform: uppercase;
  position: relative;
  padding: 7px 12px;
  color: ${color._FFFFFF};
  display: inline-flex;
  width: 200px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  align-items: center;
  @media (max-width: 991px) {
    padding: 5px;
  }
`
