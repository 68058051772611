import React from 'react'
import * as Styled from '../style'
import { constants } from '../../../utils/constant'
const IconTitle = ({
  title,
  activeMatchOption,
  src,
  openDrawer,
  individualView = false,
  showCounter = false,
  handleMatchOption = () => {},
}) => {
  const handleOptionSelect = (e) => {
    if (individualView) {
      handleMatchOption(e)
    }
  }
  return (
    <Styled.SBContent
      individualview={individualView}
      onClick={() => handleOptionSelect(title)}
      activematchoption={activeMatchOption === title}
    >
      {showCounter && <Styled.CountNumber>4</Styled.CountNumber>}

      <Styled.SBMatchIcon>
        {title === constants.Virtual_Sports ? (
          <Styled.Icon activematchoption={activeMatchOption === title}>
            {constants.SRL}
          </Styled.Icon>
        ) : (
          <Styled.MatchIcon
            src={src}
            alt={title}
            activematchoption={activeMatchOption === title}
            width={'18px'}
            height={'18px'}
          />
        )}
      </Styled.SBMatchIcon>
      {openDrawer && <Styled.SBMatchTitle>{title}</Styled.SBMatchTitle>}
    </Styled.SBContent>
  )
}

export default IconTitle
