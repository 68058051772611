import React, { useEffect, useState } from 'react'
import * as Styled from './style'
import { BetsData, Stacks, _constants } from './data'
import EditStackModal from '../EditStackModal/EditStackModal'
import ModalCont from '../../container/Modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { constants, getToastPosition } from '../../utils/constant'
import { getOpenBetsData, removeEmptyItemsFromArray } from '../../utils/utils'
import { io } from 'socket.io-client'
import { logout } from '../../redux/auth/action'
import { setFancyBetsData, setMatchedBetsData } from '../../redux/match/action'

const PlaceBetBox = ({
  backlayAmount,
  handleCloseBetSection,
  min_bet,
  max_bet,
  bgColor,
  bet_delay,
  odd_type,
  teamName,
  oddStr,
  oddVolume,
}) => {
  const [count, setCount] = useState(backlayAmount || 0)
  const [bet, setBet] = useState(0)
  const [show, setShow] = useState(false)
  const [betsAmount, setBetsAmount] = useState(BetsData)
  const [stackAmount, setStackAmount] = useState(BetsData)
  const [showLogin, setShowLogin] = useState(false)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const username = useSelector((state) => state.auth.username)
  const dispatch = useDispatch()

  const isDemoLogin = localStorage.getItem('isDemoLogin')
  const [values, setValues] = useState([])
  const [inputDisabled, setInputDisabled] = useState(false)
  const [eventFire, setEventFire] = useState(false)

  const getLoginState = () => {
    if (isAuthenticated) {
      return false
    } else if (isDemoLogin) {
      return false
    }
    return true
  }
  const handleCloseModal = () => {
    setShowLogin(false)
  }
  const oddtypes = (type) => {
    switch (type) {
      case 'DIGIT':
        return '1'
      case 'RUNS':
        return '0'
      default:
        return '1'
    }
  }

  const handleShowModal = () => {
    if (isDemoLogin) {
      toast.warn('Placing bet not allowed in demo login', getToastPosition())
    } else if (getLoginState()) {
      setShowLogin(true)
    } else {
      const socket = io('https://bet.100panel.org', {
        transports: ['websocket'],
      })
      const socketRequest = {
        rate: count,
        amount: bet?.toString(),
        action: oddtypes(odd_type),
        delay: bet_delay,
        input: oddVolume?.toString(),
        str: oddStr,
        team: teamName,
        uuid: localStorage.getItem('userId') || '',
        username: username?.toString(),
      }
      socket.emit('place-bet', socketRequest)

      setEventFire(!eventFire)
      socket.once(`client_bet_status`, (data) => {
        if (data == 'rate changed! try next time!') {
          toast.error(data, getToastPosition())
        } else if (data == 'unauthorized!') {
          dispatch(logout())
        } else {
          toast.success(data, getToastPosition())
        }
      })
      socket.once(`client_bet_status_${username?.toString()}`, (data) => {
        const getMatchedBetsData = data && getOpenBetsData(data, 1)
        const getFanceBetsData = data && getOpenBetsData(data, 0)
        dispatch(setMatchedBetsData(getMatchedBetsData))
        dispatch(setFancyBetsData(getFanceBetsData))
      })
    }
  }

  const handleClose = () => {
    const data = JSON.parse(localStorage.getItem('stackValue'))
    const updatedData = data?.map((item, index) => {
      return {
        amount: item,
        id: index + 1,
      }
    })
    setBetsAmount(updatedData?.length > 0 ? updatedData : BetsData)
    setStackAmount(updatedData?.length > 0 ? updatedData : BetsData)
    setShow(false)
  }
  const handleShow = () => {
    // handleShowModal()
    !getLoginState() && setShow(true)
  }

  useEffect(() => {
    setCount(backlayAmount)
  }, [backlayAmount])
  const increment = (event) => {
    event.preventDefault()
    setCount((prevCount) => prevCount + 10)
  }

  useEffect(() => {
    if (bet > max_bet) {
      toast.warn(`value cannot exceed max bet: ${max_bet}`, getToastPosition())
      return
    }
  }, [bet])

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('stackValue'))
    const updatedData = data?.map((item, index) => {
      return {
        amount: item,
        id: index + 1,
      }
    })
    setBetsAmount(updatedData?.length > 0 ? updatedData : BetsData)
    setStackAmount(updatedData?.length > 0 ? updatedData : BetsData)
  }, [])
  const decrement = (event) => {
    event.preventDefault()
    setCount((prevCount) => prevCount - 10)
  }

  const increaseBet = (data) => {
    if (Number(bet + data) <= Number(max_bet)) {
      setBet((prevCount) => prevCount + Number(data))
    } else {
      toast.warn(`max bet is: ${max_bet}`, getToastPosition())
    }
  }
  const changeValue = (e, max_bet) => {
    if (e.target.value > max_bet) {
      toast.warn(`value cannot exceed max bet: ${max_bet}`, getToastPosition())
      return
    } else if (e.target.value < min_bet) {
      toast.warn(`value cannot below min bet: ${min_bet}`, getToastPosition())
    }
    setBet(parseInt(e.target.value))
  }
  const handleEditStack = (e, data, index) => {
    e.preventDefault()
    const newValues = [...values]
    newValues[index] = e.target.value
    // Check if the value exceeds the max value
    if (newValues.filter((v) => v === e.target.value).length > 1) {
      toast.warn('Each stack value must be unique', getToastPosition())
      setInputDisabled(true)
    } else if (e.target.value > 5000000) {
      toast.warn('value cannot exceed 5000000', getToastPosition())
      setInputDisabled(true)
      return
    } else {
      setInputDisabled(false)
    }

    setValues(newValues)
    let updatedValue = e.target.value
    let updatedStack = stackAmount?.map((item) => {
      if (item?.id === data?.id) {
        return {
          amount: parseInt(updatedValue),
          id: data?.id,
        }
      } else {
        return {
          amount: item.amount,
          id: item?.id,
        }
      }
    })
    setStackAmount(updatedStack)
  }
  const handleBets = (data) => {
    switch (data) {
      case 1:
        setBet(Number(min_bet))
        break
      case 2:
        setBet(Number(max_bet))
        break
      case 3:
        handleShow()
        break
      case 4:
        setBet(0)
        break
      default:
        break
    }
  }
  const handleStackSave = () => {
    setBetsAmount(stackAmount)
    const stack = stackAmount?.map((item) => {
      return item.amount
    })
    localStorage.setItem(
      'stackValue',
      JSON.stringify(removeEmptyItemsFromArray(stack)),
    )
    toast.success(constants.Stack_Success_Message, getToastPosition())

    handleClose()
  }

  return (
    <div>
      <ModalCont show={showLogin} handleClose={handleCloseModal} />
      <Styled.Container bgColor={bgColor}>
        <Styled.Content>
          <Styled.InputGroup className="input-group">
            <Styled.InputGroupText onClick={count > 0 ? decrement : () => {}}>
              <i class="bi bi-dash"></i>
            </Styled.InputGroupText>
            <Styled.Input
              placeholder="0"
              value={count}
              type="text"
              required=""
              name=""
              min={0}
              max={1000}
              readonly="readonly"
            />
            <div>
              <Styled.InputGroupText>
                <i
                  class="bi bi-plus"
                  onClick={count < 1000 ? increment : () => {}}
                ></i>
              </Styled.InputGroupText>
            </div>
          </Styled.InputGroup>
          {/* right input */}
          <Styled.BetStacks>
            <Styled.BetStacksInput
              placeholder="0"
              required=""
              type="number"
              min="0"
              max={100}
              value={bet}
              onChange={(e) => changeValue(e, max_bet)}
            />
          </Styled.BetStacks>
        </Styled.Content>
        {/* button Section */}
        {betsAmount
          ?.sort((a, b) => a - b)
          ?.map((item) => {
            return (
              <Styled.Button
                type="button"
                class="btn-secondary m-l-5 m-b-5"
                onClick={() => increaseBet(item.amount)}
              >
                <i class="bi bi-plus"></i>
                <Styled.ButtonSpan>{item.amount}</Styled.ButtonSpan>
              </Styled.Button>
            )
          })}
        {/* Stacks */}
        {Stacks?.map((item) => {
          return (
            <Styled.StackButton
              type="button"
              _color={item.color}
              className=" btn-secondary m-l-5 m-b-5"
              onClick={() => handleBets(item.id)}
            >
              <Styled.StackButtonSpan>{item.name}</Styled.StackButtonSpan>
            </Styled.StackButton>
          )
        })}
        <Styled.Description>
          {_constants.MinBet} {min_bet || _constants.MinBid} {_constants.MaxBet}{' '}
          {max_bet || _constants.MaxBid}
        </Styled.Description>
        {/* Action Buttons */}
        <div class="col-md-12 px-0 text-center">
          <Styled.ActionDangerButton
            type="button"
            class="btn-sm"
            onClick={handleCloseBetSection}
          >
            {_constants.Cancel}
          </Styled.ActionDangerButton>
          <Styled.ActionSuccessButton
            isDisabled={bet <= max_bet && bet >= min_bet ? false : true}
            type="button"
            class="btn-sm"
            disabled={
              bet <= (max_bet || _constants.MaxBid) ||
              min_bet ||
              _constants.MinBid
                ? false
                : true
            }
            onClick={
              bet <= (max_bet || _constants.MaxBid) ? handleShowModal : () => {}
            }
          >
            {_constants.PlaceBet}
          </Styled.ActionSuccessButton>
        </div>
      </Styled.Container>
      <EditStackModal
        show={show}
        stackAmount={stackAmount}
        handleEditStack={handleEditStack}
        handleStackSave={handleStackSave}
        handleClose={handleClose}
        inputDisabled={inputDisabled}
      />
    </div>
  )
}

export default PlaceBetBox
