import styled from 'styled-components'
import { color } from '../../color/color'
export const Row = styled.div`
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-top: 0px;
`
export const Row12 = styled.div`
  flex: 0 0 auto;
  width: 100%;
  display: flex;
`
export const Commentary = styled.div`
  background: ${color._6D081D};
  margin-bottom: 1px;
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  border-radius: 1px;
  color: ${color._FFFFFF};
  text-transform: uppercase;
  font-size: 14px;
  @media (max-width: 991px) {
    background: ${color._FFFFFF} !important;
    color: ${color._000000} !important;
  }
`

export const Img = styled.img`
  width: 22px;
  padding: 3px;
  vertical-align: middle;
  height: auto;
`
