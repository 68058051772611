import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Styled from './style'
import { IoMdClose } from 'react-icons/io'
import {
  Container,
  ErrorMessage,
  FormGroup,
  Image,
  PasswordInput,
  PhoneInputWrapper,
  ButtonContainer,
  Btn,
  Icon,
  OtpInput,
  ModalInnerDiv,
  GlobalStyle,
} from './style'

import { FaEye } from 'react-icons/fa'
import { color } from '../../color/color'
import logoImage from '../../assets/images/logo.png'
import { PhoneInput } from 'react-international-phone'
import useResetPassword from '../../hooks/resetPasswordHook'
import { constants } from '../../constants/constants'

const ResetModal = ({ show, handleClose, setShow = () => null }) => {
  const { state, handleFormSubmit, dispatch, ActionTypes, resetState } =
    useResetPassword()
  const [otpLength, setOtpLength] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handlePasswordChange = () => {
    setShowPassword(!showPassword)
  }
  const handleConfirmPasswordChange = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const closeModal = () => {
    resetState()
    handleClose()
  }

  const onChangeOtpInput = (e) => {
    const cleanedValue = e.target.value
      .replace(/[^0-9]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
    setOtpLength(cleanedValue)
    dispatch({
      type: ActionTypes.SET_OTP,
      payload: cleanedValue,
    })
  }
  useEffect(() => {
    return () => {
      state.showOTP = false
    }
  }, [])

  return (
    <Styled.Modal>
      <Modal show={show} onHide={closeModal}>
        <ModalInnerDiv>
          <GlobalStyle />

          <Container>
            <IoMdClose
              className="close-icon"
              onClick={closeModal}
              color="#ffffff"
            />
            <div className="text-center pt-3">
              <Image
                className="img-fluid main-logo"
                src={logoImage}
                alt="logoImage"
              />
            </div>
            <Styled.ResetForm
              id="reset_form"
              onSubmit={(e) => handleFormSubmit(e, closeModal)}
            >
              <FormGroup>
                <PhoneInputWrapper>
                  <PhoneInput
                    defaultCountry="in"
                    value={''}
                    placeholder={constants.Mobile_no}
                    onChange={(phone) =>
                      dispatch({
                        type: ActionTypes.SET_PHONE,
                        payload: phone,
                      })
                    }
                  />
                </PhoneInputWrapper>
              </FormGroup>
              {state.showOTP && (
                <>
                  <FormGroup>
                    <OtpInput
                      type="text"
                      className="form-control"
                      id={constants.OTP}
                      name={constants.OTP}
                      placeholder={constants.OTP.toUpperCase()}
                      onChange={onChangeOtpInput}
                      value={otpLength}
                      maxLength="6"
                    />
                  </FormGroup>
                  <FormGroup>
                    <PasswordInput
                      type={showPassword ? constants.text : constants.PASSWORD}
                      className="form-control"
                      id={constants.PASSWORD}
                      name={constants.PASSWORD}
                      value={state.password}
                      placeholder={constants.PASSWORD}
                      onChange={(password) =>
                        dispatch({
                          type: ActionTypes.SET_PASSWORD,
                          payload: password?.target?.value.replace(/\s/g, ''),
                        })
                      }
                    />
                    <Icon>
                      <FaEye
                        onClick={handlePasswordChange}
                        color={color._FFFFFF}
                      />
                    </Icon>
                    <ErrorMessage>{constants.PASSWORD_RULES}</ErrorMessage>
                  </FormGroup>
                  <FormGroup>
                    <PasswordInput
                      type={
                        showConfirmPassword
                          ? constants.text
                          : constants.PASSWORD
                      }
                      className="form-control"
                      id={constants.PASSWORD}
                      name={constants.CONFIRM_PASSWORD}
                      value={state.confirmPassword}
                      placeholder={constants.CONFIRM_PASSWORD}
                      onChange={(password) =>
                        dispatch({
                          type: ActionTypes.SET_CONFIRM_PASSWORD,
                          payload: password?.target?.value.replace(/\s/g, ''),
                        })
                      }
                    />
                    <Icon>
                      <FaEye
                        onClick={handleConfirmPasswordChange}
                        color={color._FFFFFF}
                      />
                    </Icon>
                  </FormGroup>
                </>
              )}
              <FormGroup>
                <ButtonContainer>
                  <Btn
                    type="submit"
                    className="btn btn-primary mt-1"
                    id="signup_submit"
                  >
                    {state.showOTP ? 'UPDATE PASSWORD' : constants.GET_OTP}
                  </Btn>
                </ButtonContainer>
              </FormGroup>
            </Styled.ResetForm>
          </Container>
        </ModalInnerDiv>
      </Modal>
    </Styled.Modal>
  )
}

export default ResetModal
