import React from 'react'
import * as Styled from '../style'
import { LowerContent, constants } from '../../../utils/constant'
import IconTitle from './IconTitle'
import { FaStar } from 'react-icons/fa'

const ContentOptions = ({
  activeMatchOption,
  setActiveMatchOption,
  drawerOption,
  setDrawerOption,
  setMatchDetailsView,
}) => {
  const handleMatchOption = (e) => {
    setMatchDetailsView(false)
    setDrawerOption(constants.Home)
    setActiveMatchOption(e)
  }

  return (
    <Styled.ContentOptions className="content-options">
      {drawerOption !== constants.Inplay && (
        <Styled.Favourites
          onClick={() => setDrawerOption(constants.Favourites)}
        >
          <FaStar />
        </Styled.Favourites>
      )}

      <Styled.IPL>
        <IconTitle
          key={constants.IPL}
          title={constants.IPL}
          src="https://sports-v3.mysportsfeed.io/static/media/tata_ipl.c2fb067a.png"
          openDrawer={true}
          individualView={true}
          activeMatchOption={activeMatchOption}
          handleMatchOption={handleMatchOption}
          showCounter={true}
        />
      </Styled.IPL>
      {LowerContent.map((elem, indx) => {
        return (
          <IconTitle
            key={elem.title + indx}
            title={elem.title}
            src={elem.icon}
            openDrawer={true}
            individualView={true}
            activeMatchOption={activeMatchOption}
            handleMatchOption={handleMatchOption}
            showCounter={true}
          />
        )
      })}
    </Styled.ContentOptions>
  )
}

export default ContentOptions
