const ACTIONTYPES = {
  MATCH_DATA_FETCH_SUCCESS: 'MATCH_DATA_FETCH_SUCCESS',
  MATCH_DATA_FETCH_FAIL: 'MATCH_DATA_FETCH_FAIL',
  MATCH_DATA_ODD_FETCH_SUCCESS: 'MATCH_DATA_ODD_FETCH_SUCCESS',
  PLACE_BET_FETCH_SUCCESS: 'PLACE_BET_FETCH_SUCCESS',
  FANCY_BET_SUCCESS: 'FANCY_BET_SUCCESS',
  MATCHED_BET_SUCCESS: 'MATCHED_BET_SUCCESS',
  MATCH_DATA_SCORE_FETCH_SUCCESS: 'MATCH_DATA_SCORE_FETCH_SUCCESS',
}

export default ACTIONTYPES
