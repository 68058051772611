import React from 'react'
import * as Styled from './styles'
import { cardData, constants, playersData } from './constant'
import { color } from '../../../color/color'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import Marquee from '../../../components/Marquee/Marquee'

const PlayerTable = ({ player, style, labels, rows }) => {
  return (
    <div className="col-6" style={style}>
      <Styled.Table>
        <tr>
          <Styled.RowHeadingContainer>{player}</Styled.RowHeadingContainer>
          <Styled.BoxDataBlueContainer>
            {labels.back}
          </Styled.BoxDataBlueContainer>
          <Styled.BoxDataPinkContainer>
            {labels.lay}
          </Styled.BoxDataPinkContainer>
        </tr>
        {rows.length &&
          rows?.map((row, index) => {
            const [key, value] = Object.entries(row)[0]
            return (
              <tr key={key + index + value.type}>
                <Styled.RowHeadingDataContainer>
                  {value.type}
                </Styled.RowHeadingDataContainer>
                <Styled.BoxDataBlue isSuspended={value.isSuspended}>
                  <div className="align-items-center justify-content-center position-relative">
                    {value.isSuspended && (
                      <Styled.SuspendedContainer>
                        <Lock fill={color._fff} />
                      </Styled.SuspendedContainer>
                    )}
                    {value.back}
                  </div>
                </Styled.BoxDataBlue>
                <Styled.BoxDataPink isSuspended={value.isSuspended}>
                  <div className="align-items-center justify-content-center position-relative">
                    {value.isSuspended && (
                      <Styled.SuspendedContainer>
                        <Lock fill={color._fff} />
                      </Styled.SuspendedContainer>
                    )}
                    {value.lay}
                  </div>
                </Styled.BoxDataPink>
              </tr>
            )
          })}
      </Styled.Table>
    </div>
  )
}

const TeenPattiOneDay = () => {
  return (
    <Styled.Container className="container">
      <div className="row">
        {playersData.length &&
          playersData.map((playerData, index) => (
            <PlayerTable
              key={index + playerData.labels}
              player={playerData.player}
              style={playerData.style}
              labels={playerData.labels}
              rows={playerData.rows}
            />
          ))}
      </div>

      <div className="row mt-3">
        <Styled.ColContainer className="col-12">
          <Styled.Table>
            <tr>
              <td></td>
              {cardData.headers.length &&
                cardData.headers.map((header, index) => (
                  <Styled.CardTableRow key={index + header}>
                    {header}
                  </Styled.CardTableRow>
                ))}
            </tr>
            {cardData.rows.length &&
              cardData.rows.map((row, rowIndex) => (
                <tr key={rowIndex + row.label}>
                  <Styled.CardTableDataHeading>
                    {row.label}
                  </Styled.CardTableDataHeading>
                  {row.values.map((valueData, valueIndex) => (
                    <Styled.CardTableData
                      key={valueIndex}
                      isSuspended={valueData.isSuspended}
                    >
                      <div className="text-center position-relative">
                        {valueData.isSuspended && (
                          <Styled.SuspendedContainer>
                            <Lock fill={color._fff} />
                          </Styled.SuspendedContainer>
                        )}
                        {valueData.value}
                      </div>
                    </Styled.CardTableData>
                  ))}
                </tr>
              ))}
          </Styled.Table>
        </Styled.ColContainer>
      </div>
      <div className="row mt-2">
        <div className="col-12 px-0">
          <Marquee
            label={constants.playAndWin}
            bgColor={color._086F3F}
            fontColor={color._fff}
          />
        </div>
      </div>
    </Styled.Container>
  )
}

export default TeenPattiOneDay
