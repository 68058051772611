import React from 'react'
import * as Styled from './style'
const GamesFilter = ({
  subtabsOption,
  activeTab,
  handleSubTab,
  scroll = true,
}) => {
  return (
    <Styled.Subtabs isScroll={scroll}>
      {subtabsOption.map((elem, indx) => {
        return (
          <Styled.Option
            key={elem.title + indx}
            onClick={() => handleSubTab(elem)}
            active={activeTab.title === elem.title}
          >
            {elem.title}
          </Styled.Option>
        )
      })}
    </Styled.Subtabs>
  )
}

export default GamesFilter
