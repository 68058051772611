import React from 'react'
import * as Styled from '../style'
import TrapeSeven from './trape-seven.png'
const LowHigh7 = () => {
  return (
    <Styled.LowHigh7>
      <Styled.TrapeSeven
        src={TrapeSeven}
        alt="TrapeSeven"
        width={'60px'}
        height={'60px'}
      />
      <Styled.ValueDiv>
        <Styled.ValueDiv7 alignItem="flex-end" margin="right">
          <Styled.Value7>2.04</Styled.Value7>
          <Styled.Label7>Low</Styled.Label7>
        </Styled.ValueDiv7>
        <Styled.ValueDiv7 alignItem="flex-start" margin="left">
          <Styled.Value7>1.96</Styled.Value7>
          <Styled.Label7>High</Styled.Label7>
        </Styled.ValueDiv7>
      </Styled.ValueDiv>
    </Styled.LowHigh7>
  )
}

export default LowHigh7
