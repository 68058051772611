import React from 'react'
import { TopBox, Image, Title } from '../styles'
const ProfileImage = ({ userImage, username }) => {
  return (
    <TopBox flex={1}>
      <Image src={userImage} alt="profile-img" />
      <Title>{username}</Title>
    </TopBox>
  )
}

export default ProfileImage
