import React, { useEffect, useState, useRef } from 'react'
import * as Styled from './style'
import PagesHeader from '../../components/PagesHeader/PagesHeader'
import CasinoImg from '../../assets/sidebar/menu-99999.png'
import { constants } from '../../utils/constant'
import ImageOption from '../../components/ImageOptionGallery/ImageOption'
import { useDispatch, useSelector } from 'react-redux'
import { getCasinoGames } from '../../redux/casino/action'
import Loader from '../../components/Loader/Loader'
import Layout from '../../Layout'

const useIntersectionObserver = (callback, options) => {
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)
    if (ref.current) observer.observe(ref.current)

    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [callback, options])

  return ref
}

const Casino = () => {
  const allCasinoGames = useSelector((state) => state.casino.data)
  const isLoading = useSelector((state) => state.casino.isLoading)
  const [casGames, setCasGames] = useState([])
  const dispatch = useDispatch()
  const avoidRerender = useRef(false)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!avoidRerender.current) {
      dispatch(getCasinoGames(token))
    }
    return () => (avoidRerender.current = true)
  }, [dispatch])

  useEffect(() => {
    const formattedGames =
      allCasinoGames?.length &&
      allCasinoGames
        ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
        ?.map((item) => {
          return {
            src: item.imageUrl,
            alt: item.name,
            id: item.name,
            ...item,
          }
        })
    setCasGames(formattedGames)
  }, [allCasinoGames])

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target
        img.src = img.dataset.src
        img.onload = () => img.classList.add('loaded')
      }
    })
  }

  const options = { threshold: 0.1 }
  const imgRef = useIntersectionObserver(handleIntersection, options)

  return (
    <Layout>
      <Styled.Casino className="display-flex">
        <Styled.Content>
          <PagesHeader src={CasinoImg} heading={constants.Casino} />
          {isLoading ? (
            <Loader />
          ) : (
            <ImageOption
              options={
                casGames.length
                  ? casGames?.map((game) => ({
                      ...game,
                      src: game.src,
                      dataSrc: game.src,
                      ref: imgRef,
                    }))
                  : []
              }
            />
          )}
        </Styled.Content>
      </Styled.Casino>
    </Layout>
  )
}

export default Casino
