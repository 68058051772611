import { Navigate, Route, Routes } from 'react-router-dom'
import { PageComponent, componentRoutes } from './utils/componentRoutes'
import { useSelector } from 'react-redux'
import PageNotFound from './pages/NotFound/NotFoundPage'
import './index.css'

function App() {
  const RoutesArr = [
    {
      id: 1,
      path: componentRoutes.SIGNUP,
      element: PageComponent.SignupPage,
    },
    {
      id: 2,
      path: componentRoutes.HOME,
      element: PageComponent.HomePage,
    },
    {
      id: 3,
      path: componentRoutes.Profile,
      element: PageComponent.ProfilePage,
    },
    {
      id: 4,
      path: componentRoutes.AccountStatement,
      element: PageComponent.AccountsStatementPage,
    },
    {
      id: 5,
      path: componentRoutes.StakeSettings,
      element: PageComponent.StakeSettingsPage,
    },
    {
      id: 6,
      path: componentRoutes.ProfitLoss,
      element: PageComponent.ProfitLossPage,
    },
    {
      id: 7,
      path: componentRoutes.UnsettledBets,
      element: PageComponent.UnsettledBetsPage,
    },
    {
      id: 8,
      path: componentRoutes.ChangePassword,
      element: PageComponent.ChangePasswordPage,
    },
    {
      id: 9,
      path: componentRoutes.Casino,
      element: PageComponent.Casino,
    },
    {
      id: 10,
      path: componentRoutes.ICasino,
      element: PageComponent.ICasino,
    },
    {
      id: 11,
      path: componentRoutes.Inplay,
      element: PageComponent.Inplay,
    },
    {
      id: 12,
      path: componentRoutes.HomeSports,
      element: PageComponent.HomeSports,
    },
    {
      id: 13,
      path: componentRoutes.Cricket,
      element: PageComponent.Cricket,
    },
    {
      id: 14,
      path: componentRoutes.Football,
      element: PageComponent.Football,
    },
    {
      id: 15,
      path: componentRoutes.Tennis,
      element: PageComponent.Tennis,
    },
    {
      id: 16,
      path: componentRoutes.Politics,
      element: PageComponent.Politics,
    },
    {
      id: 17,
      path: componentRoutes.HorseRacing,
      element: PageComponent.HorseRacing,
    },
    {
      id: 18,
      path: componentRoutes.GreyRacing,
      element: PageComponent.GreyRacing,
    },
    {
      id: 19,
      path: componentRoutes.Kabaddi,
      element: PageComponent.Kabaddi,
    },
    {
      id: 20,
      path: componentRoutes.Binary,
      element: PageComponent.Binary,
    },
    {
      id: 21,
      path: componentRoutes.CasinoDetails,
      element: PageComponent.CasinoDetails,
    },
    {
      id: 22,
      path: componentRoutes.SportsBook,
      element: PageComponent.SportsBook,
    },
    {
      id: 23,
      path: componentRoutes.SportsDetails,
      element: PageComponent.SportsDetails,
    },
    {
      id: 24,
      path: componentRoutes.deposit,
      element: PageComponent.deposit,
    },
    {
      id: 25,
      path: componentRoutes.widthdraw,
      element: PageComponent.widthdraw,
    },
  ]

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  return (
    <Routes>
      {RoutesArr?.map((elem) => {
        if (
          (elem.path === componentRoutes.deposit ||
            elem.path === componentRoutes.widthdraw) &&
          !isAuthenticated
        ) {
          return (
            <Route
              key={elem.id}
              path={elem.path}
              element={<Navigate to={componentRoutes.HOME} />}
            />
          )
        } else {
          return <Route key={elem.id} path={elem.path} element={elem.element} />
        }
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default App
