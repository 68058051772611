import { color } from '../../color/color'

export const BetsData = [
  {
    amount: 100,
    id: 1,
  },
  {
    amount: 200,
    id: 2,
  },
  {
    amount: 500,
    id: 3,
  },
  {
    amount: 1000,
    id: 4,
  },
  {
    amount: 2000,
    id: 5,
  },
  {
    amount: 5000,
    id: 6,
  },
  {
    amount: 10000,
    id: 7,
  },
  {
    amount: 25000,
    id: 8,
  },
]

export default function generateBetsData(minAmount, maxAmount) {
  const BetsData = []
  let id = 1
  let amount = minAmount

  while (amount <= maxAmount) {
    BetsData.push({ amount, id })
    id++
    if (amount === maxAmount) break
    amount = getNextAmount(amount)
  }

  if (maxAmount > minAmount) {
    BetsData.push({ amount: maxAmount, id })
  }

  return BetsData
}

function getNextAmount(currentAmount) {
  return currentAmount * 2
}

export const Stacks = [
  {
    name: 'MIN STACK',
    id: 1,
    color: color._C10930,
  },
  {
    name: 'MAX STACK',
    id: 2,
    color: color._6D081D,
  },
  {
    name: 'EDIT STACK',
    id: 3,
    color: color._008000,
  },
  {
    name: 'CLEAR',
    id: 4,
    color: color._FF0000,
  },
]

export const _constants = {
  MaxBid: 50000,
  MinBid: 100,
  Cancel: 'Cancel',
  PlaceBet: 'Place Bet',
  MinBet: 'Min Bet:',
  MaxBet: 'Max Bet:',
}
