export const playersData = [
  {
    player: 'Player A',
    style: { paddingLeft: 0 },
    labels: { back: 'Back', lay: 'Lay' },
    rows: [
      {
        blueRow: {
          type: 'Main',
          back: '1.47',
          lay: 'Lay',
          isSuspended: false,
        },
      },
      {
        pinkRow: {
          type: 'Executive',
          back: '1.47',
          lay: 'Lay',
          isSuspended: false,
        },
      },
    ],
  },
  {
    player: 'Player B',
    style: { paddingRight: 0 },
    labels: { back: 'Back', lay: 'Lay' },
    rows: [
      {
        blueRow: {
          type: 'Main',
          back: '1.47',
          lay: 'Lay',
          isSuspended: true,
        },
      },
      {
        pinkRow: {
          type: 'Executive',
          back: '1.47',
          lay: 'Lay',
          isSuspended: true,
        },
      },
    ],
  },
]

export const cardData = {
  headers: ['Card 1', 'Card 2', 'Card 3', 'Card 4', 'Card 5', 'Card 6'],
  rows: [
    {
      label: 'Odd',
      values: [
        { value: '1.86', isSuspended: false },
        { value: '0', isSuspended: false },
        { value: '3.67', isSuspended: true },
        { value: '5.34', isSuspended: false },
        { value: '0', isSuspended: false },
        { value: '1.93', isSuspended: true },
      ],
    },
    {
      label: 'Even',
      values: [
        { value: '2.34', isSuspended: true },
        { value: '0', isSuspended: false },
        { value: '4.46', isSuspended: false },
        { value: '0', isSuspended: false },
        { value: '2.44', isSuspended: false },
        { value: '4.12', isSuspended: true },
      ],
    },
  ],
}

export const constants = {
  playAndWin: 'Play & Win',
}
