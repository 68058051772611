// will add utils files which are commonW

export const chunkArray = (array, size) => {
  const result = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array?.slice(i, i + size))
  }
  return result
}

export const convertDate = (str) => {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [date.getFullYear(), mnth, day].join('-')
}
// Common function to set token in local storage
export const setAuthData = ({
  token,
  userId,
  phone,
  isWithdrawPassword,
  username,
  phoneCountryCode,
}) => {
  localStorage.setItem('token', token)
  localStorage.setItem('userId', userId)
  localStorage.setItem('phone', phone)
  localStorage.setItem('isWithdrawPassword', isWithdrawPassword)
  localStorage.setItem('username', username)
  localStorage.setItem('phoneCountryCode', phoneCountryCode)
}
export const setUserDetails = ({ userDetails }) => {
  localStorage.setItem('userDetails', JSON.stringify(userDetails))
}

export const setSportsDetails = ({ sportsDetails }) => {
  sessionStorage.setItem('sportsDetails', JSON.stringify(sportsDetails))
}

export const setOpenMatchedData = ({ openMatchedBets }) => {
  localStorage.setItem(
    'openMatchedBets',
    openMatchedBets && JSON.stringify(openMatchedBets),
  )
}
export const setOpenFancyData = ({ openFancyBets }) => {
  localStorage.setItem(
    'openFancyBets',
    openFancyBets && JSON.stringify(openFancyBets),
  )
}

export const getUserDetails = () => {
  const userDetails =
    localStorage.getItem('userDetails') &&
    JSON.parse(localStorage.getItem('userDetails'))

  return { userDetails }
}
export const getSportsDetails = () => {
  const sportsDetails =
    sessionStorage.getItem('sportsDetails') &&
    JSON.parse(sessionStorage.getItem('sportsDetails'))

  return { sportsDetails }
}
export const getOpenMatchedBetsData = () => {
  const openMatchedBets =
    localStorage.getItem('openMatchedBets') &&
    JSON.parse(localStorage.getItem('openMatchedBets'))

  return { openMatchedBets }
}
export const getOpenFancyBetsData = () => {
  const openFancyBets =
    localStorage.getItem('openFancyBets') &&
    JSON.parse(localStorage.getItem('openFancyBets'))

  return { openFancyBets }
}

export const getAuthData = () => {
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')
  const phone = localStorage.getItem('phone')
  const username = localStorage.getItem('username')
  const isWithdrawPassword = localStorage.getItem('isWithdrawPassword')
  const phoneCountryCode = localStorage.getItem('phoneCountryCode')
  return {
    token,
    userId,
    phone,
    isWithdrawPassword,
    username,
    phoneCountryCode,
  }
}

export const removeAuthData = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userId')
  localStorage.removeItem('phone')
  localStorage.removeItem('isWithdrawPassword')
  localStorage.removeItem('username')
  localStorage.removeItem('phoneCountryCode')
}

export function sortByCreatedDate(data) {
  return data.sort(function (a, b) {
    var dateA = new Date(a?.start_time?.S)
    var dateB = new Date(b?.start_time?.S)
    return dateB - dateA
  })
}

export const getAllMatches = (data) => {
  const sportsMapping = {
    1: 'FOOTBALL',
    2: 'TENNIS',
    4: 'CRICKET',
    44: 'CRICKET',
    2378961: 'POLITICS',
    7: 'HORSE RACING',
    4339: 'GREYHOUND RACING',
    99994: 'KABADDI',
    99999: 'CASINO',
    99998: 'INT CASINO',
    99990: 'BINARY',
    26420387: 'Mixed Martial Arts',
    998917: 'Volleyball',
    7524: 'Ice Hockey',
    7522: 'Basketball',
    7511: 'Baseball',
    3503: 'Darts',
    29: 'Futsal',
    20: 'Table Tennis',
    5: 'Rugby',
    404: 'LOTTERY',
  }

  const eventGroups = {}

  data?.length &&
    data.forEach((event) => {
      if (event?.event_type_id?.N) {
        const eventType = event?.event_type_id?.N
        const sportsName = sportsMapping[eventType] || 'Unknown Sport'
        const teamName = event?.short_name?.S
        const eventName = event?.long_name?.S
        const isPlay = event?.in_play?.N
        const isLive = event?.active?.N
        const teams = {}
        const eventId = event?.event_id?.N
        const t11 = event?.t11
        const t12 = event?.t12
        const t21 = event?.t21
        const t22 = event?.t22
        const t31 = event?.t31
        const t32 = event?.t32
        const t41 = event?.t41

        const t42 = event?.t42
        const t51 = event?.t51
        const t52 = event?.t52
        const t61 = event?.t61
        const t62 = event?.t61

        // Format date and time
        const dateTime = new Date(event?.start_time?.S)
        const matchDate = dateTime.toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
        })
        const matchTime = dateTime.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })

        const match = {
          teamName: teamName,
          eventName: eventName,
          isPlay: isPlay,
          matchDate: matchDate,
          matchTime: matchTime,
          eventId: eventId,
          isLive: isLive,
          teams: teams,
          t11: t11,
          t12: t12,
          t21: t21,
          t22: t22,
          t31: t31,
          t32: t32,
          t41: t41,
          t42: t42,
          t51: t51,
          t52: t52,
          t61: t61,
          t62: t62,
          ...event,
        }

        if (!eventGroups[sportsName]) {
          eventGroups[sportsName] = {
            sportsName: sportsName,
            matches: [],
          }
        }
        eventGroups[sportsName].matches.push(match)
      }
    })

  return Object.values(eventGroups)
}

export const getBookmakerData = (bookmakerData) => {
  let bookmakerDataObj = {}
  let formattedArr = []
  for (let i = 0; i < bookmakerData?.length; i++) {
    bookmakerDataObj = bookmakerData[i]
    let bookmakerDataRunners =
      Object.keys(bookmakerDataObj)?.length &&
      JSON.parse(bookmakerDataObj?.data?.runners.obj || '{}')
    let runnerValues =
      bookmakerDataRunners && Object.values(bookmakerDataRunners)
    formattedArr.push({ ...bookmakerDataObj, teamData: runnerValues })
  }
  return formattedArr
}

export const getMatchOddsData = (data) => {
  const results = []
  let parsedData = []
  if (typeof data === 'string') {
    parsedData = JSON.parse(data)
  }

  parsedData.forEach((item) => {
    const result = {}

    result.team_name_matched = item.team_name_matched
    result.mtype = item.mtype
    result.marketId = item.marketId
    result.bet_min = item.bet_min
    result.bet_max = item.bet_max
    result.locked = item.locked
    result.inPlay = item.inPlay
    result.active = item.active
    result.eventId = item.eventId
    result.delay = item.delay

    result.teamData = {}
    const runnersObj = item.runners.obj

    result.teamData = {
      name1: runnersObj.name1,
      name1full: runnersObj.name1full,
      back1: runnersObj.back1,
      lay1: runnersObj.lay1,
      name2: runnersObj.name2,
      name2full: runnersObj.name2full,
      back2: runnersObj.back2,
      lay2: runnersObj.lay2,
      fav: runnersObj.fav,
    }

    result.loop = item.runners.loop
    results.push(result)
  })

  return results
}

export const getFancyData = (sportsEventId, fancyData) => {
  let fancyDataObj
  for (let i = 0; i < fancyData.length; i++) {
    if (sportsEventId === fancyData[i].event_id.S) fancyDataObj = fancyData[i]
  }
  let fancyDataFormat = fancyDataObj && fancyDataObj.data.M
  let fancyDataValues = fancyDataFormat && Object.values(fancyDataFormat)
  let fancyDataArr = []

  for (let i = 0; i < fancyDataValues?.length; i++) {
    fancyDataArr.push(JSON.parse(fancyDataValues[i].S))
  }

  return fancyDataArr
}

export const getspecificEvent = (data, eventId) => {
  console.log('🚀 ~ getspecificEvent ~ data, eventId:', data, eventId)
  // Iterate through each event in the data array
  for (let event of data) {
    // Check if the current event's event_id matches the requested eventId
    if (
      event?.event_id?.N === eventId?.toString() ||
      event?.event_id?.S === eventId?.toString()
    ) {
      return {
        name: event?.long_name?.S,
        date: event?.start_time?.S,
        min_bet: event?.bet_min?.N,
        max_bet: event?.bet_max?.N,
        event_id: event?.event_id?.N,
      }
    }
  }
}

export const sortedAlphabeticallyMatches = (matchData) => {
  return matchData.sort((a, b) => {
    const sportsNameA = a?.sportsName?.toUpperCase()
    const sportsNameB = b?.sportsName?.toUpperCase()

    if (sportsNameA < sportsNameB) {
      return -1
    }
    if (sportsNameA > sportsNameB) {
      return 1
    }
    return 0
  })
}

export const transformInput = (input) => {
  return (
    input &&
    Object.keys(input).map((key) => {
      const parsedData = input[key]
      return {
        id: parsedData.id,
        name: parsedData?.headname,
        min_bet: parseInt(parsedData?.min_limit, 10),
        max_bet: parseInt(parsedData?.max_limit, 10),
        b1: parseInt(parsedData?.SessInptNo),
        bs1: parseInt(parsedData?.NoValume),
        b2: parseInt(parsedData?.SessInptYes),
        bs2: parseInt(parsedData?.YesValume),
        odd_type: parsedData?.type,
        bet_delay: parsedData?.bet_delay,
        str: parsedData?.str,
        grp: parsedData?.grp,
      }
    })
  )
}

export const formatNumber = (num) => {
  if (num >= 1e6) {
    return (num / 1e3).toFixed(0) + 'k'
  }
  if (num >= 1e9) {
    return (num / 1e6).toFixed(0) + 'm'
  }
  return num.toString()
}

export const removeEmptyItemsFromArray = (data = []) => {
  return data.map((item) =>
    item === undefined || item === '' || item === null ? 0 : Number(item),
  )
}

export const removeDuplicateObjectFromArray = (data) => {
  return data.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => t.title === obj.title),
  )
}

export const convertBMDataForOdds = (arr = []) => {
  let result = arr?.reduce((acc, str) => {
    let parts = str?.split('|')
    let market_id = parts?.length && parts[0]

    // Check if market_id already exists in the accumulator
    let existingObj = acc?.find((obj) => obj?.market_id === market_id)

    if (existingObj) {
      // Merge the parts into existing object
      for (let i = 1; i < parts?.length; i++) {
        existingObj[`key${i}`] = parts[i]
      }
    } else {
      // Create a new object if market_id doesn't exist
      let newObj = { market_id: market_id }
      for (let i = 1; i < parts?.length; i++) {
        newObj[`key${i}`] = parts[i]
      }
      acc.push(newObj)
    }

    return acc
  }, [])

  return result
}
export const mergeArraysByMarketId = (array1 = [], array2 = []) => {
  if (array1?.length > 0) {
    const result =
      array1
        ?.map((item1) => {
          let matchingItem = array2?.find(
            (item2) =>
              item2?.market_id && item2?.market_id?.S === item1?.market_id,
          )

          if (matchingItem) {
            return {
              ...{ t11: getValueAfterNthKey(item1, 'ACTIVE', 1, 1) || null },
              ...{ t12: getValueAfterNthKey(item1, 'ACTIVE', 1, 2) || null },
              ...{ t21: getValueAfterNthKey(item1, 'ACTIVE', 1, 7) || null },
              ...{ t22: getValueAfterNthKey(item1, 'ACTIVE', 1, 8) || null },

              ...{ t31: getValueAfterNthKey(item1, 'ACTIVE', 3, 1) || null },
              ...{ t32: getValueAfterNthKey(item1, 'ACTIVE', 3, 2) || null },
              ...{ t41: getValueAfterNthKey(item1, 'ACTIVE', 3, 7) || null },
              ...{ t42: getValueAfterNthKey(item1, 'ACTIVE', 3, 8) || null },

              ...{ t51: getValueAfterNthKey(item1, 'ACTIVE', 2, 1) || null },
              ...{ t52: getValueAfterNthKey(item1, 'ACTIVE', 2, 2) || null },
              ...{ t61: getValueAfterNthKey(item1, 'ACTIVE', 2, 7) || null },
              ...{ t62: getValueAfterNthKey(item1, 'ACTIVE', 2, 8) || null },
              ...matchingItem,
            }
          }
          return item1
        })
        ?.filter((item) => item !== undefined) || []
    const unmatchedItems =
      array2?.filter(
        (item2) =>
          !array1?.some((item1) => item1?.market_id === item2?.market_id?.S),
      ) || []

    return result?.length > 0 || unmatchedItems?.length > 0
      ? [...result, ...unmatchedItems]
      : []
  } else {
    return array2
  }
}

export const getValueAfterNthKey = (data, key, n, offset) => {
  // Initialize counters
  let count = 0
  let result = null
  // Iterate through the keys in the object
  for (let k in data) {
    if (data?.hasOwnProperty(k) && data[k] === key) {
      count++
      // Check if the nth occurrence is reached
      if (count === n) {
        // Get the next keys based on the offset
        let nextKeys = Object.keys(data).slice(
          Object.keys(data).indexOf(k) + 1,
          Object.keys(data).indexOf(k) + 1 + offset,
        )
        result = nextKeys?.map((nextKey) => data[nextKey])
        break
      }
    }
  }

  return result?.length ? result?.pop() : null
}

export const getOpenBetsData = (data = {}, index) => {
  const selectedObject = data[index] || data[0]

  const betsArray = Object?.values(selectedObject)?.reduce((acc, market) => {
    if (market?.bets) {
      acc.push(...market?.bets)
    }
    return acc
  }, [])

  return betsArray
}

export const handleAccessOfOdds = (
  back_price,
  lay_price,
  matchType,
  placebetStatus,
) => {
  if (matchType == 'MATCH_ODDS' || matchType == 'bm1') {
    if (!placebetStatus) {
      return false
    } else if (back_price * 1 > lay_price * 1) {
      return false
    } else if (back_price === '0.00') {
      return false
    } else if (back_price * 1 == lay_price * 1) {
      return false
    } else if ((back_price = '-' || lay_price == '-')) {
      return false
    } else {
      return true
    }
  }
  if (matchType == 'BOOKMAKER') {
    if (!placebetStatus) {
      return false
    } else if (back_price * 1 > lay_price * 1) {
      return false
    } else if (back_price === '0.00' && lay_price === '0.00') {
      return false
    } else if (back_price * 1 == lay_price * 1) {
      return false
    } else {
      return true
    }
  }
}

export const getOddsData = (arr1 = {}, arr2 = []) => {
  const result = []

  const matchMap = arr2.reduce((acc, item) => {
    ;(item.matches || []).forEach((match) => {
      acc[match.eventId] = match
    })
    return acc
  }, {})

  Object.keys(arr1).forEach((key) => {
    arr1[key].forEach((item1) => {
      const matchingMatch = matchMap[item1.gmid]
      if (matchingMatch) {
        result.push({ ...item1, ...matchingMatch })
      } else {
        result.push({ ...item1 })
      }
    })
  })

  return result
}
