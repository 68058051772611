import styled from 'styled-components'
import { color } from '../../color/color'

export const WrapperContainer = styled.section`
  background-color: ${color._fff};
  ${
    '' /* background-color: ${(props) => (props.toggle ? color._E9EFF8 : color._000)}; */
  }
`

export const HeaderContainer = styled.div`
  background-color: ${color._343435};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`

export const BackButtonContainer = styled.div``

export const Button = styled.button`
  color: ${color._FFFFFF};
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px;
  background-color: ${color._6D081D};
  outline: none;
  border: none;
`

export const UserName = styled.span`
  font-size: 13px;
  text-transform: uppercase;
  padding: 5px;
  color: ${color._FFFFFF};
`

export const Container = styled.div`
  ${
    '' /* display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 15px;
  flex-wrap: wrap;
  gap: 2%; */
  }
`
export const LeftColContainer = styled.div`
  padding-right: 0px !important;
`
export const MidColContainer = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
`
export const RightColContainer = styled.div`
  padding-left: 2px !important;
`

export const LeftContainer = styled.div`
  ${'' /* flex: 0.24; */}
  border-radius: 7.523px;
  ${
    '' /* background-color: ${(props) => (props.toggle ? color._FFFFFF : color._222)}; */
  }
  background-color: ${color._E5E7EB};
  @media (max-width: 821px) {
    flex: 1;
    margin-bottom: 20px;
  }
`

export const LogoContainer = styled.div`
  background-image: url('https://game.royalgaming.online/static/media/sidebar-logo-bg.2d7f33113ca5cb39bf0e.svg');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 15vh;
  gap: 20px;
  padding: 10px;
  display: flex;
  border-radius: 7.523px;
`

export const LinkContainer = styled.div`
  background-color: initial;
  border: 1px solid ${color._CF8D2E};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: auto 0;

  > span {
    color: ${color._FFFFFF};
  }
`

export const ButtonContainer = styled.div`
  background-color: ${(props) =>
    props.toggle ? color._FFFFFF : color._000000};
  border: 1px solid color._000000;
  border-radius: 20px;
  display: flex;
  padding: 5px;
  width: 90%;
  margin: 10px auto;
`

export const Btn = styled.button`
  color: ${color._000000};
  font-family: Roboto Condensed, sans-serif;
  font-size: 8px;
  font-weight: 500;
  height: 30px;
  margin-right: 5px;
  min-height: 30px;
  padding: 0;
  width: 31%;
  background: ${(props) => (props.even ? color._CF8D2E : color._FFFFFF)};
  outline: none;
  border-radius: 15px;
  border: none;
`

export const SearchContainer = styled.div`
  position: relative;
  width: 90%;
  margin: 5px auto;
  background-color: ${color._FFFFFF};
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7); /* White glow effect */
  transition: box-shadow 0.7s ease-in-out;

  &:focus-within {
    box-shadow: 0 0 15px 3px rgba(255, 255, 255, 0.9); /* Enhanced glow on focus */
  }

  > input {
    width: 100%;
    height: 35px;
    font-weight: 400;
    font-size: 1rem;
    color: ${color._FFFFFF};
    background-color: ${(props) =>
      props.toggle ? color._FFFFFF : color._000000};
    outline: none;
    border: ${(props) => (props.toggle ? color._000000 : color._FFFFFF)};
    ${
      '' /* border: ${(props) =>
    '1px solid' + (props.toggle ? color._000000 : color._FFFFFF)}; */
    }
    cursor: text;
    ${'' /* border-radius: 5px; */}
    padding: 10px;
  }

  > input:hover {
    border: 1px solid ${color._CF8D2E};
  }

  > svg {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    color: ${(props) => (props.toggle ? color._000000 : color._FFFFFF)};
  }
`

export const CardWrapper = styled.div`
  width: 90%;
  margin: 20px auto;
  transition: border 0.9s ease;

  &:hover {
    border: 1px solid ${color._CF8D2E};
    padding: 10px;
  }

  > img {
    width: 100%;
  }
`

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.toggle ? color._000000 : color._FFFFFF)};
  padding: 5px;

  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }
`

export const MiddleContainer = styled.div`
  ${'' /* flex: 1; */}
  padding: 0 8px;
  @media (max-width: 612px) {
    flex: 1;
    margin-bottom: 20px;
    padding: 0px;
  }
  @media (max-width: 370px) {
    width: 87vw;
  }
`

export const RightContainer = styled.div`
  ${'' /* flex: 0.24; */}
  gap: 20px;
  @media (max-width: 612px) {
    flex: 1;
  }
`

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${color._FFFFFF};
  padding: 15px;
  border-radius: 7.5px;
  > div {
    color: ${color._000000};
  }
`

export const TopLeftContainer = styled.div`
  display: flex;
  flex: 0.7;
  gap: 5px;
`
export const TopRightContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0.3;
  justify-content: flex-end;
  gap: 5px;
`
export const BottomContainer = styled.div`
  background: url(https://rg-assests.royalgaming.online/common/openbets-container-bg.svg);
  background-color: ${color._FFFFFF};
  background-size: cover;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
`

export const CreditContainer = styled.div`
  align-items: center;
  background: ${color._1C1C1C};
  display: flex;
  height: 35px;
  width: 100%;
  color: ${color._FFFFFF};
`

export const BalanceContainer = styled.div`
  align-items: center;
  background: ${color._CF8D2E};
  color: ${color._FFFFFF};
  display: flex;
  font-family: Roboto Condensed, sans-serif;
  font-size: 10px;
  font-weight: 800;
  height: 35px;
  justify-content: center;
  flex: 1;
`
export const CreditWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 5px;
`

export const CreditSpan = styled.span`
  font-size: 8px;
  font-weight: 800;
`

export const BetSpan = styled.span`
  display: block;
  text-align: center;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 800;
  color: ${color._CF8D2E};
  margin-top: 10px;
`

export const Divider = styled.hr`
  margin: 0px 5px 10px 5px;
  color: ${color._CF8D2E};
`

export const MarketContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${color._000000};
  color: ${color._CCCCCC};
  height: 35px;
  padding: 0 5px;
  margin: 10px 0px;
`

export const NoBetContainer = styled.div`
  background-color: ${color._CCCCCC};
  height: 35px;
  display: grid;
  place-items: center;
`

export const RulesButton = styled.div`
  border: none;
  outline: none;
  cursor: pointer;
  backgroundcolor: ${color._000000};
  border: 1px solid ${color._B97F2B};
  borderradius: 3px;
  cursor: pointer;
  display: flex;
  justifycontent: center;
  alignitems: center;
  color: ${color._FFFFFF};
  padding: 3px;

  > svg {
    display: block;
    margin: auto;
  }

  > span {
    border: none;
    outline: none;
  }
`

export const MiddleContainerHeader = styled.header`
  color: ${(props) => (props.toggle ? color._000000 : color._FFFFFF)};
  display: flex;
  justify-content: space-between;
  background-color: ${color._2C3E50};
  padding: 10px 10px;
  ${'' /* padding: 5px 10px; */}
`

export const VideoContainer = styled.div`
  ${'' /* margin: 10px 0px; */}
  position:relative;
  width: 100%;
`

export const ButtonGroupContainer = styled.div`
  display: flex;
  gap: 1px;
  @media (max-width: 370px) {
    flex-wrap: wrap;
  }
`

export const RoundIdContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color._FFFFFF};
  font-size: 9px;
  font-weight: 700;
`

export const PlayersHistoryButton = styled.button`
  background-color: ${color._CF8D2E};
  border: none;
  outline: none;
  border-radius: 3px;
  color: ${color._FFFFFF};
  font-size: 9px;
  font-weight: 700;
  padding: 5px;
  height: 30px;
`

export const BetButton = styled.button`
  padding: 4px;
  font-size: 13px;
  outline: none;
  border: 2px solid;
  border-image-slice: 1 !important;
`

export const BetButtonWrapper = styled.div`
  border: 1px solid ${color._CF8D2E};
  display: flex;
  justify-content: space-between;
  height: 35px;
  align-items: center;
  background: linear-gradient(180deg, ${color._464646}, ${color._0F0F0F});

  @media (max-width: 370px) {
    height: auto;
    flex-wrap: wrap;
    width: 87vw;
  }
`

export const Image = styled.img`
  border-radius: 20px;
`
export const IconImage = styled.img``
export const CardImage = styled.img`
  height: 46px;
`

export const Theme = styled.div``
export const GameDiv = styled.div``
export const Span = styled.span``
export const PlayerHistorySpan = styled.span`
  cursor: pointer;
`
export const GameSpan = styled.span`
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  text-transform: uppercase;
  font-family: Roboto Condensed, sans-serif;
`
export const GameNameSpan = styled.span`
  color: ${color._2F2F2F};
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
  margin: 0;
  font-family: Roboto Condensed, sans-serif;
`
export const Username = styled.div``
export const OpenBetContainer = styled.div``
export const MemberURL = styled.div`
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center elements horizontally */
  gap: 5px; /* Add some space between elements */
`
export const SearchInput = styled.input``

export const Rules = styled.span`
  color: ${(props) => (props.toggle ? color._000000 : color._FFFFFF)};
`
export const RulesSpan = styled.a`
  color: ${(props) => (props.toggle ? color._000000 : color._FFFFFF)};
  cursor: pointer;
  margin-left: 10px;
`

export const BottomCardWrapper = styled.div`
  background: url(https://rg-assests.royalgaming.online/common/openbets-container-bg.svg);
  background-color: ${color._FFFFFF};
  background-size: cover;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
`
export const BottomCardViewTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap};
  padding: 5px;
  flex-direction: ${(props) => props.direction};
  > span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500px;
    color: #cf8d2e;
  }
`

export const BottomCardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  > h2 {
    font-weight: 900;
    font-size: 12px;
  }
`

export const MinMax = styled.h2`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  color: ${color._CF8D2E};
`

export const Back = styled.h2`
  display: flex;
  flex: 0.2;
  justify-content: center;
  color: ${color._72BBEF};
`

export const Lay = styled.h2`
  display: flex;
  flex: 0.2;
  justify-content: center;
  color: ${color._FAA9BA};
`

export const BottomCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 10px;
  background-color: ${color._FFFFFF};
  border-radius: 4px;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.11);
  font-family: Roboto Condensed, sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 10px;
`

export const Text = styled.span`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  color: ${color._000000};
  font-size: 12px;
  line-height: 20px;
  padding: 7px 2px;
`

export const LockButton = styled.button`
  display: flex;
  flex: 0.2;
  justify-content: center;
  background-color: ${color._72BBEF};
  padding: 5px;
  outline: none;
  border: none;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
`

export const LockButtonRed = styled(LockButton)`
  background-color: ${color._FAA9BA};
`
export const BottomCardRowWrapper = styled.div`
  margin-bottom: 10px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color._FFFFFF};
  font-size: 12px;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const NormalViewContainer = styled.div`
  background: url(https://rg-assests.royalgaming.online/common/openbets-container-bg.svg);
  background-color: ${color._222};
  background-size: cover;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
`

export const NormalViewCardContainer = styled.div`
  align-items: flex-end;
  background: linear-gradient(90deg, #0a523c, #02111e);
  border: 1px solid rgba(207, 141, 46, 0.35);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  padding: 6px;
  width: 100%;
`

export const OddContainer = styled.div`
  background: ${(props) => props.bgcolor};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
  outline: solid 1px rgba(207, 141, 46, 0.35);
  outline-offset: -1px;
  padding: 10px;
  text-transform: uppercase;
  border-radius: ${(props) =>
    props.index === 0
      ? '10px 0px 0px 10px'
      : props.index === 2
      ? '0px 10px 10px 0px'
      : '0px'};
`

export const BetWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-arround;
  color: ${color._FFFFFF};
  width: 100%;
  align-items: center;
  .boxContainer {
    width: 100%;
    .boxText {
      text-align: center;
    }
  }
`

export const BetContainer = styled.div`
  align-items: center;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(180deg, grey 0.01%, #313433),
    linear-gradient(180deg, #fff 0%, hsla(0, 0%, 100%, 0));
  background-origin: border-box;
  border: 1px double transparent;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 600;
  height: 47px;
  justify-content: center;
  width: 48%;
`

export const UndoButton = styled.button`
            outline: none;
            border: none;
            align-items: center;
            background: ${color._000000};
            border: 1px solid ${color._CF8D2E};
            border-radius: 6px;
            color: ${color._FFFFFF};
            cursor: pointer;
            display: flex;
            flex-direction: column;
            font-family: Roboto Condensed, sans-serif;
            font-size: 8px;
            font-weight: 700,
            justify-content: center;
            margin-right: 5px;
            padding: 1% 0;
            pointer-events: visible;
            text-transform: uppercase;
`

export const ResetButton = styled.button`
  outline: none;
  border: none;
  align-items: center;
  background: ${color._000000};
  border: 1px solid ${color._CF8D2E};
  border-radius: 6px;
  color: ${color._FFFFFF};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: Roboto Condensed, sans-serif;
  font-size: 8px;
  font-weight: 700;
  justify-content: center;
  margin-right: 5px;
  padding: 1% 0;
  pointer-events: visible;
  text-transform: uppercase;
`

export const MoneyDataWrapper = styled.div`
  display: flex;
  justifycontent: space-around;
  width: 100%;
  flex: 0.5;
`

export const MoneyDataContainer = styled.div`
  text-align: center;
  position: relative;
  border: 1px double transparent;
  border-radius: 100px;
  opacity: 1 !important;
  width: 100% !important;
`

export const MoneyDataImage = styled.img`
  height: 40px;
  width: 40px;
  display: block;
  margin: 0 auto;
`

export const MoneyDataSpan = styled.span`
  fontsize: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${color._FFFFFF};
  textalign: center;
`

export const NormalViewBtnContainer = styled.div`
  display: flex;
  flex: 0.2;
  justify-content: flex-end;
`

export const NormalViewRowWrapper = styled.div`
  display: flex;
  flex: 0.3;
`

export const OddContainerImage = styled.img`
  height: 72px;
  objectfit: contain;
`

export const CountdownTimerContainer = styled.div`
  position: absolute;
  bottom: 70px;
  right: 30px;
  z-index: 999;
  color: #fff;
  background-color: #757575;
  font-weight: 600;
  font-size: 18px;
  width: 30px;
  display: grid;
  place-items: center;
`

export const DynamicImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  padding: 5px;
`
