import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader/Loader'
import CasinoProvider from '../../container/CasinoProvider/CasinoProvider'
import ScoreCard from '../../container/ScoreCard/ScoreCard'
import {
  convertBMDataForOdds,
  getAllMatches,
  getOddsData,
  mergeArraysByMarketId,
  sortedAlphabeticallyMatches,
} from '../../utils/utils'
import { _constants } from './constants'
import * as Styled from './style'
import { useEffect, useRef } from 'react'
import { getMatchOddsData } from '../../redux/match/action'
import { useSocket } from '../../SocketContext'

const HomeSports = () => {
  const matchesData = useSelector((state) => state.match.data)
  const odds = useSelector((state) => state.match.odds)
  const isLoading = useSelector((state) => state.match.isLoading)
  const stopEmit = useSelector((state) => state.match.stopEmit)

  const dispatch = useDispatch()
  const { socket } = useSocket()
  const matchData = getAllMatches(matchesData)
  const sortedMatches = matchData?.length
    ? sortedAlphabeticallyMatches(matchData)
    : []

  const avoidRerender = useRef(false)
  const socketTimeout = useRef(null)
  useEffect(() => {
    if (socket) {
      if (socketTimeout.current) {
        clearTimeout(socketTimeout.current)
      }

      if (!avoidRerender.current && stopEmit) {
        avoidRerender.current = true
        socket.emit('all-bm')
        socket.emit('all-events')
        socket.emit('odds', 4)
        socket.on('odds', (_data) => {
          dispatch(getMatchOddsData(getOddsData(_data?.data, matchesData)))
        })
      }
      return () => (avoidRerender.current = true)
      // return () => {
      //   socket.disconnect()
      //   socket.off()
      //   socket.disconnect(true)
      // }
    }
  }, [socket])

  return isLoading ? (
    <Loader />
  ) : sortedMatches.length ? (
    <>
      {sortedMatches.map((item) => (
        <ScoreCard
          key={item.sportsName}
          matches={item.matches}
          name={item.sportsName}
          odds={odds}
        />
      ))}
      <CasinoProvider />
    </>
  ) : (
    <Styled.Span>{_constants.noRecord}</Styled.Span>
  )
}
export default HomeSports
