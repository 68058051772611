import React from 'react'
import * as Styled from './styles'
import { cardData } from './constant'
import { color } from '../../../color/color'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'

const TestTeenPatti = () => {
  return (
    <Styled.Container className="container">
      <div className="row">
        <Styled.ColContainer className="col-12">
          <Styled.Table>
            <tr>
              <td></td>
              {cardData.headers.length &&
                cardData.headers.map((header, index) => (
                  <Styled.CardTableRow key={index + header}>
                    {header}
                  </Styled.CardTableRow>
                ))}
            </tr>
            {cardData.rows.length &&
              cardData.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <Styled.CardTableDataHeading>
                    {row.label}
                  </Styled.CardTableDataHeading>
                  {row.values.map((valueData, valueIndex) => (
                    <Styled.CardTableData
                      key={valueIndex.value + valueIndex}
                      isSuspended={valueData.isSuspended}
                    >
                      <div className="text-center position-relative">
                        {valueData.isSuspended && (
                          <Styled.SuspendedContainer>
                            <Lock fill={color._fff} />
                          </Styled.SuspendedContainer>
                        )}
                        <Styled.Odds>{valueData.value}</Styled.Odds>
                      </div>
                    </Styled.CardTableData>
                  ))}
                </tr>
              ))}
          </Styled.Table>
        </Styled.ColContainer>
      </div>
    </Styled.Container>
  )
}

export default TestTeenPatti
