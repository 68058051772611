import React from 'react'

const StakeSettings = () => {
  return (
    <div>
      Stake
    </div>
  )
}

export default StakeSettings
