import styled from 'styled-components'
import { color } from '../../color/color'
export const Section = styled.section`
  & .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  @media (max-width: 992px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`
export const Col1 = styled.div`
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  @media (max-width: 992px) {
    flex: 0 0 auto;
    width: 100%;
  }
`

export const GameHeading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2px;
  border-color: ${color._343435};
  background-color: ${color._343435};
  margin-bottom: 0;
  color: ${color._FFFFFF};
  padding: 4px 8px;
  font-size: 14px;
  &:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  }
`
export const Title = styled.span`
  font-size: 14px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
`
export const Col2 = styled.div`
  @media (max-width: 992px) {
    width: 0;
  }
`
export const PlaceBet = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
`
export const Cardheader = styled.div`
  border-color: ${color._343435};
  background-color: ${color._343435};
  margin-bottom: 0;
  color: ${color._FFFFFF};
  padding: 4px 8px;
  font-size: 14px;
  &:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  }
`
export const H6 = styled.h6`
  color: ${color._FFFFFF};
  padding: 2px 8px;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2px;
  margin-bottom: 0px;
`

export const FancyButton = styled.button`
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  color: ${color._FFFFFF};
  background: linear-gradient(
    180deg,
    ${color._FA7E29} 0%,
    ${color._F6682F} 80%,
    ${color._F6682F} 100%
  );
  box-shadow: inset 0px -10px 20px 0px ${color._9F0101};
  font-weight: 700;
  border-radius: 5px;
  text-transform: uppercase;
  margin-right: 3px;
  font-size: 12px;
  padding: 5px 45px;
  position: relative;
  border: 1px solid ${color._C10930};
  text-decoration: none;
  width: 100%;
  margin: 5px 0px;
  font-family: 'Titillium Web', sans-serif;
`
export const FancyBlockButton = styled.button`
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  color: ${color._C10930};
  background: ${color._FFFFFF};
  font-weight: 700;
  border-radius: 5px;
  text-transform: uppercase;
  margin-right: 3px;
  font-size: 12px;
  padding: 5px 5px;
  position: relative;
  border: 1px solid ${color._C10930};
  text-decoration: none;
  width: 165px;
  height: 29px;
  margin: 3px 0px;
  white-space: nowrap;
  &:hover {
    background: linear-gradient(
      180deg,
      ${color._FA7E29} 0%,
      ${color._F6682F} 80%,
      ${color._F6682F} 100%
    );
    box-shadow: inset 0px -10px 20px 0px ${color._9F0101};
    color: ${color._FFFFFF};
  }
  @media (max-width: 991px) {
    padding: 5px 45px;
  }
`
export const ButtonBox = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  background-color: ${color._FFFFFF};
  margin: 1px 0px;
  gap: 5px;
`

export const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${color._FFFFFF};
  border-bottom: 1px solid ${color._F2EDF4};
  height: 42px;
`

export const MatchBox = styled.div`
  display: flex;
  flex: 1;
  padding: 5px 10px;
`
export const MatchTitle = styled.h6`
  gap: 3px;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  color: ${color._333333};
  font-family: 'Titillium Web', sans-serif;
  text-transform: capitalize;
  padding: 0 0 0 5px;
  max-width: 300px;
  overflow: hidden;
  white-space: normal;
  max-width: max-content;
  text-overflow: ellipsis;
  vertical-align: middle;
`

export const BetsBox = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`
export const BackLay = styled.div`
  flex: 2;
  justify-content: end;
  display: flex;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const BetDetails = styled.div`
  flex: 1;
  padding: 5px 10px;
  @media (max-width: 991px) {
    display: none;
  }
`

export const BetsTitle = styled.div`
  font-size: 10px;
  color: ${color._43444A};
  text-align: right;
  line-height: 1.1;
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
`
export const fancyContainer = styled.div`
  width: 100%;
  background-color: ${color._FFFFFF};
`

export const AnnouncementBox = styled.div`
  width: 100%;
  & .announcement-container {
    padding: 0 5px;
    font-size: 11px;
    font-weight: 500;
    background: ${color._F1F1F1};
    color: ${color._000000};
  }
  & .announcement-image {
    display: none;
    width: 0;
  }
`

export const BackLayContainer = styled.div`
  display: flex;
  background-color: ${color._FFFFFF};
  border-bottom: 1px solid ${color._F2EDF4};
  height: 1.5rem;
`
export const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
`

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 3rem;
  @media (max-width: 991px) {
    flex: 0.5;
    gap: 38%;
  }
`

export const Heading = styled.h6`
  font-size: 11px;
  text-transform: uppercase;
  border: none;
  line-height: normal;
  padding: 4px 0;
  font-weight: 700;
  color: rgb(51, 51, 51);
  font-family: 'Titillium Web', sans-serif;
`

export const BlBox = styled.div`
  background: ${color._F9C8D3};
  width: 25%;
  padding: 0;
  text-align: center;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease 0s;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30px;
  border-left: 1px solid ${color._F8F8F8};
  height: 100%;
  @media (max-width: 991px) {
    width: 35%;
  }
`
export const BlBoxLay = styled.div`
  background: ${color._94DFFF};
  width: 25%;
  padding: 0;
  text-align: center;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease 0s;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30px;
  border-left: 1px solid ${color._F8F8F8};
  height: 100%;
  @media (max-width: 991px) {
    width: 35%;
  }
`
export const Span = styled.span`
  display: block;
  line-height: 1.1;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  font-family: 'Titillium Web', sans-serif;
  color: ${color._333333};
  cursor: pointer;
  font-family: inherit;
`

export const Em = styled.em`
  display: block;
  font-style: normal;
  font-size: 10px;
  font-weight: 400;
  color: ${color._000000};
  text-transform: capitalize;
  font-family: 'Titillium Web', sans-serif;
  cursor: pointer;
  font-family: inherit;
`

export const IsMobile = styled.div`
  @media (min-width: 992px) {
    width: 100%;
    display: none;
  }
`
