import ACard from '../CasinoMeter/cardImages/A.png'

export const COLUMN_HEADERS = ['Dragon', 'Tiger', 'Lion']
export const TABLE_DATA = [
  {
    rowHeader: 'Winner',
    values: [2.94, 2.94, 2.94],
    image: null,
  },
  {
    rowHeader: '',
    values: [2.94, 2.94, 2.94],
    image: ACard,
  },
]

export const LOCKED_DATA = [
  {
    rowHeader: '',
    values: [2.94, 2.94, 2.94],
    image: ACard,
  },
  {
    rowHeader: '',
    values: [null, null, null],
    image: ACard,
    locked: true,
  },
]

export const tableScope = {
  row: 'row',
  col: 'col',
}

export const tableType = [
  { data: TABLE_DATA, locked: false },
  { data: LOCKED_DATA, locked: true },
]
