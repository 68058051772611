import React from 'react'
import * as Styled from '../style'
import { FaStar } from 'react-icons/fa'
import { ResponsiveDrawerData, constants } from '../../../utils/constant'
import { IoIosArrowForward } from 'react-icons/io'
import { color } from '../../../color/color'
const ResponsiveDrawer = ({
  drawerOption,
  setDrawerOption,
  setDrawerSlider,
}) => {
  return (
    <Styled.ResponsiveDrawer>
      {ResponsiveDrawerData.map((elem, indx) => {
        return (
          <Styled.DrawerOptions
            key={elem.title + indx}
            active={drawerOption === elem.title}
            onClick={() => {
              setDrawerOption(elem.title)
              setDrawerSlider(false)
            }}
          >
            {elem.title === constants.Favourites ? (
              <FaStar
                color={
                  drawerOption === elem.title ? color._FFFFFF : color._000000
                }
                className="icon"
              />
            ) : (
              <Styled.DrawerIcon
                src={elem.icon}
                alt={elem.title}
                active={drawerOption === elem.title}
              />
            )}
            <Styled.DrawerTitle>{elem.title}</Styled.DrawerTitle>
          </Styled.DrawerOptions>
        )
      })}
      <Styled.CloseDrawer
        className="flex-column-aicenter-jccenter"
        onClick={() => setDrawerSlider(false)}
      >
        <IoIosArrowForward color={color._FFFFFF} />
      </Styled.CloseDrawer>
    </Styled.ResponsiveDrawer>
  )
}

export default ResponsiveDrawer
