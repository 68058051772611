import React, { useState } from 'react'
import PasswordField from './PasswordField'
import { StyledSpan, PasswordFooter, NoteSpan } from '../styles'
import { constants } from '../../../utils/constant'
import { changePassword } from '../../../redux/auth/action'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

const PasswordContent = () => {
  const navigate = useNavigate()
  const [password, setPassword] = useState({
    currentPassword: '',
    newPassword: '',
    reEnterPassword: '',
  })
  const token = useSelector((state) => state.auth.token)
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
    reEnterPassword: '',
  })
  const dispatch = useDispatch()
  const loader = useSelector((state) => state.loading)

  const handleChange = (e) => {
    const { name, value } = e.target

    setPassword((prev) => {
      return {
        ...prev,
        [name]: value.replace(/\s{1,}/g, '').replace(/[^a-zA-Z0-9 ]+/g, ''),
      }
    })

    setErrors((prev) => {
      return { ...prev, [name]: '' }
    })
  }

  const handleClick = (e) => {
    e.preventDefault()

    const newErrors = {}

    if (!password.currentPassword.trim()) {
      newErrors.currentPassword = 'Current password is required'
    }

    if (!password.newPassword.trim()) {
      newErrors.newPassword = 'New password is required'
    } else if (password.newPassword.trim().length < 7) {
      newErrors.newPassword = 'Password must be at least 7 characters long'
    } else if (password.newPassword.trim().length > 10) {
      newErrors.newPassword = 'Password must not exceed 10 characters'
    }

    if (!password.reEnterPassword.trim()) {
      newErrors.reEnterPassword = 'Please re-enter the new password'
    } else if (
      password.newPassword.trim() !== password.reEnterPassword.trim()
    ) {
      newErrors.reEnterPassword =
        'New password and re-entered password must match'
    }
    if (
      password.newPassword.trim() === password.currentPassword.trim() &&
      password.currentPassword.trim().length > 1 &&
      password.newPassword.trim().length > 1
    ) {
      newErrors.reEnterPassword =
        'New password and Current password must not be same'
    }

    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0) {
      if (loader.isLoading) return
      dispatch(changePassword(password, token, navigate))
      setPassword({
        currentPassword: '',
        newPassword: '',
        reEnterPassword: '',
      })
    }
  }

  return (
    <>
      <PasswordField
        label={constants.CURRENT_PASSWORD}
        name={'currentPassword'}
        value={password.currentPassword}
        handleChange={handleChange}
        errors={errors}
      />
      <PasswordField
        label={constants.NEW_PASSWORD}
        name={'newPassword'}
        value={password.newPassword}
        handleChange={handleChange}
        errors={errors}
      />
      <PasswordField
        label={constants.RE_ENTER_PASSWORD}
        name={'reEnterPassword'}
        value={password.reEnterPassword}
        handleChange={handleChange}
        errors={errors}
      />
      <PasswordFooter>
        <NoteSpan>
          <em>{constants.NOTE}:</em>
        </NoteSpan>
        <em>{constants.PASSWORD_RULE_FIRST}</em>
      </PasswordFooter>
      <PasswordFooter>
        <NoteSpan>
          <em>{constants.NOTE}: </em>
        </NoteSpan>
        <em>{constants.PASSWORD_RULE_SECOND}</em>
      </PasswordFooter>
      <StyledSpan onClick={handleClick}>{constants.CHANGE_PASSWORD}</StyledSpan>
    </>
  )
}

export default PasswordContent
