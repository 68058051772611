import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Styled from './style'
import { IoMdClose } from 'react-icons/io'
import {
  Container,
  FormGroup,
  PasswordInput,
  PhoneInputWrapper,
  ButtonContainer,
  Btn,
  Icon,
  OtpInput,
  ModalInnerDiv,
  GlobalStyle,
  SaveBtn,
  HeaderBox,
  ModalHeaderSpan,
  ResetForm,
  Label,
  ConfirmLabel,
} from './style'
import { constants } from '../../constants/constants'
import { FaEye } from 'react-icons/fa'
import { color } from '../../color/color'
import { PhoneInput } from 'react-international-phone'
import useWithDraw from './WithdrawHook'
import { _constants } from './contants'

const WithdrawalModal = ({ show, handleClose }) => {
  const { state, handleFormSubmit, dispatch, ActionTypes, resetState } =
    useWithDraw(handleClose)
  const [otpLength, setOtpLength] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handlePasswordChange = () => {
    setShowPassword(!showPassword)
  }
  const handleConfirmPasswordChange = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const closeModal = () => {
    resetState()
    handleClose()
  }

  const onChangeOtpInput = (e) => {
    const cleanedValue = e.target.value
      .replace(/[^0-9]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
    setOtpLength(cleanedValue)
    dispatch({
      type: ActionTypes.SET_OTP,
      payload: cleanedValue,
    })
  }
  useEffect(() => {
    return () => {
      state.showOTP = false
    }
  }, [])
  return (
    <Styled.Modal>
      <Modal show={show} onHide={closeModal}>
        <ModalInnerDiv>
          <GlobalStyle />

          <Container>
            <HeaderBox>
              <IoMdClose
                color={color._FFFFFF}
                className="close-icon"
                onClick={closeModal}
              />
              <ModalHeaderSpan className="text-center pt-3">
                {_constants.ResetWithdrawPassword}
              </ModalHeaderSpan>
            </HeaderBox>
            <ResetForm id="withdraw_form">
              <FormGroup>
                <PhoneInputWrapper>
                  <Label>{_constants.MOBILENO}</Label>
                  <PhoneInput
                    defaultCountry="in"
                    value={''}
                    placeholder={constants.Mobile_no}
                    onChange={(phone) =>
                      dispatch({
                        type: ActionTypes.SET_PHONE,
                        payload: phone,
                      })
                    }
                  />
                </PhoneInputWrapper>
              </FormGroup>
              {state.showOTP && (
                <>
                  <FormGroup>
                    <Label>{_constants.OTP}</Label>
                    <OtpInput
                      type="text"
                      className="form-control"
                      id={constants.OTP}
                      name={constants.OTP}
                      placeholder={constants.OTP.toUpperCase()}
                      onChange={onChangeOtpInput}
                      value={state?.otp}
                      maxLength="6"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{_constants.PASSWORD}</Label>
                    <PasswordInput
                      type={showPassword ? constants.text : constants.PASSWORD}
                      className="form-control"
                      id={constants.PASSWORD}
                      name={constants.PASSWORD}
                      value={state.password}
                      placeholder={constants.PASSWORD}
                      onChange={(password) =>
                        dispatch({
                          type: ActionTypes.SET_PASSWORD,
                          payload: password?.target?.value.replace(/\s/g, ''),
                        })
                      }
                    />
                    <Icon>
                      <FaEye
                        onClick={handlePasswordChange}
                        color={color._FFFFFF}
                      />
                    </Icon>
                  </FormGroup>
                  <FormGroup>
                    <ConfirmLabel>{_constants.CONFIRMPASSWORD}</ConfirmLabel>
                    <PasswordInput
                      type={
                        showConfirmPassword
                          ? constants.text
                          : constants.PASSWORD
                      }
                      className="form-control"
                      id={constants.PASSWORD}
                      name={constants.CONFIRM_PASSWORD}
                      value={state.confirmPassword}
                      placeholder={constants.CONFIRM_PASSWORD}
                      onChange={(password) =>
                        dispatch({
                          type: ActionTypes.SET_CONFIRM_PASSWORD,
                          payload: password?.target?.value.replace(/\s/g, ''),
                        })
                      }
                    />
                    <Icon>
                      <FaEye
                        onClick={handleConfirmPasswordChange}
                        color={color._FFFFFF}
                      />
                    </Icon>
                  </FormGroup>
                </>
              )}
            </ResetForm>
            <Modal.Footer className="modal-footer-section">
              <div>
                <ButtonContainer>
                  <Btn
                    type="submit"
                    className="btn btn-secondary mt-1"
                    id="signup_submit"
                    onClick={closeModal}
                  >
                    {_constants.Close}
                  </Btn>
                </ButtonContainer>
              </div>
              <div>
                <ButtonContainer>
                  <SaveBtn
                    type="submit"
                    className="btn btn-primary mt-1"
                    id="signup_submit"
                    onClick={handleFormSubmit}
                  >
                    {!state.showOTP ? constants.GET_OTP : _constants.Save}
                  </SaveBtn>
                </ButtonContainer>
              </div>
            </Modal.Footer>
          </Container>
        </ModalInnerDiv>
      </Modal>
    </Styled.Modal>
  )
}

export default WithdrawalModal
