import styled, { keyframes } from 'styled-components'
import { color } from '../../color/color'

export const Button = styled.button`
  content: '<';
  background: #c10930;
  text-transform: uppercase;
  border: 1px solid #c10930;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

export const PaymentsContainer = styled.div`
  overflow-x: auto;
  justify-content: start;
  max-width: 51.5vw;
  width: 100%;
  margin-bottom: 10px;
  border: none;
  background: #fff;
  flex-wrap: nowrap;
  border-radius: 15px;
  padding: 10px 0;
  border-bottom: 5px solid #e4e4e4;
  display: flex;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  a {
    text-decoration: none;
  }
  & :hover {
    background: #f3f2f6;
    border-color: #e9ecef #e9ecef #dee2e6;
    color: #000000;
  }
  & :active {
    color: #fff;
  }
  @media (max-width: 777px) {
    max-width: 95vw;
  }
`

export const PaymentBoxs = styled.button`
  font-size: 14px;
  background: ${({ isactive }) => (isactive ? color._C10930 : color._FFFFFF)};
  color: ${({ isactive }) => (isactive ? color._FFFFFF : color._000000)};
  border-radius: 15px;
  border: 1px solid #d5d5d5;
  cursor: pointer;
  margin: 0 5px;
  display: block;
  padding: 0.5rem 1rem;
  text-transform: none;
  img {
    display: block;
    margin: 0 auto;
    width: 40px;
    object-fit: contain;
    border-style: none;
  }
`
const zoomInZoomOut = keyframes`
  0% {
    transform: scale(1, 1)
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
`
export const Card = styled.div`
  word-wrap: break-word;
  background: #fff;
  box-shadow: none;
  border: 1px solid #eee;
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 12px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  position: relative;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 0 ${color._DDDDDD};
  }
`

export const CardBody = styled.div`
  text-align: center;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 10px 0px 0px 0px;
`

export const CardTitle = styled.h5`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  position: relative;
  font-family: inherit;
  line-height: 1.2;
  color: inherit;
  margin-bottom: 25px;
  &:after {
    content: '';
    width: 20%;
    display: block;
    background: #d5d5d5;
    height: 1px;
    margin: 0 auto;
    bottom: -7px;
    position: absolute;
    left: 0;
    right: 0;
  }
`

export const AccountData = styled.div`
  background: #f3f2f6;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 10px;
`
export const CardText = styled.p`
  font-weight: 300;
  font-size: 15px;
  position: relative;
  text-align: left;
  padding: 2px 8px;
  color: #222;
  margin-bottom: 2px;
  b {
    font-weight: bolder;
  }
  img {
    width: 100%;
    padding: 1rem;
  }
`

export const CopyButton = styled.button`
  border: 1px solid #f3f2f6;
  background: none;
  padding: 0;
  font-size: 15px;
  position: absolute;
  right: 0%;
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  line-height: 1.5;
  border-radius: 0.2rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  @media (max-width: 991px) {
    right: 0%;
  }
`

export const LinkCard = styled.div`
  background: #fff;
  box-shadow: none;
  border: 1px solid #eee;
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 12px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  position: relative;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 0 ${color._DDDDDD};
  }
`

export const LinkCardBody = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 0px;
  color: #000;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  p {
    margin-bottom: 2px;
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    b {
      color: #d25505;
      font-weight: bolder;
    }
  }
`
export const GreenWrapper = styled.div`
  cursor: pointer;
  margin-top: 10px;
  background: ${color._FFFFFF};
  box-shadow: none;
  border: 1px solid ${color._EEEEEE};
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 12px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 0 ${color._DDDDDD};
  }
  @media (max-width: 776px) {
    padding: 2px;
  }
`

export const GreenContainer = styled.div`
  background: ${color._008d05};
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  @media (max-width: 776px) {
    padding: 10px;
  }
`

export const IconSpan = styled.span`
  font-size: 12px;
  color: ${color._FFFFFF};
  animation: ${zoomInZoomOut} 2s ease-out infinite;
  font-weight: 700;
  svg {
    font-size: 12px;
    color: ${color._FFFFFF};
    animation: ${zoomInZoomOut} 2s ease-out infinite;
    font-weight: 700;
  }
`

export const Bold = styled.b`
  font-size: 10px;
  font-weight: 900;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: ${color._FFFFFF};
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
`

export const PaymentForm = styled.form`
  position: relative;
  &:before {
    content: '';
    width: 1px;
    height: 100%;
    background: #d5d5d5;
    position: absolute;
    left: -15px;
    top: 0;
  }
  @media (max-width: 991px) {
    background: none;
  }
`
export const FormBody = styled.div`
  position: relative;
  -webkit-box-flex: 1;
  padding: 1rem;
`
export const FormFooter = styled.div`
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
`
export const FormFooterButton = styled.button`
  background: #00b800;
  border-color: #00b800;
  padding: 10px 30px;
  text-transform: uppercase;
  font-weight: 200;
  border-radius: 2px;
  width: 100%;
  color: #fff;
  &:hover {
    background: green !important;
    color: #fff !important;
  }
`

export const FormFooterGroup = styled.div`
  margin-bottom: 1rem;
  label {
    text-align: left;
    display: inherit;
    font-size: 17px;
    font-weight: 500;
    margin: 0;
    small {
      font-size: 80%;
      font-weight: 400;
      color: red;
      margin-left: 6px;
    }
    input {
      border-radius: 5px;
      font-size: 13px;
      color: #000;
      font-weight: 300;
      height: 50px;
      text-align: left;
    }
    input[type='file'] {
      border: none;
      padding: 0;
      height: auto !important;
    }
  }
  .read-only {
    background-color: #e9ecef !important;
  }
`

export const PermissionCheckbox = styled.div`
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;

  input {
    border-radius: 5px;
    font-size: 13px;
    color: #000;
    font-weight: 300;
    height: 50px;
    text-align: left;
    position: absolute;
    left: 15px;
  }
  a {
    text-decoration: none;
    color: #6d081d;
    cursor: pointer;
  }
  a:hover {
    text-decoration: underline;
  }
  label {
    text-align: left;
    display: inherit;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
  }
`

export const CopyToolTip = styled.div`
  position: absolute;
  top: 100%;
  left: 70%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 5;
`
export const Wrapper = styled.div`
  background-color: ${color._343435};
  margin-bottom: 5px;
  font-size: 13px;
  text-transform: uppercase;
  padding: 5px;
  margin-left: 10px;
  color: ${color._FFFFFF};
  @media (max-width: 991px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`

export const BackButton = styled.button`
  background-color: ${color._6D081D};
  outline: none;
  border: none;
  padding: 4px 8px;
  color: ${color._FFFFFF};
`

export const ContentWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 776px) {
    display: block;
  }
`

export const LeftSection = styled.div`
  margin-top: 10px;
  flex: 0.8;
  @media (max-width: 776px) {
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 10px;
  }
`

export const InputWrapper = styled.div`
  background: ${color._FFFFFF};
  box-shadow: none;
  border: 1px solid ${color._EEEEEE};
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 15px;
  margin-bottom: 12px;
`

export const Input = styled.input`
  outline: none;
  flex: 1;
  border: 1px solid ${color._DDDDDD};
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10px;
  font-size: 13px;
  color: ${color._000000};
  font-weight: 300;
  height: 50px;
  text-align: left;
`

export const SubmitButton = styled.button`
  text-transform: uppercase;
  background: green;
  color: ${color._FFFFFF};
  font-weight: 700;
  border: 1px solid green;
  letter-spacing: 1px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
`

export const InfoWrapper = styled.div`
  background: ${color._FFFFFF};
  box-shadow: none;
  border: 1px solid ${color._EEEEEE};
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 1.25rem;
  display: flex;
  gap: 5px;

  ol {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0 !important;
  }

  li {
    font-size: 10px !important;
    color: red;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';

    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 0 ${color._DDDDDD};
  }
  @media (max-width: 776px) {
    padding: 10px;
  }
`
export const NoRecords = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  font-family: sans-serif;
  width: 100%;
  @media (max-width: 776px) {
    width: 100%;
  }
`

export const RightSection = styled.div`
  margin-top: 10px;
  background: ${color._FFFFFF};
  box-shadow: none;
  border: 1px solid ${color._EEEEEE};
  transition: all 0.3s ease;
  border-radius: 25px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex: 0.2;

  .table-responsive {
    overflow-x: scroll;
    overflow-y: scroll;
    height: auto;
    max-height: 180px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    width: 330px !important;
    max-width: 100%;
  }
  table {
    margin-bottom: 0;
  }
  td {
    font-size: 10px;
    min-width: 70px;
    padding: 0.3rem 0.3rem;
    border: 1px solid #e5e5e5 !important;
    max-width: fit-content;
    width: auto;
  }
  .withdraw-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  th {
    padding: 7px;
    text-align: center;
    border: none;
    background: ${color._6D081D};
    color: ${color._FFFFFF};
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    position: sticky;
    top: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
    .table-responsive {
      width: 100%;
      margin-top: 10px;
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  gap: 0px;
  margin-top: 5px;
`

export const AmountSpan = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin: 0px 0px 2px 2px;
`

export const SportsTitle = styled.span`
  &:before {
    content: '';
    background: linear-gradient(180deg, ${color._C10930} 0, ${color._C10930});
    clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
    width: 18px;
    height: calc(100% + 0px);
    position: absolute;
    right: -17px;
    top: 0;
  }
  background: ${color._C10930};
  color: ${color._FFFFFF};
  display: block;
  border-radius: 0;
  position: relative;
  font-size: 10px;
  text-transform: uppercase;
  padding: 5px 5px;
  font-weight: 600;
  width: 70%;
  left: 0;
  z-index: 1;
  bottom: 0;
  border-top-left-radius: 5px;
  margin-bottom: 0.5rem;
  @media (max-width: 991px) {
    padding: 5px;
  }
`

export const Img = styled.img`
  max-width: 100%;
  height: auto;
  width: 20px;
  height: 20px;
  max-width: 20px;
  margin-right: 3px;
  height: auto;
  vertical-align: middle;
`
export const BottomSection = styled.div`
  padding: 13px 26px 13px 26px;
  @media (max-width: 776px) {
    padding: 13px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: contain;
  @media (max-width: 776px) {
    width: 50%;
    display: flex;
    margin: 0px auto;
  }
  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    margin: 0px auto;
  }
`

export const LoadingOverlay = styled.div`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  align-items: center;
  justify-content: center;
  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: black;
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
`
const getColor = (item) => {
  if (item?.status?.toUpperCase() == 'APPROVED') {
    return '#28a745'
  } else if (item?.status?.toUpperCase() == 'PENDING') {
    return '#ffc107'
  } else if (item?.status?.toUpperCase() == 'REJECTED') {
    return '#f44336'
  } else {
    return 'transparent'
  }
}
export const EllipsisTableData = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70px;
  .withdraw-status {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding: 2px;
    border: 1px solid ${({ item }) => getColor(item)};
    color: ${({ item }) => getColor(item)};
    font-weight: bold;
    font-size: 9px;
    font-family: 'Titillium Web', sans-serif;
    &:hover {
      color: black;
      background-color: ${({ item }) => getColor(item)};
    }
  }
  svg {
    color: ${color._FFFFFF};
    background-color: ${color._F44336};
    border-radius: 2px;
    padding: 2px;
    width: 1.3em;
    height: 1.3em;
    cursor: pointer;
  }
`
