import { Constants } from '../constants'
import * as Styled from '../styles'


const TopUserInfo = ({ toggle, toggleFunction }) => (
  <Styled.TopContainer toggle={toggle}>
    <Styled.TopLeftContainer toggle={toggle}>
      <Styled.Image
        src={Constants.desktopIconUrl}
        alt={Constants.desktopIconUrl}
      />
      <Styled.Username>{Constants.username}</Styled.Username>
    </Styled.TopLeftContainer>
    <Styled.TopRightContainer>
      <Styled.Theme>{Constants.Theme}</Styled.Theme>
      <Styled.Image
        src={Constants.toggleIconUrl}
        alt={Constants.Theme}
        onClick={toggleFunction}
      />
    </Styled.TopRightContainer>
  </Styled.TopContainer>
)

export default TopUserInfo
