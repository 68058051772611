import React from 'react'
import BetDetail from './InnerPageComp/BetDetail'
import AccordionFunc from './Accordian'
import { color } from '../../../color/color'
import * as Styled from '../style'
import { constants } from '../../../utils/constant'
import MatchDetails from './InnerPageComp/MatchDetails'

const InnerPage = ({
  activeBetId,
  setActiveBetId,
  setBetDetails,
  setBetsSlider,
  setAddedBet,
  availableBet,
}) => {
  const dummyData = [
    {
      betTitle: 'Winner (incl. super over)',
      betName: 'Demo 1',
      matchTitle: 'Demo 1 v Demo 2',
      icon: 'up',
      id: '1',
      availableBet: availableBet,
    },
    {
      betTitle: 'Winner (incl. super over)',
      betName: 'Demo 2',
      matchTitle: 'Demo 1 v Demo 2',
      icon: 'donw',
      id: '2',
      availableBet: availableBet,
    },
    {
      betTitle: 'Winner (incl. super over)',
      betName: 'Demo 1',
      matchTitle: 'Demo 1 v Demo 2',
      id: '3',
    },
    {
      betTitle: 'Winner (incl. super over)',
      betName: 'Demo 1',
      matchTitle: 'Demo 1 v Demo 2',
      icon: 'up',
      id: '4',
      availableBet: availableBet,
    },
  ]
  return (
    <Styled.InnerPage>
      <MatchDetails />
      <Styled.ALLDiv>
        <Styled.All>{constants.ALL}</Styled.All>
      </Styled.ALLDiv>
      <AccordionFunc
        title={'Winner (incl. super over)'}
        bgColor={color._d6d6d6}
        defaultExpanded={true}
      >
        <Styled.BetsAccordianData className="display-flex">
          {dummyData.map((elem, indx) => (
            <BetDetail
              betTitle={elem.betTitle}
              betName={elem.betName}
              matchTitle={elem.matchTitle}
              icon={elem.icon}
              id={elem.id}
              activeBetId={activeBetId}
              setActiveBetId={setActiveBetId}
              setBetDetails={setBetDetails}
              setBetsSlider={setBetsSlider}
              setAddedBet={setAddedBet}
              availableBet={elem.availableBet}
              key={elem.id + indx}
            />
          ))}
        </Styled.BetsAccordianData>
      </AccordionFunc>
    </Styled.InnerPage>
  )
}

export default InnerPage
