import spade from '../CasinoMeter/cardImages/spade.png'
import club from '../CasinoMeter/cardImages/club.png'
import heart from '../CasinoMeter/cardImages/heart.png'
import diamond from '../CasinoMeter/cardImages/diamond.png'

export const playersData = [
  {
    player: '',
    style: { paddingLeft: 0 },
    labels: { back: 'Back', lay: 'Lay' },
    rows: [
      {
        blueRow: {
          type: 'Dragon',
          back: '1.47',
          lay: 'Lay',
          isSuspended: false,
        },
      },
      {
        pinkRow: {
          type: 'Tiger',
          back: '1.47',
          lay: 'Lay',
          isSuspended: false,
        },
      },
    ],
  },
]

export const pairData = [
  { id: 1, number: '12', description: 'Pair', isSuspended: true },
]

export const playersMidData = [
  {
    player: '',
    style: { paddingLeft: 0 },
    labels: { back: 'Even', image1: '', lay: 'Odd', image2: '' },
    rows: [
      {
        blueRow: {
          type: 'Dragon',
          back: '1.47',
          lay: '1.98',
          isSuspended: false,
        },
      },
      {
        blueRow: {
          type: 'Tiger',
          back: '1.47',
          lay: '1.98',
          isSuspended: false,
        },
      },
    ],
  },
  {
    player: '',
    style: { paddingLeft: 0 },
    labels: {
      back: 'Red',
      imageback1: heart,
      imageback2: diamond,
      lay: 'Black',
      imagelay1: club,
      imagelay2: spade,
    },
    rows: [
      {
        blueRow: {
          type: 'Dragon',
          back: '1.47',
          lay: '1.95',
          isSuspended: true,
        },
      },
      {
        blueRow: {
          type: 'Tiger',
          back: '1.47',
          lay: '1.95',
          isSuspended: true,
        },
      },
    ],
  },
]

export const cardData = {
  headers: [club, spade, heart, diamond],
  rows: [
    {
      label: 'Dragon',
      values: [
        { value: '1.86', isSuspended: false },
        { value: '0', isSuspended: false },
        { value: '3.67', isSuspended: true },
        { value: '5.34', isSuspended: false },
      ],
    },
    {
      label: 'Tiger',
      values: [
        { value: '2.34', isSuspended: true },
        { value: '0', isSuspended: false },
        { value: '4.46', isSuspended: false },
        { value: '0', isSuspended: false },
      ],
    },
  ],
}
