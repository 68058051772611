import React from 'react'
import * as Styled from './style'
const Button = ({ btnName, handleClick }) => {
  return (
    <Styled.Button className="font-family-common" onClick={handleClick}>
      {btnName}
    </Styled.Button>
  )
}

export default Button
