import styled from 'styled-components'
import { color } from '../../../color/color'
export const Trap = styled.div``

export const PlayerComp = styled.div`
  display: flex;
  width: 48%;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid ${color._000};
  margin-top: 10px;
`
export const PlayerName = styled.div`
  width: 60%;
  padding-left: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
`
export const BetDiv = styled.div`
  display: flex;
  width: 40%;
`

export const Bet = styled.div`
  background-color: ${({ bgcolor }) => bgcolor};
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const PlayerData = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
`
export const LowHigh7 = styled.div`
  width: 48%;
  margin-top: 10px;
  border: 1px solid ${color._000};
  position: relative;
`
export const TrapeSeven = styled.img`
  position: absolute;
  left: 40%;
  top: -5px;
`
export const ValueDiv = styled.div`
  display: flex;
`
export const ValueDiv7 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItem }) => alignItem};
  width: 50%;
  padding-right: ${({ margin }) => (margin === 'right' ? '12%' : null)};
  padding-left: ${({ margin }) => (margin === 'left' ? '7%' : null)};
`
export const Value7 = styled.div`
  font-weight: bold;
`
export const Label7 = styled.div``
