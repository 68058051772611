import React from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Styled from './style'
import { IoMdClose } from 'react-icons/io'
import Rules from '../Rules/Rules'
import { _constants } from './constants'
const RulesModalCont = ({ show, handleClose }) => {
  return (
    <Styled.Modal>
      <Modal show={show} onHide={handleClose} size="lg">
        <Styled.ModalHeader>
          <Styled.ModalTitle>{_constants.title}</Styled.ModalTitle>
          <IoMdClose onClick={handleClose} />
        </Styled.ModalHeader>
        <Rules />
      </Modal>
    </Styled.Modal>
  )
}

export default RulesModalCont
