import React from 'react'
import SubDetails from './MatchSubDetails'

const MatchDetails = ({
  handleBetSection,
  handleCloseBetSection,
  currentSportsId,
  showBetSection,
  sportsName,
  eventDetails,
  restructuredBookmakerData,
  restructuredFancyData,
  key,
  backlayAmount,
  bgColor,
  oddStr,
  placebetStatus,
}) => {
  return (
    restructuredBookmakerData && (
      <SubDetails
        handleBetSection={handleBetSection}
        handleCloseBetSection={handleCloseBetSection}
        currentSportsId={currentSportsId}
        showBetSection={showBetSection}
        sportsName={sportsName}
        eventDetails={eventDetails}
        restructuredBookmakerData={restructuredBookmakerData}
        restructuredFancyData={restructuredFancyData}
        key={key}
        backlayAmount={backlayAmount}
        bgColor={bgColor}
        oddStr={oddStr}
        placebetStatus={placebetStatus}
      />
    )
  )
}

export default MatchDetails
