import React from 'react'
import * as Styled from './styles'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { COLUMN_HEADERS, tableScope, tableType } from './constant';
import { color } from '../../../color/color';


const TableContainer = ({ data, locked }) => {
    const renderTableRows = (data, locked = false) =>
        data.length && data.map((row, rowIndex) => (
            <tr key={rowIndex + row.rowHeader}>
                <Styled.TableHeadRow
                    scope={tableScope.row}
                    locked={locked}
                    image={row.image}
                >
                    {row.image ? <Styled.CardImage src={row.image} /> : row.rowHeader}
                </Styled.TableHeadRow>
                {row.values.map((value, colIndex) => (
                    <Styled.TableDataCell
                        key={colIndex}
                        locked={locked}
                    >
                        {locked ? <Lock fill={color._fff} /> : value}
                    </Styled.TableDataCell>
                ))}
            </tr>
        ));

    return (
        <Styled.Table className="table">
            <thead>
                <tr>
                    <Styled.TableHeadColEmpty scope={tableScope.col}></Styled.TableHeadColEmpty>
                    {COLUMN_HEADERS.map((header, index) => (
                        <Styled.TableHeadCol
                            key={index + header}
                            scope={tableScope.col}
                        >
                            {header}
                        </Styled.TableHeadCol>
                    ))}
                </tr>
            </thead>
            <tbody>{renderTableRows(data, locked)}</tbody>
        </Styled.Table>
    );
};

const DragonTigerLion = () => {
    return (
        <>
            <div className="row">
                {tableType.map((table, index) => (
                    <div key={index + table.data} className="col-md-6">
                        <TableContainer data={table.data} locked={table.locked} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default DragonTigerLion