import React from 'react'
import { Nav, NavTabs } from '../styles'
import TabNavItem from './TabNavItem'
import { tabs } from '../../../utils/constant'

const TabNav = ({ activeTab, handleTabChange }) => {
  
    return (
      <Nav>
        <NavTabs className="nav" id="nav-tab" role="tablist">
          {tabs.map((tab) => (
            <TabNavItem
              key={tab}
              tabId={tab}
              activeTab={activeTab}
              handleTabChange={handleTabChange}
            />
          ))}
        </NavTabs>
      </Nav>
    )
  }

export default TabNav
