import styled from 'styled-components'
import { color } from '../../color/color'

const jc_ai_centre = `
  align-items: center;
  justify-content: center;
`

const jc_sp_ai_centre = `
  align-items: center;
  justify-content: space-around;
`
const jc_sb_ai_centre = `
  align-items: center;
  justify-content: space-between;
`

export const SportsBook = styled.div`
  & .sport-book-drawer {
    overflow-y: scroll;
  }

  & .sport-book-drawer :: -webkit-scrollbar {
    display: none;
  }

  & .sport-book-drawer {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`

export const Header = styled.div`
  background-color: ${color._343435};
  color: ${color._fff};
  padding: 4px 8px;
  font-size: 17px;
`

export const BackBtn = styled.button`
  cursor: pointer;
  background: ${color._6D081D};
  border: none;
  color: ${color._fff};
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px;
`

export const Title = styled.span``

export const Drawer = styled.div`
  width: ${({ opendrawer }) => (opendrawer ? '250px' : '64px')};
  height: calc(100vh - 40px);
`

export const UpperContent = styled.div`
  display: ${({ opendrawer }) => (opendrawer ? 'grid' : 'block')};
  grid-template-columns: auto auto auto;
  flex-wrap: wrap;
  border-bottom: 1px solid ${color._CCCCCC};
  padding: ${({ opendrawer }) => (opendrawer ? '10px 16px 14px' : null)};
  margin-left: ${({ opendrawer }) => (!opendrawer ? '-3px' : null)};
`

export const LowerContent = styled.div`
  margin-top: 15px;
  & .sb-match-option:hover {
    background-color: ${color._116257};
  }
`

export const MidSection = styled.div``

export const LowerContentTitle = styled.div`
  color: ${color._000000};
  font-family: roboto;
  padding: 0 0 0 1.1rem;
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  opacity: 0.5;
  margin-bottom: 8px;
  margin-top: 22px;
`

export const SBMatch = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  color: ${({ activematchoption }) =>
    activematchoption ? color._FFFFFF : color._000000};
  background-color: ${({ activematchoption }) =>
    activematchoption ? color._116257 : null};
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 10px;
`
const SBContentNonIndividualView = ` width: 90%;
display: flex;
margin-left: 10px;`

const mainContentMain = `
display: flex;
align-items: center;
padding: 10px 1.2rem;
min-height: 42px;
position: relative;
font-size: 10px;
border-radius: 4px;
background-color: #DFE8E9;
margin:5px;
cursor: pointer;
`

export const SBContent = styled.div`
  ${({ individualview, activematchoption }) =>
    individualview && activematchoption
      ? `${mainContentMain}
    background-color:${color._116257};
    color: ${color._FFFFFF}
    `
      : individualview
      ? mainContentMain
      : SBContentNonIndividualView}
`

export const Favourites = styled.div`
  ${mainContentMain}
`

export const SBMatchIcon = styled.div``

export const SBMatchTitle = styled.div`
  margin-top: 5px;
  margin-left: 10px;
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  letter-spacing: 0.02em;
`

export const SBMatchArrow = styled.div``

export const MatchIcon = styled.img`
  ${({ activematchoption }) =>
    !activematchoption ? ` filter: invert(0) brightness(0.5);` : null}
`

export const Icon = styled.p`
  padding-top: 3px;
  margin-bottom: 0px;
  font-size: 90%;
  opacity: 0.6;
  color: ${({ activematchoption }) =>
    activematchoption ? color._FFFFFF : color._000000};
`

export const GridOption = styled.div`
  color: ${color._FFFFFF};
  display: flex;
  flex-direction: column;
  ${jc_ai_centre}
  width: 63px;
  height: 68px;
  text-align: center;
  background: ${({ activeOption, opendrawer }) =>
    activeOption ? color._116257 : opendrawer ? color._CCCCCC : null};
  border-radius: 4px;
  margin: 5px 2.5px;
  cursor: pointer;

  & .Star {
    color: ${color._FFFFFF};
    filter: ${({ title, activeOption }) =>
      title === 'Live' ? null : activeOption ? null : 'invert(100%)'};
  }
  & .color-change {
    filter: ${({ title, activeOption }) =>
      title === 'Live' ? null : activeOption ? null : 'invert(100%)'};
  }
`

export const IMG = styled.img``
export const Svg = styled.div`
  background-color: ${({ title }) => (title === 'Live' ? color._FFFFFF : null)};
  padding: ${({ title }) => (title === 'Live' ? '0 5px 0 5px' : null)};
`

export const SVGTitle = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${({ activeOption }) =>
    activeOption ? color._FFFFFF : color._000000};
`

const commonBtnCss = `
position: absolute;
top: 20%;
width: 20px !important;
height: 70px;
border: 2px solid ${color._000000};
background-color:${color._116257};
color: ${color._fff};
font-weight: bold;
`
export const CloseBtn = styled.button`
  ${commonBtnCss}
  left: ${({ activeOption }) =>
    activeOption === 'Search' ? '226px' : '220px'};
  border-radius: 42px 0 0 42px;
`

export const OpenBtn = styled.button`
  ${commonBtnCss}
  left: ${({ activeOption }) =>
    activeOption === 'Search' || activeOption === 'MyBets' ? '65px' : '56px'};
  z-index: 1;
  border-radius: 0 42px 42px 0;
`

export const ContentOptions = styled.div`
  display: flex;
  text-wrap: nowrap;
`

export const Content = styled.div`
  background-color: ${color._d6d6d6};
  width: ${({ opendrawer }) =>
    opendrawer ? 'calc(100vw - 250px)' : 'calc(100vw - 64px)'};
  height: 100vh;
  & .content-options {
    overflow-x: scroll;
  }

  & .content-options :: -webkit-scrollbar {
    display: none;
  }

  & .content-options {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & .result-options {
    overflow-x: scroll;
    margin-top: 10px;
    margin-left: -5px;
  }

  & .result-options :: -webkit-scrollbar {
    display: none;
  }

  & .result-options {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @media (max-width: 928px) {
    width: 100%;
  }
`

export const SliderImage = styled.img`
  height: 230px;

  @media (max-width: 530px) {
    height: 150px;
  }
`

export const SliderDiv = styled.div`
  & .slick-dots {
    bottom: 0;
  }
  & .slick-dots li button:before {
    color: ${color._FFFFFF};
  }
  padding: 10px 24px;
  height: 260px;

  @media (max-width: 530px) {
    height: 180px;
  }
`

export const IPL = styled.div``

export const CountNumber = styled.div`
  position: absolute;
  top: -5px;
  right: -3px;
  background-color: ${color._116257};
  padding: 1px 6px;
  border-radius: 50px;
  border: 1px solid ${color._FFFFFF};
  width: 20px;
  color: ${color._FFFFFF};
`

export const AccordianDiv = styled.div`
  background-color: ${({ bgcolor }) => bgcolor};
  margin-top: 5px;
  & .accordian {
    background-color: ${({ bgcolor }) => bgcolor};
  }

  & .accordianDetails {
    background-color: ${({ bgcolor }) => bgcolor};
  }
`

export const PlacedBets = styled.div`
  background-color: ${color._d6d6d6};
  width: 100%;
  min-height: 90px;
`
export const PlacedBetsTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
`
export const PlacedBetsPara = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  opacity: 0.5;
`

export const AccordianData = styled.div`
  display: grid;
  gap: 20px;
  padding: 10px 25px;
  cursor: pointer;
`

export const AccTitle = styled.div`
  font-weight: bold;
  ${({ titlebottom }) =>
    titlebottom
      ? `  border-bottom: 2px solid ${color._d6d6d6};
  width: 100%;
  padding-bottom: 8px;`
      : null}
`

export const TitleIcon = styled.div``

export const TitleImg = styled.img`
  padding-right: 10px;
`

export const EventDetail = styled.div`
  @media (max-width: 928px) {
    flex-direction: column;
  }
`

export const TimeTeam = styled.div`
  width: 50%;
  justify-content: space-around;
  @media (max-width: 928px) {
    width: 100%;
    flex-direction: column;
  }
`

export const FavScore = styled.div`
  width: 50%;
  @media (max-width: 928px) {
    width: 100%;
    ${jc_ai_centre}
  }
`

export const VS = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  padding: 2px 2.5px;
  opacity: 0.7;
  color: black;
  width: 25px;
  border-radius: 27px;
  background-color: ${color._CCCCCC};
  display: flex;
  ${jc_ai_centre}
`

export const Time = styled.div`
  width: 40%;
  margin-top: 10px;
  @media (max-width: 928px) {
    align-items: baseline;
  }
`
export const Team = styled.div`
  width: 60%;
  @media (max-width: 928px) {
    width: 100%;
  }
`
export const ValueDiv = styled.div`
  @media (max-width: 928px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const Fav = styled.div`
  cursor: pointer;
  width: 10%;
  ${jc_ai_centre}
  @media (max-width: 928px) {
    position: absolute;
    top: 68px;
    right: -20px;
  }
`
export const Score = styled.div`
  width: 90%;
  @media (max-width: 928px) {
    width: 50%;
  }
`
export const Values = styled.p`
  margin-bottom: 8px;
  text-align: center;
  justify-content: flex-start;
  font-family: ibm plex sans;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
`
export const TeamTitle = styled.p`
  opacity: 0.6;
  margin-bottom: 0;
  font-size: 12px;
  margin-bottom: 8px;
`
export const ScoreTitle = styled.div`
  text-align: center;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  opacity: 0.5;
  padding-top: 2px;
  padding-bottom: 8px;
`
export const ScoreValues = styled.div`
  ${jc_sp_ai_centre}
`
export const ScoreBox = styled.p`
  color: ${({ fontcolor }) => (fontcolor ? fontcolor : color._FFFFFF)};
  background: ${({ backgroundcolor }) =>
    backgroundcolor ? backgroundcolor : color._d6d6d6};
  border-radius: 3px;
  min-height: 28px !important;
  min-width: 80px;
  padding: 3px;
  display: flex;
  j${jc_ai_centre}
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 17px;
  letter-spacing: 0.02em;
  margin-top: ${({ extramargintop }) => (extramargintop ? '25px' : null)};
`

export const ScoreTitleBox = styled.div``

export const SportImg = styled.img`
  filter: ${({ image }) =>
    image === 'first'
      ? 'invert(30%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%)'
      : 'invert(29%) sepia(95%) saturate(2731%) hue-rotate(324deg) brightness(97%) contrast(92%)'};
  padding-right: 6.4px;
`
export const RightSlideButton = styled.div`
  width: 46px;
  background: ${color._116257};
  height: 100px;
  position: absolute;
  border: 2px solid ${color._000000};
  top: 5rem;
  right: 0;
  color: ${color._000000};
  border-top-left-radius: 42px;
  border-bottom-left-radius: 42px;
  display: flex;
  ${jc_ai_centre}
  cursor: pointer;

  @media (max-width: 928px) {
    display: inline-flex;
    -webkit-box-align: center;
    ${jc_ai_centre}
    -webkit-box-pack: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-height: 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 0px;
    min-width: 0px;
    z-index: 1050;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
      rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
    color: rgb(255, 255, 255);
    position: absolute;
    top: 55%;
    bottom: 45%;
    right: 0px;
    width: 12px;
    height: 80px;
    border: 1px solid ${color._FFFFFF};
    border-radius: 20px 0px 0px 20px;

    & .arrowDiv:hover {
      background-color: transparent;
    }
  }
`

export const ArrowDiv = styled.div`
  position: relative;
  left: -12px;
  background-color: ${color._116257};
  border-radius: 20px;
  border: 2px solid black;
  color: ${color._FFFFFF};

  @media (max-width: 928px) {
    border: 1px solid ${color._FFFFFF};
    left: -4px;
    font-size: 12px;
    width: 1em;
    display: flex;
    ${jc_ai_centre}
    height: 1em;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1rem;
  }
`
export const RightSlideIcon = styled.img``

export const BetsSidebar = styled.div`
  width: 367px;
  background-color: ${color._EEEEEE};
  position: absolute;
  right: 0;
  top: 36px;
  height: 100vh;
  border-left: 3px solid ${color._116257};

  @media (max-width: 929px) {
    bottom: 0;
    width: 100%;
    top: 50%;
    border-left: none;
  }
`
export const ResponsiveBetBar = styled.div``
export const RBTitleDiv = styled.div`
  background-color: ${color._CCCCCC};
  ${jc_sb_ai_centre}
  height: 50px;
  border-radius: 10px 10px 0 0;
  border: 1px solid ${color._4D4D4D};
`
export const RBTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: ${color._FFFFFF};
`

export const RBLeft = styled.div`
  & .title-icon {
    filter: grayscale(100%) brightness(20%) sepia(300%) hue-rotate(50deg)
      saturate(500%);
    margin: 0px 5px 0px 5px;
  }
`
export const RBRight = styled.div`
  border-radius: 50%;
  padding: 3px 5px 3px 5px;
  background-color: ${color._116257};
  cursor: pointer;
`

export const RBSelect = styled.div`
  height: 50px;
`
export const RBOption = styled.div`
  width: 50%;
  ${jc_sp_ai_centre}
  background-color: ${({ active }) => (active ? color._116257 : null)};
  color: ${({ active }) => (active ? color._FFFFFF : color._000000)};
  cursor: pointer;
`
export const RBContent = styled.div``

export const BetIDInput = styled.div`
  & .submitBtn {
    margin-left: 5px;
    background: ${color._116257};
  }
`

export const MatchedBets = styled.div`
  & .matchedTable {
    background-color: ${color._d6d6d6};
    border: 1px solid ${color._FFFFFF};
  }

  & .table-head {
    color: ${color._000000} !important;
    font-family: montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    opacity: 0.5;
  }
`

export const NoRecord = styled.div`
  margin-top: 10px;
  font-size: 12px;
  padding: 4px;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  color: ${color._000000} !important;
  font-weight: bolder;
`

export const CloseBets = styled.div`
  position: absolute;
  width: 14px !important;
  background: ${color._116257};
  height: 4.5rem;
  padding: 0;
  position: relative;
  top: -140px;
  left: -3px;
  color: ${color._FFFFFF};
  border-radius: 5px;
  cursor: pointer;
`

export const CloseDrawer = styled.div`
  position: absolute;
  background: ${color._116257};
  border-radius: 40px;
  min-width: unset;
  width: 10px;
  z-index: 100;
  height: 80px;
  top: 0;
  padding: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0 10px 10px 0;
  border: 1px solid ${color._FFFFFF};
`

export const ResponsiveDrawer = styled.div`
  width: 120px;
  background-color: ${color._CCCCCC};
  height: 220px;
  position: absolute;
  right: 0;
  bottom: 100px;
  border: 1px solid ${color._000000};
  border-radius: 10px;
  z-index: 10;
`
export const DrawerOptions = styled.div`
  display: flex;
  ${jc_sp_ai_centre}
  padding: 6px 14px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? color._116257 : null)};
  color: ${({ active }) => (active ? color._FFFFFF : color._000000)};
`
export const DrawerIcon = styled.img`
  filter: ${({ active }) => (active ? null : 'invert(100%)')};
`
export const DrawerTitle = styled.div``

export const MyBets = styled.div`
  margin-left: 8px;
`

export const Result = styled.div`
  margin-left: 8px;
`

export const DateDiv = styled.div`
  width: 100%;
  overflow-x: auto;
`

export const MyBetsTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 8px 0px 8px 0px;
  margin-left: ${({ marginLeft }) => (marginLeft ? '8px' : null)};
`

export const Filters = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0px 10px 0px;
  justify-content: space-around;
  & .css-10o2lyd-MuiStack-root {
    width: 200px;
  }

  & .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    color: ${color._FFFFFF};
  }
`
export const DateOption = styled.div`
  display: flex;
  width: 64px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${color._808080};
  font-size: 80%;
  justify-content: space-around;
  letter-spacing: 0.02857em;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  opacity: 0.7;
  margin-right: 16px;
  background-color: ${color._dfe8e9};
`
export const Date = styled.div``
export const Day = styled.div``

export const ResultOptions = styled.div`
  overflow-x: scroll;
  text-wrap: nowrap;
`

export const Favourite = styled.div`
  margin-left: 8px;
`

export const FavouriteContent = styled.h5`
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
`
export const Search = styled.div`
  margin-left: 8px;
`
export const QuickSearch = styled.div`
  margin-left: 8px;
`
export const SearchDiv = styled.div`
  ${jc_sb_ai_centre}
  width: 82%;
  height: 44px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${color._CCCCCC};
  padding: 5px;
  margin: 20px 0px 20px 0px;
`
export const SearchIcon = styled.div``
export const SearchInput = styled.input`
  background: transparent;
  border: none;
  margin-left: 5px;
  width: 90%;
  outline: none;
`
export const SearchLeft = styled.div`
  width: 100%;
`
export const SearchRight = styled.div`
  cursor: pointer;
`
export const SearchContent = styled.div`
  ${jc_ai_centre}
`
export const QuicklySearchDiv = styled.div`
  background-color: ${color._CCCCCC};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 83px;
  width: 27%;
`

export const QuicklySearch = styled.div`
  font-size: 12px;
  line-height: 15px;
  font-family: montserrat, sans-serif;
  font-weight: 600;
`

export const QuickSearchTagLine = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-family: montserrat, sans-serif;
  font-weight: 500;
`
export const Results = styled.div`
  padding: 20px 0px 20px 0px;
`

export const ResultHeading = styled.h6`
  width: 100%;
  text-align: center;
  border-bottom: 2px solid ${color._CCCCCC};
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-family: montserrat, sans-serif;
  font-weight: bolder;
`
export const ResultSpan = styled.span`
  background: ${color._d6d6d6};
  padding: 0 10px;
`

export const BetTab = styled.div`
  width: 100%;
  height: auto;
  padding: 10px;
  background-color: ${color._F2F2F2};
  margin: 5px;
  border-radius: 8px;
`
export const CrossDiv = styled.div`
  position: absolute;
  background-color: ${color._804A47};
  padding: 3px;
  color: ${color._FFFFFF};
  right: 6%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 10px;
  display: flex;
  ${jc_ai_centre}
  z-index:10;
`
export const MatchTitle = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 200%;
  text-align: left;
  opacity: 0.6;
`
export const BetTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: left;
  line-height: 16px;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
`
export const OddsStake = styled.div`
  ${jc_ai_centre}
`
export const Odds = styled.div`
  width: 50%;
  ${jc_sp_ai_centre}
`

export const OddsStakeTitle = styled.div`
  font-style: normal;
  line-height: 13px;
  letter-spacing: 0;
  text-align: left;
  font-weight: 700;
  font-size: 10px;
  opacity: 0.5;
`
export const OddsInput = styled.div`
  border-radius: 3px;
  width: 70%;
  font-weight: 400;
  font-size: 14px;
  border: 2px solid ${color._FFFFFF} !important;
  background: ${color._F2F2F2};

  & .odds-input-child {
    width: 50%;
    display: flex;
    ${jc_sp_ai_centre}
  }

  & .odds-input-child:nth-child(odd) {
    background-color: ${color._d6d6d6};
  }
`
export const Stake = styled.div`
  width: 50%;
  ${jc_sp_ai_centre}
`

export const OddsIcon = styled.div``

export const StakeTitle = styled.div``
export const Clear = styled.div`
  text-align: right;
  margin-right: 3%;
  font-size: 10px;
  cursor: pointer;
`
export const StakeInput = styled.input`
  width: 70%;
  background: ${color._F2F2F2};
  border: 2px solid ${color._116257};
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`
export const OddsValue = styled.div``
export const BetValues = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const BetTabFooter = styled.div`
  ${jc_sb_ai_centre}
  background-color: ${color._CCCCCC};
  margin-top: 5px;
`
export const BetOption = styled.div`
  margin-top: 5px;
  width: calc(25% - 5px);
  box-sizing: border-box;
  background-color: ${color._d6d6d6};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  border-radius: 3px;
  display: flex;
  ${jc_sp_ai_centre}
  flex: 0 0 calc(33.33% - 10px);
  margin: 5px;
  box-sizing: border-box;
  cursor: pointer;
`
export const BFootLeft = styled.div`
  padding: 5px;
`
export const Profit = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: -0.01em;
  opacity: 0.7;
`
export const Pts = styled.div`
  font-style: normal;
  letter-spacing: -0.01em;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`
export const PlaceBet = styled.div`
  margin-right: 10px;
  border-radius: 5px;
  color: ${color._FFFFFF};
  border-radius: 6px;
  background: ${color._116257};
  border: 0;
  cursor: pointer;
  padding: 5px 18px;
  height: 32px;
  border-radius: 3px !important;
`

export const BetMessage = styled.div`
  font-size: 14px;
  color: red;
  font-weight: 700;
`

export const BetsAccordianData = styled.div`
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 908px) {
    flex-direction: column;
  }
`
export const InnerPage = styled.div`
  width: 95%;
  padding: 10px;

  & .css-15v22id-MuiAccordionDetails-root {
    background-color: ${color._d6d6d6};
  }
`
export const ALLDiv = styled.div`
  width: 100%;
  background-color: ${color._CCCCCC};
`
export const All = styled.div`
  width: 50px;
  padding: 10px;
  margin-left: 10px;
  font-weight: bolder;
  border-bottom: 3px solid #116257;
`
