import React from 'react'
import * as Styled from './style'

const HighlightedCard = ({ item = [] }) => {
  return (
    <>
      <Styled.Container>
        {/* it will map the highlighted data and show it on UI */}
        {item &&
          item.map((i, index) => {
            return (
              <Styled.Anchor
                href=""
                id="highlighted-card"
                key={i?.name + index}
              >
                {/* will add the href when we make the sports pages directory */}
                <Styled.Span>{i?.name}</Styled.Span>
              </Styled.Anchor>
            )
          })}
      </Styled.Container>
    </>
  )
}

export default HighlightedCard
