export const playerTablesData = [
  {
    player: 'Player A',
    style: { paddingLeft: 0 },
    labels: {
      back: { value: 1.73, isSuspended: false },
      lay: { value: 1.77, isSuspended: false },
    },
    rows: [
      {
        type: 'Mini Bacarrat A',
        back: '1.11',
        isSuspended: false,
        colspan: 2,
      },
      {
        type: 'Total A',
        back: '14',
        backTotal: '30',
        isSuspendedBack: true,
        lay: '14',
        layTotal: '40',
        isSuspendedLay: false,
      },
    ],
  },
  {
    player: 'Player B',
    style: { paddingRight: 0 },
    labels: {
      back: { value: 1.73, isSuspended: true },
      lay: { value: 1.77, isSuspended: true },
    },
    rows: [
      {
        type: 'Mini Bacarrat B',
        back: '1.11',
        isSuspended: true,
        colspan: 2,
      },
      {
        type: 'Total B',
        back: '14',
        backTotal: '30',
        isSuspendedBack: false,
        lay: '14',
        layTotal: '40',
        isSuspendedLay: true,
      },
    ],
  },
]

export const colorData = [{ label: 'Color Plus', isSuspended: true }]
