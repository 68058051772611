import styled from 'styled-components'
import { color } from '../../color/color'

export const Box = styled.div`
  box-sizing: border-box;
`
export const BetTableRow = styled.div`
  background: ${color._FFFFFF};
  color: ${color._1E1E1E};
  border-bottom: 1px solid ${color._F8F8F8};
  transition: 0.9s;
  &:hover {
    box-shadow: inset 0 0 10px 0px ${color._F9F9F9};
    border-bottom: 1px solid ${color._DBEDFF};
  }
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: 2px;
  margin-left: -2px;
  width: 100%;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 2px;
  padding-left: -2px;
  margin-top: 0px;
  align-items: center;
  @media (max-width: 991px) {
    margin-left: 0px;
    padding-right: 0px;
  }
`

export const ColMd6 = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    flex: 0 0 auto;
    width: 100%;
  }
`

export const GameBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  @media (max-width: 991px) {
    padding-right: 0.5rem;
  }
`
export const GameLeftColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 82%;
  @media (max-width: 991px) {
    width: 75%;
  }
`
export const GameName = styled.div`
  margin-left: 5px;
  width: 100%;
  max-width: 40%;
  cursor: pointer;
`
export const GameNameAnchor = styled.div`
  text-decoration: none;
`
export const TeamName = styled.p`
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Titillium Web', sans-serif;
  width: 260px;
  color: ${color._000000};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  @media (max-width: 1300px) {
    width: 200px;
  }
  @media (max-width: 991px) {
    max-width: 150px;
    font-size: 12px;
  }
  @media (max-width: 360px) {
    max-width: 120px;
    font-size: 10px;
  }
`
export const TeamEvent = styled.div`
  font-size: 10px;
  color: ${color._838383};
  font-weight: 500;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  max-width: 280px;
  @media (max-width: 991px) {
    max-width: 150px;
    width: 150px;
  }
  @media (max-width: 360px) {
    max-width: 120px;
    font-size: 10px;
  }
`
export const GameDate = styled.div`
  width: 78px;
  display: block;
  font-size: 9px;
  font-weight: 500;
  color: ${color._444444};
  background: ${color._E9EFF8};
  padding: 4px;
  margin: 0 4px;
  @media (max-width: 991px) {
    width: 50px;
    padding: 0;
    font-size: 8px;
  }
`
export const InplayDateDiv = styled.div`
  display: flex;
`
export const GameDateInplay = styled.div`
  width: 78px;
  display: block;
  font-size: 9px;
  font-weight: 500;
  color: ${color._444444};
  background: ${color._E9EFF8};
  padding: 4px;
  margin: 0 4px;
  @media (max-width: 991px) {
    width: 50px;
    padding: 0;
    font-size: 8px;
    margin: 3px 4px;
  }
  text-align: center;
  position: relative;
  background: none;
  border: none;
`

export const GameDateSpan = styled.span`
  @keyframes run {
    0% {
      left: 0;
      width: 5%;
    }
    50% {
      width: 100%;
    }
    100% {
      right: 0;
      width: 5%;
    }
  }

  color: ${color._03B37F};
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  &:after {
    content: '';
    background-color: ${color._03B37F};
    height: 2px;
    width: 50px;
    position: absolute;
    bottom: 2px;
    animation: linear infinite alternate;
    animation-name: run;
    animation-duration: 1.2s;
    -webkit-animation: linear infinite alternate;
    -webkit-animation-name: run;
    -webkit-animation-duration: 1.2s;
    @media (max-width: 991px) {
      height: 1.5px;
    }
  }
  @media (max-width: 991px) {
    font-size: 11px;
    font-weight: 700;
  }
`
export const GameDatePara = styled.p`
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-size: 9px;
  font-weight: 500;
  font-family: 'Titillium Web', sans-serif;
`

export const GameIcons = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${color._7F7F7F};
`
export const GameIconAnchor = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${color._7F7F7F};
`
export const GameIconSpan = styled.span`
  background: ${color._C10930};
  width: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  height: 20px;
  vertical-align: super;
  color: ${color._FFFFFF};
  line-height: 20px;
  font-size: 9px;
  font-weight: 700;
  margin-left: 3px;
`
export const GameIconImage = styled.img`
  width: 15px;
  vertical-align: baseline;
  cursor: pointer;
  @media (max-width: 991px) {
    width: 17px;
  }
`
export const ImageBox = styled.div`
  display: flex;
  cursor: pointer;
`

export const Col4 = styled.div`
  flex: 0 0 auto;
  width: 33.33333333%;
  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
`
export const BackLay = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const BlBox = styled.div`
  background: ${color._66D1FF};
  width: 100%;
  padding: 0;
  text-align: center;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease 0s;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50px;
  border-left: 1px solid ${color._F8F8F8};
  font-family: 'Titillium Web', sans-serif;
  @media (max-width: 991px) {
    height: 28px;
    font-size: 14px;
    font-weight: 700;
  }
`
export const BlBoxLay = styled.div`
  background: ${color._FFB4C5};
  width: 100%;
  padding: 0;
  text-align: center;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease 0s;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50px;
  border-left: 1px solid ${color._F8F8F8};
  font-family: 'Titillium Web', sans-serif;
  @media (max-width: 991px) {
    height: 28px;
    font-size: 14px;
    font-weight: 700;
  }
`
export const Span = styled.span`
  display: block;
  line-height: 1.1;
`

export const Em = styled.em`
  display: block;
  font-style: normal;
  font-size: 11px;
  font-weight: 400;
  @media (max-width: 991px) {
    display: none;
  }
`
