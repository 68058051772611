export const sportsName = {
  home: 'HOME',
  inplay: 'IN-PLAY',
  cricket: 'CRICKET',
  football: 'FOOTBALL',
  tennis: 'TENNIS',
  politics: 'POLITICS',
  horseRacing: 'HORSE RACING',
  greyRacing: 'GREYHOUND RACING',
  kabaddi: 'KABADDI',
  casino: 'CASINO',
  sportsBook: 'SPORTS BOOK',
  intCasino: 'INT CASINO',
  binary: 'BINARY',
}
