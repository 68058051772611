import axios from 'axios'
import interceptor from '../redux/axios'

const handleErrorResponse = (error) => {
  throw new Error(
    error.response
      ? error.response.data.message
      : 'An error occurred during the request.',
  )
}

export const _get = async (endpoint, token) => {
  const headers = {
    authorizationToken: `Bearer  ${token}`,
  }
  try {
    const response = await interceptor.get(endpoint, { headers })
    return response
  } catch (error) {
    handleErrorResponse(error)
  }
}

export const _post = async (endpoint, data) => {
  try {
    const response = await interceptor.post(endpoint, data)

    return response
  } catch (error) {
    handleErrorResponse(error)
  }
}

export const _put = async (endpoint, data) => {
  try {
    const response = await interceptor.put(endpoint, data)
    return response
  } catch (error) {
    handleErrorResponse(error)
  }
}

export const _delete = async (endpoint) => {
  try {
    const response = await interceptor.delete(endpoint)
    return response
  } catch (error) {
    handleErrorResponse(error)
  }
}

// FOR 100 PANEL API's

export const _getPanel = async (endpoint, token) => {
  const headers = {
    authorizationToken: `Bearer rdToken ${token}`,
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL_100}/${endpoint}`,
      { headers },
    )
    return response.data
  } catch (error) {
    handleErrorResponse(error)
  }
}

export const _postPanel = async (endpoint, data, token) => {
  const headers = {
    authorizationToken: `Bearer rdToken ${token}`,
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL_100}/${endpoint}`,
      data,
      { headers },
    )
    return response
  } catch (error) {
    handleErrorResponse(error)
  }
}
export const _putPanel = async (endpoint, data, token) => {
  const headers = {
    authorizationToken: `Bearer rdToken ${token}`,
  }
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL_100}/${endpoint}`,
      data,
      { headers },
    )
    return response
  } catch (error) {
    handleErrorResponse(error)
  }
}
