import React from 'react'
import {
  bottomCardData,
  containerType,
  middleContainerData,
  topContainerData,
} from './constant'
import * as Styled from './styles'
import { ReactComponent as Lock } from '../../../assets/svg/sports/lock.svg'
import { color } from '../../../color/color'

const DragonTiger = () => {
  return (
    <Styled.MainContainer className="container">
      {/* top container */}
      <Styled.BackgroundContainer className="row mb-3">
        {topContainerData.length &&
          topContainerData.map((col, index) => (
            <div key={index} className={`${col.colClass} text-center mt-2`}>
              {col.isDivider ? (
                <div className="d-flex">
                  <div className="col-1">
                    <Styled.Divider />
                  </div>
                  <Styled.Col11 className="col-11">
                    <Styled.MidSpan>{col.span}</Styled.MidSpan>
                    {col.containerText.isSuspended ? (
                      <Styled.MidSuspendedContainer>
                        <div
                          className="position-relative"
                          style={{ height: '100%' }}
                        >
                          <Styled.SuspendedContainer>
                            <Lock fill={color._fff} />
                          </Styled.SuspendedContainer>
                          <div> {col.containerText.label}</div>
                        </div>
                      </Styled.MidSuspendedContainer>
                    ) : (
                      <Styled.MidContainer>
                        {col.containerText.label}
                      </Styled.MidContainer>
                    )}
                  </Styled.Col11>
                </div>
              ) : (
                <>
                  <Styled.MidSpan>{col.span}</Styled.MidSpan>
                  {col.containerType === containerType.midContainer && (
                    <Styled.MidContainer>
                      {col.containerText.label}
                    </Styled.MidContainer>
                  )}
                  {col.containerType === containerType.suitedContainer && (
                    <Styled.SuitedContainer>
                      {col.containerText.label}
                    </Styled.SuitedContainer>
                  )}
                </>
              )}
            </div>
          ))}
      </Styled.BackgroundContainer>
      {/* center container */}
      <Styled.BackgroundContainer className="row mb-3 mt-3">
        {middleContainerData?.map((item) => {
          return (
            <div className="col-3 text-center">
              <div>
                <Styled.MidSpan>{item.odd1}</Styled.MidSpan>
                {item.isSuspended ? (
                  <Styled.MidSuspendedContainer>
                    <div
                      className="position-relative"
                      style={{ height: '100%' }}
                    >
                      <Styled.SuspendedContainer>
                        <Lock fill={color._fff} />
                      </Styled.SuspendedContainer>
                      <div> {item.btn1text}</div>
                    </div>
                  </Styled.MidSuspendedContainer>
                ) : (
                  <Styled.MidContainer>{item.btn1text}</Styled.MidContainer>
                )}
              </div>
              <div className="mt-4 mb-2">
                <Styled.MidSpan>{item.odd2}</Styled.MidSpan>
                {item.isSuspended ? (
                  <div className="position-relative text-center">
                    <Styled.SuspendedContainer>
                      <Lock fill={color._fff} />
                    </Styled.SuspendedContainer>
                    <Styled.ImageSuspendedContainer>
                      <Styled.Image src={item.image1} />
                      <Styled.Image src={item.image2} />
                    </Styled.ImageSuspendedContainer>
                  </div>
                ) : (
                  <Styled.ImageContainer>
                    <Styled.Image src={item.image1} />
                    <Styled.Image src={item.image2} />
                  </Styled.ImageContainer>
                )}
              </div>
            </div>
          )
        })}
      </Styled.BackgroundContainer>
      {/* bottom container */}

      <div className="row mb-3">
        {bottomCardData?.map((section, index) => {
          const ColContainer = section.colContainer
          return (
            <ColContainer key={index} className="col-6 text-center">
              <Styled.BackgroundContainer className="px-4 pt-3 pb-3">
                <Styled.MidSpan className="mb-3 mt-2">
                  {section.title}
                </Styled.MidSpan>
                <div className="row">
                  {section.rows?.map((row, rowIndex) => (
                    <div key={rowIndex} className="col-12 pt-2">
                      <div className={row.className}>
                        {row.cards?.map((card) => (
                          <div>
                            {card.isSuspended ? (
                              <div className="position-relative text-center">
                                <Styled.SuspendedContainer>
                                  <Lock fill={color._fff} />
                                </Styled.SuspendedContainer>
                                <Styled.CardImage
                                  src={card.src}
                                  alt={card.alt}
                                  className="img-fluid"
                                />
                              </div>
                            ) : (
                              <Styled.CardImage
                                src={card.src}
                                alt={card.alt}
                                className="img-fluid"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Styled.BackgroundContainer>
            </ColContainer>
          )
        })}
      </div>
    </Styled.MainContainer>
  )
}

export default DragonTiger
