import styled from 'styled-components'
import { color } from '../../color/color'

export const ModalInnerDiv = styled.div`
  background-color: ${color._005098};
  position: relative;
  width: 100%;
  height: auto;

  & .close-icon {
    position: absolute;
    right: -20px;
    top: 5px;
    cursor: pointer;
    color: ${color._FFFFFF};
    z-index: 9999;
  }

  @media (max-width: 993px) {
    & .close-icon {
      right: 5px;
    }
  }
`

export const Modal = styled.div``
