import { useEffect, useState } from 'react'
import * as Styled from '../styles'
import ReactPlayer from 'react-player'

const DynamicImages = ({ images = [] }) => {
  return images.map((image) => {
    return (
      <img
        src={image.src}
        alt="img-dynamic"
        style={{ maxWidth: '25px', maxHeight: '25px' }}
      />
    )
  })
}

const CountdownTimer = ({ initialSeconds }) => {
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds)

  useEffect(() => {
    let interval = null
    if (secondsLeft > 0) {
      interval = setInterval(() => {
        setSecondsLeft((prev) => prev - 1)
      }, 1000)
    } else if (secondsLeft === 0) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [secondsLeft])

  return <div>{secondsLeft}</div>
}

const VideoPlayer = ({
  videoUrl,
  images,
  showImage = true,
  showTimer = true,
}) => {
  return (
    <Styled.VideoContainer>
      {showImage && (
        <Styled.DynamicImageContainer>
          <DynamicImages images={images} />
        </Styled.DynamicImageContainer>
      )}

      {/* <ReactPlayer
        url={videoUrl}
        width={'100%'}
        controls={true}
        playing={true}
        loop={true}
        playsinline={true}
        volume={0.5}
        muted={true}
      /> */}
      <iframe src={videoUrl}></iframe>
      {showTimer && (
        <Styled.CountdownTimerContainer>
          <CountdownTimer initialSeconds={25} />
        </Styled.CountdownTimerContainer>
      )}
    </Styled.VideoContainer>
  )
}

export default VideoPlayer
