import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import * as Styled from '../style'
export default function AccordionFunc({
  title,
  iconSrc,
  altIcon,
  showIcon,
  bgColor,
  titleBottom = false,
  detailsBackgroundColor = false,
  children,
  defaultExpanded = false,
}) {
  return (
    <Styled.AccordianDiv bgcolor={bgColor}>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordian"
        >
          {showIcon && (
            <Styled.TitleIcon>
              <Styled.TitleImg src={iconSrc} alt={altIcon} />
            </Styled.TitleIcon>
          )}
          <Styled.AccTitle titlebottom={titleBottom}>{title}</Styled.AccTitle>
        </AccordionSummary>
        <AccordionDetails
          className={detailsBackgroundColor ? 'accordianDetails' : ''}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Styled.AccordianDiv>
  )
}
