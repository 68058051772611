import styled, { keyframes } from 'styled-components'
import { color } from '../../color/color'

const marqueeAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`

export const Container = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  background-color: ${({ bgColor }) => bgColor || ''};
`

export const Span = styled.span`
  display: inline-block;
  animation: ${marqueeAnimation} ${({ time }) => time} linear infinite;
  font-size: 12px;
  font-weight: 700;
  color: ${({ fontColor }) => fontColor || color._000};
  padding: 10px 10px 10px 100%;
`
