import React, { useEffect, useRef, useState } from 'react'
import AccountInfoData from '../../container/AccountInfoData/AccountInfoData'
import { ProfitAndLossTableHeader, constants } from '../../utils/constant'
import Layout from '../../Layout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAccountStatement } from '../../redux/auth/action'
import Loader from '../../components/Loader/Loader'
const ProfitLoss = () => {
  const dispatch = useDispatch()
  const avoidRerender = useRef(false)
  const token = useSelector((state) => state.auth.token)
  const profitLoss = useSelector((state) => state.auth.accountStatementData)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!avoidRerender.current) {
      dispatch(fetchAccountStatement(token, true)).finally(() => {
        setLoading(false)
      })
    }
    return () => (avoidRerender.current = true)
  }, [dispatch, token])

  return (
    <>
      <Layout>
        {loading ? (
          <Loader />
        ) : (
          <AccountInfoData
            heading={constants.Profit_Loss_Heading}
            btnName={constants.Submit}
            tableHeader={ProfitAndLossTableHeader}
            tableBody={profitLoss}
            isProfitLoss={true}
          />
        )}
      </Layout>
    </>
  )
}

export default ProfitLoss
